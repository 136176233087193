import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
// import TopHeader from "./components/TopHeader";
// import "../AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
// import LibraryNavBar from "./components/LibraryNavBar";
import {Link, useParams, useNavigate} from "react-router-dom";

const ViewProfile = () => {
    let navigate = useNavigate();

    let {id} = useParams();
    // console.log(id);
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="View Faculty's Profile" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Faculty's Profile"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className={"row"}>
                        <div className={"text-right"}>
                            {/*<Link to="/Library"></Link>*/}
                            {/*<a href={"Library"}><button className={"btn btn-theme-colored2"}> <span className={"fa fa-backward"}></span> Back</button></a>*/}
                        </div>
                    </div>
                    {
                        id === "1" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/LibPrabhjot.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Prabhjot Kaur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>S. Joginder Singh Bajwa</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>02/07/1966</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address :</b></td>
                                        <td className={"text-center"}>Riar House, Civil Lines, Gurdaspur - 143 521</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 9855434404</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>prabhriar@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Assistant Librarian</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Library</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>M.A., M.Lib. I.Sc.</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>24/03/2002</td>
                                        <th className={"text-center"}>Now</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Assistant Librarian</th>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>06/11/1995</td>
                                        <th className={"text-center"}>23/03/2002</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Assistant Librarian</th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {
                        id === "2" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/LibNavdeep.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Dr Navdeep Singh Rajput</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Sukhdev Singh Rajput</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>30/06/1978</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address :</b></td>
                                        <td className={"text-center"}>Vill. Bhago Kawan, P.O Magar Mudian, Distt. Gsp.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 8146983366</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>rajputnavdeep27@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Library Assistant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Library</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Phd (GNDU) M.A. Public Adm.; M.Lib & Info.Sc.</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>27/03/2002</td>
                                        <th className={"text-center"}>Now</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Library Assistant</th>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {
                        id === "3" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/LibNirmal.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Nirmal Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>S. Gopal Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>13/12/1968</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill. & PO Kala Nangal, Distt. Gurdaspur. Punjab
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>09876806082; 01874 - 643681</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Library Attendant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Library</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>10+2, ITI</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>01/11/2002</td>
                                        <th className={"text-center"}>Now</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Library Attendant</th>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "4" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/Arpinder.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Arpinder Singh Sandhu</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Joginder Singh Sandhu</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>29/04/1963</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>H.No 6,Type 5, BCET Staff Colony,Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>01874-210355, 9646020532</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>singh_arpinder@yahoo.com,
                                            singh_arpinder@rediffmail.com
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Associate Professor</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Library</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>ME(Computer Science Engineering)</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    }
                    {
                        id === "5" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/Deepak.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Deepak Kaila</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Ramesh Kaila</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>25/10/1974</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Staff Colony, BCET Campus</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>9464548801</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>deepakkaila@bcetgsp.ac.in</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Asstt. Professor in Computer Practices</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Computer Centre</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>MCA</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>04/08/2010</td>
                                        <th className={"text-center"}>Now</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Assistant Professor in Computer Practices</th>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>30/10/2008</td>
                                        <th className={"text-center"}>03/08/2010</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Lecturer in Computer Practices</th>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>3</td>
                                        <td className={"text-center"}>21/6/2004</td>
                                        <th className={"text-center"}>29/10/2008</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Computer Operator</th>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "6" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/Mukhwinder.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Mukhwinder Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Hardial Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>07/04/1970</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill. Mustfabad Jattan P.O. Babehali,Teh. & Distt.
                                            Gsp.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 9464090076,+91 6284912781</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>mukhwindersingh@bcetgsp.ac.in</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Laboratory Attendant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Computer Centre</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Matric</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>1/1/2000</td>
                                        <th className={"text-center"}>Now</th>
                                        <th className={"text-center"}>BCET, Gurdaspur - 143 521</th>
                                        <th className={"text-center"}>Maint. Attdt.</th>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>1/1/1997</td>
                                        <th className={"text-center"}>31/12/1999</th>
                                        <th className={"text-center"}>NBCC BCET Gurdaspur</th>
                                        <th className={"text-center"}>Maint. Attdt.</th>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "7" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/Sandeep.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Sandeep Mahajan</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Dwarka Nath</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>xxxxxxxxxxx</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>38-II-B, Staff Colony SBSSU Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>08146814488</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>sandeepmahajan@bcetgsp.ac.in</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Laboratory Attendant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Computer Centre</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>xxxxxxxxxxxxxx</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "8" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkSurinder.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Surinder Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>S. Kabal Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>7/5/1965</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>BCET Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 9914039328</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>singh_surinder1699@yahoo.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Lecturer W/Shop Practices</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>B.Tech.(Mechanical Engg.)</td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-justify"}>Sr. No.</th>
                                        <th className={"text-justify"}>From</th>
                                        <th className={"text-justify"}>Till</th>
                                        <th className={"text-justify"}>Name and Address of Organisation</th>
                                        <th className={"text-justify"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>30/12/08</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>Beant College of Engg. & Tech. Gurdaspur</td>
                                        <td className={"text-center"}>Lecturer in W/Shop Practices</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>2/11/1995</td>
                                        <td className={"text-center"}>29/12/08</td>
                                        <td className={"text-center"}>B.C.E.T., Gurdaspur</td>
                                        <td className={"text-center"}>LecW/Shop Instructor
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>3</td>
                                        <td className={"text-center"}>19/2/1990</td>
                                        <td className={"text-center"}>1/11/1995</td>
                                        <td className={"text-center"}>GTB Khalsa Poly. Chappianwali (Malout)</td>
                                        <td className={"text-center"}>W/Shop Instructor</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    }
                    {
                        id === "9" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkAvtar.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Avtar Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 9464448770</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>sainigs.1965@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Lecturer W/Shop Practices</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>B.Tech (Mechanical Engg.)</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>30/12/08</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur - 143 521</td>
                                        <td className={"text-center"}>Lecturer in W/Shop Practices</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>16/11/1995</td>
                                        <td className={"text-center"}>29/12/08</td>
                                        <td className={"text-center"}>NBCC BCET Gurdaspur</td>
                                        <td className={"text-center"}>W/Shop Instructor</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>3</td>
                                        <td className={"text-center"}>18/10/1991</td>
                                        <td className={"text-center"}>15/11/1995</td>
                                        <td className={"text-center"}>SLIET,Longowal Distt. Sangrur (Pb)</td>
                                        <td className={"text-center"}>Technician Welding</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {
                        id === "10" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkAvtar.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Avtar Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Resham singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>5/7/1973</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Bcet , Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 9855023926</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>avtarsbanwait@yahoo.co.in</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Workshop Instructor</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>ME (Mechanical)</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>Aug. 1992</td>
                                        <td className={"text-center"}>March 2002</td>
                                        <td className={"text-center"}>SONALIKA group of Industries and GNA group of
                                            industries Hoshiarpur
                                        </td>
                                        <td className={"text-center"}>Astt. Engineer</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>-4-2002</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET , gurdaspur</td>
                                        <td className={"text-center"}>W/Shop Instructor</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {
                        id === "11" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkKuldip.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Kuldip Lal</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Ram Prakash</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>10/08/1965</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>VPO Haryana</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 01886250589</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>workshop instructor</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>matric ITI & Two years Appreniceship in PRH and
                                            six month training in forging Technology, CIHT,Jalandhar
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>27-3-2002</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur</td>
                                        <td className={"text-center"}>W/Shop Instructor</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>27-3-2002</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur</td>
                                        <td className={"text-center"}>W/Shop Instructor</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "12" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkBalwant.png"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Balwant Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Late Sh. Rasalu Ram</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>15/4/1971</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>H.No 26A, Khalsa Avenue, Amritsar</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91 9855341009</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>balwant2cool@gmail.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Foreman</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Diploma in Mech. Engg.</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>18/4/2002</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur</td>
                                        <td className={"text-center"}>W/Shop Instructor</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>1/4/1995</td>
                                        <td className={"text-center"}>17/11/2000</td>
                                        <td className={"text-center"}>Leader Valves Ltd ,Jalandhar</td>
                                        <td className={"text-center"}>Engineer</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>3</td>
                                        <td className={"text-center"}>15/6/1993</td>
                                        <td className={"text-center"}>7/4/1994</td>
                                        <td className={"text-center"}>Bestavision Electronics Ltd Ludhiana</td>
                                        <td className={"text-center"}>Tr.Supervisor</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>4</td>
                                        <td className={"text-center"}>9/9/1991</td>
                                        <td className={"text-center"}>10/6/1993</td>
                                        <td className={"text-center"}>Veer Industrial Corporation Ldh.</td>
                                        <td className={"text-center"}>Inspector</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "13" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkChiranji.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Chiranji Lal</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh.Rattan Lal</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>6/12/1961</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Q.no.Type IV BCET Gsp.</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>0186-2243631</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Skilled Assistant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Matric ,ITI</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>25/10/1995</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur</td>
                                        <td className={"text-center"}>Skilled Asstt.</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>10/03/1986</td>
                                        <td className={"text-center"}>12/09/1995</td>
                                        <td className={"text-center"}>Emson Sales D-2 Focal Point Ludhiana</td>
                                        <td className={"text-center"}>Machinst (CNC operator)</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>3</td>
                                        <td className={"text-center"}>1/02/1983</td>
                                        <td className={"text-center"}>13/12/1984</td>
                                        <td className={"text-center"}>M/s Harig India Ltd, Mohan Nagar Gaziabad UP</td>
                                        <td className={"text-center"}>Machinist</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>4</td>
                                        <td className={"text-center"}>8.2.1982</td>
                                        <td className={"text-center"}>31/1/1983</td>
                                        <td className={"text-center"}>M/s Harig India Ltd, Mohan Nagar Gaziabad UP</td>
                                        <td className={"text-center"}>Apprentiship</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        id === "14" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkInderjit.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Inderjit Kumar</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Karam Chand</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>7/5/1975</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill Ramnagar PO & Distt Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>9463715592</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Skilled Assistant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Matric ,ITI</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>25/10/1995</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur</td>
                                        <td className={"text-center"}>w/s attendent</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>Aug 1994</td>
                                        <td className={"text-center"}>Jan 1995</td>
                                        <td className={"text-center"}>Jaggi Trunk Hohse. Gsp.</td>
                                        <td className={"text-center"}>Trainee</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {
                        id === "15" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkNaresh.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Naresh Prabhakar</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Sohan Lal Sharma</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>26/1/1967</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>B XXIII/170 Inside Paharigate Btala Distt.Gsp</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>0187 1221441</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Skilled Assistant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>ITI</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={"row"}>
                                <div className={"col-lg-12 col-md-12"}>
                                    <h4 className="text-theme-colored2">Experience</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>From</th>
                                        <th className={"text-center"}>Till</th>
                                        <th className={"text-center"}>Name and Address of the organisation</th>
                                        <th className={"text-center"}>Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>31/10/1995</td>
                                        <td className={"text-center"}>Now</td>
                                        <td className={"text-center"}>BCET, Gurdaspur</td>
                                        <td className={"text-center"}>Skilled assistant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>01/05/1989</td>
                                        <td className={"text-center"}>30/04/1995</td>
                                        <td className={"text-center"}>Aver Welding Works</td>
                                        <td className={"text-center"}>Welder</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }

                    {
                        id === "16" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkRam.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Ram Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>H/Capt. Bharat Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>14/8/1967</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill Ghot Kalan P/O Bhattian Distt. Gsp</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>01874244389, 9815276130</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Technical Grade-II</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Central Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>+2,ITI,CTI, Diploma in Mech</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    }
                    {
                        id === "17" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkAjmer.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Ajmer Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh Rachhpal Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>1/4/1975</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vil. Bhulla PO Dorangla Distt. Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>9417947938</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>ajmer_sanju@rediffmail.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Skilled Assistant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>10+2,ITI.CTI</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>25.10.95</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>w/s attendent</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>01.08.1994</td>
                                            <td className={"text-center"}>01.06.1995</td>
                                            <td className={"text-center"}>L.S.Dhiman Industries,Kot mangal Singh
                                                Ludhiana
                                            </td>
                                            <td className={"text-center"}>Milling man</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }

                    {
                        id === "18" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkSubash.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Subash Chandar</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Karam Chand</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>13/4/1962</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Prem Nagar Near Happy School College Road
                                            Gurdaspur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>9417237300</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Technical Grade-II</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>10th, ITI, Arts & Crafts Teacher Training course
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>25.10.95</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>Skilled Asstt.</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>Jan 1991</td>
                                            <td className={"text-center"}>20.6.1995</td>
                                            <td className={"text-center"}>Ramgaria Furniture House Pathankot</td>
                                            <td className={"text-center"}>worker</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        id === "19" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkVikram.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Vikramjit Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Bachan Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>1/9/1974</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill Magrala PO Dinanagar Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>+91-9041181216</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}>mahavir_god@yahoo.com</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Technical Grade-II</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Matric, 10+2 vocational, Diploma Mech. Engg</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>28 oct-1995</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>Technician Grade II</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>1992</td>
                                            <td className={"text-center"}>2016</td>
                                            <td className={"text-center"}>Guru Nanak Industry And Presently Beant
                                                College Of Engg. &Tech Gurdaspur
                                            </td>
                                            <td className={"text-center"}>Technician Grade II</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3</td>
                                            <td className={"text-center"}>April 1992</td>
                                            <td className={"text-center"}>Sept. 1995</td>
                                            <td className={"text-center"}>Guru Nanak Industry Batala</td>
                                            <td className={"text-center"}>Asstt. Trainee</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        id === "20" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkArvinder.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Arvinder Kumar</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Garib Dass</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>12/4/1997</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>VPO Babeali Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>018744320115</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Workshop Attendant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>10+2, ITI</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>01.11.2002</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>w/s Attendent</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>20.8.95</td>
                                            <td className={"text-center"}>31.10.2002</td>
                                            <td className={"text-center"}>B.C.E.T Gurdasur</td>
                                            <td className={"text-center"}>w/s attendent</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        id === "21" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkSukhdev.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Sukhdev Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>SH. Piara Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>18/3/1967</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>VPO Sahowal Distt. Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>01874254755</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Workshop Attendant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}> ITI</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>27.10.1995</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>w/s Attendent</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>15.4.1994</td>
                                            <td className={"text-center"}>04.3.1995</td>
                                            <td className={"text-center"}>Northland Sufar Complex Dasua</td>
                                            <td className={"text-center"}>Asst. Carpenter</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        id === "22" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkRai.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Rai Bahadur Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>S. Ajaib Singh</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>20-05-1980</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill.: Ghesal P.O.:Jangal Teh. & Distt.
                                            Gurdaspur
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}>9464427012</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Workshop Attendant</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>I.T.I and APP, in Welding Trade</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>16.01.2009</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>w/s Attendent</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>15.09.2007</td>
                                            <td className={"text-center"}>27.11.2009</td>
                                            <td className={"text-center"}>Aman Bhalla Polytechnic College(Kotle
                                                Pathankot ).
                                            </td>
                                            <td className={"text-center"}>Welding Instructor</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3</td>
                                            <td className={"text-center"}>01.05. 2004</td>
                                            <td className={"text-center"}>28.02.2006</td>
                                            <td className={"text-center"}>Gurusimran Auto Indus. (Ludh.)</td>
                                            <td className={"text-center"}>Welder</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>4</td>
                                            <td className={"text-center"}>01.01. 2001</td>
                                            <td className={"text-center"}>28.02. 2003</td>
                                            <td className={"text-center"}>ARC Indian Pvt.Ltd.(Delhi)</td>
                                            <td className={"text-center"}>Welder</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }
                    {
                        id === "23" &&
                        <div className={"row"}>

                            <div className={"col-lg-2 col-md-2"}>
                                <img id={"libFac"} src={"../assets/images/faculty/WorkTarsem.jpg"}
                                     className={"img-responsive"}/>
                            </div>
                            <div className={"col-lg-8 col-md-8"}>
                                <table className={"table table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}><b>Name:</b></td>
                                        <td className={"text-center"}>Tarsem Masih</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Father's Name:</b></td>
                                        <td className={"text-center"}>Sh. Thura Masih</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Date of Birth :</b></td>
                                        <td className={"text-center"}>15-01-1968</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Address:</b></td>
                                        <td className={"text-center"}>Vill. & P.O Sahowal, Distt. Gurdaspur</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Contact No.:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Email:</b></td>
                                        <td className={"text-center"}></td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Designation:</b></td>
                                        <td className={"text-center"}>Peon</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Department:</b></td>
                                        <td className={"text-center"}>Workshop</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}><b>Qualification:</b></td>
                                        <td className={"text-center"}>Matric</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className={"row"}>
                                    <div className={"col-lg-12 col-md-12"}>
                                        <h4 className="text-theme-colored2">Experience</h4>
                                        <div className="double-line-bottom-theme-colored-2"></div>
                                    </div>
                                    <table className={"table table-responsive"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>From</th>
                                            <th className={"text-center"}>Till</th>
                                            <th className={"text-center"}>Name and Address of the organisation</th>
                                            <th className={"text-center"}>Designation</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>30-10-2009</td>
                                            <td className={"text-center"}>till date</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>Peon on contract basis</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>01-04-1996</td>
                                            <td className={"text-center"}>29-10-2009</td>
                                            <td className={"text-center"}>BCET, Gurdaspur</td>
                                            <td className={"text-center"}>Gardening/ Bus conductor under contracter</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }

                    {id > "23" && navigate("/")}

                </div>
            </div>

            <Footer/>
        </>
    );
}

export default ViewProfile;