import axios from "axios";
import {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import Footer from "./components/Footer";
import NavBar2 from "./components/NavBar2";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {URL} from "./components/MyModules";


function NoticeBoard() {
    let navigate = useNavigate();
    let {id} = useParams();
    // console.log(id);

    let [notice, setNotice] = useState([]);
    let [title, setTitle] = useState('');
    let [description, setDescription] = useState('');

    useEffect(() => {
        axios.post(`${URL}getNoticeByID`, {
            noticeid: id
        }).then(async res => {
            // console.log(res.data.data);
            if (res.data.data.length > 0) {
                await setTitle(res.data.data[0].title);
                await setDescription(decodeURIComponent(res.data.data[0].description));
            } else {
                navigate("/");
            }
        }).catch(err => {
            console.log("Data Not Fetched");
        });
    }, []);

    function createMarkup() {
        return {__html: description}
    }

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Notice Board" subTitle="Notice Board" bdLink="Notice Board"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font"}>{title}</h3>
                    <div className="double-line-bottom-theme-colored-2"></div>
                    <div dangerouslySetInnerHTML={createMarkup()}/>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default NoticeBoard;