import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import FacultyCard from "../components/FacultyCard";
import axios from "axios";
import {URL} from "../components/MyModules";

const DeptElectronicsCommEng = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(4);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' ******')
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Electronics & Communication Engineering "
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Electronics & Communication Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/*from file*/}
                                    Department of Electronics & Communication Engineering is one of the oldest
                                    departments in the university. The department has state of art infrastructure and
                                    highly qualified and experienced faculty. All the labs of the department are well
                                    equipped and even have equipments beyond curriculum for learning of students. Highly
                                    qualified faculty is the major strength of the department, which focuses on
                                    stimulating talent to groom them in meeting global challenges of the industry. The
                                    faculty lays emphasis on both theory and practical aspects, and prepares students
                                    for challenging scenarios. The faculty is actively involved in the research
                                    activities with more than 150 research papers published in the reputed
                                    international/national journals and conferences.<br/>
                                </p>
                                <p className="font-p-v m-7-v">
                                    {/*from oldweb*/}
                                    The Department has well qualified faculty & state of the art infrastructure
                                    facilities. The all round development & competence of students is ensured by the
                                    balance exposure to modern class room Teaching, advanced Software & Hardware
                                    Technology in the Labs of the Department. The emphasis of the entire programme is to
                                    enable the students to take up more challenging assignments in their organizations
                                    on completion of these courses.</p>

                            </div>
                            <div className="col-sm-5 padd-10-v">
                                <img src="assets/images/faculty/HOD_ElecComm_Ajay_Kumar.jpg"
                                     className="img-responsive HOD-image-v" alt=""/>
                                <p className="text-left-10 text-theme-colored2"><strong> HOD, Department : Dr Ajay
                                    Kumar</strong></p></div>
                        </div>
                        {/*<div>*/}
                        {/*    <p className="font-p-v m-7-v">*/}
                        {/*        /!*from oldweb*!/*/}
                        {/*        The department comprises multi-disciplinary faculty members, at present, mainly related*/}
                        {/*        to Physics, Chemistry, Mathematics, Humanities (Communication English) and Management*/}
                        {/*        background to cater the needs of multi-disciplinary students at B.C.E.T. Campus,*/}
                        {/*        Gurdaspur. Our faculty members and scholars provide a home to the most promising*/}
                        {/*        doctoral and post-doctoral research with an access to educational/Research Laboratory*/}
                        {/*        resources and unparalleled laboratory and library facilities for both Basic/Applied and*/}
                        {/*        Engineering research. Growing steadily, today the department not only teaches various*/}
                        {/*        topics in B.Tech. and M.Tech. courses, but also runs its own M.Sc. Courses in Applied*/}
                        {/*        Mathematics and Applied Physics. Besides the Central Computing facilities of the*/}
                        {/*        Institute, the department has its own Numerical Analysis Lab. The Department is engaged*/}
                        {/*        in providing highest level and quality of academic education. The main focus of the*/}
                        {/*        teaching and research in the department is centered on interdisciplinary themes. The*/}
                        {/*        students are provided a comprehensive practical exposure to the various instrumental*/}
                        {/*        techniques required for their further education.</p>*/}

                        {/*</div>*/}
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechECE">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/4.png" className="Image_A"
                                             alt="Btech ECE"/>
                                    </div>
                                    <p className="myColor_A">B. Tech. Electronics & Communication Engineering(ECE)</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechEE">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/3.png" className="Image_A"
                                             alt="Electrical Engineering(EE)"/>
                                    </div>
                                    <p className="myColor_A">B. Tech. Electrical Engineering(EE)</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/PhdECE">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/25.png" className="Image_A"
                                             alt="Ph.D (Electronics & Communication Engineering)"/>
                                    </div>
                                    <p className="myColor_A">Ph.D (Electronics & Communication Engineering)</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptElectronicsCommEng;