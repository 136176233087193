import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useForm} from "react-hook-form"; // React Hook Form

// import {CounsellingContext} from "../App";

import PreRegisInstructions from "./PreRegisInstructions";
import PreRegForm from "./PreRegForm";
import NavBar2 from "../../pages/components/NavBar2";
import BreadCrumbSection from "../../pages/components/BreadCrumbSection";
import Footer from "../../pages/components/Footer";
import {URL} from "../../pages/components/MyModules";

const PreRegistration = () => {
    const [degreeType, setDegreeType] = useState([]);

    // Fetch Degrees From DB
    const GetDegreeList = () => {
        axios.get(`${URL}getDegreeList`).then(res => {
            // console.log(res.data.data);
            setDegreeType(res.data.data);

            // let filterDegree = res.data.data.filter(x => x.degree_name === "Under Graduate" || x.degree_name === "Post Graduate");
            // console.log(filterDegree);
            // setDegreeType(filterDegree);
        }).catch(err => {
            console.log(err);
        });
    }

    useEffect(() => {
        document.title = "Pre-Registration 2024 | SBSSU";
        GetDegreeList(); // Call Function To Get Data from DB
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Registration 2024 | SBSSU" bdLink="Registration"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font"}>
                        Registration
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="container">
                        <div className="big-title text-center"></div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="defaultform contact_form alert"
                                     style={{
                                         border: '50px',
                                         backgroundColor: 'linear-gradient(315deg, #cacaca, #f0f0f0)',
                                         boxShadow: '-20px -20px 60px #bebebe, 20px 20px 60px #ffffff'
                                     }}>

                                    {/* -- INSTRUCTIONS -- */}
                                    {/*<PreRegisInstructions/>*/}

                                    {/* -- FORM -- */}
                                    <PreRegForm degreeType={degreeType}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="section"></div>*/}

            {/*  FOOTER COMPONENT  */}
            <Footer/>
        </>
    )
}

export default PreRegistration;