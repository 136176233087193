import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const PhdBioTech = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Doctor of Philosophy Courses" subTitle="Phd(CSE)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Doctor of Philosophy: Chemical Engineering &amp; Bio
                            Technology
                        </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The Department of Chemical Engineering &amp; Bio Technology at Sardar Beant Singh
                                    State University, Gurdaspur Punjab offer admission to its Ph.D program in discipline
                                    of

                                    <ul>
                                        <li>Chemical Engineering</li>
                                        <li>Bio Technology</li>
                                    </ul>
                                </p>
                                <p className=" font-p-v m-7-v">
                                   The department is also engaged in interdisciplinary work with research groups
                                    spanning across all the department at S.B S State University and outside S.B S State
                                    University as well through collaboration.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">

                                <p className="font-p-v">
                                    <h4 className="text-theme-colored2">Research Areas</h4>
                                    <ul>
                                        <li>Environment Pollution</li>
                                        <li>Multiphase Flows</li>
                                        <li>Reactive Separations</li>
                                        <li>Renewable Energy</li>
                                        <li>Bioinformatics</li>
                                        <li>Biomass conversion</li>
                                        <li>Modelling and Simulation</li>
                                        <li>Process Control</li>
                                        <li>Computational Biology</li>
                                        <li>Biopolymer</li>
                                        <li>Catalyst synthesis and characterization</li>
                                    </ul>
                                </p>
                            </div>
                            <div className="col-md-4 text-center m-7-v">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/btc.jpg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                The candidates with minimum 55% marks (or an equivalent grade in a point scale
wherever grading system is followed) in Master&#39;s Degree (in Engineering/Technology
Science/Management/Computer application/Humanities and in those other areas, in
which University decides to undertake the curriculum) of SBSSU, Gurdaspur or an
equivalent degree of recognized Indian or Foreign University (recognized by
UGC/AIU/or any other approved and authorized statutory Assessment and
Accreditation agency) in the subject concerned/allied subject/cognate subject shall be
eligible.
                            </p>
                            <p className="font-p-v m-7-v">
                                Relaxation of 5% of marks, from 55% to 50%, or an equivalent relaxation of grade,
may be allowed for those belonging to SC/ST/OBC(non-creamy layer)/Differently-
abled and other categories of candidates as per the decision of UGC from time to
time, or for those who had obtained their Master&#39;s degree prior to 19th September,
1991. The eligibility marks of 55% (or an equivalent grade in a point scale wherever
grading system is followed) and the relaxation of 5% to the categories mentioned
above are permissible based only on the qualifying marks without including the grace
mark procedures. Candidates already having PhD in a particular faculty are eligible
only for a different subject/stream in the same faculty or in a different faculty as per
clause 1.0(i) above.
                            </p>
                            <p className="font-p-v m-7-v">
                                The candidates shall be admitted by the. University following a two stage process based
                                on qualifying
                                <ol className=" m-7-v">
                                    <li>PhD entrance test</li>
                                    <li>Subsequent interview</li>
                                </ol>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default PhdBioTech;