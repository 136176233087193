import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
// import TopHeader from "./components/TopHeader";
// import "../AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
// import LibraryNavBar from "./components/LibraryNavBar";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";

const Workshop = (props) => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Workshop" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Workshop"/>

            <div className="section3">
                <div className="container pt-90">

                    <div className="row">
                        <h1 className="text-theme-colored2">Central Workshop</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>


                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Introduction">

                            <div className="row">
                                <h4 className="text-theme-colored2">Introduction</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>


                            <div className={"row"}>
                                <div className={"col-lg-4 col-md-4 col-sm-12"}>
                                    <img id="rdgImg" src={"assets/images/Faculty/WorkSurinder.jpg"} className={"center-block img img img-responsive img-circle "}/>
                                    <p className={"text-center"}>

                                        <b> <ins>Coordinator of Workshop</ins> : Sh. Surinder Singh </b> <br/>
                                        <b> <ins>Email</ins>  : </b> singh_surinder1699@yahoo.com<br/>
                                    </p>d
                                </div>
                                <div className={"col-lg-8 col-md-8 col-sm-12"}>
                                    <h4 className={"h4 pt-90"}>Introduction</h4>
                                    <p className={"text-justify"}>
                                        Central Workshop is the common facility for all the branches and having most sophisticated machine tools & equipments. Besides important training work, central workshop extends services in the form of repair work to institute & hostels.
                                        Facility of making project work and working model is there for B.Tech/M.Tech students.
                                    </p>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey={2} title="Shops">
                            <div className={"container"}>
                                <div className="row">
                                    <h3 className="text-theme-colored2">Shops And Other Infrastructure</h3>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <div className="row">
                                    <h4 className="text-theme-colored2">Name of Shops/Labs</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>

                                </div>
                                <div className={"row"}>
                                    <div className={"col-lg-4 col-md-4"}></div>
                                    <div className={"col-lg-4 col-md-4"}>
                                        <div className={"col-lg-8 col-md-8"}></div>
                                        <div className={"col-lg-4 col-md-4"}>

                                            <a className={"text-right"} href={"https://view.officeapps.live.com/op/view.aspx?src=http%3A%2F%2Fwww.bcetgsp.ac.in%2Fdownload_pdf%2FLabsInfo%2Fworkshop%2FWorkshopPERFORMA35.xls&wdOrigin=BROWSELINK"} target={"_blank"}><button className={"btn btn-theme-colored2"}>
                                                <span className={"fa fa-arrow-circle-o-right"}></span> Click here for Equipment Detail(Proforma-I,II and III)</button></a>
                                        </div>
                                        <ul type={"square"}>
                                            <li>Fitting Shop</li>
                                            <li>Welding Shop</li>
                                            <li>Machine Shop</li>
                                            <li>Smithy Shop</li>
                                            <li>Foundry Shop</li>
                                            <li>Sheet Metal Shop</li>
                                            <li>Heat Treatment Shop</li>
                                            <li>Carpentry Shop</li>
                                            <li>Electrical Shop</li>
                                        </ul>
                                    </div>
                                </div>

                                <h4 className="text-theme-colored2">Equipments in Fitting Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Bench Grinder ¼ HP Ø 150 mm	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Drilling M/C Cap Ø 13 mm	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Surface Plate C.I ( 2′x2′)	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>


                                <h4 className="text-theme-colored2">Equipments in Welding Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Arc welding (500 Amp. & 300 Amps.)	</td>
                                                <td className={"text-center"}>02 Sets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Spot welding</td>
                                                <td className={"text-center"}>01 Sets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Oxy Acetylene gas welding	</td>
                                                <td className={"text-center"}>02 Set</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>MIG ( Metal Inert Gas ) welding M/C	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Portable welding M/C	</td>
                                                <td className={"text-center"}>02</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6</td>
                                                <td className={"text-center"}>T.I.G.Welding M/C	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>

                                <h4 className="text-theme-colored2">Equipments in Machine Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>HMT Lathes LB-17/1000	</td>
                                                <td className={"text-center"}>05 Nos.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Padministudentlathes</td>
                                                <td className={"text-center"}>05 Nos.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>UniversalMillingM/C	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>RadialDrillingM/C	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Tool&CutterGrinderM/CHMT	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6</td>
                                                <td className={"text-center"}>AllGearedShaperM</td>
                                                <td className={"text-center"}>02 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>7</td>
                                                <td className={"text-center"}>PillarDrillingM/C</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>8</td>
                                                <td className={"text-center"}>PowerHacksaw	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>9</td>
                                                <td className={"text-center"}>CapstanLathe</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>10</td>
                                                <td className={"text-center"}>PedestalGrindingM/C	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>11</td>
                                                <td className={"text-center"}>BenchGrinder</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>12</td>
                                                <td className={"text-center"}>CNCLatheTrainer	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>12</td>
                                                <td className={"text-center"}>CNCLatheTrainer	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>13</td>
                                                <td className={"text-center"}>CNCMillTainer</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>14</td>
                                                <td className={"text-center"}>PlannerM/C	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>15</td>
                                                <td className={"text-center"}>SlotterM/C	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>16</td>
                                                <td className={"text-center"}>Rurrut Lathe Machine</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>17</td>
                                                <td className={"text-center"}>3D Pantograph and Ingraving Machine	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>18</td>
                                                <td className={"text-center"}>Plastic Injection Moulding Machine	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>


                                <h4 className="text-theme-colored2">Equipments in Smithy Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Black Smith open hearth furnace	</td>
                                                <td className={"text-center"}>05 Nos.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Anvil Block with stand 100 kg.	</td>
                                                <td className={"text-center"}>04 Nos.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Swage Block		</td>
                                                <td className={"text-center"}>02 Nos.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>Power Hammer capacity 50 Kg	</td>
                                                <td className={"text-center"}>01 No.</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Others Smithy Hand Tools	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>

                                <h4 className="text-theme-colored2">Equipments in Foundry Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Moulding boxes and other moulding tools along with oil fired tilting furnace ( Size 20″x 20″ ) for casting of aluminum & other non- ferrous metals</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Moulding hardness tester, ′B′ scale range.	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Permeability tester with steel accessories.	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>Pit furnace	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Core drying oven.	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6</td>
                                                <td className={"text-center"}>Sand Muller Machines	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>7</td>
                                                <td className={"text-center"}>Sieve Shaker, with 12 pc sieves	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>8</td>
                                                <td className={"text-center"}>Direct Reading Moisture teller	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>9</td>
                                                <td className={"text-center"}>Universal Sand Strangth machine (Hyd type)	</td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>10</td>
                                                <td className={"text-center"}>Mini Cupola - Model VMC Capacity 1/4 ton with high pressure blower	</td>
                                                <td className={"text-center"}></td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>

                                <h4 className="text-theme-colored2">Equipments in Sheet Metal Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Universal cutting Machine 300 mm	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Bending & Folding Machine 36″	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Edge folding Machine	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>Shearing Machine	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Pipe bending Machine ( 2″)	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6</td>
                                                <td className={"text-center"}>Fly press	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>7</td>
                                                <td className={"text-center"}>Arbour	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>

                                <h4 className="text-theme-colored2">Equipments in Heat Treatment Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Oven for Core drying	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Binocular Research Metallurgical Microscope	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Carburising Furnace	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>Jomny End quench testing M/C	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Metallurgical Mounting press	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6</td>
                                                <td className={"text-center"}>Metallurgical Microscope Analyzer	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>7</td>
                                                <td className={"text-center"}>Rock Mill Hardness Testing Machine	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>8</td>
                                                <td className={"text-center"}>Muffle Furnance	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>9</td>
                                                <td className={"text-center"}>Metallurgical Liuisher Machine	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>10</td>
                                                <td className={"text-center"}>Abrasive Disc Cuttaff Machine	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                </div>


                                <h4 className="text-theme-colored2">Equipments in Carpentry Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Wooden Lathe	</td>
                                                <td className={"text-center"}>02</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Circular Saw	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Band Saw	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>Universal wood working M/C and others tools.</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <h4 className="text-theme-colored2">Equipments in Electrical Shop</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <thead className={"table-head-red-V"}>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of the Equipment</th>
                                            <th className={"text-center"}>Quantity</th>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1</td>
                                                <td className={"text-center"}>Single Phase transformer 1KVA	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2</td>
                                                <td className={"text-center"}>Voltmeter</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3</td>
                                                <td className={"text-center"}>Multimeter MY-68	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4</td>
                                                <td className={"text-center"}>Tong Tester MECO – MD- 2700

                                                </td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5</td>
                                                <td className={"text-center"}>Portable Drilling Machine ( 0-10mm)	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6</td>
                                                <td className={"text-center"}>Bench Vive	</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>



                            </div>

                        </Tab>

                        <Tab eventKey={3} title="Projects">
                            <div className="container">
                                <h4 className="text-theme-colored2">Projects/Grants</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <div className={"row"}>
                                    <div className={"col-lg-3 col-md-3"}></div>
                                    <div className={"col-lg-6 col-md-6"}>
                                        <table className={"table table-responsive"}>
                                            <tr>
                                                <th className={" text-center"}>Name of the Agency</th>
                                                <td className={"text-center"}>AICTE	</td>
                                            </tr>
                                            <tr>
                                                <th className={" text-center"}>Amount Sanctioned</th>
                                                <td className={"text-center"}>10 Lakhs</td>
                                            </tr>
                                            <tr>
                                                <th className={" text-center"}>Equipment Purchased</th>
                                                <td className={"text-center"}>CNC Lathe Trainer</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div className={"col-lg-3 col-md-3"}></div>
                                </div>
                            </div>

                        </Tab>

                        <Tab eventKey={4} title="R & D Facility">
                            <div className="row">
                                <h3 className="text-theme-colored2">R & D Facility/Consultancy</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <ul>
                                <li>CNC Trainer Lathe</li>
                                <li>CNC Mill Trainer</li>
                            </ul>

                        </Tab>

                        <Tab eventKey={5} title="Future Plans">
                            <div className="row">
                                <h3 className="text-theme-colored2">Future Plans</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <p className={"text-justify"}>
                                To start training for unemployed rural youth in the trade of carpentry, welding & sheet metal etc. to become entrepreneur. Further to introduce part time and regular CNC training for ITI, diploma pass students of the region.
                            </p>
                        </Tab>

                        <Tab eventKey={6} title="Workshop Faculty">
                            <div className="row">
                                <h3 className="text-theme-colored2">Workshop's Faculty</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkSurinder.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Surinder Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Lecturer W/Shop Practices</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9914039328</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>singh_surinder1699@yahoo.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/8"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkGurnam.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Gurnam Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Lecturer W/Shop Practices</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 9464448770</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>sainigs.1965@gmail.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/9"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkAvtar.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Avtar Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Workshop Instructor</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 9855023926</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>avtarsbanwait@yahoo.co.in</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/10"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkKuldip.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Kuldip Lal</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Workshop Instructor</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>01886250589</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/11"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkBalwant.png"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Balwant Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Foreman</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9855341009</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>balwant2cool@gmail.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/12"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkChiranji.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Chiranji Lal</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Skilled Assistant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>01862243631</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/13"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkInderjit.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Inderjit Kumar</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Skilled Assistant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9463715592</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/14"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>

                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkNaresh.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Naresh Prabhakar</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Skilled Assistant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9463715592</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/15"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkAjmer.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Ajmer Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Skilled Assistant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9417947938</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>ajmer_sanju@rediffmail.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/17"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkSubash.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Subash Chandar</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Technical Grade-II</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9417237300</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/18"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkVikram.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Vikramjit Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Technical Grade-II</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91-9041181216</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>mahavir_god@yahoo.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/19"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkArvinder.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Arvinder Kumar</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Workshop Attendant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>018744320115</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/20"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkSukhdev.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Sukhdev Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Workshop Attendant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>01874254755</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/21"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkRai.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Rai Bahadur Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Workshop Attendant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>9464427012</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/22"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/WorkTarsem.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Tarsem Masih</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Peon</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}></td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/23"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>

                            </div>
                        </Tab>

                    </Tabs>


                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Workshop;