import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const Bvoc = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Undergraduate Courses" subTitle="B.Voc" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Bachelor of Vocation: Refrigeration and Air
                            Conditioning</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">

                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    The department of mechanical engineering also offers three years course at
                                    undergraduate level leading to the award of B. Voc. in the discipline of
                                    Refrigeration & Air Conditioning. In this program, students will learn adequate
                                    skills required for a particular trade of Refrigeration and Air Conditioning. In
                                    this course there is flexibility of multiple exits. First exit is after one year as
                                    Diploma in Vocation (D. Voc). Second exit is after second year with Advance Diploma
                                    in Vocation (Ad. D. Voc.). And third exit after completion of three years with
                                    Bachelor in Vocation (B. Voc.).
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/bvoc.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed the 10+2 or its equivalent (e.g. 2 year ITI)
                                examination in any stream conducted by a recognized Board / University / Council.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 3 years long, with Six Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/academic/MechEng/BVoc/BVoc-RAC-Syllabus-2021-Batch-Onwards.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Bvoc;