import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function EsterSociety() {
    document.title = "Ester Society";

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Student's Corner" subTitle="ESTER" bdLink="ESTER"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">ESTER SOCIETY</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">

                            <div className="row">
                                <div className="col-lg-9"><p className="m-7-v font-p-v">
                                    ESTER is referred to as the Electronic Society for Technical Education and Research.
                                    Its
                                    objectives are scientific, literary, and educational in character. The society
                                    strives
                                    for the advancement of the theory and practise of electronics and communication
                                    engineering and the maintenance of a professional standing among its members, all in
                                    consonance with the special attention to such aims within the field of interest of
                                    the
                                    society as defined. The Society's aid in promoting close cooperation and exchange of
                                    technical information among its members and, to this end, shall hold meetings for
                                    the
                                    presentation of papers and their discussion; workshops; and seminars through its
                                    committees for the needs of its members.
                                </p></div>
                                <div className="col-lg-3">
                                    <img src="assets/images/logo/ester.jpg" alt="ESTER LOGO" className="faculty-img_V"/>
                                </div>
                            </div>


                            <h4 className="my-font-style text-theme-colored2">Membership:</h4>
                            <p className="font-p-v">Membership in the Society is available to all E.C.E. Department
                                students only who have a professional interest in any phase of the field of interest of
                                the Society. However, other department students can also join with permission of HOD
                                ECE. Affiliates may participate in the Society's activities, as organised by the
                                Society.</p>

                            <h4 className="my-font-style text-theme-colored2"> Field of Interest:</h4>
                            <p className="font-p-v">The society's field of interest is limited to applications of
                                electronics and communication engineering with additional knowledge to improve oneself
                                in this field. The field of interest of the society may be enlarged, reduced or shifted
                                moderately as the occasion requires.</p>

                            <br/>

                            <h4 className="my-font-style text-theme-colored2">Incharge ESTER :-</h4>

                            <span><b>Dr. Gurpadam Singh</b></span> <br/>
                            <span>Associate Professor, ECE</span> <br/>
                            <span>Contact No: 94641-06036 </span> <br/>
                            <span>Email ID: gurpadam@yahoo.com</span> <br/>
                        </div>

                        <div className="mb-35 text-justify">

                            <h1 className="text-theme-colored2 my-font-style">DETAIL OF EVENTS ORGANIZED BY ESTER
                                SOCIETY </h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <h4 className="my-font-style">The detail of the Students Technical
                                Activities from 2014-15 to 2019-20 are as mentioned below in the tabular form:
                            </h4>


                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V" >Sr.&nbsp;No. <br/><br/></th>
                                        <th className="table-title-V" >Name of Activity <br/></th>
                                        <th className="table-title-V text-center">Brief Description of Activity</th>
                                        <th className="table-title-V text-center"> Date of Activity and Time</th>
                                        <th className="table-title-V text-center"> Venue of activity</th>
                                        <th className="table-title-V text-center">Organized by ESTER Society members
                                        </th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>A Seminar on <br/> “Automation ”</td>
                                        <td align="center" valign="top">Technical Seminar</td>
                                        <td align="center" valign="top">10/2/2015,
                                            4.45PM-5.30PM <br/> (Tuesday)
                                        </td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Namita Sharma <br/>( URN
                                            1137443,8th Sem ECE)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td>A Seminar on <br/>“Smart&nbsp;Car&nbsp;Parking&nbsp;<br/>Automation”</td>
                                        <td align="center" valign="top">Technical
                                            Seminar
                                        </td>
                                        <td align="center" valign="top">5/9/2015
                                            3.00-4.00PM <br/> (Saturday)
                                        </td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Sukhwinder Singh <br/> ( URN 1303347, 5th Sem ECE)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td> Aptitude  Test <br/> “ TechnoQuiz ”</td>
                                        <td align="center" valign="top">Technical Quiz</td>
                                        <td align="center" valign="top">5/9/2015,
 4.00-5.00PM <br/>(Saturday)
</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Sahil (130335) <br/> Ketan Vij (1403349) <br/>  Saggi Jaswal(1303334)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td>Seminar on <br/>“Electronics <br/> Technologies“</td>
                                        <td align="center" valign="top">Technical Seminar</td>
                                        <td align="center" valign="top"> 19/10/2015 4.00-5.00PM <br/> (Monday)</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Rajnish Kumar (1403358) <br/> Ketan Vij (1403349) <br/> Amandeep Kumar(1403338) <br/>  Rajan Singh (1403357)   </td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>Seminar on <br/> “Cloud Computing“</td>
                                        <td align="center" valign="top">Technical Seminar</td>
                                        <td align="center" valign="top">5/11/2015 3.00-4.00PM <br/> (Thursday).</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Manaswi Kumar URN 1403295 <br/> (3rd  Sem ECE) </td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>Seminar on <br/>“Computer Networking“ </td>
                                        <td align="center" valign="top">Technical Seminar</td>
                                        <td align="center" valign="top">26/3/2016 4.00-5.00PM <br/> (Saturday)</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Mahan Raj URN 1237901 <br/> (8rd  Sem ECE)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td> Mind Sweeper </td>
                                        <td align="center" valign="top">Technical Quiz</td>
                                        <td align="center" valign="top">18/4/2016 4.00-5.00PM  <br/> (Monday).</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Srishti Sharma (URN 1303344) <br/> Chander Shekhar   (URN 1403266) <br/> Gurpreet Singh	(URN 1403277) <br/> Gurcharan Singh (URN 1403274)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td>Seminar on <br/>“OLEDs“</td>
                                        <td align="center" valign="top">Technical Seminar</td>
                                        <td align="center" valign="top">15/3/2018 4.00-5.00PM <br/> (Monday).</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Navjot Kaur URN 1601667 <br/>(5th  Sem ECE) </td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td>Seminar on <br/>"Smart&nbsp;phone<br/>Processors”</td>
                                        <td align="center" valign="top">Technical Seminar</td>
                                        <td align="center" valign="top"> 04/4/2019 4.20-5.00PM  <br/>(Thursday)</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Akash Sharma URN 1702069(ECE)<br/>  Nitin URN 1702091(ECE)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>10.</strong></td>
                                        <td>Quiz Competition </td>
                                        <td align="center" valign="top">Technical Quiz </td>
                                        <td align="center" valign="top">04/4/2019 4.00-4.20 PM <br/> (Thursday)</td>
                                        <td align="center" valign="top">ECE Deptt., BCET,GSP</td>
                                        <td align="center" valign="top">Nandeshwer Yadav(URN 1601665) <br/>  Navjot Kaur(URN 1601667)</td>
                                    </tr>
                                    <tr>
                                        <td><strong>11.</strong></td>
                                        <td>What is inside <br/> a Personal Computer? <br/> How to Use it for <br/> safe Browsing and  and <br/>  for Cyber Security</td>
                                        <td align="center" valign="top">Expert Lecture</td>
                                        <td align="center" valign="top">30-05-2020 11.30AM to 1.45PM</td>
                                        <td align="center" valign="top">Online</td>
                                        <td align="center" valign="top">Dr. Gurpadam Singh I/C ESTER Society</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
);
}

export default EsterSociety;
