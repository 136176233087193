import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Rti = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Right to Information" subTitle="
Information for Public Under Right to Information Act, 2005" bdLink="Right to Information"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Right to Information</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>


                        <p className="m-7-v text-justify">In compliance to section 4(1)(b) and 4(2) of the Right to the Information
                            Act,2005, the general public is hereby informed that Sardar Beant Singh State University,
                            Gurdaspur formerly known as Beant College of Engineering & Technology,Gurdaspur has been
                            established by Government of Punjab and registered as a Society under Societies Registration
                            Act, 1860. Its affairs are managed by the Board of Governors under the Chairmanship of
                            Honâ€™ble Technical Education Minister, Punjab.</p>

                        <p className="m-7-v text-justify">In compliance to section 4(1)(b) and 4(2) of the Right to the Information
                            Act,2005, the general public is hereby informed that Sardar Beant Singh State University,
                            Gurdaspur formerly known as Beant College of Engineering & Technology,Gurdaspur has been
                            established by Government of Punjab and registered as a Society under Societies Registration
                            Act, 1860. Its affairs are managed by the Board of Governors under the Chairmanship of
                            Hon’ble Technical Education Minister, Punjab.</p>

                        <p className="m-7-v text-justify">The University provides technical education for catering the needs of
                            technical manpower at the under graduate as well as post graduate level. At present, this
                            college imparts under graduate courses in six disciplines namely:</p>
                        <ul>
                            <li>Computer Science & Engineering</li>
                            <li>Electronics & Communication Engineering</li>
                            <li>Information Technology</li>
                            <li>Mechanical Engineering</li>
                            <li>Bio Technology</li>
                            <li>Civil Engineering</li>
                            <li>Chemical Engineering</li>
                        </ul>
                        <p className="m-7-v text-justify">
                            Post Graduate courses in three disciplines namely
                        </p>
                        <ul>
                            <li>MSc. (Physics)</li>
                            <li>MSc. (Chemistry)</li>
                            <li>Thermal Engineering sanctioned by AICTE. The college is affiliated to the Punjab
                                Technical University, Jalandhar.
                            </li>
                        </ul>
                        <p className="m-7-v text-justify">At present, Dr. Susheel Mittal, is the Vice Chancellor of the University.
                            Dr. Rajeev Bedi, Associate Professor is the Public Information Officer of the University.
                            The University has Department of Applied Sciences, Humanities & Management, Department of
                            Computer Science and Engineering, Department of Mechanical Engineering, Department of
                            Electronics & Communication Engineering, Department of Information Technology, Department of
                            Bio Technology and Department of Chemical Engineering besides Central Workshop and Computer
                            Centre.</p>
                        <p className="m-7-v text-justify">The University follows its own Bye- Laws in all service matters and
                            wherever, the Bye-Laws are silent, the Punjab Civil Service Rules are followed.</p>


                        <h4>The important contact numbers of the University are as under :</h4>
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name & Designation</th>
                            <th className="table-title-V">Contact No.</th>
                            <th className="table-title-V">E-mail Address</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Dr. Susheel Mittal, Vice Chancellor</td>
                                <td className="table-body-padd-V">01874-221463, 221464</td>
                                <td className="table-body-padd-V">vcsbssugsp@gmail.com</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Dr. Nripjit, Registrar</td>
                                <td className="table-body-padd-V">01874-221463</td>
                                <td className="table-body-padd-V">registrar@bcetgsp.ac.in</td>
                            </tr>
                            </tbody>
                        </table>
                        <p className="m-7-v">It is for the information of every faculty/staff member of the University
                            that the following Officers will be working as Public Information Officer/Assistant Public
                            Information Officer/Appellant Authority for the college under &quot;The Right To Information Act,
                            2005&quot;. A copy of Act has been sent to the Central Library and Departmental Libraries for the
                            information of every faculty/staff member.</p>
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V">Designation</th>
                            <th className="table-title-V">Office</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Dr. Susheel Mittal</td>
                                <td className="table-body-padd-V">Vice Chancellor</td>
                                <td className="table-body-padd-V">Appellant Authority</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Dr. Nripjit</td>
                                <td className="table-body-padd-V">Associate Professor</td>
                                <td className="table-body-padd-V">Public Information Officer</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Sh. Madan Chand</td>
                                <td className="table-body-padd-V">Supt Grade II</td>
                                <td className="table-body-padd-V">Asst. Public Information Officer</td>
                            </tr>
                            </tbody>
                        </table>

                        <div className="text-center margin-25-V">
                            Download the Right to Information Act
                            <a className="btn margin-x-25-v theme_btn_V" href="/assets/pdf/rti-act.pdf" target="_blank">RTI
                                Act,2005</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Rti;