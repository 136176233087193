import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const StaffResidence = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Staff Residence" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Staff Residence"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Staff Residence</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className={"row"}>
                        <p>The faculty and staff members have been provided residential accommodation in the institute
                            campus itself.
                            The details of the houses is as under:</p>
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-8 col-md-8 col-md-offset-2 col-lg-offset-2"}>
                            <table className={"table table-responsive table-bordered"}>
                                <thead className={"table-head-red-V "}>
                                <tr>
                                    <th className={" text-center"}>#</th>
                                    <th className={" text-center"}>Types of Houses</th>
                                    <th className={" text-center"}>No. of Houses</th>
                                </tr>
                                </thead>
                                <tr>
                                    <td className={" text-center"}>1</td>
                                    <td className={" text-center"}>Principal's Residence</td>
                                    <td className={" text-center"}>01</td>
                                </tr>
                                <tr>
                                    <td className={" text-center"}>2</td>
                                    <td className={" text-center"}>1800 sq. ft. Houses</td>
                                    <td className={" text-center"}>08</td>
                                </tr>
                                <tr>
                                    <td className={" text-center"}>3</td>
                                    <td className={" text-center"}>1300 sq. ft. Houses</td>
                                    <td className={" text-center"}>16</td>
                                </tr>
                                <tr>
                                    <td className={" text-center"}>4</td>
                                    <td className={" text-center"}>1000 sq. ft. Houses</td>
                                    <td className={" text-center"}>24</td>
                                </tr>
                                <tr>
                                    <td className={" text-center"}>5</td>
                                    <td className={" text-center"}>750 sq. ft. Houses</td>
                                    <td className={" text-center"}>36</td>
                                </tr>
                                <tr>
                                    <td className={" text-center"}>6</td>
                                    <td className={" text-center"}>600 sq. ft. Houses</td>
                                    <td className={" text-center"}>36</td>
                                </tr>
                                <tr>
                                    <td className={" text-center"}>7</td>
                                    <td className={" text-center"}>400 sq. ft. Houses</td>
                                    <td className={" text-center"}>36</td>
                                </tr>

                            </table>
                        </div>
                    </div>
                    <div className={"row pt-20"}>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                                <img src={"assets/images/sr01.jpg"} className={"img-responsive staffresiIMG"}/>
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                                <img src={"assets/images/sr02.jpg"} className={"img-responsive staffresiIMG"}/>
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                                <img src={"assets/images/sr03.jpg"} className={"img-responsive staffresiIMG"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"row pt-20"}>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                                <img src={"assets/images/sr04.jpg"} className={"img-responsive staffresiIMG"}/>
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                                <img src={"assets/images/sr05.jpg"} className={"img-responsive staffresiIMG"}/>
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                                <img src={"assets/images/sr06.jpg"} className={"img-responsive staffresiIMG"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"row pt-20"}>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                            <img src={"assets/images/sr07.jpg"} className={"img-responsive staffresiIMG"} />
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                            <img src={"assets/images/sr08.jpg"} className={"img-responsive staffresiIMG"} />
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                            <img src={"assets/images/sr09.jpg"} className={"img-responsive staffresiIMG"} />
                            </div>
                        </div>
                    </div>
                    <div className={"row pt-20"}>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                            <img src={"assets/images/sr10.jpg"} className={"img-responsive staffresiIMG"} />
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                            <img src={"assets/images/sr11.jpg"} className={"img-responsive staffresiIMG"} />
                            </div>
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <div className="staffresiDIV">
                            <img src={"assets/images/sr12.jpg"} className={"img-responsive staffresiIMG"} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer/>
        </>
    );
}

export default StaffResidence;