import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const ShoppingComplex = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Shopping Complex and Other Refreshment Facilities"
                               subTitle="Sardar Beant State University ,Gurdaspur" bdLink="Shopping Complex"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">CANTEEN AND OTHER VENDING FACILITIES ON THE CAMPUS</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className={"row"}>
                        <ol>
                            <li>Bank (State Bank of Patiala, Ext. Counter) with ATM facility Phone 01874 - 223791</li>
                            <li>S.T.D P.C.O, Courier and Photocopier</li>
                            <li>Stationery & General Store</li>
                            <li>Bakery shop</li>
                            <li>Verka Milk Booth</li>
                            <li>NESTLE Nescafe Stall</li>
                            <li>Canteen</li>
                            <li>Mail Box</li>
                        </ol>
                    </div>
                    <div className="shopcompl_head">
                        Bank (State Bank of Patiala, Ext. Counter) with ATM facility
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-8 col-md-8 col-sm-12"}>
                            <img src={"assets/images/bank.jpg"} style={{height: "460px"}}
                                 className={"img-responsive img-rounded"} alt="Bank" />
                        </div>
                        <div className={"col-lg-4 col-md-4 col-sm-12"}>
                            <img src={"assets/images/atm.jpg"} style={{height: "460px"}}
                                 className={"img-responsive center-block"} alt="ATM" />
                        </div>
                    </div>
                    <div className="shopcompl_head">
                        NESTLE Nescafe Stall
                    </div>
                    <div className="row">
                        <div className={"col-lg-12"}>
                            <img src={"assets/images/nescafe.jpg"} className={"img-responsive center-block"} alt="Nescafe" />
                        </div>
                    </div>
                    <div className="shopcompl_head">
                        Verka Milk Booth
                    </div>
                    <div className="row">
                        <div className={"col-lg-12"}>
                            <img src={"assets/images/verka.jpg"} className={"img-responsive center-block"} alt="Verka" />
                        </div>
                    </div>
                    <div className="shopcompl_head">
                        Shopping Complex
                    </div>
                    <div className="row">
                        <div className={"col-lg-12"}>
                            <img src={"assets/images/shopcomplex.jpg"} className={"img-responsive center-block"} alt="Shopping Complex" />
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default ShoppingComplex;