import {useNavigate} from "react-router-dom";
import {URL} from "./MyModules";

const FacultyCard = (props) => {
    let navigate = useNavigate();

    const showProfile = (data) => {
        // console.log(data.obj.facultyid);
        // let {id} = data;
        let id = data.obj.facultyid;

        if (typeof id === "number") {
            navigate(`/Faculty-Profile/${id}`);
        }
    }

    return (
        <>
            <div className="col-md-2 card rteleft facultycard" onClick={() => showProfile(props)}>
                <div className="post-card">
                    <div className="post-img">
                        {/*<a href="#">*/}
                        <img src={`${URL}assets/images/faculty/${props.photo}`} alt="Faculty Photo"
                             className="center-block"/>
                        {/*</a>*/}
                    </div>

                    <div className="post-content">
                        <h3 className="team-title">
                            {/*<a href="/anujg">*/}
                            {props.facultyname}
                            {/*</a>*/}
                        </h3>
                        <h2 className="team-subtitle">{props.designation}</h2>
                        <div className="team-description" style={{'display': 'none'}}>
                            <p style={{'margin': '0px'}}>{props.qualification}</p>
                            <p>
                                <a className="text-theme-colored2" href={"mailto:" + props.email}>{props.email}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default FacultyCard;