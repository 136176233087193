import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const BuildingWorkComittee = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="Building and Works Committee" bdLink="Building and Works Committee"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">
                            Board of Governors</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className="">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V">Designation</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Principal Secretary, Govt. of Punjab, Dept. of Technical Education of Indl. Training, Pb.</td>
                                <td className="table-body-padd-V">Chairman
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Director, Technical Education & Industrial Training, Punjab.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Director, SLIET Longowal.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">Director, NIT Jalandhar.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">Chief Engineer, PWD B&R, Punjab.</td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">6.</td>
                                <td className="table-body-padd-V">Chief Architect, Punjab.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">7.</td>
                                <td className="table-body-padd-V">Chief Engineer, NBCC Limited.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">8.</td>
                                <td className="table-body-padd-V">Director, Beant College of Engineering & Technology, Gurdaspur.</td>
                                <td className="table-body-padd-V">Member Secretary
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default BuildingWorkComittee;

