import React from 'react';
import { Slide, Fade, Zoom} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const slideImages  = [
    {
        url: 'assets/images/slider/sliderimage1.jpg',
        caption: 'Slide 1'
    },
    {
        url: 'assets/images/slider/sliderimage2.jpg',
        caption: 'Slide 2'
    },
    {
        url: 'assets/images/slider/sliderimage3.jpg',
        caption: 'Slide 3'
    },
    {
        url: 'assets/images/slider/sliderimage4.jpg',
        caption: 'Slide 4'
    },
];

const fadeImages  = [
    {
        url: 'assets/images/slider/sliderimage1.jpg',
        caption: 'Slide 1'
    },
    {
        url: 'assets/images/slider/sliderimage2.jpg',
        caption: 'Slide 2'
    },
    {
        url: 'assets/images/slider/sliderimage3.jpg',
        caption: 'Slide 3'
    },
    {
        url: 'assets/images/slider/sliderimage4.jpg',
        caption: 'Slide 4'
    },
];

const images = [
    'assets/images/slider/slider-1.jpg',
    'assets/images/slider/222-.jpg',
    'assets/images/slider/444.jpg'
];


const SlideImagePage = () => {
    return (
        // <div className="slide-container" style={{"height":"400px"}}>
        //     <Slide>
        //         {slideImages.map((slideImage, index)=> (
        //             <div className="each-slide" key={index}>
        //                 <div style={{'backgroundImage': `url(${slideImage.url})`}}>
        //                     <span>{slideImage.caption}</span>
        //                 </div>
        //             </div>
        //         ))}
        //     </Slide>
        // </div>

        // Fade Effect
        <div className="slide-container">
            <Fade autoPlay="true" loop="true" showNavs="false">
                {fadeImages.map((fadeImage, index) => (
                    <div className="each-fade" key={index}>
                        <div className="image-container">
                            <img src={fadeImage.url} style={{"width":"100%"}} />
                        </div>
                        {/*<h2>{fadeImage.caption}</h2>*/}
                    </div>
                ))}
            </Fade>
        </div>

        // Zoom Effect
        // <div className="slide-container">
        //     <Zoom scale={0.4}>
        //         {
        //             images.map((each, index) => <img key={index} style={{width: "100%"}} src={each} />)
        //         }
        //     </Zoom>
        // </div>

    )
}

export default SlideImagePage;