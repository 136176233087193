import {Link} from "react-router-dom";
// import {GoLocation} from "react-icons/go";
import {FaHome} from "react-icons/fa";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarAlt, faCreditCardAlt, faEnvelope, faLocationDot, faUser} from '@fortawesome/free-solid-svg-icons'
import NavLinks from "./NavLinks";
import TopHeader from "./TopHeader";
import LogoSection from "./LogoSection";
import "./NavBar2.css";
import {useEffect} from "react";
import RightMenuLinks from "./RightMenuLinks";

const NavBar2 = (props) => {

    useEffect(() => {
        ToTop();
    }, [])

    function ToTop() {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <TopHeader/>

            <header
                className={props.headerClass === "" ? "header normal-header" : "header normal-header affix2 navbar-fixed-top"}>
                <div className="container-fluid">
                    <nav className="navbar navbar-default yamm">
                        <div className="container-full">
                            <Link to="/" className="toprowlogo2">
                                <img src="../assets/images/logo/beantunilogo.png" alt="Logo"/>
                            </Link>
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                        data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                            {/*end navbar header*/}
                            <Link className="first-child" title="Home Page" to="/">
                                <img className="logo-scrolled-to-fixed showTopRow" id="logo-scrolled-to-fixed"
                                     src="../assets/images/logo/beantunilogo.png" alt="Logo"/>
                            </Link>

                            <RightMenuLinks cname="leftMargin" />

                            {/*nav-collapse*/}
                        </div>
                        {/*container-fluid*/}
                    </nav>
                </div>
                {/*end container*/}
            </header>
        </>
    );
}
export default NavBar2;