import NavBar2 from "../components/NavBar2";
import Footer from "../components/Footer";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";

function NCC() {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Student's Corner" subTitle="NCC" bdLink="NCC"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">NCC Activities</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="row">
                            <div className="col-md-9">
                                <div className="mb-35 text-justify">
                                    <p>The Indian Parliament passed the National Cadet Corps Act in 1948, thus creating
                                        the
                                        National Cadet Corps (NCC). The motto of the NCC is Unity and Discipline. The
                                        Aims of
                                        the National Cadet Corps are;</p>

                                    <ul>
                                        <li>To develop qualities of character, courage, comradeship, discipline,
                                            leadership,
                                            secular out look, spirit of adventure and sportsmanship and the ideals of
                                            selfless
                                            service among the youth to make them useful citizens.
                                        </li>
                                        <li>To create a human resource of organized, trained and activated youth, to
                                            provide
                                            leadership in all walks of life including the Armed Forces and always
                                            available for
                                            the service of the nation.
                                        </li>
                                    </ul>

                                    <p>The NCC is open to all regular students of schools and colleges on a voluntary
                                        basis. The
                                        cadets have no liability for active military service. The Senior Division of
                                        Army wing
                                        in our college has entry for both boys and girls.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="mb-35">
                                    <img src="assets/images/faculty/Baljinder-Singh.jpg"
                                         className="HOD-image-v3"
                                         alt=""/>
                                    <div className="text-center">
                                        <b>Asst. Prof. Baljinder Singh</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <strong className="my-sub-titles certificate ">Ranks for senior division cadets in the NCC
                                are as under:-</strong>
                            <ul>
                                <li>Senior Under Officer</li>
                                <li>Under Officer</li>
                                <li>Company Sergeant Major</li>
                                <li>Company Quarter Master Sergeant</li>
                                <li>Sergeant</li>
                                <li>Corporal</li>
                                <li>Lance Corporal</li>
                                <li>Cadet</li>
                            </ul>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Certificates in NCC</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <p>
                                <span className="certificate my-sub-titles">
                                    <u><strong>A-Certificate</strong></u><strong>&nbsp;&nbsp;:-&nbsp;</strong>
                                </span>
                                Cadets have to attend 75 % of the parades held during the year.
                            </p>

                            <p>
                                <span className="certificate my-sub-titles">
                                    <u><strong>B-Certificate</strong></u><strong>&nbsp;&nbsp;:-&nbsp;</strong>
                                </span>
                                Cadets have to attend 75 % of the parades held during the year.
                            </p>

                            <ul>
                                <li>Have to attend at least one camp.</li>
                                <li>Have to appear in Theory and Practical Test conducted in NCC battalions every
                                    academic year.
                                </li>
                            </ul>

                            <p>The theory part consists of special to Corps (Infantry) subjects, civil defense &
                                first-aid. The practical test includes rifle drill, map reading, cleaning & maintaining
                                of rifles and machine guns.</p>

                            <p>
                                <span className="certificate my-sub-titles">
                                    <u><strong>C-Certificate</strong></u><strong>&nbsp;&nbsp;:-&nbsp;</strong>
                                </span>
                                Cadet should have passed B-certificate of NCC.
                            </p>

                            <ul>
                                <li>Similar to the B-Certificate, cadet should fulfill all the conditions in third year
                                    also. As far as the syllabus is concerned, it is same but the questions are tougher.
                                </li>
                            </ul>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">NCC Activities</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <p>
                                <span className="certificate my-sub-titles">
                                   <strong>Regular Parades in the institution</strong>
                                </span>
                            </p>

                            <p>
                                <span className="certificate my-sub-titles">
                                    <u><strong>Camps</strong></u><strong>&nbsp;&nbsp;:-&nbsp;</strong>
                                </span>
                                NCC camps are conducted through out the academic year. Camps are of various types and
                                each serves a different purpose. The camps are of 10-12 days duration and the cadets
                                receive intensive practical and theoretical training. These includes:
                            </p>

                            <div className="ncc-camps">
                                <img src="../assets/images/ncc/ncc1.jpg" alt=""/>
                            </div>

                            <ul>
                                <li>Annual Training Camps</li>
                                <li>National Integration Camps</li>
                                <li>Thall Sena Camps</li>
                                <li>Army Attachment Camps</li>
                                <li>RD Participation camp</li>
                                <li>ID Participation camp etc.</li>
                            </ul>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">NCC contributions in AZADI KA AMRIT
                                MAHOTSAV</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="ncc-camps">
                                <Link to="/IndependenceDay">
                                    <img src="../assets/images/ncc/freedom16.jpeg" alt=""/>
                                </Link>
                            </div>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">Various other activities will be as
                                under</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <ul>
                                <li> Trekking and Mountaineering Expedition</li>
                                <li> Adventure Training</li>
                                <li> Drill Training</li>
                                <li> Weapon Training</li>
                                <li> Map Reading</li>
                                <li> Field Craft and Battle Craft Training</li>
                                <li> Traffic Signals/Control Training</li>
                                <li> First Aid Tactics</li>
                                <li> Handling Disaster Situations</li>
                                <li> Social Evil Awareness</li>
                                <li> House Keeping</li>
                                <li> Youth Exchange Programme</li>
                                <li> Sports Training</li>
                                <li> Cultural Activities</li>
                                <li> Community Development</li>
                                <li> Activities on behalf of D.G.NCC etc</li>
                            </ul>

                            <div className="ncc-camps">
                                <img src="../assets/images/ncc/ncc2.jpg" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default NCC;