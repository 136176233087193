

const BreadCrumbSection = (props) => {
    return (
        <>
            <div className="page-title section lb section2">
                <div className="container">
                    <div className="clearfix">
                        <div className="title-area pull-left">
                            <h2>{props.mainTitle} <small>{props.subTitle}</small></h2>
                        </div>
                        {/* /.pull-right */}
                        <div className="pull-right hidden-xs">
                            <div className="bread">
                                <ol className="breadcrumb">
                                    <li><a href="#">Home</a></li>
                                    <li className="active">{props.bdLink}</li>
                                </ol>
                            </div>
                            {/* end bread */}
                        </div>
                        {/* /.pull-right */}
                    </div>
                    {/* end clearfix */}
                </div>
            </div>
        </>
    );
}

export default BreadCrumbSection;