import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const BtechECE = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Undergraduate Courses" subTitle="B.Tech(ECE)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Bachelor of Technology: Electronics & Communication
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    Department of Electronics & Communication Engineering offers four years course at
                                    undergraduate level leading to the award of B. Tech. degree in the discipline of
                                    ECE. There is a huge demand for competent engineers throughout the world; from
                                    telecom, power, mobile & internet communications, precision engineering to consumer
                                    electronics, infrastructure, internet of things, robotics, artificial intelligence,
                                    Digital System Design, Embedded Systems, VLSI etc.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    They can offer specialized services in the field of design and manufacture of
                                    electronic devices, systems management, installation, maintenance and repair,
                                    technical sales, computer management both hardware and software, telecommunications
                                    etc which are well paid and offer excellent career growth opportunities. Due to
                                    versatility of Electronics and Communication Engineering, a student can go for the
                                    following specialized programs after B.Tech in ECE
                                </p>
                                <ul className="m-7-v">
                                    <li>Application Software Development</li>
                                    <li>Artificial Intelligence and Machine Learning</li>
                                    <li>Cloud Based Software Development</li>
                                    <li>Mobile and Web Application Development</li>
                                    <li>Internet of Things</li>
                                </ul>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/btechECE.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                Passed the 10+2 examination from a board recognized or established by central/state
                                government through a legislation and a member of Boards of School Education (COBSE), New
                                Delhi with Physics/ Mathematics/ Chemistry / Biotechnology / Computer Science / Biology
                                / Electronics/ Information Technology/ Informatics Practices / Technical Vocational
                                Subject / Agriculture / Engineering Graphics / Entrepreneurship (Any of three). Obtained
                                at least 45% marks (40% in case of Candidates belonging to reserved category) in the
                                above subject taken together.
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                Passed Diploma in any Engineering Trade from Punjab State Board of Technical Education &
                                Industrial Training, Chandigarh or Sant Longowal Institute of Engineering and
                                Technology, Longowal (SLIET), or any such examination from any other recognized State
                                Board of Technical Education with atleat 45% marks (40% in case of candidates belonging
                                to reserved category).
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                The candidates who have passed two years certificate course from Sant Longowal Institute
                                of Engineering and Technology, Longowal (SLIET) shall be eligible.
                            </p>

                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 4 years long, with Eight Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 - 3 to 8 Sem */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v">
                                            <a className="btn margin-x-25-v theme_btn_V theme-btn"
                                               href="/assets/pdf/admissions/UG/BTech/ECE/Syllabus_Scheme_ECE_2023.pdf"
                                               target="_blank">
                                                <i className="fa fa-file-pdf-o padd-10-v"></i>
                                                <span className="hide-v">Download Syllabus</span>
                                            </a>
                                        </td>
                                        <td className="table-body-padd-V syllabus-table-v">2023 3rd to 8th Semester</td>
                                    </tr>

                                    {/* 2023 - 1, 2 Sem */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BTech/revised-syllabus-scheme-2023-merged-first-year.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">2023 First Year</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-ece-2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">First Year</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/FY_BTECH2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-ece-2020.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2020 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-ece-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2018 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default BtechECE;