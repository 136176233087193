import NavBar2 from "./pages/components/NavBar2";
import BreadCrumbSection from "./pages/components/BreadCrumbSection";
import Footer from "./pages/components/Footer";
import axios from "axios";
import {URL} from "./pages/components/MyModules";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import Style from "./assets/css/TopNews.module.css"

const TopNews = () => {
    let navigate = useNavigate();
    let [topNews, setTopNews] = useState([]);
    let [brief, setBrief] = useState('');
    let [fetchingData2, setFetchingData2] = useState(true);

    useEffect(() => {
        document.title = "Top News";
        window.localStorage.setItem('id', 'empty');
        FetchTopNews();
    }, []);

    // Fetch Top News
    function FetchTopNews() {
        axios.get(`${URL}getTopNewsList`)
            .then(async res => {
                // console.log(res.data);
                await setTopNews(res.data.data[0]);
                await setBrief(decodeURIComponent(res.data.data[0].description));
                await setFetchingData2(false);
            }).catch(err => {
            console.log(err);
        });
    }

    function createMarkup() {
        return {__html: brief}
    }

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Top News" subTitle="Top News" bdLink="Top News"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font"}>
                        {topNews.title}
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>


                    <div className="row">
                        <div className="col-md-4 text-center">
                            <img className={Style.imgWidth} src={URL + "topnews/" + topNews.photo} alt="Top News Photo"/>
                        </div>

                        <div className="col-md-8">
                            <p className={Style.desc} dangerouslySetInnerHTML={createMarkup()}></p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default TopNews;