import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const EDC = () => {
    document.title = "Entrepreneurship Development Club";
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Entrepreneurship Development Club"
                               subTitle="Student's Corner" bdLink="EDC"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">Entrepreneurship Development Club</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">
                            <p className="font-p-v m-7-v text-justify">The Entrepreneurship Club aims to provide a conduit by which
                                students can access
                                entrepreneurial resources, network with community entrepreneurs, and share ideas. The
                                club
                                is dedicated to furthering understanding about new and small businesses.</p>
                        </div>

                        <div className="mb-35 text-justify">
                            <strong>Incharge EDC Club</strong> <br/>
                            <strong>Mr. Arun Nanda</strong> <br/>
                            <strong>Assistant Professor</strong> <br/>
                            <strong>Department of Mechanical Engineering</strong> <br/>
                            <strong>PH.-9888490015</strong> <br/>
                            <strong>E-mail—
                                <a className="mail-to text-theme-colored2"
                                   href="mailto:er.arunnanda@gmail.com">
                                    er.arunnanda@gmail.com
                                </a>
                            </strong>
                        </div>

                        <div className="mb-35 text-justify">
                            <strong>President</strong> <br/>
                            <strong>Sahil</strong> <br/>
                            <strong>Branch-CSE</strong> <br/>
                            <strong>Contact No: 8054915214</strong> <br/>
                        </div>

                        <div className="mb-35 text-justify">
                            <strong>Vice-President</strong> <br/>
                            <strong>Naveen Koul</strong> <br/>
                            <strong>Branch-CSE</strong> <br/>
                            <strong>Contact No: 9419421067</strong> <br/>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Annual Events (Year 2017-2018)</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V text-center">Date</th>
                                        <th className="table-title-V text-center">Name of Event/Subject</th>
                                        <th className="table-title-V text-center">Conducted By</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td align="center"><strong>1.</strong></td>
                                        <td>31/08/2017</td>
                                        <td align="center" valign="top">Induction</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>

                                    <tr>
                                        <td align="center" valign="top"><strong>2. </strong></td>
                                        <td valign="top">05/09/2017</td>
                                        <td align="center" valign="top">Selected Executives</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>

                                    <tr>
                                        <td align="center" valign="top"><strong>3. </strong></td>
                                        <td valign="top">14/09/2017</td>
                                        <td align="center" valign="top">Workshop on (Startup India & Skill Development)</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>

                                    <tr>
                                        <td align="center" valign="top"><strong>4. </strong></td>
                                        <td valign="top"></td>
                                        <td align="center" valign="top">Quiz Competition</td>
                                        <td align="center" valign="top">President</td>
                                    </tr>

                                    <tr>
                                        <td align="center" valign="top"><strong>5. </strong></td>
                                        <td style={{width:"200px"}}>16&nbsp;AND&nbsp;17 NOVEMBER</td>
                                        <td align="center" valign="top">Techfest Event</td>
                                        <td align="center" valign="top">Head coordinators</td>
                                    </tr>

                                    <tr>
                                        <td align="center" valign="top"><strong>6. </strong></td>
                                        <td valign="top">08.11.2017 to 10.11.2017</td>
                                        <td style={{width:"330px"}} align="center" valign="top">Entrepreneurship Awareness Programme (EAP)</td>
                                        <td align="center" valign="top">Department of Science & Technology, Govt. of India and Entrepreneurship Development Institute of India (EDII), Ahmadabad</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Annual Events (Year 2016-2017)</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V text-center">Date</th>
                                        <th className="table-title-V text-center">Name of Event/Subject</th>
                                        <th className="table-title-V text-center">Conducted By</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td align="center" valign="top"><strong>2. </strong></td>
                                        <td valign="top">19/09/2016</td>
                                        <td align="center" valign="top">Free Workshop</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>3. </strong></td>
                                        <td valign="top">22/09/2016</td>
                                        <td align="center" valign="top">Induction</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>4. </strong></td>
                                        <td valign="top">08/10/2016</td>
                                        <td align="center" valign="top">Selected Executives</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>5. </strong></td>
                                        <td valign="top">14/10/2016</td>
                                        <td align="center" valign="top">Quiz Competiton</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>6. </strong></td>
                                        <td valign="top">10 & 11 November,2016</td>
                                        <td align="center" valign="top">Tech-Fest Events</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>


                                    <tr>
                                        <td align="center" valign="top"><strong>7. </strong></td>
                                        <td style={{width:"200px"}} valign="top">19/12/2016 - 01/01/2017</td>
                                        <td style={{width:"350px"}} align="center" valign="top">Faculty Development Program
                                            (Two Weeks)</td>
                                        <td align="center" valign="top">Department of Science & Technology, Govt. of India and Entrepreneurship Development Institute of India (EDII), Ahmadabad</td>
                                    </tr>


                                    <tr>
                                        <td align="center" valign="top"><strong>8. </strong></td>
                                        <td valign="top">14.03.2017 to 16.03.2017</td>
                                        <td align="center" valign="top">Entrepreneurship Awareness Programme (EAP)</td>
                                        <td align="center" valign="top">Department of Science & Technology, Govt. of India and Entrepreneurship Development Institute of India (EDII), Ahmadabad</td>
                                    </tr>


                                    <tr>
                                        <td align="center" valign="top"><strong>9. </strong></td>
                                        <td valign="top">6/04/2017</td>
                                        <td align="center" valign="top">Share-o-Market</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>


                                    <tr>
                                        <td align="center" valign="top"><strong>10. </strong></td>
                                        <td valign="top">7/04/2017</td>
                                        <td align="center" valign="top">Spoon Boon</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>11. </strong></td>
                                        <td valign="top">20/04/2017</td>
                                        <td align="center" valign="top">Group Discussion</td>
                                        <td align="center" valign="top">Incharge EDC</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default EDC;