import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Registrar = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="Registrar" bdLink="Registrar"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h2 className="text-theme-colored2 mb-15"> Registrar </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="row">
                            <div className="col-sm-7">
                                <table className="table font-22 table-borderless">
                                    <tbody>
                                    <tr>
                                        <th width={200}>Name</th>
                                        <td>Dr. Ajay Kumar</td>
                                    </tr>
                                    <tr>
                                        <th>Designation</th>
                                        <td>Registrar</td>
                                    </tr>
                                    <tr>
                                        <th>Nationality</th>
                                        <td>Indian</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-sm-5 text-center">
                                <img src="assets/images/staff/reg_sir.jpg" style={{width: '300px'}} alt="Registrar SBSSU"/>
                            </div>
                        </div>

                        <div>
                            <h3 className="text-theme-colored2 mb-15"> Registrar's Desk </h3>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <p className="text-justify">
                                Greetings to all aspirants, scholars, and visitors,
                            </p>
                            <p className="text-justify">
                                As the Registrar of Sardar Beant Singh State University(SBSSU), situated in the
                                picturesque Gurdaspur region of Punjab, India, it gives me immense pleasure to extend a
                                warm and hearty welcome to our vibrant campus.
                            </p>
                            <p className="text-justify">
                                SBSSU is a place of inspiration, innovation, and transformation, where we take great
                                pride in providing a nurturing and intellectually stimulating environment for all our
                                students. With a commitment to academic excellence, we offer a wide array of programs,
                                including engineering at the undergraduate and postgraduate levels, as well as exciting
                                opportunities for doctoral research.
                            </p>
                            <p className="text-justify">Our mission is to empower the next generation of leaders,
                                thinkers, and creators, equipping them with the knowledge, skills, and ethical values
                                necessary to thrive in an ever-changing world. Through a blend of rigorous academics,
                                cutting-edge research, and practical experience, we aim to shape well-rounded
                                individuals who can contribute meaningfully to society.
                            </p>
                            <p className="text-justify">At SBSSU, we believe that education goes beyond textbooks and
                                classrooms. Alongside the core curriculum, we encourage students to engage in various
                                co-curricular and extracurricular activities that foster holistic development. From
                                cultural events and sports competitions to technical symposiums and community outreach
                                programs, there's something for everyone to explore their interests and passions.</p>
                            <p className="text-justify">Our distinguished faculty members are not only experts in their
                                respective fields but also mentors and guides who are dedicated to nurturing the
                                potential within each student. They actively encourage critical thinking, collaboration,
                                and independent research, inspiring students to push the boundaries of knowledge.</p>
                            <p className="text-justify">Furthermore, we take pride in our state-of-the-art
                                infrastructure, including modern laboratories, libraries, and research centers,
                                providing an enriching learning experience for all. Our commitment to innovation and
                                technology integration ensures that our students stay ahead in this fast-paced digital
                                age.</p>
                            <p className="text-justify">As an institution, we celebrate diversity and inclusivity,
                                welcoming students from all corners of the country and beyond. Young University's
                                multicultural environment fosters mutual respect, understanding, and a spirit of unity
                                that enriches the learning experience for everyone.</p>
                            <p className="text-justify">To prospective students, I assure you that at Young University,
                                your dreams and aspirations will find a nurturing home. To our current students, I
                                encourage you to make the most of your time here, seize every opportunity, and embrace
                                challenges as stepping stones to success.</p>
                            <p className="text-justify">I invite you all to explore our website, which serves as a
                                gateway to the wealth of information about our programs, admissions, faculty, research,
                                and much more. Our dedicated staff is available to assist you throughout your academic
                                journey, and we eagerly await your inquiries and applications.</p>
                            <p className="text-justify">Thank you for considering Young University for your educational
                                voyage. Together, let's embark on a transformative journey of growth and excellence.
                            </p>

                            <p className="text-justify p-lh">
                                Warm regards, <br/>
                                <b>Dr. Ajay Kumar</b> <br/>
                                <b>SBSSU, Gurdaspur, Punjab, India</b>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Registrar;