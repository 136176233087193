import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Vicechancellor = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="Vice Chancellor" bdLink="Vice Chancellor"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h2 className="text-theme-colored2 mb-15"> Vice Chancellor </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="row">
                            <div className="col-sm-7">
                                <table className="table font-22">
                                    <tr>
                                        <th width={200}>Name</th>
                                        <td>Dr. Susheel Mittal</td>
                                    </tr>
                                    <tr>
                                        <th>Designation</th>
                                        <td>Vice Chancellor</td>
                                    </tr>
                                    <tr>
                                        <th>Date of Joining</th>
                                        <td>15 November 2021</td>
                                    </tr>
                                    <tr>
                                        <th>Nationality</th>
                                        <td>Indian</td>
                                    </tr>
                                    <tr>
                                        <th>Present Address</th>
                                        <td>Dr. Susheel Mittal
                                            Vice Chancellor
                                            SBSSU, Gurdaspur – 143 530 Punjab INDIA
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Curriculum Vitae</th>
                                        <td><a href="assets/pdf/cv_vc.pdf" className="text-theme-colored2"
                                               target='_blank'>Click here for CV</a>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div className="col-sm-5">
                                <img src="assets/images/vc_sir.jpeg" className="img-responsive" alt=""/>
                                {/*<p className="text-center text-theme-colored2">*/}
                                {/*    <strong>Vice Chancellor, Dr. Susheel Mittal</strong>*/}
                                {/*</p>*/}
                            </div>
                        </div>

                        <div>
                            <h3 className="text-theme-colored2 mb-15"> Vice Chancellor's Desk </h3>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <p className="text-justify p-lh">
                                Sardar Beant Singh State University, Gurdaspur formerly known as Beant College of
                                Engineering & Technology, Gurdaspur was established in 1995 by the Punjab Government to
                                impart the technical education in the border area of Punjab. The University is imparting
                                quality education in the field of Engineering & Technology, Computer Applications,
                                Management, Applied Sciences and Agriculture. The University is also offering
                                Diploma level courses in the disciplines of Mechanical Engineering, Electronics &
                                Communication Engineering and Computer Science & Engineering.<br/>

                                Apart from the technical education, emphasis is laid to develop the overall personality
                                of students. Students are encouraged to participate in the various sports, cultural and
                                other extra curricular activities. Our students are not only performing well in
                                academics, but also they are excelling in sports and extra curricular activities at the
                                university and inter-university levels. <br/>

                                The Training and the Placement Cell is helping the students
                                to find the suitable placements in the industry also. Students of this University are
                                well placed in the reputed organizations to serve the
                                people all over the Globe. <br/>

                                The University administration is highly supportive and motivator for the all-round
                                growth & development of the students and maintains cordial and harmonious relationship
                                with its employees. I take this opportunity to welcome all the stakeholders and students
                                from the core of my heart and assure that the University will further achieve great
                                progress on all fronts with its sincere and dedicated efforts. <br/>
                                <b>Dr. Susheel Mittal</b><br/>
                                <b>Vice Chancellor</b>
                            </p>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Vicechancellor;