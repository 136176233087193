import Footer from "./components/Footer";
import React, {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import {Link as Link2} from "react-router-dom";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Anchor} from "antd";
let {Link} = Anchor;

const BoardofGovernors = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="Board of Governors" bdLink="Board of Governors"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <div className="col-lg-6">
                            <h1 className="text-theme-colored2">
                                Board of Governors</h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                        </div>
                        <div className="col-lg-6 text-right">
                           <div className=''>
                               <Link2 to="/boardofgovernors" smooth className='btn theme-btn  myButton_A'>
                                   <Anchor affix="false" targetOffset="55">
                                       <Link className="nav-link" href="#bogmeeting" title="Minutes of Meetings"/>
                                   </Anchor>
                               </Link2>
                           </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V">Designation</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">The Minister-in-Charge of the Department of Technical
                                    Education and Industrial Training, Punjab
                                </td>
                                <td className="table-body-padd-V">Chairman
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">The Administrative Secretary to Government of Punjab,
                                    Department of Technical Education and Industrial Training
                                </td>
                                <td className="table-body-padd-V">Vice-Chairman
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Vice-Chancellor, the Sardar Beant Singh State
                                    University
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">Vice-Chancellor, the IK Gujral Punjab Technical
                                    University
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">Administrative Secretary to Government of Punjab,
                                    Department of Higher Education
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">6.</td>
                                <td className="table-body-padd-V">Administrative Secretary to Government of Punjab,
                                    Department of Finance
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">7.</td>
                                <td className="table-body-padd-V">Member of the Punjab Legislative Assembly from
                                    Gurdaspur assembly constituency
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">8.</td>
                                <td className="table-body-padd-V">Member of the Punjab Legislative Assembly from
                                    Dinanagar assembly constituency
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">9.</td>
                                <td className="table-body-padd-V">Director, Indian Institute of Management, Amritsar
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">10.</td>
                                <td className="table-body-padd-V">Director, Indian Institute of Technology, Ropar</td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">11.</td>
                                <td className="table-body-padd-V">Vice-Chairman, University Grants Commission, New Delhi
                                    or his nominee
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">12.</td>
                                <td className="table-body-padd-V">President of the Confederation of Indian Industry or
                                    his nominee
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">13.</td>
                                <td className="table-body-padd-V">President of the PHD Chamber of Commerce and
                                    Industries or his nominee
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">14.</td>
                                <td className="table-body-padd-V">Two members from amongst the eminent Industrialists
                                </td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">15.</td>
                                <td className="table-body-padd-V">wo members from amongst the eminent educationists</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">16.</td>
                                <td className="table-body-padd-V">Two heads of the departments by rotation from amongst
                                    the heads of the departments of the University
                                </td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div id='bogmeeting' className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">
                            Minutes of Meetings</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className="table-responsive">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">University BOG Meeting</th>
                            <th className="table-title-V text-center">Agenda</th>
                            <th className="table-title-V text-center">Minutes of Meeting</th>
                            </thead>

                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">
                                    1st BOG Meeting
                                </td>
                                <td className="table-body-padd-V text-center">
                                    <a href="assets/pdf/bog/BOG-1(Meeting-Agenda).pdf"
                                       target='_blank' className='btn theme-btn  myButton_A'>
                                        <i className='fa fa-file-pdf-o'></i>
                                    </a>
                                </td>
                                <td className="table-body-padd-V text-center">
                                    <a href="assets/pdf/bog/BOG-1(Minutes-of-Meeting).pdf"
                                       target='_blank' className='btn theme-btn  myButton_A'>
                                        <i className='fa fa-file-pdf-o'></i>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default BoardofGovernors;

