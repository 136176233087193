import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const MusicDramaticsClub = () => {
    document.title = "Music and Dramatics Club";
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Music and Dramatics Club"
                               subTitle="Student's Corner" bdLink="Music and Dramatics Club"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">Music and Dramatics Club</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">
                            <p className="font-p-v m-7-v text-justify">
                                Music and Dramatics (M&D) activities are the basic needs of one’s life. They can
                                enlighten the soul and refresh the mind. In combination with studies, these activities
                                play a vital role in polishing a student’s persona. Just like sports, music and
                                dramatics activities help a student to provide a break from a loaded study schedule to
                                relax, refresh and to develop/improve any skills in these fields that will always add to
                                a students overall abilities.
                            </p>

                            <p className="font-p-v m-7-v text-justify">
                                Music and Dramatics Club of BCET provides a platform for the students to demonstrate and
                                enhance their skills in various fields of music like Singing, Instrument playing, Music
                                Composing etc and dramatics like skits, Theatre, monoacting, etc at college and
                                university level.
                            </p>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">Internal Events</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <ul>
                                <li>College Cultural Festival</li>
                                <li>On- Stage Events during Tech Fest, Alumni Meet, etc</li>
                                <li>Bcet ki mandeer</li>
                                <li>Tehzeeb(in collaboration with Biogen Society)</li>
                            </ul>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">External Events</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <ul>
                                <li>PTU Youth Festival(Zonal and Interzonal)</li>
                            </ul>
                        </div>

                        <div className="mb-35">
                            <strong>Incharge M&D Club</strong> <br/>
                            <strong>Mr. Lalit</strong> <br/>
                            <span>Assistant Professor, Department of Computer Science Engineering</span> <br/>
                            <span>PH.-9041680435</span> <br/>
                            <span>Email- <a className="mail-to download-links"
                                            href="mailto:lalit2630@gmail.com">lalit2630@gmail.com</a></span>
                        </div>

                        <hr/>

                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="mb-35">
                                    <strong>President</strong> <br/>
                                    <span>Sukhpreet Singh</span> <br/>
                                    <span>Branch-ME (Third Year)</span> <br/>
                                    <span>Email: sukhpbodal98@gmail.com</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="mb-35">
                                    <strong>Vice-President</strong> <br/>
                                    <span>Roopal Kaushal</span> <br/>
                                    <span>Branch-CSE (Third Year)</span> <br/>
                                    <span>Email: Roopal110497@gmail.com</span> <br/>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="mb-35">
                                    <strong>General-Secretary</strong> <br/>
                                    <span>Twinkle Katnour</span> <br/>
                                    <span>Branch-ME (Third Year)</span> <br/>
                                    <span>Contact No: 9478574760</span> <br/>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="mb-35">
                                    <strong>Record Keeping</strong> <br/>
                                    <span>Jasleen Kaur</span> <br/>
                                    <span>Branch-Bio Tech (Third Year)</span> <br/>
                                    <span>Email Id: kaur69jas@gmail.com</span> <br/>
                                </div>
                            </div>
                        </div>

                        {/* Head Executives */}
                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">Head Executives</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V text-center">S.NO</th>
                                        <th className="table-title-V text-center">NAME</th>
                                        <th className="table-title-V text-center">BRANCH</th>
                                        <th className="table-title-V text-center">YEAR</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td align="center" valign="top"><strong>1. </strong></td>
                                        <td align="center" valign="top">Sukhpreet Singh</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>2. </strong></td>
                                        <td align="center" valign="top">Roopal Kaushal</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>3. </strong></td>
                                        <td align="center" valign="top">Twinkle Katnour</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>4. </strong></td>
                                        <td align="center" valign="top">Jasleen Kaur</td>
                                        <td align="center" valign="top">Bio-Tech</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>5. </strong></td>
                                        <td align="center" valign="top">Akriti Namdev</td>
                                        <td align="center" valign="top">Bio-Tech</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>6. </strong></td>
                                        <td align="center" valign="top">Anand</td>
                                        <td align="center" valign="top">I.T</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Senior Executives */}
                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">Senior Executives</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V text-center">S.NO</th>
                                        <th className="table-title-V text-center">NAME</th>
                                        <th className="table-title-V text-center">BRANCH</th>
                                        <th className="table-title-V text-center">YEAR</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td align="center" valign="top"><strong>1. </strong></td>
                                        <td align="center" valign="top">Amy Masih</td>

                                        <td align="center" valign="top">Bio-Tech</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>2. </strong></td>
                                        <td align="center" valign="top">Kewal Sharma</td>
                                        <td align="center" valign="top">CHE</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>3. </strong></td>
                                        <td align="center" valign="top">Anamika</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>4. </strong></td>
                                        <td align="center" valign="top">Simran Mahajan</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>5. </strong></td>
                                        <td align="center" valign="top">Amritpal Singh</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>6. </strong></td>
                                        <td align="center" valign="top">Akhil Kumar</td>
                                        <td align="center" valign="top">M.E</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>7. </strong></td>
                                        <td align="center" valign="top">Shiv Kumar</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>8. </strong></td>
                                        <td align="center" valign="top">Atil Kumar</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>

                                    <tr>
                                        <td align="center" valign="top"><strong>9. </strong></td>
                                        <td align="center" valign="top">Pawan Singh</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>10. </strong></td>
                                        <td align="center" valign="top">Rajan Kashap</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>11. </strong></td>
                                        <td align="center" valign="top">Gurpreet Singh</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    <tr>
                                        <td align="center" valign="top"><strong>12. </strong></td>
                                        <td align="center" valign="top">Ram Kumar</td>
                                        <td align="center" valign="top">ME</td>
                                        <td align="center" valign="top">Third Year</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35">
                            <Link id="nss-pdf-link" to="../assets/pdf/pp.pdf" target="_blank">
                                Click Here for Various Events Conducted <i className="fa fa-file-pdf-o"></i>
                            </Link>
                        </div>

                        <div style={{border: "1px solid #000",padding:"50px 0 10px 0"}}>
                            <div className="">
                                <div className="row">
                                    <div className="col-sm-4 text-center mb-35">
                                        <img src="./assets/images/musicdrama/mc1.jpg" className="Image_A"
                                             width="221" height="166"
                                             alt="Computer Science and Engg."/>
                                    </div>

                                    <div className="col-sm-4 text-center mb-35">
                                        <img src="./assets/images/musicdrama/mc2.jpg" className="Image_A"
                                             width="221" height="166"
                                             alt="Computer Science and Engg."/>
                                    </div>

                                    <div className="col-sm-4 text-center mb-35">
                                        <img src="./assets/images/musicdrama/mc3.jpg" className="Image_A"
                                             width="221" height="166"
                                             alt="Computer Science and Engg."/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-4 text-center mb-35">
                                    <img src="./assets/images/musicdrama/mc4.jpg" className="Image_A"
                                         width="221" height="166"
                                         alt="Computer Science and Engg."/>
                                </div>

                                <div className="col-sm-4 text-center mb-35">
                                    <img src="./assets/images/musicdrama/mc5.jpg" className="Image_A"
                                         width="221" height="166"
                                         alt="Computer Science and Engg."/>
                                </div>

                                <div className="col-sm-4 text-center mb-35">
                                    <img src="./assets/images/musicdrama/mc6.jpg" className="Image_A"
                                         width="221" height="166"
                                         alt="Computer Science and Engg."/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default MusicDramaticsClub;