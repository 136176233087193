import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import FacultyCard from "../components/FacultyCard";
import axios from "axios";
import {URL} from "../components/MyModules";

const DeptAgriculture = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(8);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' ******')
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }


    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Agriculture"
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    {/*Intro*/}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Agriculture</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-8 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    Department of Agriculture was established in June 2018 in association with Bio
                                    Technology and Faculty of Chemical Engineering. The college started the four year UG
                                    programme B.Sc (Honour) Agriculture from the academic year 2018-2019 with intake
                                    of 60 students . Now intake capacity has increased to 120 students from Academic
                                    session 2021-2022. The curriculum and the syllabi of graduation are revised from
                                    time
                                    to time as per the latest directions of I.C.A.R (Indian Council of Agricultural
                                    Research),
                                    New Delhi and Punjab State Council of Agriculture Education Chandigarh. The
                                    objective of this course is to focus on food, health, environment &amp; the welfare
                                    of
                                    farmers. So we encourage to resolve various issues in agriculture and related areas
                                    with a strong sense of purpose, and to accomplish their objectives with a rich sense
                                    of
                                    social responsibility and a wide state outlook.<br/>
                                    <br/>
                                    It is a matter of achievement that the department has set up Soil testing,
                                    Microbiology, Biochemistry, Plant Protection and Physiology lab in short span of
                                    period for agriculture students. Along with these important labs, we have got
                                    success
                                    in establishing field for agronomy and horticulture. Another initiative has been
                                    started
                                    to establish the ‘Herbal Garden’ by collecting regional medicinal plants. With this
                                    move, the department is going to establish Agro-meteorology observatory in the
                                    campus. So that students can analyze the field status in real time weather
                                    conditions.
                                </p>
                            </div>
                            <div className="col-sm-4 padd-10-v">
                                <img src="assets/images/staff/rajeevKumar.jpeg"
                                     className="img-responsive HOD-image-v" alt=""/>
                                <p className="text-left-10 text-theme-colored2 ">
                                    <strong> HOD, Department : Prof. Rajeev Kumar Awasthi</strong></p></div>
                        </div>
                        <div></div>
                    </div>

                    <div className="m-7-v mb-25">
                        <h1 className="text-theme-colored2">E-magazine</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <a href="/assets/pdf/magazine/Department-Agriculture-E-magazine.pdf" target="_blank" className="btn  theme_btn_V theme-btn">
                            View E-magazine
                        </a>
                    </div>

                    {/*Vision Mission*/}
                    <div className="m-7-v">
                        <h1 className="text-theme-colored2">Vision And Mission</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <h4 className="my-font-style text-theme-colored2">Vision</h4>
                        <p className="font-p-v m-7-v">
                            Striving to enhance the national food security and agriculture policy by providing education
                            through practical and innovative approaches.
                        </p>
                        <h4 className="my-font-style text-theme-colored2">Mission</h4>
                        <ul className="font-p-v">
                            <li>Development of human resources in the field of agriculture to serve the cause of the
                                state.
                            </li>
                            <li>Creation of a sustained learning environment for acquiring technical knowledge and
                                professional application of the same in the domain of agriculture and its allied
                                discipline.
                            </li>
                            <li>Inculcating amongst our students a deep understanding of the fundamental principles,
                                concepts of agriculture and its practice in the field.
                            </li>
                            <li>Creating a conducive environment for innovation to translate theoretical knowledge into
                                practical applications.
                            </li>
                            <li>Preparing budding agriculturist and agro-businessman to meet the increasing
                                technological and social challenges and creative approach to handle the agriculture
                                problems.
                            </li>
                        </ul>

                    </div>

                    {/*Programme Education  objective*/}
                    <div className="m-7-v padd-10-v">
                        <h1 className="text-theme-colored2">Programme Education Objectives (PEO's)
                        </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <ol className="font-p-v padd-10-v">
                            <li>To provide students a platform to learn the statistics, biology and economic
                                fundamentals necessary to formulate and understand agriculture problems and to prepare
                                them for graduate studies.
                            </li>
                            <li>To develop the ability among students to analyse agriculture data and technical concepts
                                to solve problems relevant to the general practice of the agriculture sector.
                            </li>
                            <li>Students will develop professional skills that prepare them for immediate employment in
                                industries and other organizations.
                            </li>
                            <li>Students will learn to communicate their ideas to be effective in collaboration with
                                other members of teams.
                            </li>
                            <li>To promote student awareness of life-long learning and to introduce them to professional
                                ethics and codes of professional practice.
                            </li>
                        </ol>

                    </div>

                    {/*Programme   objective*/}
                    <div className="m-7-v padd-10-v">
                        <h1 className="text-theme-colored2">Programme Objectives (PO's)
                        </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <ul className="font-p-v padd-10-v">
                            <li>The graduates are expected to have ability to apply knowledge of statistics, economics
                                and agriculture.
                            </li>
                            <li>Graduates are expected to identify, formulate and solve agriculture related problems.
                            </li>
                            <li> The graduates are expected to have ability to design and conduct experiments, as well
                                as to analyse and interpret data.
                            </li>
                            <li> The graduates are expected to have ability to design a agriculture system and process
                                that meets desired specifications and requirements.
                            </li>
                            <li>The graduates are expected to possess ability to function on multi-disciplinary teams.
                            </li>
                            <li> The graduates are expected to have understanding of professional and ethical
                                responsibility.
                            </li>
                            <li> The graduates are expected to engage themselves in life-long learning.
                            </li>
                            <li> The graduates are expected to have knowledge of contemporary issues.
                            </li>
                            <li> The graduates are expected to possess ability to use the techniques, skills, and modern
                                agriculture implements for profitable agriculture engineering sector.
                            </li>
                            <li> The graduates are expected to have the broad education necessary to understand the
                                impact of agriculture sector in a global, economic and societal context.
                            </li>
                            <li> The graduates are expected to deliver fundamentals of agriculture sector in effective
                                and innovative ways .
                            </li>
                        </ul>

                    </div>

                    {/*Courses Offered*/}
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BscAgriculture">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="assets/images/admission_icons/16.png" className="Image_A"
                                             alt="B. Sc. (Hons.) Agriculture"/>
                                    </div>
                                    <p className="myColor_A">B.Sc. (Hons.) Agriculture</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* Faculty */}
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo} facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo} facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo} facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo} facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }
                        </div>
                    </div>

                    {/*Facilities*/}
                    <div className="m-7-v">
                        <h1 className="text-theme-colored2">Facilities and Infrastructure</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <h4 className="my-font-style text-theme-colored2">LABORATORIES
                        </h4>
                        <ol className="font-p-v m-7-v">
                            <li>Soil Testing lab</li>
                            <li>Microbiology</li>
                            <li>Agronomy (Field)</li>
                            <li>Agrometeorology</li>
                            <li>Horticulture (Fruits/Vegetables)</li>
                            <li>Plant Protection</li>
                            <li>Biology</li>
                            <li>Communication lab</li>
                        </ol>


                        <h4 className="my-font-style text-theme-colored2">MAJOR FACILITIES IN THE DEPARTMENT
                        </h4>
                        <ul className="font-p-v">
                            <li>Soil Tensiometer</li>
                            <li>Digital colorimeter</li>
                            <li>Electrical Conductivity meter</li>
                            <li>pH meter</li>
                            <li>Laminar air flow chamber</li>
                            <li>Campbell sunshine recorder</li>
                            <li>Ordinary rain gauge</li>
                            <li>Cup anemometer</li>
                            <li>Soil thermometer</li>
                            <li>Thermo hygrograph</li>
                            <li>Single microscope</li>
                            <li>Autoclave</li>
                            <li>Compound microscope</li>
                        </ul>

                    </div>

                    {/*FACULTY PROFILE*/}
                    <div className="m-7-v padd-10-v">
                        <h1 className=" text-theme-colored2">FACULTY PROFILE</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <tr>
                                    <th className="table-title-V">Sr.&nbsp;No.</th>
                                    <th className="table-title-V">Name</th>
                                    <th className="table-title-V">Designation</th>
                                    <th className="table-title-V">Qualification</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td className="table-body-padd-V">1.</td>
                                    <td className="table-body-padd-V">Mrs. Maninderpreet Kaur</td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">
                                        M.Sc (Agronomy )
                                    </td>
                                </tr>

                                <tr>
                                    <td className="table-body-padd-V">2.</td>
                                    <td className="table-body-padd-V">Mr. Lovepreet Singh</td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">M.Sc ( Soil Sciences )</td>
                                </tr>

                                <tr>
                                    <td className="table-body-padd-V">3.</td>
                                    <td className="table-body-padd-V">Ms. Pushpinder Kaur</td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">M.Sc(Fruit Science)</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">4.</td>
                                    <td className="table-body-padd-V">Mr. Amulgurpreet Singh</td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">M.Sc(Fruit Science)</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">5.</td>
                                    <td className="table-body-padd-V">Mr. Harsimranjeet Singh
                                    </td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">M.Sc ( Vegetable Sciences )</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">6.</td>
                                    <td className="table-body-padd-V">Ms. Alisha Sharma
                                    </td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">M.Sc(Agriculture Economics)</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">7.</td>
                                    <td className="table-body-padd-V">Ms. Harmandeep Kaur
                                    </td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                    <td className="table-body-padd-V">
                                        M.Sc (Horticulture)

                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Student Profile*/}
                    <div>
                        <h1 className=" text-theme-colored2">STUDENT ADVISORS
                        </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <tr>

                                    <th className="table-title-V">Batch</th>
                                    <th className="table-title-V">Name of Advisor</th>
                                    <th className="table-title-V">Designation</th>

                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td className="table-body-padd-V">2018</td>
                                    <td className="table-body-padd-V">Mrs. Maninderpreet Kaur
                                    </td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">2019</td>
                                    <td className="table-body-padd-V">Mr. Lovepreet Singh</td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">2020</td>
                                    <td className="table-body-padd-V">Ms. Pushpinder Kaur
                                    </td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">2021</td>
                                    <td className="table-body-padd-V">Mr. Harsimranjeet Singh
                                    </td>
                                    <td className="table-body-padd-V">Assistant Professor</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    {/*<div className="padd-10-v">*/}
                    {/*    <div className="row">*/}
                    {/*        <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>*/}
                    {/*        <p className="font-p-v m-7-v">*/}
                    {/*            <table className="table-hover table margin-25-V">*/}
                    {/*                <thead className="table-head-red-V">*/}
                    {/*                <th className="table-title-V padd-l-10">Scheme</th>*/}
                    {/*                <th className="table-title-V padd-l-36">Syllabus</th>*/}
                    {/*                <th className="table-title-V">Remarks</th>*/}
                    {/*                </thead>*/}
                    {/*                <tbody>*/}

                    {/*                <tr>*/}
                    {/*                    <td className="table-body-padd-V syllabus-table-v">2021</td>*/}
                    {/*                    <td className="table-body-padd-V syllabus-table-v"><a*/}
                    {/*                        className="btn margin-x-25-v theme_btn_V theme-btn"*/}
                    {/*                        href="/assets/pdf/2021_Syllabus_Agriculture.pdf"*/}
                    {/*                        target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span*/}
                    {/*                        className="hide-v"> Download*/}
                    {/*                        Syllabus</span></a></td>*/}
                    {/*                    <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>*/}
                    {/*                </tr>*/}
                    {/*                </tbody>*/}
                    {/*            </table>*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptAgriculture;