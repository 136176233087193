import React, {useEffect} from "react";
import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";

const ThankYou = () => {
    useEffect(() => {
        // window.location.reload();
    });

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection
                mainTitle="Registration Complete"
                subTitle=""
                bdLink="Registration 2024 | SBSSU"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font"}>Thank you for registering at SBSSU</h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <h4 className="mb-35">We have sent an email with password. Please use that password to login and
                        complete application <a href="http://www.sbssugsp.ac.in:8000/student"
                                                rel="noreferrer"
                                                className="btn btn-theme-colored2 btn-sm my-button-visited"
                                                target="_blank">Click here to login</a></h4>

                    <h4>If you have trouble logging in drop
                        email to <a href="mailto:shalinder@vmmeducation.com"
                                    style={{
                                        color: "#C21717",
                                        textDecoration: "underline"
                                    }}>shalinder@vmmeducation.com</a></h4>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default ThankYou;