import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const PhdECE = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Doctor of Philosophy Courses" subTitle="Phd (ECE)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Doctor of Philosophy: Electronics & Communication
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    Apart from B.Tech (ECE) and B.Tech (EE), the department also offers Ph.D program in
                                    the discipline of Electronics & Communication Engineering.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    This course is mainly for students who further like to examine aspects of Electrical
                                    engineering for the purpose laboratory, and/or an industrial research and
                                    development career in a specialization within electrical engineering.
                                    Ph.D. Electrical Communication Engineering, provides the candidate with a plethora
                                    of opportunities which may aid them significantly in their further prospects,
                                    moreover this field of engineering has always been blooming.

                                    Even in this era of recession this area is still blooming as it is apparent of
                                    various mergers that have happened of electrical based companies in the recent past.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/MtechCSE.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                The candidates with minimum 55% marks (or an equivalent grade in a point scale wherever
                                grading system is followed) in Master's Degree (in Engineering/Technology
                                Science/Management/Computer application/Humanities and in those other areas, in which
                                University decides to undertake the curriculum) of SBSSU, Gurdaspur or an equivalent
                                degree of recognized Indian or Foreign University (recognized by UGC/AIU/or any other
                                approved and authorized statutory Assessment and Accreditation agency) in the subject
                                concerned/allied subject/cognate subject shall be eligible.
                            </p>
                            <p className="font-p-v m-7-v">
                                Relaxation of 5% of marks, from 55% to 50%, or an equivalent relaxation of grade, may be
                                allowed for those belonging to SC/ST/OBC(non-creamy layer)/Differently-abled and other
                                categories of candidates as per the decision of UGC from time to time, or for those who
                                had obtained their Master's degree prior to 19th September, 1991. The eligibility marks
                                of 55% (or an equivalent grade in a point scale wherever grading system is followed) and
                                the relaxation of 5% to the categories mentioned above are permissible based only on the
                                qualifying marks without including the grace mark procedures. Candidates already having
                                PhD in a particular faculty are eligible only for a different subject/stream in the same
                                faculty or in a different faculty as per clause 1.0(i) above.
                            </p>
                            <p className="font-p-v m-7-v">
                                The candidates shall be admitted by the. University following a two stage process based
                                on qualifying
                                <ol className=" m-7-v">
                                    <li>PhD entrance test</li>
                                    <li>Subsequent interview</li>
                                </ol>
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/PhD/ECE/2021_Syllabus.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default PhdECE;