import TopHeader from "./TopHeader";
import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import NavBar2 from "./NavBar2";
import BreadCrumbSection from "./BreadCrumbSection";

const UnderConstructionPage = () => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Page Under Construction" subTitle="Page Under Construction"
                               bdLink="Page Under Construction"/>

            <div className="section3">
                <div className="container pt-90">
                    {/*<h3 className={"my-font-style my-bold-font"}>Grievance Redressal Cell</h3>*/}
                    {/*<div className="double-line-bottom-theme-colored-2"></div>*/}

                    <div className="jumbotron">
                        <h2 className="text-center">Page Under Construction</h2>
                    </div>

                </div>
            </div>

            {/*  FOOTER COMPONENT  */}
            <Footer/>
        </>
    )
}

export default UnderConstructionPage;