import NavBar2 from "./components/NavBar2";
import Footer from "./components/Footer";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Carousel from 'better-react-carousel';
import image1 from '../assets/campuspulse/raggingPoster1.png';
import image2 from '../assets/campuspulse/raggingPoster2.png';
import image3 from '../assets/campuspulse/raggingPoster3.png';
import image4 from '../assets/campuspulse/raggingPoster4.png';
import "./AntiRagging.css";
// import AntiRaggingStyle from "../../src/assets/css/AntiRagging.module.css";

import {ImageGroup, Image} from 'react-fullscreen-image'

const images = [
    image1,
    image2,
    image3,
    image4,
];

const AntiRagging = () => {
    const handleSelect = (eventKey) => {
        if (eventKey === 5) {
            window.open('assets/pdf/anti-ragging/National_Ragging_Prevention_Program.pdf', '_blank');
        }
    };

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Anti-Ragging" subTitle="Anti-Ragging Guidelines and Policies"
                               bdLink="Anti-Ragging"/>

            <div className="section3">
                <div className="container pt-90">

                    {/*TITLE*/}
                    <div className="row mb-25">
                        <h1 className="text-theme-colored2">Anti-Ragging Guidelines and Policies</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*<div className="double-line-bottom-theme-colored-2"></div>*/}
                    </div>

                    {/*POSTERS*/}
                    <div className="container">
                        <ImageGroup>
                            <ul className="images">
                                {images.map(i => <li key={i}><Image src={i} alt="TeqFest"/></li>)}
                            </ul>
                        </ImageGroup>
                    </div>


                    {/*TABS*/}
                    <div className="m-30-v">
                        <h3 className="text-theme-colored2">
                            Anti-Ragging Committees, Information, Guidelines for all students:
                        </h3>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <Tabs defaultActiveKey={1} onSelect={handleSelect} id="uncontrolled-tab-example">
                            <Tab eventKey={1} title="About Ragging">
                                <div className="container text-justify">
                                    <h4 className="text-theme-colored2">What Constitutes Ragging?</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                    <p>Ragging constitutes one or more of any of the following acts:</p>
                                    <ul className="list-height">
                                        <li>Any conduct by any student or students whether by words spoken or written or
                                            by an act which has the effect of teasing, treating or handling with
                                            rudeness a fresher or any other student.
                                        </li>
                                        <li>Indulging in rowdy or undisciplined activities by any student or students
                                            which causes or is likely to cause annoyance, hardship, physical or
                                            psychological harm or to raise fear or apprehension thereof in any fresher
                                            or any other student.
                                        </li>
                                        <li>Asking any student to do any act which such student will not in the
                                            ordinary course do and which has the effect of causing or generating a sense
                                            of shame, or torment or embarrassment so as to adversely affect the physique
                                            or psyche of such fresher or any other student.
                                        </li>
                                        <li>Any act by a senior student that prevents, disrupts or disturbs the
                                            regular academic activity of any other student or a fresher.
                                        </li>
                                        <li>Exploiting the services of a fresher or any other student for completing the
                                            academic tasks assigned to an individual or a group of students.
                                        </li>
                                        <li>Any act of financial extortion or forceful expenditure burden put on a
                                            fresher or any other student by students
                                        </li>
                                        <li>Any act of physical abuse including all variants of it: sexual abuse,
                                            homosexual assaults, stripping, forcing obscene and lewd acts, gestures,
                                            causing bodily harm or any other danger to health or person;
                                        </li>
                                        <li>Any act or abuse by spoken words, emails, post, public insults which
                                            would also include deriving perverted pleasure, vicarious or sadistic thrill
                                            from actively or passively participating in the discomfiture to fresher or
                                            any other student.
                                        </li>
                                        <li>Any act that affects the mental health and self-confidence of a fresher or
                                            any other student with or without an intent to derive a sadistic pleasure or
                                            showing off power, authority or superiority by a student over any fresher
                                        </li>
                                    </ul>


                                    <h4 className="text-theme-colored2">SUMMARY OF UGC REGULATIONS
                                        ON CURBING THE MENACE OF RAGGING IN HIGHER EDUCATIONAL INSTITUTIONS, 2009.
                                    </h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                    <ol className="list-height no-decoration">
                                        <li>
                                            <span className="text-theme-colored2 text-bold">PREAMBLE : </span>
                                            In view of the directions of the Hon’ble Supreme Court dated 8.05.2009 and
                                            in consideration of the determination of the Central Government and the
                                            University Grants Commission to prohibit, prevent and eliminate the scourge
                                            of ragging
                                        </li>
                                        <li>
                                            <span className="text-theme-colored2 text-bold">OBJECTIVE : </span>
                                            To eliminate ragging in all its forms from universities, deemed universities
                                            and other higher educational institutions in the country by prohibiting it
                                            under these Regulations, preventing its occurrence and punishing those who
                                            indulge in ragging as provided for in these Regulations and the appropriate
                                            law in force.
                                        </li>
                                        <li><span
                                            className="text-theme-colored2 text-bold">WHAT CONSTITUTES RAGGING : </span>
                                            <p>Ragging constitutes one or more of any of the following acts:</p>
                                            <ul className="list-height">
                                                <li>Any conduct by any student or students whether by words spoken or
                                                    written or
                                                    by an act which has the effect of teasing, treating or handling with
                                                    rudeness a fresher or any other student.
                                                </li>
                                                <li>Indulging in rowdy or undisciplined activities by any student or
                                                    students
                                                    which causes or is likely to cause annoyance, hardship, physical or
                                                    psychological harm or to raise fear or apprehension thereof in any
                                                    fresher
                                                    or any other student.
                                                </li>
                                                <li>Asking any student to do any act which such student will not in
                                                    the
                                                    ordinary course do and which has the effect of causing or generating
                                                    a sense
                                                    of shame, or torment or embarrassment so as to adversely affect the
                                                    physique
                                                    or psyche of such fresher or any other student.
                                                </li>
                                                <li>Any act by a senior student that prevents, disrupts or disturbs
                                                    the
                                                    regular academic activity of any other student or a fresher.
                                                </li>
                                                <li>Exploiting the services of a fresher or any other student for
                                                    completing the
                                                    academic tasks assigned to an individual or a group of students.
                                                </li>
                                                <li>Any act of financial extortion or forceful expenditure burden put
                                                    on a
                                                    fresher or any other student by students
                                                </li>
                                                <li>Any act of physical abuse including all variants of it: sexual
                                                    abuse,
                                                    homosexual assaults, stripping, forcing obscene and lewd acts,
                                                    gestures,
                                                    causing bodily harm or any other danger to health or person;
                                                </li>
                                                <li>Any act or abuse by spoken words, emails, post, public insults
                                                    which
                                                    would also include deriving perverted pleasure, vicarious or
                                                    sadistic thrill
                                                    from actively or passively participating in the discomfiture to
                                                    fresher or
                                                    any other student.
                                                </li>
                                                <li>Any act that affects the mental health and self-confidence of a
                                                    fresher or
                                                    any other student with or without an intent to derive a sadistic
                                                    pleasure or
                                                    showing off power, authority or superiority by a student over any
                                                    fresher
                                                </li>
                                            </ul>
                                        </li>
                                        <li><span className="text-theme-colored2 text-bold">MEASURES FOR PROHIBITION OF RAGGING : </span>
                                            <p>There are a number of such measures at institution level, University
                                                Level, District level etc. Some of them that are important for students
                                                to know are as follows:</p>
                                            <ul className="list-height">
                                                <li> No institution shall permit or condone any reported incident of
                                                    ragging in any form; and all institutions shall take all necessary
                                                    and

                                                    required measures, including but not limited to the provisions of
                                                    these Regulations, to achieve the objective of eliminating ragging,
                                                    within the institution or outside.

                                                </li>
                                                <li>All institutions shall take action in accordance with these
                                                    Regulations against those found guilty of ragging and/or abetting
                                                    ragging, actively or passively, or being part of a conspiracy to
                                                    promote ragging.
                                                </li>
                                                <li>Every public declaration of intent by any institution, in any
                                                    electronic, audiovisual or print or any other media, for admission
                                                    of students to any course of study shall expressly provide that
                                                    ragging is totally prohibited in the institution, and anyone found
                                                    guilty of ragging and/or abetting ragging, whether actively or
                                                    passively, or being a part of a conspiracy to promote ragging, is
                                                    liable to be punished in accordance with these Regulations as well
                                                    as under the provisions of any penal law for the time being in
                                                    force.
                                                </li>
                                                <li>The telephone numbers of the Anti-Ragging Helpline and all the
                                                    important functionaries in the institution, including but not
                                                    limited to the Head of the institution, faculty members, members of
                                                    the Anti- Ragging Committees and Anti-Ragging Squads, District and
                                                    Sub- Divisional authorities, Wardens of hostels, and other
                                                    functionaries or authorities where relevant, shall be published in
                                                    the brochure of admission/instruction booklet or the prospectus.
                                                </li>
                                                <li>The application for admission, enrolment or registration must be
                                                    accompanied by an Anti Ragging affidavit signed by a student in a
                                                    prescribed format and another Anti Ragging Affidavit signed by a
                                                    Parent/Guardian. (Both these Affidavits can be downloaded from the
                                                    Web )
                                                </li>
                                                <li>Any distress message received at the Anti-Ragging Helpline shall
                                                    be simultaneously relayed to the Head of the Institution, the Warden
                                                    of the Hostels, the Nodal Officer of the affiliating University, if
                                                    the incident reported has taken place in an institution affiliated
                                                    to a University, the concerned District authorities and if so
                                                    required, the District Magistrate, and the Superintendent of Police,
                                                    and shall also be web enabled so as to be in the public domain
                                                    simultaneously for the media and citizens to access it.
                                                </li>
                                                <li>On receipt of the recommendation of the Anti Ragging Squad or on
                                                    receipt of any information concerning any reported incident of
                                                    ragging, the Head of institution shall immediately determine if a
                                                    case under the penal laws is made out and if so, either on his own
                                                    or through a member of the Anti-Ragging Committee authorised by him
                                                    in this behalf, proceed to file a First Information Report (FIR),
                                                    within twenty four hours of receipt of such information or
                                                    recommendation, with the police and local authorities, under the
                                                    appropriate penal provisions.
                                                </li>
                                                <li>The Commission shall maintain an appropriate data base to be
                                                    created out of affidavits, affirmed by each student and his/her
                                                    parents/guardians and stored electronically by the institution,
                                                    either on its or through an agency to be designated by it; and such
                                                    database shall also function as a record of ragging complaints
                                                    received, and the status of the action taken thereon.
                                                </li>
                                                <li>The Commission shall include a specific condition in the
                                                    Utilization Certificate, in respect of any financial assistance or
                                                    grants-in-aid to any institution under any of the general or special
                                                    schemes of the

                                                    Commission, that the institution has complied with the anti-ragging
                                                    measures.
                                                </li>
                                                <li>Any incident of ragging in an institution shall adversely affect
                                                    its accreditation, ranking or grading by NAAC or by any other
                                                    authorised accreditation agencies while assessing the institution
                                                    for accreditation, ranking or grading purposes.
                                                </li>
                                                <li>The Commission may accord priority in financial grants-in-aid to
                                                    those institutions, otherwise eligible to receive grants under
                                                    section 12B of the Act, which report a blemishless record in terms
                                                    of there being no reported incident of ragging.
                                                </li>

                                            </ul>
                                        </li>
                                        <li><span className="text-theme-colored2 text-bold">ADIMINISTRATIVE ACTION IN THE EVENT OF RAGGING : </span>
                                            <p>The institution shall punish a student found guilty of ragging after
                                                following the procedure and in the manner prescribed here in under:</p>
                                            <ul className="list-height">
                                                <li>The Anti-Ragging Committee of the institution shall take an
                                                    appropriate decision, in regard to punishment or otherwise,
                                                    depending on the facts of each incident of ragging and nature and
                                                    gravity of the incident of ragging established in the
                                                    recommendations of the Anti-Ragging Squad
                                                </li>
                                                <li>
                                                    The Anti-Ragging Committee may, depending on the nature and
                                                    gravity of the guilt established by the Anti-Ragging Squad, award,
                                                    to those found guilty, one or more of the following punishments,
                                                    namely;
                                                    <ol type="I">
                                                        <li> Suspension from attending classes and academic
                                                            privileges.
                                                        </li>
                                                        <li> Withholding/ withdrawing scholarship/ fellowship and other
                                                            benefits.
                                                        </li>
                                                        <li> Debarring from appearing in any test/ examination or other
                                                            evaluation process.
                                                        </li>
                                                        <li> Withholding results.</li>
                                                        <li> Debarring from representing the institution in any
                                                            regional, national or international meet, tournament, youth
                                                            festival, etc.
                                                        </li>
                                                        <li> Suspension/ expulsion from the hostel.</li>
                                                        <li> Cancellation of admission.</li>
                                                        <li> Rustication from the institution for period ranging from
                                                            one to four semesters.
                                                        </li>
                                                        <li> Expulsion from the institution and consequent debarring
                                                            from admission to any other institution for a specified
                                                            period
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>Provided that where the persons committing or abetting the act of
                                                    ragging are not identified, the institution shall resort to
                                                    collective punishment.
                                                </li>
                                                <li>Where in the opinion of the appointing authority, a lapse is
                                                    attributable to any member of the faulty or staff of the
                                                    institution, in the matter of reporting or taking prompt action to
                                                    prevent an incident of ragging or who display an apathetic or
                                                    insensitive attitude towards complaints of ragging, or who fail to
                                                    take timely steps, whether required under these Regulations or
                                                    otherwise, to prevent an incident or incidents of ragging, then such
                                                    authority shall initiate departmental disciplinary action, in
                                                    accordance with the prescribed procedure of the institution, against
                                                    such member of the faulty or staff. Provided that where such lapse
                                                    is
                                                    attributable to the Head of the institution, the authority
                                                    designated to appoint such Head shall take such departmental
                                                    disciplinary action; and such action shall be without prejudice to
                                                    any action that may be taken under the penal laws for abetment of
                                                    ragging for failure to take timely steps in the prevention of
                                                    ragging or punishing any student found guilty of ragging.
                                                </li>
                                                <li>An appeal against the order of punishment by the Anti-Ragging
                                                    Committee shall lie,
                                                    <ul>
                                                        <li>in case of an order of an institution,
                                                            affiliated to or constituent part, of a University, to the
                                                            Vice-Chancellor of the University;
                                                        </li>
                                                        <li>in case of an order of a
                                                            University, to its Chancellor.
                                                        </li>
                                                        <li>in case of an institution of
                                                            national importance created by an Act of Parliament, to the
                                                            Chairman
                                                            or Chancellor of the institution, as the case may be.
                                                        </li>
                                                    </ul>

                                                </li>

                                            </ul>
                                        </li>
                                    </ol>


                                    <h4 className="text-theme-colored2">SUMMARY OF THE JUDGMENT OF THE HON. SUPREME
                                        COURT
                                        DELIVERED ON THE 8th MAY 2009.
                                    </h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                    <ol className="list-height">
                                        <li>The Hon. Supreme court ordered that a number of recommendations made by the
                                            Raghavan Committee be implemented immediately. These included:
                                            <ul>
                                                <li>Confidence building measures such as appointment of counsellors,
                                                    arrival of senior students a week or two weeks after the Juniors
                                                    have arrived; joint sensitization programmes; joint orientation
                                                    programme of 'freshers' and ‘seniors’ to be addressed by the
                                                    principal/Head of the institution; organization on large scale of
                                                    cultural, sports and other activities; make provisions for faculty
                                                    members to dine with the hostel residents in their respective
                                                    hostels etc.
                                                </li>
                                                <li>Every institution must have an Anti-Ragging Committee and an Anti
                                                    - Ragging Squad. There should be a Monitoring Cell on Ragging at the
                                                    University Level that would coordinate with the affiliated colleges
                                                    and institutions under its domain. There should be a Monitoring Cell
                                                    at the level of the Chancellor of the State Universities.
                                                </li>
                                                <li>In the, light of the increasing number of private commercially
                                                    managed, lodges or hostels outside campuses, such hostels and
                                                    management must be registered with the local police authorities and
                                                    permission to start such hostels or register them must necessarily
                                                    be recommended by the Heads of educational institutions. It should
                                                    be mandatory for both local police, local administration as well the
                                                    institutional authorities to ensure vigil on incidents that may come
                                                    within the definition of ragging.
                                                </li>
                                                <li>Wardens must be accessible at all hours and therefore it is
                                                    important that they, be available on telephone and other modes of
                                                    communication. Similarly, the telephone numbers of the other
                                                    important functionaries - Heads of institutions, faculty members,
                                                    members of the anti-ragging committees, district and sub-divisional
                                                    authorities and state authorities where relevant, should also be
                                                    widely disseminated for the needy to get in touch or seek help in
                                                    emergencies.
                                                </li>
                                                <li>Brochures or booklet/leaflet distributed to each student at the
                                                    beginning of each academic session for obtaining undertaking not to
                                                    indulge or abet ragging, shall contain the blueprint of prevention
                                                    and methods of redress."
                                                </li>
                                                <li>The educational institutions shall ensure that each hostel should
                                                    have a full-time warden who resides within the hostel, or at the
                                                    very least, in the close vicinity thereof.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>The Hon. Supreme Court acknowledged that The Ministry of Human Resource
                                            Development, Government of India, in consultation with UGC, MCI, AICTE and
                                            other similar regulatory bodies was in the process of setting up a central
                                            crisis-hotline and anti-ragging database in the manner suggested by Dr. Raj
                                            Kachroo. The Hon. Court , however, added that:
                                            <ul>
                                                <li>The task of monitoring the database be given to a nongovernmental
                                                    agency, to be immediately nominated by the Union of India to build
                                                    confidence in the public and also to provide information of non
                                                    compliance to the regulatory bodies and to the Raghavan Committee.
                                                </li>
                                                <li>The database shall be created out of affidavits affirmed by each
                                                    student and his/her parents/guardians, which affidavits shall be
                                                    stored electronically, and shall contain the details of each
                                                    student.
                                                </li>
                                                <li>The database shall also function as a record of ragging complaints
                                                    received, and the status of the action taken thereon.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>The Hon. Supreme Court ordered that Regulations on Curbing the Menace of
                                            Ragging, formulated by the UGC, must be adopted by all other regulatory
                                            bodies, such as AICTE, MCI, DCI, NCI etc.
                                        </li>
                                        <li>The Hon. Supreme Court acknowledged that the incident involving the death of
                                            Aman Kachroo clearly indicated that the formulation of guidelines and
                                            regulations was not sufficient. Hence, the Hon. Court ordered that such
                                            regulations shall have to be enforced strictly, and penal consequences for
                                            the heads of the institutions/administration of the institution who do not
                                            take timely steps in the prevention of ragging and punishing those who rag.
                                            In addition to penal consequences, departmental enquiries be initiated
                                            against such heads institutions / members of the administration / faculty
                                            members / non-teaching staff, who display an apathetic or insensitive
                                            attitude towards complaints of ragging;
                                        </li>
                                        <li>The Hon. Supreme Court said that not only the students, but also the faculty
                                            must be sensitized towards the ills of ragging, and the prevention thereof.
                                            Non-teaching staff, which includes administrative staff, contract employees,
                                            security guards etc., have also to be regularly sensitized towards the evils
                                            and consequences of ragging;
                                        </li>
                                        <li>The Hon. Supreme Court ordered that the Principal or Head of the
                                            Institution/Department shall obtain an undertaking from every employee of
                                            the institution including teaching and non-teaching members of staff,
                                            contract labour employed in the premises either for running canteen or as
                                            watch and ward staff or for cleaning or maintenance of the
                                            buildings/lawns etc. that he/she would report promptly any case of ragging
                                            which comes to his/her notice. A provision shall be made in the service
                                            rules for issuing certificates of appreciation to such members of the staff
                                            who report ragging which will form port of their service record.
                                        </li>
                                        <li>The Hon. Supreme Court said that it was necessary that parents/guardians of
                                            freshers assume responsibility for promptly bringing to the notice of the
                                            Head of the Institution any instance of ragging.
                                        </li>
                                        <li>The Hon. Supreme Court said that the SHO/SP, within whose jurisdiction a
                                            particular college falls, shall be responsible for ensuring that no ragging
                                            takes place on the campus of the concerned college, and to effectively deal
                                            with incidents of ragging, should, any such incidents take place. Once a
                                            central database/crisis hotline is made operative then as soon as SHO/SP,
                                            within whose jurisdiction a particular college falls, is contacted by the
                                            crisis hotline staff, then such SHO/SP shall deal effectively with the
                                            incident and cooperate and communicate with the crisis hotline staff and/or
                                            the independent monitoring agency. This will build confidence and encourage
                                            people to report incidences of ragging without fear or delay;
                                        </li>
                                        <li>The Hon. Supreme court said that once the database/crisis hotline is
                                            operative, State Governments shall amend their anti-ragging statutes to
                                            include provisions that place penal consequences on institutional heads.
                                        </li>
                                    </ol>


                                    <h4 className="text-theme-colored2">Why should I & How can I use On Line affidavits
                                        ?</h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                    <h5 className="text-theme-colored2">Why?</h5>
                                    <ol className="list-height">
                                        <li>It is mandatory for every student and his/her parents to submit an anti
                                            ragging affidavit at the time of first admission and there after each year
                                            at the time of annual registration. These are UGC’s regulations.
                                        </li>
                                        <li>It is the order of the Hon. Supreme Court that contact details of
                                            students must be collected from these affidavits and stored electronically
                                            at a central location.
                                        </li>
                                        <li>Uptil now each college collected such information. But it was not stored
                                            in any central location. But this year the Ragging Prevention Program
                                            developed an ON LINE procedure for downloading anti ragging affidavits. As a
                                            result college authorities do not have to collect information separately and
                                            compile it. It will save a lot of their time and energy.
                                        </li>
                                    </ol>
                                    <h5 className="text-theme-colored2">Why?</h5>
                                    <ol className="list-height">
                                        <li>It is a simple procedure comprising 3 steps
                                            <ul className="no-decoration">
                                                <li>Step 1: Log on to <a className="text-theme-colored2"
                                                                         href="https://www.ANTIRAGGING.in"
                                                                         target="_blank"> www.ANTIRAGGING.in</a> or <a
                                                    className="text-theme-colored2"
                                                    href="https://amanmovement.org/registration/public/amanmovement/"
                                                    target="_blank">www.AMANMOVEMENT.org.</a> and Click
                                                    on the button called
                                                    – On line affidavits.
                                                </li>
                                                <li>Step 2: Fill in the information as desired and submit the form.</li>
                                                <li>Step 3: On successful completion you will receive affidavits, both
                                                    for Students and Parents, through E mail.
                                                </li>
                                            </ul></li>
                                        <li>If you do not have an E mail address please create one before you log in. If
                                            your parents do not have an E Mail/Mobile/ Landline

                                            Phone number please do not panic. You can give those of your friends or
                                            relatives. There is absolutely nothing to worry. If you make a mistake while
                                            submitting your form you can start a fresh and submit the information again.
                                            There is no problem. It is a very easy process.
                                        </li>
                                    </ol>


                                </div>
                            </Tab>

                            <Tab eventKey={2} title="Anti-Ragging Committee">
                                <div className="container text-justify">
                                    <p>In view of the judgement of the
                                        Hon’ble Supreme Court in Civil Appeal
                                        No.887 of 2009 dated 08.05.2009, to prohibit, prevent and
                                        eliminate the scourge of ragging, <b>an Anti -
                                            Ragging Committee, Anti Ragging squad
                                            and Ragging Monitoring Cell </b> consisting of the following
                                        members are constituted. This committee will
                                        ensure the compliance with the provisions of the UGC /
                                        AICTE regulations along with provisions
                                        of any law inforce for the time being concerning
                                        ragging and will also monitor and oversee
                                        the performance of Anti Ragging
                                        Squad in prevention of ragging in the institute.
                                    </p>

                                    <h4 className="text-theme-colored2">
                                        Details about the Anti-Ragging Committee 2024-2025
                                    </h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>

                                    <table className={"table table-responsive table-hover"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Members</th>
                                            <th className={"text-center"}>Designation</th>
                                            <th className={"text-center"}>Mobile No.</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>Vice Chancellor, SBSSU,
                                                Gurdaspur
                                            </td>
                                            <td className={"text-center"}>Chairman</td>
                                            <td className={"text-center"}>9815653261
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>Advocate Manmohan Kaur,
                                                Punjab and Haryana High Court,
                                                Chandigarh
                                            </td>
                                            <td className={"text-center"}>Member Legal</td>
                                            <td className={"text-center"}>9876141037
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3</td>
                                            <td className={"text-center"}>Deputy Commissioner or his nominee
                                            </td>
                                            <td className={"text-center"}>Member Civil / Administration

                                            </td>
                                            <td className={"text-center"}>1874247500
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>4</td>
                                            <td className={"text-center"}>SSP or his nominee
                                            </td>
                                            <td className={"text-center"}>Member Police
                                                Administration
                                            </td>
                                            <td className={"text-center"}>1874241173
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>5</td>
                                            <td className={"text-center"}>Sh. Vinod Gupta, Bureau Chief Punjab
                                                Kesari
                                            </td>
                                            <td className={"text-center"}>Member Media
                                            </td>
                                            <td className={"text-center"}>9814114566
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>6</td>
                                            <td className={"text-center"}>Sh. Romesh Mahajan, President, De-Addiction
                                                society ,Gurdaspur
                                            </td>
                                            <td className={"text-center"}>Member NGO Involved in youth
                                                activities
                                            </td>
                                            <td className={"text-center"}>9814577709
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>7</td>
                                            <td className={"text-center"}>
                                                Registrar, SBSSU, Gurdaspur
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9855559956
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>8</td>
                                            <td className={"text-center"}>
                                                Dr. S.K.Gupta ,HOD CSE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>8054182334
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>9</td>
                                            <td className={"text-center"}>
                                                Dr. Dilbag Singh,HOD, Mech Engineering
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>8872458883
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>10</td>
                                            <td className={"text-center"}>Dr. R.C Gangwar, Principal Diploma Wing
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9877689893
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>11</td>
                                            <td className={"text-center"}>
                                                Dr. Ajay Kumar, HOD,ECE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9855559956
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>12</td>
                                            <td className={"text-center"}>
                                                Dr. Rakesh Kumar Dogra ,HOD, Applied Physics
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9872150166
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>13</td>
                                            <td className={"text-center"}>Dr. Anju Awasthi, HOD, Applied Chemistry</td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>8872700517
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>14</td>
                                            <td className={"text-center"}>
                                                Dr. Jagdev Singh, HOD, Civil Engg.
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>
                                                8872053020
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>15</td>
                                            <td className={"text-center"}>Dr. R.K. Awasthi, HOD, BSc. Agri
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9464090076
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>16</td>
                                            <td className={"text-center"}>
                                                Sh. Baljeev Kumar, HOD CHE ,Bio Technology

                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9417198711
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>17</td>
                                            <td className={"text-center"}>
                                                Sh. Raj Kumar, Clerk
                                            </td>
                                            <td className={"text-center"}>Member Non Teaching</td>
                                            <td className={"text-center"}>8725817770
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>18</td>
                                            <td className={"text-center"}>
                                                Ms. Shruti ,2nd Year CSE
                                            </td>
                                            <td className={"text-center"}>Member Student</td>
                                            <td className={"text-center"}>9103290977
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>19</td>
                                            <td className={"text-center"}>
                                                Ms. Chahat Gupta, 3rd Year CSE
                                            </td>
                                            <td className={"text-center"}>Member Student
                                            </td>
                                            <td className={"text-center"}>6005010642
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>20</td>
                                            <td className={"text-center"}>Mr. Anant Raj, 2nd Year CSE
                                            </td>
                                            <td className={"text-center"}>Member Student
                                            </td>
                                            <td className={"text-center"}>8084969883
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>21</td>
                                            <td className={"text-center"}>
                                                Mr. Anil Kumar (Parent of Mr. Param ,ME 2nd Year )

                                            </td>
                                            <td className={"text-center"}>Member Parent
                                            </td>
                                            <td className={"text-center"}>8727068855
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>22</td>
                                            <td className={"text-center"}>Mr. Romesh Chander Sharma
                                                (Parent of Ms. Kirtika Sharma ,CSE 2nd Year)

                                            </td>
                                            <td className={"text-center"}>Member Parent</td>
                                            <td className={"text-center"}>

                                                9086212709

                                            </td>
                                        </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </Tab>

                            <Tab eventKey={3} title="Anti-Ragging Squad">
                                <div className="container text-justify">
                                    <p>In view of the judgement of the
                                        Hon’ble Supreme Court in Civil Appeal
                                        No.887 of 2009 dated 08.05.2009, to prohibit, prevent and
                                        eliminate the scourge of ragging, <b>an Anti -
                                            Ragging Committee, Anti Ragging squad
                                            and Ragging Monitoring Cell </b> consisting of the following
                                        members are constituted. This committee will
                                        ensure the compliance with the provisions of the UGC /
                                        AICTE regulations along with provisions
                                        of any law inforce for the time being concerning
                                        ragging and will also monitor and oversee
                                        the performance of Anti Ragging
                                        Squad in prevention of ragging in the institute.
                                    </p>
                                    <h4 className="text-theme-colored2">
                                        Details about the Anti-Ragging Squad 2024-2025
                                    </h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>

                                    <table className={"table table-responsive table-hover"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Members</th>
                                            <th className={"text-center"}>Designation</th>
                                            <th className={"text-center"}>Mobile No.</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>
                                                Dr. Om Pal Singh, Chief Warden
                                            </td>
                                            <td className={"text-center"}>Chairman</td>
                                            <td className={"text-center"}>9855472059
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>Sh. Sanjeev Mahajan, Warden Hostel No. 3&1
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9878053388
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3</td>
                                            <td className={"text-center"}>

                                                Dr. Rajesh Sharma, Warden Hostel No. 2&4
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9872178653
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>4</td>
                                            <td className={"text-center"}>
                                                Mrs. Anita Suman, Warden Hostel No.5

                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9464068400
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>5</td>
                                            <td className={"text-center"}>
                                                Mrs. Neetu Associate Professor ECE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9872204441
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>6</td>
                                            <td className={"text-center"}>
                                                Dr. Sarbjit Singh, Associate Professor ME

                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9814888828
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>7</td>
                                            <td className={"text-center"}>
                                                Sh. Sukhbir Singh, AP CSE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9888501582
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>8</td>
                                            <td className={"text-center"}>
                                                Dr. Seema, AP CHE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9463211091
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>9</td>
                                            <td className={"text-center"}>
                                                Sh. P.K. Yadav, AP CHE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>8837663521
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>10</td>
                                            <td className={"text-center"}>
                                                Sh. Surinder Singh, Foremen Instructor, Central
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>8146072255
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>11</td>
                                            <td className={"text-center"}>
                                                Sh. Baljinder Singh, AP IT
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9878692288
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>12</td>
                                            <td className={"text-center"}>
                                                Sh. Gurnam Singh, President Sports
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>8360966202
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>13</td>
                                            <td className={"text-center"}>
                                                Sh. Pritpal Singh, DPE
                                            </td>
                                            <td className={"text-center"}>Member</td>
                                            <td className={"text-center"}>9915870672
                                            </td>
                                        </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </Tab>

                            <Tab eventKey={4} title="Ragging Monitoring Cell">
                                <div className="container text-justify">
                                    <p>In view of the judgement of the
                                        Hon’ble Supreme Court in Civil Appeal
                                        No.887 of 2009 dated 08.05.2009, to prohibit, prevent and
                                        eliminate the scourge of ragging, <b>an Anti -
                                            Ragging Committee, Anti Ragging squad
                                            and Ragging Monitoring Cell </b> consisting of the following
                                        members are constituted. This committee will
                                        ensure the compliance with the provisions of the UGC /
                                        AICTE regulations along with provisions
                                        of any law inforce for the time being concerning
                                        ragging and will also monitor and oversee
                                        the performance of Anti Ragging
                                        Squad in prevention of ragging in the institute.
                                    </p>
                                    <h4 className="text-theme-colored2">
                                        Details about the Ragging Monitoring Cell 2024-2025
                                    </h4>
                                    <div className="double-line-bottom-theme-colored-2"></div>

                                    <table className={"table table-responsive table-hover"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Members</th>
                                            <th className={"text-center"}>Designation</th>
                                            <th className={"text-center"}>Mobile No.</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>Dr. Jagdev Singh
                                            </td>
                                            <td className={"text-center"}>Professor, ME</td>
                                            <td className={"text-center"}>8872053020
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}>Dr. Rakesh Dogra

                                            </td>
                                            <td className={"text-center"}>Professor, Applied Physics</td>
                                            <td className={"text-center"}>9872150166
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3</td>
                                            <td className={"text-center"}>Dr. R.C.Gangwar
                                            </td>
                                            <td className={"text-center"}>Professor, CSE</td>
                                            <td className={"text-center"}>9877689893
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>4</td>
                                            <td className={"text-center"}>Dr. Arvind Sharma
                                            </td>
                                            <td className={"text-center"}>Professor, Applied Physics</td>
                                            <td className={"text-center"}>8847549049
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>5</td>
                                            <td className={"text-center"}>Dr. Jaswinder Singh
                                            </td>
                                            <td className={"text-center"}>Professor, ECE</td>
                                            <td className={"text-center"}>9463430078
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>6</td>
                                            <td className={"text-center"}>Dr. Gurpadam Singh


                                            </td>
                                            <td className={"text-center"}>Associate Professor, ECE</td>
                                            <td className={"text-center"}>9914106036
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>7</td>
                                            <td className={"text-center"}>Dr. Vipan Kumar
                                            </td>
                                            <td className={"text-center"}>Associate Professor, CHE
                                            </td>
                                            <td className={"text-center"}>9888220918
                                            </td>
                                        </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </Tab>

                            <Tab eventKey={5} title="National Ragging Prevention Program"></Tab>
                        </Tabs>
                    </div>
                </div>
            </div>


            <Footer/>
        </>
    );
}

export default AntiRagging;