import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";

function OSD() {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="OSD To Vice Chancellor"
                               bdLink="OSD To Vice Chancellor"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h2 className="text-theme-colored2 mb-15"> OSD To Vice Chancellor </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div>
                            <div className="row">
                                <div className="col-md-8">
                                    <p className="text-justify">Welcome to our esteemed university, a hub of academic
                                        excellence and innovation in the field of engineering and beyond!</p>
                                    <p className="text-justify">As the Officer on Special Duty to the Vice-Chancellor, it
                                        is my privilege to extend a warm and heartfelt greeting to all prospective students,
                                        parents, and visitors to our website.</p>
                                    <p className="text-justify">At our university, we take pride in our commitment to
                                        nurturing bright minds and fostering a passion for learning. With a rich legacy spanning
                                        several decades, we have emerged as a leading institution that offers a diverse range of
                                        courses to cater to the varied interests and aspirations of our students.</p>
                                    <p className="text-justify">Our engineering courses, backed by state-of-the-art
                                        facilities and expert faculty, provide students with a solid foundation to thrive in the
                                        ever-evolving technological landscape. We believe in empowering our engineering students
                                        to think critically, innovate, and apply their knowledge to real-world challenges.</p>
                                </div>
                                <div className="col-md-4">
                                    <img src="/assets/images/staff/rajeevkumarbedi.jpg" alt="" style={{width: '100%'}}/>
                                    <p className="text-center text-theme-colored2">
                                        <b>Dr Rajeev Kumar Bedi</b>
                                    </p>
                                </div>
                            </div>

                            <p className="text-justify">Moreover, our graduation and post-graduation programs cover an
                                extensive array of disciplines, spanning arts, sciences, humanities, commerce, and more.
                                Our goal is to provide a holistic education that prepares students not only for
                                successful careers but also to become responsible global citizens who can contribute
                                positively to society.</p>
                            <p className="text-justify">Apart from academic pursuits, we emphasize holistic development
                                and encourage students to participate in various co-curricular activities, clubs, and
                                sports to enhance their interpersonal skills and nurture leadership qualities.</p>
                            <p className="text-justify">As an institution, we prioritize research and development,
                                working at the cutting edge of knowledge to make groundbreaking discoveries and solve
                                complex problems. Our commitment to innovation drives us to explore new avenues and
                                collaborate with industry leaders and research institutions.</p>
                            <p className="text-justify">We understand the importance of inclusivity and diversity in
                                creating a vibrant learning environment. Our university welcomes students from all
                                backgrounds, cultures, and walks of life, fostering an atmosphere of tolerance, mutual
                                respect, and camaraderie.</p>
                            <p className="text-justify">Through this website, we aim to provide comprehensive
                                information about our programs, admission procedures, faculty, infrastructure, and
                                upcoming events. Our dedicated team is here to assist you at every step of your academic
                                journey and address any queries you may have.</p>
                            <p className="text-justify">As we embark on this journey together, we invite you to be a
                                part of our close-knit community, where knowledge knows no bounds, and dreams become
                                reality. Together, let us create a brighter future for generations to come.</p>
                            <p className="text-justify">Thank you for considering our university as your educational
                                destination. We look forward to welcoming you with open arms.
                            </p>

                           <b>Best regards, <br/> Dr Rajeev Kumar Bedi <br/> Sardar Beant Singh State University</b>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default OSD;