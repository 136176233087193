import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Link} from "react-router-dom";


const DeanAcademicAffairs = (props) => {

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Academics" subTitle="Dean of Academic Affair"
                               bdLink="Academics"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Dean of Academic Affair</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-3 padd-10-v">
                                <h4 className="text-theme-colored2 padd-10-v">Useful Links</h4>
                                <ul className="dean_ul">
                                    <li className="dean_li">
                                        <a href="./assets/pdf/academic/Academic_Section_Staff.pdf" target="_blank">Academic Section Staff</a>
                                    </li>
                                    <li className="dean_li">
                                        <Link to="/academicperforma">Academic Performa</Link>
                                    </li>

                                    {/*<li className="dean_li">*/}
                                    {/*    <a href="/assets/pdf/academic/UG-Manual.pdf"*/}
                                    {/*       target="_blank">UG Manual</a>*/}
                                    {/*</li>*/}

                                    {/*<li className="dean_li">*/}
                                    {/*    <a href="./assets/pdf/academic/PG-Manual.pdf" target="_blank">PG Manual</a>*/}
                                    {/*</li>*/}

                                    <li className="dean_li">
                                        <a href="./assets/pdf/academic/Academic-Automony-Letter.pdf" target="_blank">Academic Autonomy Letter</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/*from file*/}
                                    The Dean of an academic unit at SBSSU is the designated leader and administrative
                                    officer
                                    responsible for the effective and efficient operation of the University within the
                                    policies, directions
                                    and plans of the university as a whole.<br/><br/>
                                    {/*from word file*/}

                                    The Dean at SBSSU is the public spokesperson for his/her academic unit, internal and
                                    external
                                    to the
                                    university, and shall exercise the authority commensurate with the following
                                    responsibilities
                                    specifically vested in them by the trustees and/or delegated to them by the
                                    administrative officers
                                    of the university.
                                    The Dean is expected to establish and maintain a collegial work climate,
                                    cooperatively
                                    leading the academic unit towards improved productivity and relevancy, ensuring the
                                    academic
                                    integrity and curricular coherence of all programs embraced within it. <br/><br/>

                                </p>
                                {/*<p className="font-p-v m-7-v">*/}
                                {/*    Department of Applied Chemistry, Humanities & Management has emerged as one of the*/}
                                {/*    Science department of the university. The major strength of the department is highly*/}
                                {/*    qualified/experienced faculty and rich laboratory infrastructure. The faculty is*/}
                                {/*    involved in the research activities.</p>*/}

                            </div>
                            <div className="col-sm-2 padd-10-v text-center">
                                <img src="assets/images/faculty/DeanAcademinAffairs.png"
                                     className="img-responsive HOD-image-v" alt="" style={{'height':'150px','margin':'0px auto'}} />
                                <p className="text-theme-colored2"><strong> Dean Of Academic Affairs : Dr.
                                    Harish Pungotra </strong></p></div>
                        </div>
                    </div>

                    {/*<div className="row mb-25" id="myButtons m-7-v ">*/}
                    {/*     <h2 className="text-theme-colored2 padd-10-v ">Useful Links</h2>*/}
                    {/*    <div className="col-sm-4 col-xs-6 text-center">*/}
                    {/*        <a href="./assets/pdf/academic/Academic_Section_Staff.pdf" target="_blank"*/}
                    {/*           className="btn theme-btn btn-block myButton_A">Academic Section Staff</a>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-sm-4 col-xs-6 text-center">*/}
                    {/*        <a href="/academicperforma" target="_blank"*/}
                    {/*           className="btn theme-btn btn-block myButton_A">Academic Performa</a>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-sm-4 col-xs-6 text-center">*/}
                    {/*        <a href="/assets/pdf/academic/UG-Manual.pdf"*/}
                    {/*           target="_blank" className="btn btn-block theme-btn myButton_A">UG Manual</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="row">*/}
                    {/*    <div className="col-sm-4 col-xs-6 col-sm-offset-2 text-center">*/}
                    {/*        <a href="./assets/pdf/academic/PG-Manual.pdf" target="_blank"*/}
                    {/*           className="btn theme-btn btn-block myButton_A">PG Manual</a>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-sm-4 col-xs-6 text-center">*/}
                    {/*        <a href="./assets/pdf/academic/Academic-Automony-Letter.pdf" target="_blank"*/}
                    {/*           className="btn theme-btn btn-block myButton_A">Academic Autonomy Letter</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default DeanAcademicAffairs;