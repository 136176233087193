import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import FacultyCard from "../components/FacultyCard";
import axios from "axios";
import {URL} from "../components/MyModules";

const DeptChemistryHumanitiesManagement = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(6);
    }, [])

    function FetchFaculty(key) {
        console.log(key + ' ******')
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Applied Chemistry, Humanities & Management "
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Chemistry, Humanities & Management</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    The Department of Applied Sciences, Humanities and Management from its modest
                                    beginning in 1995 has now grown into an active center of quality teaching and
                                    research. This department was created with the mission of generating human resource,
                                    expanding human knowledge and benefit society through education and research on
                                    fundamental and Applied aspects of Science, Engineering and Technology in an
                                    interdisciplinary atmosphere.
                                </p>
                                <p className="font-p-v m-7-v">
                                    Department of Applied Chemistry, Humanities & Management has emerged as one of the
                                    Science department of the university. The major strength of the department is highly
                                    qualified/experienced faculty and rich laboratory infrastructure. The faculty is
                                    involved in the research activities.</p>

                            </div>
                            <div className="col-sm-5 padd-10-v">
                                <img src="assets/images/faculty/HOD_Chemistry_Anju.jpg"
                                     className="img-responsive HOD-image-v" alt=""/>
                                <p className="text-left-10 text-theme-colored2"><strong> HOD, Department : Dr. Anju
                                    Awasthi </strong></p></div>
                        </div>
                        <div>
                            <p className="font-p-v m-7-v">
                                The department comprises multi-disciplinary faculty members, at present, mainly
                                related to Physics, Chemistry, Mathematics, Humanities (Communication English) and
                                Management background to cater the needs of multi-disciplinary students at B.C.E.T.
                                Campus, Gurdaspur. Our faculty members and scholars provide a home to the most
                                promising doctoral and post-doctoral research with an access to educational/Research
                                Laboratory resources and unparalleled laboratory and library facilities for both
                                Basic/Applied and Engineering research. Growing steadily, today the department not
                                only teaches various topics in B.Tech. and M.Tech. courses, but also runs its own
                                M.Sc. Courses in Applied Mathematics and Applied Physics. Besides the Central
                                Computing facilities of the Institute, the department has its own Numerical Analysis
                                Lab. The Department is engaged in providing highest level and quality of academic
                                education. The main focus of the teaching and research in the department is centered
                                on interdisciplinary themes. The students are provided a comprehensive practical
                                exposure to the various instrumental techniques required for their further
                                education.</p>

                        </div>
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MbaDigitalMarketing">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/11.jpeg" className="Image_A"
                                             alt="MBA in Digital Marketing"/>
                                    </div>
                                    <p className="myColor_A">MBA in Digital Marketing</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MscChemistry">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/9.jpeg" className="Image_A"
                                             alt="M.Sc. Chemistry (Regular)"/>
                                    </div>
                                    <p className="myColor_A">M.Sc. Chemistry (Regular)</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BbaMarketing">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/23.jpg" className="Image_A"
                                             alt="BBA in Marketing"/>
                                    </div>
                                    <p className="myColor_A">BBA in Marketing</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <h4 className="text-theme-colored2 m-7-v">HOD</h4>

                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    {/*<h3 className="">Associate Professor</h3>*/}
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            <h4 className="text-theme-colored2 m-7-v">Management Faculty</h4>

                            {
                                assiprofData.length > 0 ? <>
                                    {/*<h3 className="">Assistant Professor</h3>*/}
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                if (row.facultyname === "Dr. Vishal Mahajan") {
                                                    return (
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    );
                                                }
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            <h4 className="text-theme-colored2 m-7-v">Chemistry Faculty</h4>
                            {
                                assiprofData.length > 0 ? <>
                                    {/*<h3 className="">Assistant Professor</h3>*/}
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                if (row.facultyname !== "Amandeep" && row.facultyname !== 'Dr. Vishal Mahajan') {
                                                    return (
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    );
                                                }
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }
                            {
                                otherprofData.length > 0 ? <>
                                    {/*<h3 className="">Lecturer</h3>*/}
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            <h4 className="text-theme-colored2 m-7-v">English Faculty</h4>

                            {
                                assiprofData.length > 0 ? <>
                                    {/*<h3 className="">Assistant Professor</h3>*/}
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                if (row.facultyname === "Amandeep") {
                                                    return (
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    );
                                                }
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptChemistryHumanitiesManagement;