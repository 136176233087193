import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const BtechBioTech = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Undergraduate Courses" subTitle="B.Tech(Bio-Tech.)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Bachelor of Technology: Bio-Technology
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The department of Biotechnology offers four years course at undergraduate level
                                    leading to the award of B. Tech. degree in the discipline of Biotechnology. In this
                                    four-year degree programme, students will learn theory and practice in the core
                                    areas of Biotechnology such as Microbiology, Biochemistry, Genetics, Cell and
                                    Molecular Biology, Bio-Process Engineering, Immunology etc. Student will also learn
                                    the principles of environment and non conventional fuels for the betterment of the
                                    society. Students will undergo Industrial training for six months to use knowledge
                                    gain in the university campus.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    The scope of BTech Biotechnology is not restricted and it is very diverse that
                                    students can easily progress into any stream easily. The perks of BTech
                                    Biotechnology have been listed below :
                                    <ul className="m-7-v">
                                        <li>Multiple opportunities</li>
                                        <li>Higher salary packages</li>
                                        <li>Further Education</li>
                                        <li>Research options</li>
                                    </ul>
                                </p>


                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/BtechBioTech.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                Passed the 10+2 examination from a board recognized or established by central/state
                                government through a legislation and a member of Boards of School Education (COBSE), New
                                Delhi with Physics/ Mathematics/ Chemistry / Biotechnology / Computer Science / Biology
                                / Electronics/ Information Technology/ Informatics Practices / Technical Vocational
                                Subject / Agriculture / Engineering Graphics / Entrepreneurship (Any of three). Obtained
                                at least 45% marks (40% in case of Candidates belonging to reserved category) in the
                                above subject taken together.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 4 years long, with Eight Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {/* 2023 */}
                                    {/*<tr>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">2023</td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v"><a*/}
                                    {/*        className="btn margin-x-25-v theme_btn_V theme-btn"*/}
                                    {/*        href="/assets/pdf/admissions/UG/BTech/revised-syllabus-Scheme-2023-merged.pdf"*/}
                                    {/*        target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span*/}
                                    {/*        className="hide-v"> Download*/}
                                    {/*        Syllabus</span></a></td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>*/}
                                    {/*</tr>*/}

                                    {/* 2023 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">First Year</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BTech/revised-syllabus-scheme-2023-merged-first-year.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    {/* 2021 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">First Year</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/FY_BTECH2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-BioTech-2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-BioTech-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2020 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-BioTech-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2018 Onwards</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                    {/*  Campus Placements  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Campus Placements</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <th className="table-title-V padd-l-10">Name</th>
                                    <th className="table-title-V ">Roll No.</th>
                                    <th className="table-title-V">Pass out Batch</th>
                                    <th className="table-title-V">Name of Company</th>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Gurkirat</td>
                                        <td className="table-body-padd-V syllabus-table-v">1237653</td>
                                        <td className="table-body-padd-V syllabus-table-v">2016</td>
                                        <td className="table-body-padd-V syllabus-table-v">Panacea Biotech Pvt Ltd.</td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Ishan Sharma</td>
                                        <td className="table-body-padd-V syllabus-table-v">1237656</td>
                                        <td className="table-body-padd-V syllabus-table-v">2016</td>
                                        <td className="table-body-padd-V syllabus-table-v">Intas Pharmaceutical Pvt.
                                            Ltd.
                                            (Biopharma Division)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Jaspreet Kaur</td>
                                        <td className="table-body-padd-V syllabus-table-v">1237657</td>
                                        <td className="table-body-padd-V syllabus-table-v">2016</td>
                                        <td className="table-body-padd-V syllabus-table-v">eCLERX services LTD,
                                            Chandigarh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Palika Mahajan</td>
                                        <td className="table-body-padd-V syllabus-table-v">1237667</td>
                                        <td className="table-body-padd-V syllabus-table-v">2016</td>
                                        <td className="table-body-padd-V syllabus-table-v">OMICS International Pvt Ltd
                                            Hyderabad
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Manish Kumar</td>
                                        <td className="table-body-padd-V syllabus-table-v">1237663</td>
                                        <td className="table-body-padd-V syllabus-table-v">2016</td>
                                        <td className="table-body-padd-V syllabus-table-v">OMICS International Pvt Ltd
                                            Hyderabad
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Purnima</td>
                                        <td className="table-body-padd-V syllabus-table-v">1237674</td>
                                        <td className="table-body-padd-V syllabus-table-v">2016</td>
                                        <td className="table-body-padd-V syllabus-table-v">IDS Infotech
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Jaskiran Kaur</td>
                                        <td className="table-body-padd-V syllabus-table-v">1303090</td>
                                        <td className="table-body-padd-V syllabus-table-v">2017</td>
                                        <td className="table-body-padd-V syllabus-table-v">E-Clerx, Eco Paryavaran
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Kirandeep Kaur</td>
                                        <td className="table-body-padd-V syllabus-table-v">1303091</td>
                                        <td className="table-body-padd-V syllabus-table-v">2017</td>
                                        <td className="table-body-padd-V syllabus-table-v">Mahendra Pvt Ltd
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Manik Sharma</td>
                                        <td className="table-body-padd-V syllabus-table-v">1303093</td>
                                        <td className="table-body-padd-V syllabus-table-v">2017</td>
                                        <td className="table-body-padd-V syllabus-table-v">Agilent technologies
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Monika Choudhary</td>
                                        <td className="table-body-padd-V syllabus-table-v">1303094</td>
                                        <td className="table-body-padd-V syllabus-table-v">2017</td>
                                        <td className="table-body-padd-V syllabus-table-v">Panera Biotec Pvt Ltd
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Lovenish Sohal</td>
                                        <td className="table-body-padd-V syllabus-table-v">1303092</td>
                                        <td className="table-body-padd-V syllabus-table-v">2017</td>
                                        <td className="table-body-padd-V syllabus-table-v">Panera Biotec Pvt Ltd
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Shivali</td>
                                        <td className="table-body-padd-V syllabus-table-v">1303096</td>
                                        <td className="table-body-padd-V syllabus-table-v">2017</td>
                                        <td className="table-body-padd-V syllabus-table-v">Panera Biotec Pvt Ltd
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Rohit Kumar</td>
                                        <td className="table-body-padd-V syllabus-table-v">1403046</td>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v">Life Cell International Pvt
                                            Ltd.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Ruchika</td>
                                        <td className="table-body-padd-V syllabus-table-v">1403047</td>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v">Chandigarh Pollution Testing
                                            Laboratory, Chandigarh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Sabhi Mahajan</td>
                                        <td className="table-body-padd-V syllabus-table-v">1403048</td>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v">OMICS International Pvt. Ltd.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Aishwarya Mahajan</td>
                                        <td className="table-body-padd-V syllabus-table-v">1501224</td>
                                        <td className="table-body-padd-V syllabus-table-v">2019</td>
                                        <td className="table-body-padd-V syllabus-table-v">Stryker India Pvt Ltd.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Ashish</td>
                                        <td className="table-body-padd-V syllabus-table-v">1501227</td>
                                        <td className="table-body-padd-V syllabus-table-v">2019</td>
                                        <td className="table-body-padd-V syllabus-table-v">OMICS International Pvt. Ltd.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Ashwani</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1501228</td>
                                        <td className="table-body-padd-V syllabus-table-v">2019</td>
                                        <td className="table-body-padd-V syllabus-table-v">Ananta Medicare Pvt Ltd
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Vikas</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1501259</td>
                                        <td className="table-body-padd-V syllabus-table-v">2019</td>
                                        <td className="table-body-padd-V syllabus-table-v">IDS INFOTECH PVT LTD,
                                            Chandigarh
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Karan Mehra</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1501239</td>
                                        <td className="table-body-padd-V syllabus-table-v">2019</td>
                                        <td className="table-body-padd-V syllabus-table-v">Him Pharmaceutical pvt ltd.
                                            H.P
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Saloni Mahajan</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1501252</td>
                                        <td className="table-body-padd-V syllabus-table-v">2019</td>
                                        <td className="table-body-padd-V syllabus-table-v">PULSUS HEALTHTECH LLP, New
                                            Delhi.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Sanjana Tandey</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1601490</td>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v">Byju's Pvt Ltd, Chandigarh.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Sourav Kumar</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1601492</td>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v">Iscon Balaji Foods Private
                                            Limited.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Paramjeet</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1601484</td>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v">The UNATI CO-OP Marketing cum
                                            Processing Society LTD.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> SHUBHPREET</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1701941</td>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v">TCS
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Ishmeet Kaur</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1701941</td>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v">Byju's Pvt Ltd, Chandigarh.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v"> Abhishek</td>
                                        <td className="table-body-padd-V syllabus-table-v"> 1801188</td>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v">Systacare Remedies Pvt Ltd,
                                            Amritsar
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>


                </div>
            </div>
            <Footer/>
        </>
    );
}

export default BtechBioTech;