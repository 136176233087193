import {useEffect} from "react";
import {Link} from "react-router-dom";
import {FaPhoneAlt, FaGlobeAsia, FaTwitter, FaLinkedinIn, FaFacebookF} from "react-icons/fa";
import {HiOutlineMail} from "react-icons/hi";

function Footer() {
    function ScrollToTop() {
        // window.scrollTo(0,0);
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    useEffect(() => {
        document.getElementById('scroll-top').style.display = "none";
    }, []);

    return (
        <>
            <footer className="copyrights">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 text-center footer-col-1">
                            <div className={"footer-logo-address"}>
                                <img src="../assets/images/logo/beantunilogo.png" alt=""/>

                                <h4 className={"mini-headings my-font-style"}>
                                    Sardar Beant Singh State University
                                </h4>
                                <h4 className={"mini-sub-headings"}>
                                    National Highway 15, Gurdaspur, Punjab 143530
                                </h4>
                            </div>

                            <ul className="check contact-details">
                                <li><Link to="#" className={"my-font-style"}><FaPhoneAlt/> 0187-4292879 </Link></li>
                                <li>
                                    <a href="mailto:registrarsbssu@gmail.com" className={"my-font-style"}>
                                        <HiOutlineMail/> registrarsbssu@gmail.com
                                    </a>
                                </li>
                                <li>
                                    <a href="http://www.sbssugsp.ac.in/"
                                       target="_blank" aria-label="Insta">
                                        <FaGlobeAsia/> http://www.sbssugsp.ac.in/
                                    </a>

                                    {/*<Link to="#" className={"my-font-style"}><FaGlobeAsia/> http://www.sbssugsp.ac.in/</Link>*/}
                                </li>
                            </ul>

                            <ul className={"social-links"}>
                                <li>
                                    <a href="https://www.instagram.com/invites/contact/?i=11oxr8gb7ldy&utm_content=nbn96ty"
                                       target="_blank" aria-label="Insta"><i className="fa fa-instagram text-white"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/Sardar-Beant-Singh-State-University-Gurdaspur-Punjab-104007688841306/"
                                       target="_blank"><FaFacebookF/></a>
                                </li>
                            </ul>
                        </div>
                        {/*end col*/}

                        <div className="col-md-3 col-sm-12">
                            <h4 className={"my-font-style headings-with-border"}>Quick Links</h4>

                            <ul className="check quick-link-1">
                                {/*<li><Link to="#">Disclaimer</Link></li>*/}
                                {/*<li><Link to="#">Feedback</Link></li>*/}
                                {/*<li><Link to="#">Reach Us</Link></li>*/}
                                <li><Link to="/Privacy-Policy">Privacy Policy</Link></li>
                                <li><Link to="/Refund-Policy">Refund Policy</Link></li>
                                <li><Link to="/Terms-Of-Use">Terms Of Use</Link></li>
                                {/*<li><Link to="#">Campus Life</Link></li>*/}
                                {/*<li><Link to="#">Important Links</Link></li>*/}
                                {/*<li><Link to="#">Alumni</Link></li>*/}
                            </ul>
                        </div>
                        {/*end col*/}

                        <div className="col-md-3 col-sm-12">
                            <h4 className={"my-font-style headings-with-border"}>Explore</h4>

                            <ul className="check quick-link-1">
                                <li>
                                    <Link to="#" target="_blank">Campus Rules</Link>
                                </li>
                                <li>
                                    <Link to="#" target="_blank">
                                        Grievance
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/IndependenceDay">
                                        <img src="assets/images/azadi-ka-amrit-mahotsav/logo.png"
                                             style={{width: '250px', height: '250px'}}
                                             alt="azadi-ka-amrit-mahotsav"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/*end col*/}

                        <div className="col-md-3 col-sm-12 text-center">
                            <div className="newsletter">
                                <a href="https://punjab.gov.in/" target="_blank">
                                    <p className="newsletter_p">Unit of <br/>Govt. of Punjab</p>
                                    <img src="../assets/images/punjabgovtlogo1.png" className="img-responsive"
                                         style={{'height': '200px', 'margin': '0px auto'}} alt="Punjab Govt. Logo"/>
                                </a>

                                {/*<form className="form-inline">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <input type="text" className="form-control"*/}
                                {/*               placeholder="Enter your email here.."/>*/}
                                {/*    </div>*/}
                                {/*    <button type="submit" className="btn btn-primary">Subscribe</button>*/}
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </div>

                <div id="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="copylinks">
                                    <h5 className={"my-font-style text-lg-left text-center-sm"}>
                                        Copyright &copy; {new Date().getUTCFullYear()} Sardar Beant Singh State University Gurdaspur. All Rights
                                        Reserved.
                                    </h5>
                                </div>
                            </div>
                            {/*end col*/}

                            <div className="col-md-6 col-sm-12">
                                <div className="footer-social">
                                    <h5 className={"my-font-style text-lg-right text-center-sm"}>
                                        Developed & Maintained by
                                        <a href="https://www.vmmeducation.com/" target="_blank"
                                           style={{textDecoration: "none"}}>
                                            <span style={{color: "#fff"}}> VMM</span>
                                        </a>
                                    </h5>
                                    {/*<Link onClick={ScrollToTop} className="dmtop" to="#"><i className="fa fa-angle-up"></i></Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* Scroll To Top Button */}
            <div className="scroll-to-top" id={"scroll-top"}>
                <Link onClick={ScrollToTop} className="dmtop" to="#"><i className="fa fa-angle-up"></i></Link>
            </div>
        </>
    );
}

export default Footer;