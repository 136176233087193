import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function NSS() {
    document.title = "NSS Activities";

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Student's Corner" subTitle="NSS" bdLink="NSS"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">NSS Activities</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">

                            <p className="m-7-v font-p-v">
                                The National Service Scheme (NSS) is an Indian government sector public service program
                                conducted by the Ministry of Youth Affairs[1] and Sports of the Government of India.
                                Popularly known as NSS, the scheme was launched in Gandhiji's Centenary year in 1969.
                                Aimed at developing student's personality through community service, NSS is a voluntary
                                association of young people in Colleges, Universities and at +2 level working for a
                                campus-community (esp. Villages) linkage.
                            </p>
                             <p  className="font-p-v">Currently There are 05 units under operation. Each unit consists of 100 student.</p>
<br/>
                            <h4 className="my-font-style text-theme-colored2">Aim of NSS</h4>
                            <p className="font-p-v">The programme aims to instilling the idea of social welfare in
                                students, and to provide service to society without bias. NSS volunteers work to ensure
                                that everyone who is needy gets help to enhance their standard of living and lead a life
                                of dignity. In doing so, volunteers learn from people in villages how to lead a good
                                life despite a scarcity of resources. it also provides help in natural and man-made
                                disasters by providing food, clothing and first aid to the disaster's victims.</p>


                            <br/>


                            <h4 className="my-font-style text-theme-colored2">Incharge NSS :-</h4>

                            <span><b>Sh. Mohit Marwaha(Asst. Professor) </b></span> <br/>
                            <span>Contact No:9815319999 </span> <br/>
                            <span>Email ID: mohitmarwaha@bcetgsp.ac.in </span> <br/>
                            <span>Fax: 01874-221463</span>
                        </div>

                        <div className="mb-35">
                            <Link id="nss-pdf-link" to="../assets/pdf/nss-form-new.pdf" target="_blank">
                                Click Here for NSS Enrollment Proforma <i className="fa fa-file-pdf-o"></i>
                            </Link>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">The NSS volunteers enrolled for the NSS
                                Programme in the
                                various Units and the programme officer(s) are as under</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V my-table-style">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th width="7%" rowSpan="2" valign="top" className="table-title-V">
                                            Sr.&nbsp;No.
                                        </th>
                                        <th width="27%" rowSpan="2" valign="top"
                                            className="table-title-V">Name&nbsp;of&nbsp;NSS&nbsp;Programme&nbsp;Officer
                                        </th>
                                        <th width="10%" rowSpan="2" valign="top" className="table-title-V">Unit</th>
                                        <th width="18%" rowSpan="2" valign="top"
                                            className="table-title-V">Phone/&nbsp;Mobile
                                        </th>
                                        <th colSpan="2" align="center" valign="top"
                                            className="table-title-V">No.&nbsp;of&nbsp;Volunteers
                                        </th>
                                        <th width="28%" rowSpan="2" valign="top" className="table-title-V">E-mail</th>
                                    </tr>
                                    <tr>
                                        <th width="13%" valign="top" className="headingthree">Male</th>
                                        <th width="13%" valign="top" className="headingthree">Female</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>1.</strong>
                                        </td>
                                        <td className="table-body-padd-V">Ms. Amarjit Kaur(CSE)</td>
                                        <td rowSpan="2" className="row-span-2 table-body-padd-V">
                                            (UNIT-I)
                                        </td>
                                        <td>9876548379</td>
                                        <td className="text-center">50</td>
                                        <td className="text-center">65</td>
                                        <td>
                                            <a className="mail-to"
                                               href="mailto:amark40@ymail.com">amark40@ymail.com</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>2.</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            Mr. Mohit Marwaha(I.T)
                                        </td>
                                        <td>09815319999</td>
                                        <td className="text-center">30</td>
                                        <td className="text-center">42</td>
                                        <td>
                                            <a className="mail-to"
                                               href="mailto:mohimarwaha@bcetgsp.ac.inmohimarwaha@bcetgsp.ac.in">
                                                mohimarwaha@bcetgsp.ac.in
                                            </a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>3.</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            Mr. Tarun Mahajan(M.E)
                                        </td>
                                        <td rowSpan="2" className="row-span-2 table-body-padd-V">
                                            (UNIT-II)
                                        </td>
                                        <td>9888376755</td>
                                        <td className="text-center">104</td>
                                        <td className="text-center">01</td>
                                        <td>
                                            <a className="mail-to" href="mailto:er.tarun_29@yahoo.co.in">
                                                tarun_29@yahoo.co.in
                                            </a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>4.</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            Dr. Rajesh Sharma(CHE)
                                        </td>
                                        <td>9872178653</td>
                                        <td className="text-center">18</td>
                                        <td className="text-center">11</td>
                                        <td>
                                            <a className="mail-to" href="mailto:Rajessharma@bcetgsp.ac.in">
                                                Rajessharma@bcetgsp.ac.in
                                            </a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>5.</strong>
                                        </td>
                                        <td className="table-body-padd-V">Mrs. Jaspreet kaur(ECE)</td>
                                        <td rowSpan="2" className="row-span-2 table-body-padd-V">
                                            (UNIT-III)
                                        </td>
                                        <td>09465511774</td>
                                        <td className="text-center">103</td>
                                        <td className="text-center">53</td>
                                        <td>
                                            <a className="mail-to" href="mailto:jaspreet_kaur1986@ymail.com">
                                                jaspreet_kaur1986@ymail.com
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>6.</strong>
                                        </td>
                                        <td className="table-body-padd-V">Ms. ARVINDER KAUR(B.T)</td>
                                        <td>9888747640</td>
                                        <td className="text-center">38</td>
                                        <td className="text-center">71</td>
                                        <td>
                                            <a className="mail-to" href="mailto:arvinderanu88@gmail.com">
                                                arvinderanu88@gmail.com
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default NSS;

