import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const IndustrialTraining = () => {
    useEffect(() => {
        document.title = "Industrial Training";
    })
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Industrial Training" subTitle="Training & Placements"
                               bdLink="Industrial Training"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font text-theme-colored2"}>
                        Training and Placement Cell
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Introduction</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <p className="font-p-v m-7-v">The College has full-fledged Training and Placement Cell equipped
                            with all modern facilities
                            for dovetailing our students’ career aspirations with corporate expectations and to build
                            strong industry interface and enhance industry interactions. A dedicated team of faculty
                            members of various disciplines having good academic and industrial exposure organizes the
                            cell’s activities.</p>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Placement Team</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V my-table-style">
                                <thead className="table-head-red-V">
                                <tr>
                                    <th className="table-title-V">Sr. No.</th>
                                    <th className="table-title-V">Name</th>
                                    <th className="table-title-V">Department</th>
                                    <th className="table-title-V">Email Id</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td className="table-body-padd-V text-center">1</td>
                                    <td className="table-body-padd-V text-center">
                                        Dr. Sarabjeet Singh Sidhu <br/>
                                        Contact No.: 01874-221463
                                    </td>
                                    <td className="table-body-padd-V text-center">Training & Placement Officer</td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:Tpobcetgurdaspur@gmail.com, tpo@bcetgsp.ac.in">
                                            Tpobcetgurdaspur@gmail.com, tpo@bcetgsp.ac.in
                                        </a>
                                    </td>
                                </tr>

                                {/*<tr>*/}
                                {/*    <td className="table-body-padd-V text-center">2</td>*/}
                                {/*    <td className="table-body-padd-V text-center">*/}
                                {/*        Dr. Rajeev Kumar Bedi <br/>*/}
                                {/*        Contact No.: 7696168094*/}
                                {/*    </td>*/}
                                {/*    <td className="table-body-padd-V text-center">ATPO</td>*/}
                                {/*    <td className="table-body-padd-V text-center">*/}
                                {/*        <a className="mail-to"*/}
                                {/*           href="mailto:cseatpo@gmail.com">*/}
                                {/*            cseatpo@gmail.com*/}
                                {/*        </a>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">
                            Department Wise Faculty In-charges
                        </h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V my-table-style">
                                <thead className="table-head-red-V">
                                <tr>
                                    <th className="table-title-V">Sr. No.</th>
                                    <th className="table-title-V">Name</th>
                                    <th className="table-title-V">Department</th>
                                    <th className="table-title-V">Email Id</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td className="table-body-padd-V text-center">1</td>
                                    <td className="table-body-padd-V text-center">Ms. Ritika</td>
                                    <td className="table-body-padd-V text-center">ATPO, Information Technology</td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:atpoitbcet@gmail.com">atpoitbcet@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V text-center">2</td>
                                    <td className="table-body-padd-V text-center">Mr. Kanchan Kumar</td>
                                    <td className="table-body-padd-V text-center">ATPO, Chemical Engg.</td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:chemical.iit@gmail.com">chemical.iit@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V text-center">3</td>
                                    <td className="table-body-padd-V text-center">Mr. Akshay Mahajan</td>
                                    <td className="table-body-padd-V text-center">ATPO, Bio Technology</td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:atpobiotech@gmail.com">atpobiotech@gmail.com</a>
                                    </td>

                                </tr>
                                <tr>
                                    <td className="table-body-padd-V text-center">4</td>
                                    <td className="table-body-padd-V text-center">Mr. Amandeep</td>
                                    <td className="table-body-padd-V text-center">ATPO, Electronics &amp; Comm. Engg.
                                    </td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:amanece02@gmail.com">atpobcetece@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V text-center">5</td>
                                    <td className="table-body-padd-V text-center">Ms. Geetanjali Sharma</td>
                                    <td className="table-body-padd-V text-center">ATPO, Computer Sc. &amp; Engg.</td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:cseatpo@gmail.com">cseatpo@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V text-center">6</td>
                                    <td className="table-body-padd-V text-center">Mr. Tarun Mahanjan</td>
                                    <td className="table-body-padd-V text-center">ATPO, Civil Engg.</td>
                                    <td className="table-body-padd-V text-center">
                                        <a className="mail-to"
                                           href="mailto:civilatpo@gmail.com">civilatpo@gmail.com</a>
                                    </td>

                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">
                            Students' Team for Placement Cell
                        </h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">The students' team consists of two members from each discipline
                            who are selected voluntarily.
                            The task of team members is to assist the staff in the smooth functioning of T&P activities
                            of any kind. This includes coordinating with companies during their PPTs and recruitment
                            processes.</p>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">
                            Objectives of the Training & Placement Cell
                        </h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <strong className="my-sub-titles certificate">
                            The primary objectives are:
                        </strong>
                        <ul style={{listStyleType: "square"}}>
                            <li>To arrange various training programs for the students as per the University curriculum
                                requirement.
                            </li>
                            <li>To assist the students for placement in reputed companies through in-campus and joint
                                campus placement programs.
                            </li>
                            <li>To arrange various Seminars/ Expert lectures/ Career awareness program etc. to prepare
                                the students for facing the interviews.<br/>
                                Apart from this the Cell is actively involved in:
                            </li>
                            <li> Enhancing industry-institute interaction program through various industrial visits of
                                faculty members and the students.
                            </li>
                            <li>Conducting Alumni activities like organizing annual Alumni meet regularly.</li>
                            <li>Conducting Entrepreneurship awareness programs for final and pre-final year students
                            </li>
                        </ul>
                    </div>

                    {/* Downloads */}
                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Downloads</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="">
                            <p>
                                <a className="download-links" href="//youth4work.com/onlinetalenttest" target="_blank">
                                    All Final Year Students are required to go and register themselves here on this
                                    link.
                                </a>
                            </p>

                            <p>
                                <a className="download-links"
                                      href="../assets/pdf/StudentConsentForm.pdf" target="_blank">
                                    Click here for Consent Form
                                </a>
                            </p>

                            <p>
                                <a className="download-links"
                                      href="../assets/pdf/Project_Guideline_and_format_B_Tech_New.pdf" target="_blank">
                                    Click here for Major Project Report Guidelines
                                </a>
                            </p>

                            <p>
                                <a className="download-links"
                                      href="../assets/pdf/Guidelines_for_sixmonth_training.pdf" target="_blank">
                                    Click here for 6-month Industrial Training Report Guidelines
                                </a>
                            </p>

                            <p>
                                <a className="download-links"
                                      href="../assets/doc_files/TRG.LETTER.doc" target="_blank">
                                    Click here to download performa for 6 Months / Weeks Training
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Training of the students</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">As per the University curriculum requirement, all the B. Tech.
                            students of various
                            disciplines have to undergo various practical trainings during their course as detailed
                            below:</p>

                        <ul>
                            <li>
                                <p className="font-p-v m-7-v">
                                    <strong className="my-sub-titles certificate">
                                        Practical training-I
                                    </strong> of four weeks (six hours a day and six days per week) duration imparted in
                                    the
                                    campus after 2nd semester in summer break. It is designed to give the exposure of
                                    the
                                    central workshop/ computer centre and other major laboratories in the college to the
                                    students of all the disciplines where they have to complete the projects assigned.
                                </p>
                            </li>
                            <li>
                                <p className="font-p-v m-7-v">
                                    <strong className="my-sub-titles certificate">
                                        Practical training-II
                                    </strong> of six week duration in industry after 4th semester in summer break.
                                </p>
                            </li>
                            <li>
                                <p className="font-p-v m-7-v">
                                    <strong className="my-sub-titles certificate">
                                        Practical training-III
                                    </strong> is six months full semester industrial training in 7th /8th semester in
                                    various industries spread all over India. It is arranged in reputed industries
                                    spread all over India. The purpose of this training is to expose the students to
                                    latest technological developments at work places/ shop floors. The objective is also
                                    to provide practical experience in solving open-ended problems in real work setting
                                    and thereby to develop confidence in the students in the analysis, synthesis and
                                    evaluation of practical problems leading to creative thinking.
                                </p>

                                <ul style={{listStyleType: "circle"}}>
                                    <li>During training the students have to do project work under the supervision of
                                        training manager/ supervisor from the industry.
                                    </li>
                                    <li>Students have to maintain their day-to-day work progress in a pre-designed daily
                                        diary issued to them by the Training and Placement Cell.
                                    </li>
                                    <li>The appraisal of the students on training is done jointly by the training
                                        manager and the teacher from the college and is based on the project work and
                                        presentation made by the students in the industry.
                                    </li>
                                    <li>For internal assessment and monitoring, the teachers make two visits during
                                        training period and assessment by monitoring is done in both the visits.
                                    </li>
                                    <li>At the end of the training as per the curriculum requirement of the university
                                        the students are evaluated on the basis of the project report prepared by the
                                        students and their internal/ external examination/viva-voce exam/ presentation.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Linkages with the Institution of
                            Excellence</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">
                            At present the high profile institutions like IITs and IISC have been linked to our
                            institute in the form of R & D activities by the faculty. Some of our faculty members are
                            pursuing their research activities along with the faculty of these institutions leading to
                            Ph.D work. At present around five faculty members are pursuing their Ph.D in such
                            institutions under QIP program of AICTE.
                        </p>

                        <p className="font-p-v m-7-v">
                            <strong className="my-sub-titles certificate">
                                Linkage in Abroad
                            </strong>
                        </p>

                        <p className="font-p-v m-7-v">The institute has established the linkages with the companies
                            situated Globally. Few major
                            companies with which the college has established links for giving industrial training to the
                            students are as per the following details:</p>

                        <ol>
                            <li>Communications & Power Industries, 3200 Patrick Henry Drive Santa Clara. CA.</li>
                            <li>LMSt Lab., Domaine Scientifique de la Doua Batiment Jean d’ Almbert, 8, rue des
                                Sciences, 69621 Villeurbanne-France.
                            </li>
                        </ol>

                        <p className="font-p-v m-7-v">
                            <strong className="my-sub-titles certificate">
                                Linkage with R&D Laboratories
                            </strong>
                        </p>

                        <p className="font-p-v m-7-v">
                            Institute has developed linkage with R & D laboratories of national level in the surrounding
                            region. The objective of this linkage is to enhance knowledge and to avail better
                            infrastructure testing facilities for both students & staff for R&D purposes. Keeping this
                            in view the institute has earlier signed MOU’s with following reputed R & D laboratories:
                        </p>

                        <ol>
                            <li>Institute of Machine Tools and Technology (IMIT) Batala, UNDP project.</li>
                            <li>Central Institute for Hand Tools Jalandhar.</li>
                        </ol>

                        <p className="font-p-v m-7-v">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As a result of these linkages the students and staff are
                            carrying out their research work in
                            the related field and are able to compete with others in a better way and are contributing
                            in the development of college and society as a whole.
                        </p>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">
                            Our Recruiters
                        </h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">
                            <strong className="my-sub-titles certificate">
                                Some of the major companies where our Alumni have been placed through in-campus,
                                off-campus and other recruitment processes are:
                            </strong>
                        </p>

                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <tbody>
                                <tr>
                                    <th>1</th>
                                    <td>Dell Software, Mohali</td>
                                    <th>30</th>
                                    <td>Punjab Tractor Ltd. Mohali</td>
                                </tr>

                                <tr>
                                    <th>2</th>
                                    <td>Indian Army, New Delhi</td>
                                    <th>31</th>
                                    <td>Cheema Boilers</td>
                                </tr>

                                <tr>
                                    <th>3</th>
                                    <td>Nucleus Software Tech. Ltd, Noida</td>
                                    <th>32</th>
                                    <td>Ingersol Rand, Banglore</td>
                                </tr>

                                <tr>
                                    <th>4</th>
                                    <td>V-Customers</td>
                                    <th>33</th>
                                    <td>Jaibharat, Maruti</td>
                                </tr>

                                <tr>
                                    <th>5</th>
                                    <td>Erriscon</td>
                                    <th>34</th>
                                    <td>Gujrat Ambuja Cement</td>
                                </tr>

                                <tr>
                                    <th>6</th>
                                    <td>Microsoft Corp., Redmond, California</td>
                                    <th>35</th>
                                    <td>Trident Group</td>
                                </tr>

                                <tr>
                                    <th>7</th>
                                    <td>Infosys Technologies, Chandigarh</td>
                                    <th>36</th>
                                    <td>GLIDE Internet Services</td>
                                </tr>

                                <tr>
                                    <th>8</th>
                                    <td>Wipro Computers, Bangalore</td>
                                    <th>37</th>
                                    <td>Thomson Press</td>
                                </tr>

                                <tr>
                                    <th>9</th>
                                    <td>Hughes Software Systems, Gurgaon</td>
                                    <th>38</th>
                                    <td>Clutch Auto</td>
                                </tr>

                                <tr>
                                    <th>10</th>
                                    <td>Satyam Computer Services Ltd, Hyderabad</td>
                                    <th>39</th>
                                    <td>Rico Cycles</td>
                                </tr>

                                <tr>
                                    <th>11</th>
                                    <td>Intel Research Laboratories</td>
                                    <th>40</th>
                                    <td>Metro Tyres</td>
                                </tr>

                                <tr>
                                    <th>12</th>
                                    <td>Wintech Software System</td>
                                    <th>41</th>
                                    <td>Hero Cycles, Ludhiana</td>
                                </tr>

                                <tr>
                                    <th>13</th>
                                    <td>Rayan Software</td>
                                    <th>42</th>
                                    <td>HCL Technologies, Noida</td>
                                </tr>

                                <tr>
                                    <th>14</th>
                                    <td>Indian Air Force & Other Defence Services, New Delhi</td>
                                    <th>43</th>
                                    <td>Spice Communication Ltd., Mohali</td>
                                </tr>

                                <tr>
                                    <th>15</th>
                                    <td>DRDO</td>
                                    <th>44</th>
                                    <td>Maruti Udyog Ltd., Gurgaon</td>
                                </tr>

                                <tr>
                                    <th>16</th>
                                    <td>Reliance Systems, Chandigarh</td>
                                    <th>45</th>
                                    <td>Newgen Software Tech. Ltd, Noida</td>
                                </tr>

                                <tr>
                                    <th>17</th>
                                    <td>Indian Oil Corporation, Panipat</td>
                                    <th>46</th>
                                    <td>AB Sugar Ltd, Hoshiarpur</td>
                                </tr>

                                <tr>
                                    <th>18</th>
                                    <td>BHEL, Haridwar</td>
                                    <th>47</th>
                                    <td>Syntel, Pune</td>
                                </tr>

                                <tr>
                                    <th>19</th>
                                    <td>Tata Consultancy Services (TCS)</td>
                                    <th>48</th>
                                    <td>L & T Ltd, New Delhi</td>
                                </tr>

                                <tr>
                                    <th>20</th>
                                    <td>LML Limited</td>
                                    <th>49</th>
                                    <td>Tyco Valves & Control India Pvt. Ltd, Mumabi</td>
                                </tr>

                                <tr>
                                    <th>21</th>
                                    <td>Imperial Auto Pvt. Ltd</td>
                                    <th>50</th>
                                    <td>Appolo Tyres, Gurgaon</td>
                                </tr>

                                <tr>
                                    <th>22</th>
                                    <td>Quark Media Publishing House Ltd.</td>
                                    <th>51</th>
                                    <td>Ashok Leyland, Chandigarh</td>
                                </tr>

                                <tr>
                                    <th>23</th>
                                    <td>JCT Ltd, Hoshiarpur</td>
                                    <th>52</th>
                                    <td>Bry Air, Gurgaon</td>
                                </tr>

                                <tr>
                                    <th>24</th>
                                    <td>Sonalika ITL, Hoshiarpur</td>
                                    <th>53</th>
                                    <td>Mukand Steels, Thana</td>
                                </tr>

                                <tr>
                                    <th>25</th>
                                    <td>Rockman Industries Ltd, Ludhiana</td>
                                    <th>54</th>
                                    <td>Sona Koya Steering System Ltd, Gurgaon</td>
                                </tr>

                                <tr>
                                    <th>26</th>
                                    <td>Sterling Telecom & Net Systems Ltd, Chennai</td>
                                    <th>55</th>
                                    <td>Bharti Teletech Ltd, Ludhiana</td>
                                </tr>

                                <tr>
                                    <th>27</th>
                                    <td>C-DAC Bangalore</td>
                                    <th>56</th>
                                    <td>Vardhman Steels, Ludhiana</td>
                                </tr>

                                <tr>
                                    <th>28</th>
                                    <td>Ingersoll Rand India Ltd, Bangalore</td>
                                    <th>57</th>
                                    <td>Hindustan Tins Works Ltd, Sonepat</td>
                                </tr>

                                <tr>
                                    <th>29</th>
                                    <td>Hyundai Motors</td>
                                    <th>58</th>
                                    <td>Sukhjit Starch & Chem. Ltd, Phagwara</td>
                                </tr>

                                <tr>
                                    <th></th>
                                    <td></td>
                                    <th>59</th>
                                    <td>Max New York Life, Gurgaon</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <p className="font-p-v m-7-v">
                            <strong className="my-sub-titles certificate">
                                Entrepreneurship
                            </strong>
                        </p>

                        <p className="font-p-v m-7-v">
                            Apart from doing our best to get the students placements in reputed companies, we strive to
                            make our students Entrepreneurs.
                        </p>

                        <ul>
                            <li>The College is having EDP cell and has conducted many EDP programs in the previous
                                years.
                            </li>
                            <li>Recently The College has signed MoU with Small Scale Industries Service Institute,
                                Ludhiana (Pb.), a Govt. of India organization under Ministry of Small Scale Industries,
                                New Delhi.
                            </li>
                        </ul>

                        <p className="font-p-v m-7-v">
                            <strong className="my-sub-titles certificate">
                                Our Strengths
                            </strong>
                        </p>

                        <ul>
                            <li>High profile faculty having P.G. and Ph.D. degrees.</li>
                            <li>Laboratories well equipped with State-of-Art facilities satisfying university curriculum
                                requirement and even beyond that.
                            </li>
                            <li>Computer center possessing more than 300 PCs having Pentium, i3 and i5 Intel
                                microprocessor along with TFT monitors with latest configuration.
                            </li>
                            <li>Library possessing air-conditioned reading halls, periodical hall, Cyber Lab having
                                latest PCs. Along with internet and Delnet, Indest access facilities.
                            </li>
                            <li>Central workshop possessing high quality precision machine tool and CNC milling and
                                Drilling trainers.
                            </li>
                            <li>Student activities: The campus is fully residential. There are four boys’ hostels and
                                two girls’ hostel fully furnished with all essential accessories and fittings. There are
                                157 houses for the college staff and their families.
                            </li>
                            <li>Transport facility: The College has good transport fleet with buses, tho cars and one
                                tractor.
                            </li>
                        </ul>

                        <p className="font-p-v m-7-v">Placement of students: There is a separate training and placement
                            cell helping the students
                            in placement by inviting various reputed companies in the campus for campus placements.</p>
                    </div>

                </div>
            </div>

            <Footer/>
        </>
    )
}

export default IndustrialTraining;