import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";

const ComputerCentre = (props) => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Computer Centre" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Computer Centre"/>

            <div className="section3">
                <div className="container pt-90">

                    <div className="row">
                        <h1 className="text-theme-colored2">Computer Centre</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>


                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Introduction">

                            <div className="row">
                                <h4 className="text-theme-colored2">Introduction</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>


                            <div className={"row"}>
                                <div className={"col-lg-4 col-md-4 col-sm-12"}>
                                    <img id="rdgImg" src={"../assets/images/faculty/head-cse.jpg"} alt="Profile"
                                         className="center-block img img img-responsive"/>
                                    <p className={"text-center"}>
                                        {/*<b> Head of Department : Dr. R C Gangwar </b> <br/>*/}
                                        <b> Head of Department : Dr Sunil Kumar Gupta </b> <br/>
                                        {/*<b> Email : </b> rakeshgangwar@gmail.com<br/>*/}
                                        <b> Contact No. :</b> +91-7837122801
                                        {/*<b> Contact No. :</b> +91-9877689893*/}
                                    </p>
                                </div>
                                <div className={"col-lg-8 col-md-8 col-sm-12"}>
                                    <h4 className={"h4 pt-90"}>Introduction</h4>
                                    <p className={"text-justify"}>

                                        {/*Computer Centre is providing the central facilities to various departments and and hostels of the institution.*/}
                                        {/*There is 1Gbps internet leased line connectivity from National Knowledge Network (NKN).*/}
                                        {/*There is Cyberoam UTM, which acts as firewall to protect institute Network from outside threats.*/}
                                        {/*It is also used to manage internet bandwidth and internet users. There is campus-wide networking connecting Computer*/}
                                        {/*Centre to various departments, sections, hostels and residential area. There is Wifi facility*/}
                                        {/*available in all the boys and girls hostel.*/}

                                        University Computer Centre (UCC) cater to the computing needs of various
                                        academic departments, administrative offices, hostels and residential houses.
                                        There is 1Gbps internet leased-line connectivity from National Knowledge Network
                                        (NKN). There is a Cyberoam UTM, which acts as firewall to protect the University
                                        Network from outside threats and cyber-attacks. It is also used to manage
                                        internet bandwidth and internet users. There is campus-wide networking on Fibre
                                        cable backbone that connects Computer Centre to various departments, sections,
                                        hostels and residential area. There is Wifi facility available in all the boys
                                        and girls hostel. Whole University Computer Centre is under CCTV surveillance
                                        24/7. One BSNL Bharat Fibre Broadband connection of 300 Mbps bandwidth is also
                                        available in UCC.

                                    </p>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey={2} title="Labs.">
                            <div className="row">
                                <h3 className="text-theme-colored2">List of Computers/Peripherals in Computer
                                    Centre</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <div className="row">
                                <h4 className="text-theme-colored2">Hardware Components</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>

                            <div className={"row"}>
                                <div className={"col-lg-2 col-md-2"}></div>
                                <div className={"col-lg-8 col-md-8"}>
                                    <table className={"table table-bordered table-hover"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Item's Description</th>
                                            <th className={"text-center"}>Quantity</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1.</td>
                                            <td className={"text-center"}>
                                                <ins>Server (Proliant ML 350 e Gen 8)</ins>
                                                # Quad Core Intel Xeon ES-2403 @ 1.8 GHz with 10 MB cache <br/>
                                                # Intel C600 Series Chipset # 16 GB DDR3 RAM <br/>
                                                # 3x300 GB SAS Raid Controller # DVD-RW 8x # 18.5” LED TFT Monitors
                                            </td>
                                            <td className={"text-center"}>02</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2.</td>
                                            <td className={"text-center"}>
                                                <ins>HP Compaq dx 7400 series</ins>
                                                # Intel Core 2 Duo @ 2.53 GHz # RAM 1 GB DDR3<br/>
                                                # Cache 2 MB L2/L3<br/> # HDD 160 GB # Monitor 15” TFT
                                            </td>
                                            <td className={"text-center"}>10</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3.</td>
                                            <td className={"text-center"}>
                                                <uns>HP Compaq dx 6100</uns>
                                                # P-IV @ 3.0 GHz # RAM 512 MB DDR1 <br/>
                                                # Cache 512 KB L2/L3 # HDD 80 GB <br/># Monitor 18.5 LED”
                                            </td>
                                            <td className={"text-center"}>61</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>4.</td>
                                            <td className={"text-center"}>
                                                <ins>HP Compaq dx 2280</ins>
                                                # P-IV @ 2.8 Ghz # RAM 1.5 GB DDR1 <br/>
                                                # Cache 1.5 MB L2/L3 # HDD 160 GB <br/> # Monitor 18.5 LED”
                                            </td>
                                            <td className={"text-center"}>10</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>5.</td>
                                            <td className={"text-center"}>
                                                HP Notebook
                                            </td>
                                            <td className={"text-center"}>01</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>6.</td>
                                            <td className={"text-center"}>
                                                HP laserjet 1022n
                                            </td>
                                            <td className={"text-center"}>02</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>7.</td>
                                            <td className={"text-center"}>
                                                UPS 6 KVA
                                            </td>
                                            <td className={"text-center"}>01</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>8.</td>
                                            <td className={"text-center"}>
                                                UPS 3KVA
                                            </td>
                                            <td className={"text-center"}>08</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>9.</td>
                                            <td className={"text-center"}>
                                                Core Switch : D-Link - Layer 3
                                            </td>
                                            <td className={"text-center"}>01</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>10.</td>
                                            <td className={"text-center"}>
                                                Edge Switch - Nortel 425-24T
                                            </td>
                                            <td className={"text-center"}>18</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>11.</td>
                                            <td className={"text-center"}>
                                                Firewall/UTM - Cyberoam
                                            </td>
                                            <td className={"text-center"}>01</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>12.</td>
                                            <td className={"text-center"}>
                                                Wifi Controller - Rukcus Wireless Zone Director 3000 (for Hostel Wifi)
                                            </td>
                                            <td className={"text-center"}>01</td>
                                        </tr>
                                        </tbody>
                                    </table>


                                </div>
                                <div className={"col-lg-2 col-md-2"}></div>
                            </div>

                            <div className="row">
                                <h4 className="text-theme-colored2">Software Components</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>

                            <div className={"col-lg-12 col-md-12 "}>
                                <table className={"table table-hover table-responsive"}>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>Anti Virus - Seqrite End Point Security</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>Window XP Pro, Window Vista Business and Ubuntu
                                            10.4
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>MS - Office Std. 2016, MS Office Professional
                                            2007
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Tab>

                        <Tab eventKey={3} title="Projects">
                            <div className="container pt-90">


                                <div className="row">
                                    <h3 className="text-theme-colored2">Computer Projects</h3>
                                    <div className="double-line-bottom-theme-colored-2"></div>
                                </div>
                                <div className="table-responsive">
                                    <table className={"table table-hover"}>
                                        <thead>
                                        <tr>
                                            <th className={"text-center"}>Sr. No.</th>
                                            <th className={"text-center"}>Name of Agency</th>
                                            <th className={"text-center"}>Amt. Sanctioned</th>
                                            <th className={"text-center"}>Name of the Project</th>
                                            <th className={"text-center"}>Equipment Purchased</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>AICTE</td>
                                            <td className={"text-center"}>10 Lacs.</td>
                                            <td className={"text-center"}>Modernisation of computer centre</td>
                                            <td>
                                                <h4 className={"text-center"}>PCS PRODIGY</h4>
                                                <ul>
                                                    <li>INTEL P-IV-1.6 GHz</li>
                                                    <li>256 MB SDRAM,</li>
                                                    <li>20 GB IDE HDD</li>
                                                    (Qty. 22)
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey={4} title="Future Plans">
                            <div className="row">
                                <h3 className="text-theme-colored2">Future Plans</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <ul>
                                <li>Extension of campus wide networking using wire less network</li>
                                <li>To conduct more courses</li>
                            </ul>

                        </Tab>
                        <Tab eventKey={5} title="Short Term">
                            <div className="row">
                                <h3 className="text-theme-colored2">Short Term Courses Organised For</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <ul>
                                <li>Education Department</li>
                                <li>Agriculture Department</li>
                                <li>ITI instructors</li>
                            </ul>
                        </Tab>
                        <Tab eventKey={6} title="Faculty">
                            <div className="row">
                                <h3 className="text-theme-colored2">Faculty</h3>
                                <div className="double-line-bottom-theme-colored-2"></div>
                            </div>
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/Arpinder.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Arpinder Singh Sandhu</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Associate Professor</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>01874-210355, 9646020532</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>singh_arpinder@yahoo.com,
                                                    singh_arpinder@rediffmail.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/4"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/Deepak.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Deepak Kaila</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Asstt. Professor in Computer Practices
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 9464548801</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>deepakkaila@bcetgsp.ac.in</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/5"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/Mukhwinder.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Mukhwinder Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Laboratory Attendant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 9464090076,6284912781</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>mukhwindersingh@bcetgsp.ac.in</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/6"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} src={"assets/images/faculty/Sandeep.jpg"}
                                             className={"img-responsive"}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Sandeep Mahajan</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Laboratory Attendant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>08146814488</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>sandeepmahajan@bcetgsp.ac.in</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/7"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                                <br/>


                            </div>
                        </Tab>

                    </Tabs>


                </div>
            </div>

            <Footer/>
        </>
    );
}

export default ComputerCentre;