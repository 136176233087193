import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
const MscPhysics = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="M.Sc (Physics) Hons." bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Master of Science in Physics: Integrated Course</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    Master of Science in Physics (Hons. Integrated course) is a five-year duration
                                    postgraduate degree course. M.Sc. integrated course is equivalent to the B.Sc.+M.Sc.
                                    course that a student can pursue after the completion of his/her 12th class. The
                                    course is designed keeping in mind the curriculum of both B.Sc. and M.Sc. course. It
                                    helps students excel in specialised courses of physics. The integrated M.Sc. course
                                    will give students an option to complete an M.Sc. course without taking a gap
                                    between an undergraduate and postgraduate course. This course gives students a scope
                                    of developing their problem solving, communication skills and critical thinking,
                                    which further broadens into interpreting scientific data that is a blessing to
                                    scientific researchers. A masters in Physics opens up avenues that are vibrant and
                                    an interesting mix of money and intellect. The job prospects are very much similar
                                    to the M.Sc. (Physics) course.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/22.jpg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those student who have passed 10+2 or equivalent examination in any stream conducted
                                by a recognized Board / University / Council with Physics as one of the subjects with at
                                least 50% Marks (45% marks in case of candidate belonging to Reserved Category) in
                                aggregate from any Board recognized or established by Central/State Government through a
                                legislation. The final admission will be based on inter-se-merit in qualifying
                                examination.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 5 years long, with Ten Semesters.
                                <br/> Exit Can Be Made After 3 Years i.e. after completing B.Sc.
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Year</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V"
                                            href="/assets/pdf/admissions/PG/MSC/HonsPhysics/2023_Syllabus.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2022</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V"
                                            href="/assets/pdf/admissions/PG/MSC/HonsPhysics/2022_Syllabus.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2022 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default MscPhysics;