import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";

function StudentGrievanceRedressalPortal() {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration"
                               subTitle="Grievance"
                               bdLink="Grievance"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h2 className="text-theme-colored2 mb-15">
                            STUDENT GRIEVANCE REDRESSAL COMMITTEE (SGRC)
                        </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">
                            <p className="text-justify">In compliance with the guidelines issued by the University Grant
                                Commission vide D.O. No.
                                F.1-13/2022(CPP-II) dated April 12, 2023, to formulate a simplified mechanism for
                                student Grievance Redressal regulations, 2023 the following Students' Grievances
                                Redressal Committee (SGRC) is constituted at Sardar Beant Singh State University,
                                Gurdaspur.</p>

                            <div className="table-responsive">
                                <table className="table-hover my-table-style mb-35">
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V text-center" style={{width: '50%'}}></td>
                                        <td className="table-body-padd-V text-center" style={{width: '50%'}}></td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dr. O.P. Singh, Professor</td>
                                        <td className="table-body-padd-V text-center">Chairperson</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dean Students Welfare</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dean, Academic Affairs</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dean Faculties</td>
                                        <td className="table-body-padd-V text-center">Members</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dr. Vipan Kumar</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dr. Anju Awasthi</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">
                                            A representative from among students to be nominated on academic
                                            merit/excellence in sports/performance in co-curricular activities
                                        </td>
                                        <td className="table-body-padd-V text-center">
                                            Special invitee<br/>( for One Year)
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <p className="text-justify">It aims to look into the complaints lodged by any student and
                                redress it as per
                                requirement. Students can reach out to SGRC with their grievances, if any, regarding
                                academic matters related to their study in the campus through an online grievance
                                form.</p>
                        </div>

                        <h2 className="text-theme-colored2 mb-15">
                            PROCEDURE FOR REDRESSAL OF GRIEVANCES BY OMBUDSPERSONS AND STUDENT GRIEVANCE REDRESSAL
                            COMMITTEES
                        </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">
                            <ol type="i">
                                <li className="text-justify mb-12">On receipt of an online complaint, the institution
                                    shall refer the complaint to
                                    the appropriate Students’ Grievance Redressal Committee, along with its comments
                                    within 15 days of receipt of the complaint on the online portal
                                </li>
                                <li className="text-justify mb-12">The Students’ Grievance Redressal Committee, as the
                                    case may be, shall fix a date
                                    for hearing the complaint which shall be communicated to the institution and the
                                    aggrieved student.
                                </li>
                                <li className="text-justify mb-12">An aggrieved student may appear either in person or
                                    authorize a representative to
                                    present the case.
                                </li>
                                <li className="text-justify mb-12">The SGRC shall send its report with recommendations,
                                    if any, to the competent
                                    authority of the institution concerned and a copy thereof to the aggrieved student,
                                    preferably within a period of 15 working days from the date of receipt of the
                                    complaint.
                                </li>
                                <li className="text-justify mb-12">Grievances not resolved by the Students’ Grievance
                                    Redressal Committee within the
                                    time period provided in these regulations may be referred to the Ombudsperson by the
                                    university.
                                </li>
                                <li className="text-justify mb-12">The Ombudsperson shall, after giving reasonable
                                    opportunities of being heard to the
                                    parties concerned, on the conclusion of proceedings, pass such order, with reasons
                                    thereof, as may be deemed fit to redress the grievance and provide such relief as
                                    may be appropriate to the aggrieved student.
                                </li>
                                <li className="text-justify mb-12">The institution, as well as the aggrieved student,
                                    shall be provided with copies of
                                    the order under the signature of the Ombudsperson.
                                </li>
                                <li className="text-justify mb-12">The institution shall comply with the recommendations
                                    of the Ombudsperson.
                                </li>
                                <li className="text-justify mb-12">The Ombudsperson may recommend appropriate action
                                    against the complainant, where a
                                    complaint is found to be false or frivolous.
                                </li>
                            </ol>
                        </div>

                        <h2 className="text-theme-colored2 mb-15">
                            4 STEPS OF STUDENT GRIEVANCE REDRESSAL PROCESS
                        </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div>
                            <ol className="mb-35">
                                <li className="mb-12">Students lodge their complaints by filing a Google form</li>
                                <li className="mb-12">Verification of the Complaint</li>
                                <li className="mb-12">Action Taken</li>
                                <li className="mb-12">Information to the complainant on the redressal of the Complaint
                                </li>
                            </ol>

                            <div className="mb-25">
                                <h4 className="text-theme-colored2">
                                    STEP 1: LODGING OF COMPLAINT
                                </h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <p className="text-justify">Students are required to fill out the Student Grievance
                                    Form <a href="https://forms.gle/rbTRo5qj6cvToTu69"
                                            style={{color: '#C21717'}}
                                            target="_blank">https://forms.gle/rbTRo5qj6cvToTu69</a> and submit the same
                                    via email or as a
                                    handwritten
                                    letter to the Chairperson of the Student Grievance Redressal Committee (SGRC).</p>
                            </div>

                            <div className="mb-25">
                                <h4 className="text-theme-colored2">
                                    STEP 2: VERIFICATION
                                </h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <p className="text-justify">On receiving the complaint, the Chairperson of the Student
                                    Grievance Redressal Committee (SGRC). will record and verify the complaint.</p>
                            </div>

                            <div className="mb-25">
                                <h4 className="text-theme-colored2">
                                    STEP 3: ACTION
                                </h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <p className="text-justify">After verification of the complaint, the Department acts
                                    upon
                                    the complaint and takes the measures necessary to resolve the issue.</p>
                            </div>

                            <h4 className="text-theme-colored2">
                                STEP 4: INTIMATING THE STUDENT
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <p className="text-justify">Once the complaint has been resolved, the student is informed
                                about the outcome, and the complaint is considered closed by the Department.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default StudentGrievanceRedressalPortal;
