import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const PhdCSE = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Doctor of Philosophy Courses" subTitle="Phd(CSE)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Doctor of Philosophy: Computer Science &amp;
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    Apart from B.Tech (CSE) and B.Tech (I.T), the department also offers Ph.D program in
                                    the discipline of Computer Science and Engineering. The doctoral degree, i.e. the
                                    Ph.D., which covers in-depth knowledge, insight, research work into the computer
                                    science field. The Ph.D., is primarily intended for students desiring a career in
                                    research and/or collegiate teaching.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    This course is mainly for students who further like to do research work in advanced
                                    algorithms, statistical methods in AI, information security, database systems,
                                    computer networks and much more.
                                </p>
                                <p className=" font-p-v">
                                    Ph.D. Computer Science and Engineering, provides the candidate with a plethora of
                                    opportunities which may aid them significantly in their further prospects, moreover
                                    this field of engineering has always been blooming. Even in this era of recession
                                    this area is still blooming as it is apparent of various mergers that have happened
                                    of computer and Information Technology based companies in the recent past years.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/MtechCSE.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                The candidates with minimum 55% marks (or an equivalent grade in a point scale wherever
                                grading system is followed) in Master's Degree (in Engineering/Technology
                                Science/Management/Computer application/Humanities and in those other areas, in which
                                University decides to undertake the curriculum) of SBSSU, Gurdaspur or an equivalent
                                degree of recognized Indian or Foreign University (recognized by UGC/AIU/or any other
                                approved and authorized statutory Assessment and Accreditation agency) in the subject
                                concerned/allied subject/cognate subject shall be eligible.
                            </p>
                            <p className="font-p-v m-7-v">
                                Relaxation of 5% of marks, from 55% to 50%, or an equivalent relaxation of grade, may be
                                allowed for those belonging to SC/ST/OBC(non-creamy layer)/Differently-abled and other
                                categories of candidates as per the decision of UGC from time to time, or for those who
                                had obtained their Master's degree prior to 19th September, 1991. The eligibility marks
                                of 55% (or an equivalent grade in a point scale wherever grading system is followed) and
                                the relaxation of 5% to the categories mentioned above are permissible based only on the
                                qualifying marks without including the grace mark procedures. Candidates already having
                                PhD in a particular faculty are eligible only for a different subject/stream in the same
                                faculty or in a different faculty as per clause 1.0(i) above.
                            </p>
                            <p className="font-p-v m-7-v">
                                The candidates shall be admitted by the. University following a two stage process based
                                on qualifying
                                <ol className=" m-7-v">
                                    <li>PhD entrance test</li>
                                    <li>Subsequent interview</li>
                                </ol>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default PhdCSE;