import NavBar2 from "./NavBar2";
import BreadCrumbSection from "./BreadCrumbSection";
import Footer from "./Footer";
import React from "react";
import {Link} from "react-router-dom";

const PageNotFound = () => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Page Not Found" subTitle="404"
                               bdLink="404"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 mtl">
                            <div className="content">
                                <div className="post-padding clearfix">
                                    <div className="notfound">
                                        <h2>404 <span>Error!</span></h2>
                                        <p>Sorry, we can't find the page you are looking for. <br/>Please go to
                                            <Link to="/"> Home </Link> page.
                                        </p>
                                        <Link to="/" className="btn btn-primary">Back to home</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default PageNotFound;