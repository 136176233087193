import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";

const Library = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Library" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Library"/>

            <div className="section3">
                <div className="container pt-90">

                    <div className="row">
                        <h1 className="text-theme-colored2">Central Library</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>

                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="Introduction">
                            <div className={"container"}>
                                <p className={"text-justify"}>
                                    The Central Library was set up in 1995 in the Science Block of the college.
                                    It is now shifted to auditorium building. Ever Since its inception the Library is
                                    playing a vital role enourging self learning supplementing academics, boosting
                                    research and development activities and promoting consultancy activities. It has the
                                    floor area of 480sq. Meter and the seating capacity of 110 readers in the reading
                                    halls.
                                    Library follows the open access system.
                                    It facilitates the users to browse and pick up the document of their choice without
                                    any hindrance.
                                </p>
                                <h4 className="text-theme-colored2">The central library maintains following types of
                                    collections: </h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <ul>
                                    <li>Books, Text Books, General Books and Reference Books</li>
                                    <li>Journals/e-journals/e-books</li>
                                    <li>Career Planning materials/CDs</li>
                                    <li>Bound Back Volumes Journals</li>
                                    <li>Standards/Manuals/Thesis</li>
                                    <li>CDs/DVDs</li>
                                    <li>Publishers catalogue/product catalogue</li>
                                    <li>Institute Publications & Faculty Research Papers</li>
                                    <li>Syllabi / Old Question papers</li>
                                    <li>News Letters/Newspapers</li>
                                </ul>


                                <h4 className="text-theme-colored2">Number of Books/Titles/Journals Print Format(Subject
                                    Wise) </h4>
                                <div className="double-line-bottom-theme-colored-2"></div>

                                <table className={"table table-responsive table-hover"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <th className={"text-center"}>Sr. No.</th>
                                        <th className={"text-center"}>Branch</th>
                                        <th className={"text-center"}>Titles</th>
                                        <th className={"text-center"}>Volumes</th>
                                        <th className={"text-center"}>Journals/E-Journals</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"text-center"}>1</td>
                                        <td className={"text-center"}>Mechanical Engg.</td>
                                        <td className={"text-center"}>2498</td>
                                        <td className={"text-center"}>6881</td>
                                        <td className={"text-center"}>7</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>2</td>
                                        <td className={"text-center"}>Computer Sc. Engg. & Information
                                            Technology
                                        </td>
                                        <td className={"text-center"}>2225</td>
                                        <td className={"text-center"}>6963</td>
                                        <td className={"text-center"}>5</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>3</td>
                                        <td className={"text-center"}>Electronics & Comm. Engg.</td>
                                        <td className={"text-center"}>1760</td>
                                        <td className={"text-center"}>4980</td>
                                        <td className={"text-center"}>7</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>4</td>
                                        <td className={"text-center"}>Chemical Engg.</td>
                                        <td className={"text-center"}>1702</td>
                                        <td className={"text-center"}>3498</td>
                                        <td className={"text-center"}>9</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>5</td>
                                        <td className={"text-center"}>Biotechnology</td>
                                        <td className={"text-center"}>415</td>
                                        <td className={"text-center"}>1498</td>
                                        <td className={"text-center"}>5</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>6</td>
                                        <td className={"text-center"}>Applied Science, Hum. & Mgmt.</td>
                                        <td className={"text-center"}>1369</td>
                                        <td className={"text-center"}>3321</td>
                                        <td className={"text-center"}>16</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>7</td>
                                        <td className={"text-center"}>Others</td>
                                        <td className={"text-center"}>291</td>
                                        <td className={"text-center"}>1112</td>
                                        <td className={"text-center"}>24</td>
                                    </tr>
                                    <tr>
                                        <td className={"text-center"}>8</td>
                                        <td className={"text-center"}>Total</td>
                                        <td className={"text-center"}>10259</td>
                                        <td className={"text-center"}>28253</td>
                                        <td className={"text-center"}>73</td>
                                    </tr>
                                    </tbody>
                                </table>


                                <h4 className="text-theme-colored2">E-Resources in Central Library</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <div className="table-responsive">
                                    <table className={"table table-hover"}>
                                        <thead className={"table-head-red-V"}>
                                        <tr>
                                            <th className={"text-center"}>#</th>
                                            <th className={"text-center"}>E-Resource/ Web Address</th>
                                            <th className={"text-center"}>Numbers</th>
                                            <th className={"text-center"}>Access Availability</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className={"text-center"}>1</td>
                                            <td className={"text-center"}>SME: The American Society of Mechanical Engineers
                                                www.asmedigitalcollection.asme.org
                                            </td>
                                            <td className={"text-center"}>26</td>
                                            <td className={"text-center"}>IP Based (Campus Wide Access)</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>2</td>
                                            <td className={"text-center"}> IEEE : All Society Periodical Package (ASPP)
                                                http://ieeexplore.ieee.org/browse/periodicals
                                            </td>
                                            <td className={"text-center"}>211</td>
                                            <td className={"text-center"}>IP Based (Campus Wide Access)</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>3</td>
                                            <td className={"text-center"}> Science Direct (Chemical Engineering)
                                                http://www.sciencedirect.com/science/journals
                                            </td>
                                            <td className={"text-center"}>30</td>
                                            <td className={"text-center"}>IP Based (Campus Wide Access)</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>4</td>
                                            <td className={"text-center"}>Science Direct (Engineering and Computer
                                                Science)
                                            </td>
                                            <td className={"text-center"}>275</td>
                                            <td className={"text-center"}>IP Based (Campus Wide Access)</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>5</td>
                                            <td className={"text-center"}>DELNET membership http://delnet.nic.in/</td>
                                            <td className={"text-center"}>e-books/Journals+ILL facility</td>
                                            <td className={"text-center"}>In Library</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>6</td>
                                            <td className={"text-center"}>https://doaj.org/</td>
                                            <td className={"text-center"}>11424</td>
                                            <td className={"text-center"}>In Library</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>7</td>
                                            <td className={"text-center"}>http://www.oajse.com/</td>
                                            <td className={"text-center"}>2000</td>
                                            <td className={"text-center"}>Open access Journals</td>
                                        </tr>
                                        <tr>
                                            <td className={"text-center"}>8</td>
                                            <td className={"text-center"}>Print Journals & Magazines</td>
                                            <td className={"text-center"}>73</td>
                                            <td className={"text-center"}>Print Journals</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <h4 className="text-theme-colored2">Facilities</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <ul>
                                    <li>Air-conditioned Library</li>
                                    <li>Library Reading Hall</li>
                                    <li>E-Lab with 08 Terminals and Internet Connectivity</li>
                                    <li>Book bank</li>
                                    <li>Photostat along with Network facilities.</li>
                                </ul>

                                <h4 className="text-theme-colored2">Services</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <ul>
                                    <li>Current Awareness Services.</li>
                                    <li>Inter Library Loan service on demand.</li>
                                    <li>Newspaper clippings service.</li>
                                    <li>Photo copying on payment basis.</li>
                                    <li>Conference/seminar information.</li>
                                    <li>Book Bank facilities for SC/ST and Economically Weaker Section.</li>
                                </ul>

                                <h4 className="text-theme-colored2">Library Membership</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-bordered table-hover"}>
                                            <thead className={"table-head-red-V"}>
                                            <tr>
                                                <th className={"text-center"}>Sr. No.</th>
                                                <th className={"text-center"}>Type</th>
                                                <th className={"text-center"}>No. of Tickets</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1.</td>
                                                <td className={"text-center"}>Teaching Faculty</td>
                                                <td className={"text-center"}>08 Tickets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2.</td>
                                                <td className={"text-center"}>Non - Teaching</td>
                                                <td className={"text-center"}>02 tickets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3.</td>
                                                <td className={"text-center"}>U. G. Students</td>
                                                <td className={"text-center"}>03 tickets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4.</td>
                                                <td className={"text-center"}>P. G. Students (Regular)</td>
                                                <td className={"text-center"}>04 tickets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5.</td>
                                                <td className={"text-center"}>P. G. Students (Part Time)</td>
                                                <td className={"text-center"}>02 tickets</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6.</td>
                                                <td className={"text-center"}>Special Members</td>
                                                <td className={"text-center"}>01 tickets</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <h4 className="text-theme-colored2">No. of Books and Periodicals</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <p className={"text-justify"}>The Number of books a borrower can take and the period for
                                    which he/she can retain as follows: </p>
                                <div className={"row"}>
                                    <div className={"col-lg-1 col-md-1"}></div>
                                    <div className={"col-lg-9 col-md-9"}>
                                        <table className={"table table-bordered table-hover"}>
                                            <thead className={"table-head-red-V"}>
                                            <tr>
                                                <th className={"text-center"}>Sr. No.</th>
                                                <th className={"text-center"}>Users</th>
                                                <th className={"text-center"}>No. of Books</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1.</td>
                                                <td className={"text-center"}>Under Graduate Students</td>
                                                <td className={"text-center"}>03 (02 text books, 01 general book)</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2.</td>
                                                <td className={"text-center"}>Post Graduate Students (Regular)</td>
                                                <td className={"text-center"}>04 (03 text books, 01 general book)</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3.</td>
                                                <td className={"text-center"}>Post Graduate Students (Part Time)</td>
                                                <td className={"text-center"}>02 (02 text books)</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4.</td>
                                                <td className={"text-center"}>Non-Teaching Staff</td>
                                                <td className={"text-center"}>02 (general books)</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5.</td>
                                                <td className={"text-center"}>Teaching Staff</td>
                                                <td className={"text-center"}>08 (03 text books,05 general books)</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6.</td>
                                                <td className={"text-center"}>Special Members</td>
                                                <td className={"text-center"}>01</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <h4 className="text-theme-colored2">Loan Period</h4>
                                <div className="double-line-bottom-theme-colored-2"></div>
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-hover"}>
                                            <thead className={"table-head-red-V"}>
                                            <tr>
                                                <th className={"text-center"}>#</th>
                                                <th className={"text-center"}>Type</th>
                                                <th className={"text-center"}>No. of Books</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}>1.</td>
                                                <td className={"text-center"}>General/Text books</td>
                                                <td className={"text-center"}>28 Days</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>2.</td>
                                                <td className={"text-center"}>Overnight/Ref. Text books</td>
                                                <td className={"text-center"}>01 Day</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>3.</td>
                                                <td className={"text-center"}>Book Bank Books</td>
                                                <td className={"text-center"}>Full semester</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>4.</td>
                                                <td className={"text-center"}>Faculty (Text books)</td>
                                                <td className={"text-center"}>Full semester</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>5.</td>
                                                <td className={"text-center"}>Current Journals/Periodicals</td>
                                                <td className={"text-center"}>Not to be issued</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>6.</td>
                                                <td className={"text-center"}>Bound Journals/Periodicals</td>
                                                <td className={"text-center"}>Overnight</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>7.</td>
                                                <td className={"text-center"}>Thesis, Non-book and CD-ROMs</td>
                                                <td className={"text-center"}>Not to be issued</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}>8.</td>
                                                <td className={"text-center"}>Reference Books</td>
                                                <td className={"text-center"}>Not to be issued</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey={2} title="Library Rules">
                            <div className={"container"}>
                                <ol type={"1."}>
                                    <li><b>
                                        <ins>Library Use</ins>
                                        :</b> The Library is primarily intended for the students and staff of the
                                        college, who can make use of Library collections, facilities and service in
                                        accordance with the rules. Other can use the Library only with the special
                                        permission of the Principal/Librarian.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Access To Library Documents</ins>
                                        :</b> The Readers have free access to books and periodicals which are on the
                                        open shelves, however, Text book, reference books and dissertations can be
                                        consulted only in the assigned area.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Services</ins>
                                        :</b> Members are free to seek the assistance of Library Staff in selecting
                                        reading material, checking of references, searching of misplaced reading
                                        material etc.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Inter Library Loan</ins>
                                        : </b> Books and periodicals not in the Library can be requested on loan from
                                        other libraries or Institutions. The cost of postage on them has to be paid by
                                        the member for whom they are borrowed.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Suggestions</ins>
                                        : </b> Members are free to recommend new books or journals for the library and
                                        to suggest improvement in library services, suggestions and recommendations,
                                        duly signed can be put in the suggestion register kept in library.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Library Membership</ins>
                                        : </b> The students, teachers and non-teaching staff of the college can enroll
                                        themselves as members by filling a prescribed application form and agreeing to
                                        abide by the rules. Persons other than those listed above can be enrolled as
                                        special members with the approval of the Principal and on payment of Rs. 1000/-
                                        as security (refundable) and the requisite fee payable annually. Borrower’s
                                        cards given to registered members are strictly non-transferable and are to be
                                        renewed every year.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Loss of library ticket/membership card</ins>
                                        :</b> Keeping of library tickets/membership card in good condition is entire
                                        responsibility of the borrower. A duplicate ticket/membership card is prepared
                                        at the rate of payment of Rs. 10/- per ticket. A lost borrowers card/ticket if
                                        found should be immediately deposited in the library. Failure to comply, or its
                                        misuse, can lead to cancellation of membership, if a duplicate card has been
                                        issued.
                                    </li>
                                    <br/>
                                    <ol type={"A"}>
                                        <li><b>
                                            <ins>Overdue Charges</ins>
                                            :</b> The following overdue charges are recovered for library material
                                            retained longer than the period of loan from all borrowers:
                                        </li>
                                        <ul>
                                            <li>General Books: 50 paise per book per day for the first week of overdue
                                                Rs. 1.00 per book per day for the second. and Rs. 2.00 per day per book
                                                for successive weeks. Grace period of four days including holidays will
                                                be allowed for every over-due general book during which no over-due
                                                charges will be realized i.e. for first four days after the due date.
                                                When the book is returned after grace period the over-due charges will
                                                be realized for the grace period also at the usual rate of 50 paise per
                                                book per day.
                                            </li>
                                            <li>Overnight Text Books: Rs. 1.00 per hour per book.</li>
                                            <li>Overnight Bound Periodicals: Rs. 2.00 per volume per hour.</li>
                                            <li>Fine can be reduced or remitted on reasonable grounds by the Principal
                                                on the recommendation of the Chairman Library Committee.
                                            </li>
                                        </ul>
                                        <li><b>
                                            <ins>Loss of Library Material</ins>
                                            :</b> Following charges are recovered from the borrowers for the loss of any
                                            library material:
                                        </li>
                                        <ol type={"a"}>
                                            <li>General Books:</li>
                                            <ul>
                                                <li>Replacement or the current price plus Rs. 5.00 as processing
                                                    charges.
                                                </li>
                                                <li>In case of a book the current price of which is not available or the
                                                    book itself has become out of print, the price to be collected from
                                                    the reader will be @ 10% increase in price per year from the date of
                                                    its procurement subject to the maximum of four times of its original
                                                    price. The reader however, is at liberty to replace the book within
                                                    45 days in case he/she could manage it.
                                                </li>
                                                <li>In case of books received free and whose price is not given in any
                                                    of the catalogues, the College Librarian is authorized to fix
                                                    reasonable price.
                                                </li>
                                                <li>The cost of the complete set may have to be paid for the loss of a
                                                    single volume if it is not available separately.
                                                </li>
                                            </ul>

                                            <li>Periodicals::</li>
                                            <ul type={"circle"}>
                                                <li><b>Single issue of Periodicals:</b> Replacement within three months
                                                    or the cost of the volume if the single issue is not available.
                                                </li>
                                                <li><b>Bound volume of Periodicals:</b> Replacement of the volume or
                                                    four times the cost of the volume.
                                                </li>
                                            </ul>
                                        </ol>
                                    </ol>
                                    <li><b>
                                        <ins>Theft/Tearing Pages of Documents</ins>
                                        : </b> In case of theft or tearing pages of the Library documents the
                                        replacement charge must be charged 10 times more than the cost of the document
                                        subject to the maximum of Rs 3000/- or cost of book whichever is higher and
                                        person involved can be debarred from his/her Library facilities depending on the
                                        seriousness of the offense.
                                    </li>
                                    <br/>
                                    <li><b>
                                        <ins>Reprographic Unit</ins>
                                        :</b> Xerox facility is available for technical papers only to the users at the
                                        following rates:
                                        A-4 size (single side) @ Rs.1.00/- only.
                                        <b>Note:</b> The Xeroxing Materials should reach one day before. Xeroxed
                                        Materials will be delivered from 02.00 P. M. to 5.00 P.M. For more than 20 pages
                                        approval will be required from the Librarian.
                                    </li>
                                    <li><b>
                                        <ins>General</ins>
                                    </b>:
                                    </li>
                                    <ol type={"a"}>
                                        <li>Every member who enters the Library shall sign the gate register in token of
                                            his acceptance to adhere to the rules of Library.
                                        </li>
                                        <li>No reader is allowed to sleep in the Library premises.</li>
                                        <li>Readers shall vacate their seats 10 minutes before the closing time of the
                                            Library.
                                        </li>
                                        <li>Books in special demand may be issued for shorter period as decided by the
                                            Librarian.
                                        </li>
                                        <li>Book shall not be transferred by a borrower to others.</li>
                                        <li>Library lending ticket/membership card and the Identity Card is obligatory
                                            while visiting the Library. These cards must be produced on demand by the
                                            Library staff.
                                        </li>
                                        <li>A member of the Library who loses a ticket/membership card shall make a
                                            written report to the Librarian.
                                        </li>
                                        <li>Misuse of borrowers’ ticket is punishable. A card thus received will be
                                            cancelled.
                                        </li>
                                        <li>The Librarian may recall any book at any time if there is demand.</li>
                                        <li>Silence and order must at all times be maintained in and around the
                                            Library.
                                        </li>
                                        <li>Smoking and carrying inflammable materials is not allowed inside or arround
                                            the Library. Any infringement of this rule shall be punishable.
                                        </li>
                                        <li>All personal belongings should be kept outside the Library at a particular
                                            place assigned for the purpose except Note Books.
                                        </li>
                                        <li>The Librarian is the incharge and is responsible for supervision and
                                            discipline in the library. He has the right to ask a person to leave the
                                            premises in case of misbehaviour. In case of doubt the Library staff can
                                            have a thorough search of Library user.
                                        </li>
                                        <li>Outsiders are allowed to visit the Library with prior written permission of
                                            the Principal.
                                        </li>
                                        <li>The last borrower shall be responsible for any damage to the book/periodical
                                            etc. unless it has been pointed out at the time of issue.
                                        </li>
                                        <li>Books shall be recalled and their issue suspended during the period of stock
                                            checking.
                                        </li>
                                        <li>The “No Dues Certificate " can be secured from the library after
                                            surrendering the borrowers' card and clearing all library dues. In case of
                                            the loss of Borrowers' card, No Dues Certificate can be issued only 14 days
                                            after the normal expiry of the membership. Provisional 'No Dues Certificate'
                                            cannot be issued.
                                        </li>
                                        <li>Taking books out of the library without authorization, marking or
                                            underlining, or in anyway disfiguring and mutilating books, or library
                                            property is a misdemeanor, which may lead to withdrawal of library
                                            privileges, or in serious cases to heavy fine and disciplinary action.
                                        </li>
                                        <li>Change of address and designation should be immediately reported at the
                                            Circulation Counter of the library.
                                        </li>
                                        <li>Library reading material borrowed may be got re-issued if it is not needed
                                            by another user. The borrower must get the library reading material
                                            re-issued in person. It is, however, at the discretion of the College
                                            Librarian, with the recommendations of the Circulation Incharge.
                                        </li>
                                        <li>No library reading material would be considered returned until it has been
                                            returned properly to the library personnel at Circulation Counter.
                                        </li>
                                        <li>Mobile should be on silent mode in and around the library.</li>
                                        <li>Violation of these rules or undisciplined behaviours in the Library will
                                            lead to cancellation of membership and disciplinary action.
                                        </li>
                                    </ol>
                                </ol>
                            </div>
                        </Tab>

                        <Tab eventKey={3} title="Faculty of Library">
                            <br/>
                            <div className={"container"}>
                                <table className={"table table-responsive"}>
                                    <thead className={"table-head-red-V"}>
                                    <tr>
                                        <td>Faculty of Library</td>
                                    </tr>
                                    </thead>
                                </table>

                                {/* Dr. Prabhjot Kaur */}
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>

                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} alt="Library Faculty" src={"assets/images/faculty/LibPrabhjot.jpg"}
                                             className={"img-responsive"} style={{height: '190px'}}/>
                                    </div>

                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Dr. Prabhjot Kaur</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Assistant Librarian</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 9855434404</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>prabhriar@gmail.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/1"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <br/>

                                {/* Dr Navdeep Singh Rajput */}
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} alt="Library Faculty" src={"assets/images/faculty/LibNavdeep.jpg"}
                                             className={"img-responsive"} style={{height: '190px'}}/>
                                    </div>

                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Dr Navdeep Singh Rajput</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Library Assistant</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 8146983366</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>prabhriar@gmail.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/2"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <br/>

                                {/* Nirmal Singh */}
                                <div className={"row"}>
                                    <div className={"col-lg-2 col-md-2"}></div>
                                    <div className={"col-lg-2 col-md-2"}>
                                        <img id={"libFac"} alt="Library Faculty" src={"assets/images/faculty/LibNirmal.jpg"}
                                             className={"img-responsive"} style={{height: '190px'}}/>
                                    </div>
                                    <div className={"col-lg-8 col-md-8"}>
                                        <table className={"table table-responsive"}>
                                            <tbody>
                                            <tr>
                                                <td className={"text-center"}><b>Name:</b></td>
                                                <td className={"text-center"}>Nirmal Singh</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Designation:</b></td>
                                                <td className={"text-center"}>Junior Technician</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Contact No.</b></td>
                                                <td className={"text-center"}>+91 9877776385</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-center"}><b>Email:</b></td>
                                                <td className={"text-center"}>prabhriar@gmail.com</td>
                                            </tr>
                                            <tr>
                                                <td className={"text-right"} colSpan={"3"}>
                                                    <Link to={"../ViewProfile/3"}>
                                                        <button className={"btn btn-md btn-theme-colored2"}><span
                                                            className={"fa fa-arrow-circle-o-right"}></span> View
                                                            Profile
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Library;