import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./FacultyCHM.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from "axios";
import {URL} from "./components/MyModules";
import FacultyCard from "./components/FacultyCard";

const FacultyCHM = (props) => {
    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(1);
    }, [])


    // Fetch Faculty
    function FetchFaculty(key) {
        // console.log(key + ' ******')
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Faculty" subTitle="Applied Science, Humanities & Management"
                               bdLink="Faculty"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">
                            Faculty</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <Tabs defaultActiveKey={1} id="uncontrolled-tab-example" onSelect={FetchFaculty}>
                        {/*<Tab eventKey={1} title="All">*/}
                        {/*   <div className="container">*/}

                        {/*   </div>*/}
                        {/*</Tab>*/}

                        <Tab eventKey={1} title="Applied Physics">
                            <div className="container">
                                <h2 className="text-theme-colored2">Applied Physics</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>

                                <h3 className="">Professor</h3>
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                <div className="clearfix"></div>
                                <hr/>

                                <h3 className="">Associate Professor</h3>
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                <div className="clearfix"></div>
                                <hr/>

                                <h3 className="">Assistant Professor</h3>
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                <div className="clearfix"></div>
                                <hr/>

                                <h3 className="">Lecturer</h3>
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={10} title="Bio-Tech">
                            <div className="container">
                                <h2 className="text-theme-colored2">Bio-Technology</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={7} title="CE">
                            <div className="container">
                                <h2 className="text-theme-colored2">Civil Engineering</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={3} title="ME">
                            <div className="container">
                                <h2 className="text-theme-colored2">Mechanical Engineering</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={8} title="Agr.Sc">
                            <div className="container">
                                <h2 className="text-theme-colored2">Agriculture Science</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={9} title="Applied Mathematics">
                            <div className="container">
                                <h2 className="text-theme-colored2">Applied Mathematics</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={5} title="Chem.Eng">
                            <div className="container">
                                <h2 className="text-theme-colored2">Chemical Engineering</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={2} title="CSE">
                            <div className="container">
                                <h2 className="text-theme-colored2">Computer Science &amp; Engineering</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={4} title="ECE">
                            <div className="container">
                                <h2 className="text-theme-colored2">Electronics &amp; Communication Engineering</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={6} title="CHM">
                            <div className="container">
                                <h2 className="text-theme-colored2">Chemistry, Humanities &amp; Management</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>

                        <Tab eventKey={13} title="Applied Chem">
                            <div className="container">
                                <h2 className="text-theme-colored2">Applied Chemistry</h2>
                                <div className="double-line-bottom-theme-colored-2 mb-25"></div>
                                {
                                    facultyData.length > 0 ? <h3 className="">Professor</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        facultyData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    facultyData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assoprofData.length > 0 ? <h3 className="">Associate Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assoprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assoprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    assiprofData.length > 0 ? <h3 className="">Assistant Professor</h3> : ""
                                }

                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        assiprofData.map((row, index) => {
                                            return (
                                                <FacultyCard key={index} obj={row} photo={row.photo}
                                                             facultyname={row.facultyname}
                                                             designation={row.designation}
                                                             qualification={row.qualification} email={row.email}/>
                                            );
                                        })
                                }
                                {
                                    assiprofData.length > 0 ? <>
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                                }

                                {
                                    otherprofData.length > 0 ? <h3 className="">Lecturer</h3> : ""
                                }
                                {

                                    fetchingData ?
                                        <i className={"fa fa-spinner fa-2x"}></i>
                                        :
                                        otherprofData.map((row, index) => {
                                            return (
                                                <>
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                </>
                                            );
                                        })
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {/*<div className="container">*/}

            {/*    /!*CARD*!/*/}
            {/*    <div className="card_V margin-25-V">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-3">*/}
            {/*                <img src="assets/images/faculty/driver1.jpeg" alt="Faculty Image" className="faculty-img_V"/>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-7">*/}
            {/*                <table className="table table-borderless">*/}
            {/*                    <tbody>*/}
            {/*                    <tr>*/}
            {/*                        <th className="table-body-padd-V">Name -</th>*/}
            {/*                        <td className="table-body-padd-V">Dr. Rakesh Dogra</td>*/}

            {/*                    </tr>*/}
            {/*                    <tr>*/}
            {/*                        <th className="table-body-padd-V">Designation -</th>*/}
            {/*                        <td className="table-body-padd-V">Professor of Physics</td>*/}
            {/*                    </tr>*/}
            {/*                    <tr>*/}
            {/*                        <th className="table-body-padd-V">Contact Number -</th>*/}
            {/*                        <td className="table-body-padd-V">N.A.</td>*/}
            {/*                    </tr>*/}
            {/*                    <tr>*/}
            {/*                        <th className="table-body-padd-V">Email -</th>*/}
            {/*                        <td className="table-body-padd-V">drdogra@yahoo.com</td>*/}
            {/*                    </tr>*/}
            {/*                    </tbody>*/}
            {/*                </table>*/}

            {/*            </div>*/}
            {/*            <div className="col-lg-2">*/}
            {/*                <a href="" className="btn btn-lg btn-danger theme_btn_V mt-l_V">Details</a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <Footer/>
        </>
    );
}

export default FacultyCHM;