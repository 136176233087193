import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";

const DownloadsForStaff = (props) => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Downloads For Staff" subTitle="Sardar Beant State University, Gurdaspur"
                               bdLink="Downloads"/>

            <div className="section3">
                <div className="container pt-90">
                    <h1 className="text-theme-colored2">Downloads (Staff)</h1>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="row" id="myButtons">
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/ArrearAffidavit.jpg" target="_blank" className="btn theme-btn btn-block myButton_A">Affidavit for Arrears</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/BillForTaHonorarium.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Bill For TA and Honorarium</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Staff_Performa/Advance-CPF.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Advance C.P.F Form</a>
                        </div>
                    </div>

                    <div className="row" id="myButtons">
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/CasualLeavePerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Casual Leave Proforma</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/EarnedLeavePerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Earned Leave Performa</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/HouseAllotmentForm.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">House Allotment Form</a>
                        </div>
                    </div>

                    <div className={"row"} id={"myButtons"}>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Rpp.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Recquisition form for Purchase </a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/GuestHousePerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Form for Guest House Allotment</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/ComplaintPerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Maintainence related Complaint Form</a>
                        </div>
                    </div>

                    <div className="row" id="myButtons">
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/TempoaryAdvance.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Temporary Advance Form</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Staff_Performa/Tour-Approval.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Tour Approval Proforma</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/LeaveTravelConcession.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Leave Travel Concession</a>
                        </div>
                    </div>

                    <div className={"row"} id={"myButtons"}>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/LectureBasisSalaryForm.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Lecture Basis Salary Form</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/TaDaClaimPerforrma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">TA-DA Claim Form</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/VehicleRequisitionPerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Transport Vehicle Requisition</a>
                        </div>
                    </div>

                    <div className={"row"} id={"myButtons"}>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Staff_Performa/Honouraium.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">
                                Honouraium
                            </a>
                        </div>
                        {/*<div className="col-sm-4 col-xs-6 text-center">*/}
                        {/*    <a href="assets/Downloads/MealsPerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Performa for Arrangement of  Meals <br/> in hostel</a>*/}
                        {/*</div>*/}
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/perfoma_of_meals_new.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Performa for Arrangement of  Meals <br/> in hostel [ NEW ]</a>
                        </div>
                    </div>

                    {/* Library Membership Performa */}
                    <h4 className="text-theme-colored2">Library Membership Performa</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className={"row"} id={"myButtons"}>
                        <div className={"col-sm-4"}></div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/LibraryMembershipForm.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Library Membership Performa</a>
                        </div>
                    </div>

                    {/* Alumni Feedback */}
                    <h4 className="text-theme-colored2">Alumni Feedback</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className={"row"} id={"myButtons"}>
                        <div className={"col-sm-4"}></div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/EmployerFeedback.doc" target="_blank" className="btn theme-btn btn-block myButton_A">Alumni Feedback Performa</a>
                        </div>
                    </div>

                    <h4 className="text-theme-colored2">ACR Performa</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className={"row"} id={"myButtons"}>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Staff_Performa/ACR-Performa-of-Faculty.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">
                                ACR Performa of Faculty
                            </a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Staff_Performa/ACR-Proforma-of-Non-Teaching-class-1-2-3.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">
                                ACR Performa of Non-Teaching <br/>class 1, 2, 3
                            </a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/Staff_Performa/ACR-Proforma-of-Non-Teaching-class-4.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">
                                ACR Performa of Non-Teaching class 4
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DownloadsForStaff;