import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Chancellor = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="Chancellor" bdLink="Chancellor"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h2 className="text-theme-colored2 mb-15"> Chancellor </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="row">
                            <div className="col-sm-7">
                                <p className="h3 font-20">
                                    <strong className="fa-1x">Sh. Banwarilal Purohit<br/>Honorable Governor of Punjab</strong> <br/>
                                    {/*Minister Incharge, Department of Tech Education &amp; Industrial Training*/}
                                </p>
                            </div>
                            <div className="col-sm-5">
                                <img src="assets/images/staff/governorpunjab.jpeg" className="img-responsive" alt=""/>
                                <p className="text-center text-theme-colored2"><strong> Sh. Banwarilal Purohit, Honorable Governor of Punjab </strong></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Chancellor;