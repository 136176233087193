import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const PhdME = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Doctor of Philosophy Courses" subTitle="Phd(Mech. Engg.)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Doctor of Philosophy: Mechanical
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">

                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                   The department of mechanical engineering also offers Ph. D. degree program in the
                                    various streams of Mechanical Engineering. The department has highly qualified
                                    faculty members eligible for guiding the Ph. D students in almost all the streams of
                                    Mechanical Engineering. The students have to clear three relevant course works
                                    before getting registered for the Ph. D. research work.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/15.png" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed M.E. / M. Tech. degree in (Mechanical/Production
                                /Industrial/ Automobile or any other relevant field) with at least 50% (45% in case of
                                candidate belonging to reserved category) marks in aggregate shall be eligible to apply
                                for Ph. D. Course. through Written test/ interview as per UGC guidelines
                            </p>
                        </div>
                    </div>

                     {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 3 years long
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default PhdME;