import React from "react";
import {Link} from "react-router-dom";

import NavBar2 from "../../pages/components/NavBar2";
import BreadCrumbSection from "../../pages/components/BreadCrumbSection";
import Counseling_Schedule_PDF from "../../assets/pdf/Counseling-Schedule-and-Essential-Qualification-24-25.pdf"

const CounsellingInstructions = () => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Online Counseling 2024 | SBSSU" bdLink="Online Counseling"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font"}>
                        Counseling Instructions
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="container">
                        <div className="alert alert-danger">
                            <h4>Online counseling will be carried out as per schedule given below. Apply for counseling
                                to get admission at Sardar Beant Singh State University, Gurdaspur in your preferred
                                course. Please pay counseling fee of Rs. 2000/- .</h4>

                            <div className="mt-20">
                                <table className="table table-hover margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Round</th>
                                        <th className="table-title-V padd-l-10">Counseling Registration dates</th>
                                        <th className="table-title-V padd-l-10">Intimation of admission</th>
                                        <th className="table-title-V padd-l-10">Payment of fee</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="bg-light">
                                        <td className="table-body-padd-V syllabus-table-v">1</td>
                                        <td className="table-body-padd-V syllabus-table-v">20/08/24 to 22/08/24</td>
                                        <td className="table-body-padd-V syllabus-table-v">23/08/24</td>
                                        <td className="table-body-padd-V syllabus-table-v">up to 26/08/24</td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2</td>
                                        <td className="table-body-padd-V syllabus-table-v">23/08/24 to 26/08/24</td>
                                        <td className="table-body-padd-V syllabus-table-v">27/08/24</td>
                                        <td className="table-body-padd-V syllabus-table-v">up to 30/08/24</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>


                            <p className="my-font-size mt-20">
                                After second round of online counseling, the admission will be done on "First come,
                                first served" basis for eligible candidates.
                            </p>

                            <p className="my-font-size mt-20">Use the following link to apply for counseling to get
                                admission.</p>


                            <div className="mt-20">
                                <Link style={{textDecoration: 'underline', color: '#C21717'}} to="/Online-Counselling">
                                    <b>Online Counselling</b>
                                </Link>
                            </div>

                            <p className="my-font-size mt-20">Once you submit the
                                counseling registration form by clicking the link above, you must login to the admission
                                portal using the id and password sent to your e-mail.</p>

                            <h5 className="mt-20">
                                <i> Once you <a style={{textDecoration: 'underline', color: '#C21717'}}
                                                href="http://sbssugsp.ac.in:8000/student" target="_blank">Login</a> to
                                    the admission portal by clicking the link we request you to:-</i>
                            </h5>

                            <ol>
                                <li>Pay Your Counseling Fees</li>
                                <li>Upload Your Preferences</li>
                                <li>Upload your Marks</li>
                            </ol>

                            {/*<a href={Counseling_Schedule_PDF} style={{margin: '35px 0'}}*/}
                            {/*   className="btn theme-btn myButton_A" target="_blank">*/}
                            {/*    <i className="fa fa-file-pdf-o"></i> Counseling Schedule and Essential Qualification*/}
                            {/*    24-25*/}
                            {/*</a>*/}

                            <h5  style={{margin: '35px 0'}}><b>Note</b>: Please check your email regularly as all the
                                communication will be done through email ID.
                            </h5>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CounsellingInstructions