import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

// import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";


const Iste = (props) => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Iste Activities" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Iste"/>

            <div className="section3">
                <div className="container pt-90">

                    <div className="row">
                        <h2 className="text-theme-colored2">Incharge ISTE</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-2 col-md-2"}></div>
                        <div className={"col-lg-8 col-md-8"}>
                            <table className={"table table-responsive"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <td className={"text-center"}>Mrs. Mandeep Kaur</td>
                                    <td className={"text-center"}>Assistant Professor<br/>(Dept. of Electronics and
                                        Communication)
                                    </td>
                                    <td className={"text-center"}><span
                                        className={"fa fa-envelope"}></span> gurpadam@yahoo.com
                                    </td>
                                </tr>
                                </thead>
                            </table>

                        </div>
                        <div className={"col-lg-2 col-md-2"}></div>
                    </div>

                    <div className="mb-35 row">
                        <h2 className="text-theme-colored2">List of Executive Members</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className={"table-responsive col-md-12 col-sm-12"}>
                            <table className={"table margin-25-V table-hover"}>
                                <thead>
                                <tr className={"table-head-red-V"}>
                                    <th className={"text-center"}>Sr. No.</th>
                                    <th className={"text-center"}>Name of the Student</th>
                                    <th className={"text-center"}>Branch, Year of Study, Contact No. and E-mail</th>
                                    <th className={"text-center"}>Designation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Ayush Bisaria</td>
                                    <td className={"text-center"}>ECE - 4th Year; +91 7696650748</td>
                                    <td className={"text-center"}>President</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Ayush Bisaria</td>
                                    <td className={"text-center"}>ECE - 4th Year; +91 7696650748</td>
                                    <td className={"text-center"}>President</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>NEHA THAKUR</td>
                                    <td className={"text-center"}>ECE - 4TH Year ; 8557979228</td>
                                    <td className={"text-center"}>CONVEYOR</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}>ASHISH CHAUHAN</td>
                                    <td className={"text-center"}>ECE - 4TH Year ; 9915102621</td>
                                    <td className={"text-center"}>PUBLICITY HEAD</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}>MANPREET KAUR</td>
                                    <td className={"text-center"}>I.T - 4th Year ; 9878808960</td>
                                    <td className={"text-center"}>TREASURE HEAD</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>SUPREET KAUR</td>
                                    <td className={"text-center"}>ECE - 4th Year ; 9041034251</td>
                                    <td className={"text-center"}>CREATIVE HEAD</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>MANPREET KAUR</td>
                                    <td className={"text-center"}>ECE - 4th Year ; 8699327648</td>
                                    <td className={"text-center"}>RECORD KEEPING HEAD</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>DEEPAK SHARMA</td>
                                    <td className={"text-center"}>I.T - 4th Year ; 8557095084</td>
                                    <td className={"text-center"}>TECHNICAL HEAD</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mb-35 row">
                        <h2 className="text-theme-colored2">Activities Performed at College Level in the Current
                            Academic Year 2015-16</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className={"table-responsive col-md-12 col-sm-12"}>
                            <table className={"table margin-25-V table-hover"}>
                                <thead>
                                <tr className={"table-head-red-V"}>
                                    <th className={"text-center"}>Sr. No.</th>
                                    <th className={"text-center"}>Activity/Event</th>
                                    <th className={"text-center"}>Date</th>
                                    <th className={"text-center"}>Winners</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Quiz Competition</td>
                                    <td className={"text-center"}>Sept. 10, 2015</td>
                                    <td className={"text-center"}>List Attached</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>TechFest-2K15</td>
                                    <td className={"text-center"}>Oct. 15-16, 2015</td>
                                    <td className={"text-center"}>
                                        <a href="/assets/pdf/IsteRes2015.pdf" target="_blank"><span
                                            className="fa fa-download"></span> Download </a>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mb-35 row">
                        <h2 className="text-theme-colored2">Recent Photographs of Activities Conducted under ISTE
                            Students Chapter</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="row m-7-v">
                            <div className="col-md-6 col-sm-12 mb-35 text-center">
                                <div className="imageEffect-v-2">
                                    <img src="../assets/images/lab/iste1-15.png" className="Image_A imgISTE"
                                         alt="Bhangra Group"/>
                                </div>
                                <p className="text-theme-colored2">Inauguration of TechFest-2K15 by Chief Guest,
                                    <br/> Our
                                    Principal (Dr. Ravi kumar) and Dr. Jagdev Singh</p>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-35 text-center">
                                <div className="imageEffect-v-2">
                                    <img src="../assets/images/lab/iste2-15.png" className="Image_A imgISTE"
                                         alt="Bhangra Group"/>
                                </div>
                                <p className="text-theme-colored2">Motivational Speech by Our <br/> Respectable Chief
                                    Guest</p>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-35 text-center">
                                <div className="imageEffect-v-2">
                                    <img src="../assets/images/lab/iste3-15.png" className="Image_A imgISTE"
                                         alt="Bhangra Group"/>
                                </div>
                                <p className="text-theme-colored2">Lamp lightning By ISTE Incharge (Mrs. Anita Suman)
                                    and Sh. Praveen Kumar</p>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-35 text-center">
                                <div className="imageEffect-v-2">
                                    <img src="../assets/images/lab/iste4-15.png" className="Image_A imgISTE"
                                         alt="Bhangra Group"/>
                                </div>
                                <p className="text-theme-colored2">Chief Guest and Principal Sir rating the students
                                    model displayed at event SCIEN-O-DARSHAN
                                </p>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-35 text-center">
                                <div className="imageEffect-v-2">
                                    <img src="../assets/images/lab/iste5-15.png" className="Image_A imgISTE"
                                         alt="Bhangra Group"/>
                                </div>
                                <p className="text-theme-colored2">Students getting Memetos for Winning an Event</p>
                            </div>
                        </div>
                    </div>

                    <div className="mb-35">
                        <h2 className="text-theme-colored2">Quiz Competition
                            September 10, 2015 Result Sheet </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className={"table-responsive"}>
                            <table className={"table margin-25-V table-hover"}>
                                <thead>
                                <tr className={"table-head-red-V"}>
                                    <th className={"text-center"}>Sr. No.</th>
                                    <th className={"text-center"}>Position</th>
                                    <th className={"text-center"}>Winners</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>First</td>

                                    <td className={"text-center"}> Vishawjit Chopra and Inderpreet (2nd year CSE)</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Second</td>

                                    <td className={"text-center"}>Ankush and Manav (3rd year IT)</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Third</td>

                                    <td className={"text-center"}>Ishan (3rd year ECE) and Vikas Sharma (3rd year
                                        Chemical)
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            <Footer/>
        </>
    );
}

export default Iste;

