import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";

function GrievanceRedressalCell() {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration"
                               subTitle="Grievance Redressal Cell"
                               bdLink="Grievance Redressal Cell"/>
            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h2 className="text-theme-colored2 mb-15">
                            Grievance Redressal Cell
                        </h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div>
                            <p className="text-justify">In compliance with the guidelines issued by the University Grant
                                Commission vide D.O. No.
                                F.1-13/2022(CPP-II) dated April 12, 2023, to formulate a simplified mechanism for
                                student
                                Grievance Redressal regulations, 2023 the following Students' Grievances Redressal
                                Committee (SGRC) is constituted for two years.</p>

                            <div className="table-responsive">
                                <table className="table-hover margin-25-V my-table-style">
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V text-center" style={{width: '50%'}}></td>
                                        <td className="table-body-padd-V text-center" style={{width: '50%'}}></td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V text-center">Dr. O.P. Singh, Professor</td>
                                        <td className="table-body-padd-V text-center">Chairperson</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dean Students Welfare</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dean, Academic Affairs</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dean Faculties</td>
                                        <td className="table-body-padd-V text-center">Members</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dr. Vipan Kumar</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">Dr. Anju Awasthi</td>
                                        <td className="table-body-padd-V text-center">Member</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V text-center">
                                            A representative from among students to be nominated on academic
                                            merit/excellence in sports/performance in co-curricular activities
                                        </td>
                                        <td className="table-body-padd-V text-center">
                                            Special invitee <br/> (for One Year)
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default GrievanceRedressalCell;