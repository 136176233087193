import Footer from "../components/Footer";
import React from "react";
import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";

const UniversityEstablishment = () => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="University Establishment" subTitle="University Establishment"
                               bdLink="University Establishment"/>


            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">
                            University Establishment
                        </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className="">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V text-center">Download</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Upgradation Certificate from College to University</td>
                                <td className="table-body-padd-V text-center">
                                    <a href="assets/pdf/transparency/upgradation-of-certificate-from-college-to-university.pdf"
                                       target='_blank' className='btn theme-btn  myButton_A'>
                                        <i className='fa fa-file-pdf-o'></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">
                                    Establishment Order of University
                                </td>
                                <td className="table-body-padd-V text-center">
                                    <a href="assets/pdf/transparency/establishment-order-of-university.pdf"
                                       target='_blank' className='btn theme-btn  myButton_A'>
                                        <i className='fa fa-file-pdf-o'></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">
                                    Sardar Beant Singh State University Act
                                </td>
                                <td className="table-body-padd-V text-center">
                                    <a href="assets/pdf/transparency/SBSSU-University-Act.pdf"
                                       target='_blank' className='btn theme-btn  myButton_A'>
                                        <i className='fa fa-file-pdf-o'></i>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default UniversityEstablishment;