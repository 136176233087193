import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const SAE = () => {
    document.title = "SAE Club";
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="SAE Club"
                               subTitle="Student's Corner" bdLink="SAE Club"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">SAE Club</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35">
                            <p className="font-p-v m-7-v text-justify">
                                The aim of the club is to enrich knowledge base in mobility industry and institutions in
                                the service of humanity and to provide its members access to SAE's programs. The club
                                provides a forum for members to informally exchange views and ideas.
                            </p>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">Incharge SAE</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <strong>Sh. Sanjeev Kumar, Assistant Professor</strong> <br/>
                            <span>Department of Mechanical Engineering</span> <br/>
                            <span>Email : <a className="mail-to download-links"
                                             href="mailto:sk_74@rediffmail.com">sk_74@rediffmail.com</a></span> <br/>
                            <span>Mobile : 9463048804</span>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">NAME</th>
                                        <th className="table-title-V">BRANCH</th>
                                        <th className="table-title-V">R.No</th>
                                        <th className="table-title-V">DESIGNATION</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td valign="top"><p>Anish Kumar</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>212/09</p></td>
                                        <td valign="top"><p>President </p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Sukanksha Mannan</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>089/09</p></td>
                                        <td valign="top"><p>Vice-President </p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Harpreet Kaur</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>028/09</p></td>
                                        <td valign="top"><p>Creative Head-1 </p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Archit Choudhary</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>214/09</p></td>
                                        <td valign="top"><p>Planning Head-1 </p></td>
                                    </tr>

                                    <tr>
                                        <td valign="top"><p>Maanraj Singh</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>L-101/09</p></td>
                                        <td valign="top"><p>Technical Head-1 </p></td>
                                    </tr>

                                    <tr>
                                        <td valign="top"><p>Gurnoor Walia</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>022/09</p></td>
                                        <td valign="top"><p>Treasurer </p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Manjit Kaur</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>050/09</p></td>
                                        <td valign="top"><p>Secretary </p></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">SENIOR EXECUTIVES</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V">Branch</th>
                                        <th className="table-title-V">Roll.No</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td valign="top"><p>Vineet Kumar</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>258/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Varun Dutta</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>254/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Navkiran Pal Singh</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>235/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Abhived Bhardwaj</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>203/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Harrisham Deep Singh</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>224/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Munish Kumar</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>233/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Aditya Dogra</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>204/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Parvesh Lata</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>136/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Bandana</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>110/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Anjali</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>106/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Nisha</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>134/05</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Tarun Arora</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>556/06</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Atul Mahajan </p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>212/06</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Shaweta Vashishat</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>551/06</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Monika</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>527/06</p></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">EXECUTIVES</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V">Branch</th>
                                        <th className="table-title-V">Roll.No</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td valign="top"><p>Navdeep&nbsp; Singh</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>291/07</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Rohit Sharma</p></td>
                                        <td valign="top"><p>IT</p></td>
                                        <td valign="top"><p>649/07</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Vidushi Mahajan</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>555/07</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Komal Bhagat</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>527/07</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Anchal Sharma</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>506/07</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Daisy Adhikari</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>517/07</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Rahul Dhiman</p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>269/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Pankaj Jaryal&nbsp;&nbsp; </p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>060/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Tarandeep </p></td>
                                        <td valign="top"><p>IT</p></td>
                                        <td valign="top"><p>663/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Shashank </p></td>
                                        <td valign="top"><p>ME</p></td>
                                        <td valign="top"><p>287/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Sudhanshu</p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>559/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Bhavya </p></td>
                                        <td valign="top"><p>ECE</p></td>
                                        <td valign="top"><p>509/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Prabhjot </p></td>
                                        <td valign="top"><p>IT</p></td>
                                        <td valign="top"><p>632/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Honey Pal</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>031/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Archana</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>012/08</p></td>
                                    </tr>
                                    <tr>
                                        <td valign="top"><p>Silky</p></td>
                                        <td valign="top"><p>CSE</p></td>
                                        <td valign="top"><p>090/08</p></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35">
                            <h4 className="my-font-style text-theme-colored2">Events Organised By Sae In Juventus-2012</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <ul>
                                <li>Racing Mania</li>
                                <li>Water Missile</li>
                                <li>Bridge Building</li>
                                <li>Car Clay Modeling</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default SAE;