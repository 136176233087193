import NavBar2 from "./components/NavBar2";
import BreadCrumbSection from "./components/BreadCrumbSection";
import Footer from "./components/Footer";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const Research = () => {
    useEffect(() => {
        document.title = "Research";
    })
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Research &amp; Development" subTitle="Research &amp; Development"
                               bdLink="Research &amp; Development"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font text-theme-colored2"}>
                        Department of Research and Development
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>


                    <div className="mb-35 text-center">

                        <img src="assets/images/faculty/researchDeanSnap.jpg"
                             className="HOD-image-v2" alt=""/>
                        <br/>
                        <br/>
                        <span>
                            <strong className="my-sub-titles certificate">Dr. Sarabjeet Singh
                                Sidhu</strong>
                        </span>
                        <br/>
                        <span>Email : <a className="mail-to text-theme-colored2 text-justify"
                                         style={{fontWeight: "600", fontSize: "16px"}}
                                         href="mailto:tpobcetgurdaspur@gmail.com">
                             sarabjeetsidhu74@gmail.com
                        </a><br/>
                        Email : <a className="mail-to text-theme-colored2 text-justify"
                                   style={{fontWeight: "600", fontSize: "16px"}}
                                   href="mailto:deanresearch@sbssugsp.ac.in ">
                                deanresearch@sbssugsp.ac.in
                        </a>
                        </span>
                        <br/>
                        <span>Contact No. :91-01874- 221464</span>
                    </div>

                    <div className="mb-35">
                        <h4 className="my-font-style text-theme-colored2">Message</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">The academic growth of the university stands on its research
                            capability, where teaching, learning, and innovation complement each other to address
                            societal needs in various issues concerned with energy, water remediation, health,
                            environment, waste utilization, etc. The research and development section of SBSSU has been
                            committed towards developing and maintaining the finest exemplars building block in both
                            fundamental research as well as applied research. The vast variety of engineering sciences
                            at SBSSU provides a unique environment that nurtures interdisciplinary research in
                            cutting-edge technology areas, such as energy, manufacturing, bioengineering, and
                            computational sciences. The diversity of in-house expertise at SBSSU has also catalysed to
                            develop the healthy ecosystem for large scale industrial collaborations in
                            multi-disciplinary areas.</p>

                        <p className="font-p-v m-7-v">To fulfill the need of carrying out Research, Development and
                            Consultancy work, our immediate goal is to encourage faculty members and students at the
                            Bachelor's and Master's levels to consciously bringing in research and innovation elements
                            in their research/project work which can also be got patented. We also encourage the nearby
                            institution/ industry personnel and PG students for conducting research and consultancy work
                            on the weekends/holidays. The state of the art research/ lab facilities enable every
                            aspiring student to conduct their research activities and enrich their career. We will
                            expect that every student will go the extra mile to learn not only the pre-defined academic
                            curriculum, but also to blend it with the need of today's Industry, and experiment the new
                            ideas and concepts, translating them into new research results.</p>

                        <p className="font-p-v m-7-v">I am privileged to be part of this great academic community that
                            shapes the best minds of the country to build world leaders. The key ingredient of this
                            success is the endless effort of my colleagues to bring high-quality publications, patents,
                            and funded projects. We, along with our colleagues from other leading academic institutions
                            of the country, are committed towards our National Mission of Swatch Bharat, Make in India,
                            Stand-up India, and making India the R&D powerhouse of the world.</p>
                    </div>

                    {/*<div className="mb-35">*/}
                    {/*    <h4 className="my-font-style text-theme-colored2">Facilities available for Research and*/}
                    {/*        Consultancy</h4>*/}
                    {/*    <div className="double-line-bottom-theme-colored-2"></div>*/}

                    {/*    <ul className="researchUL">*/}
                    {/*        <li>*/}
                    {/*            Department of Mechanical Engineering*/}
                    {/*            <ul className="researchUL">*/}
                    {/*                <li>*/}
                    {/*                    <Link to="/technicalexpertise" className="researchLinks">Technical Expertise of*/}
                    {/*                        the Faculty Members</Link>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <Link to="/facilities_res_cons" className="researchLinks">Facilities available*/}
                    {/*                        for Research and Consultancy</Link>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            Department of Computer Science and Engineering*/}
                    {/*            <ul className="researchUL">*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Technical Expertise of the Faculty*/}
                    {/*                        Members</Link>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Facilities available for Research and*/}
                    {/*                        Consultancy</Link>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            Department of Electronics and Communication Engineering*/}
                    {/*            <ul className="researchUL">*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Technical Expertise of the Faculty*/}
                    {/*                        Members</Link>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Facilities available for Research and*/}
                    {/*                        Consultancy</Link>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            Department of Information Technology*/}
                    {/*            <ul className="researchUL">*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Technical Expertise of the Faculty*/}
                    {/*                        Members</Link>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Facilities available for Research and*/}
                    {/*                        Consultancy</Link>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            Department of Chemical Engineering*/}
                    {/*            <ul className="researchUL">*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Technical Expertise of the Faculty*/}
                    {/*                        Members</Link>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Facilities available for Research and*/}
                    {/*                        Consultancy</Link>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            Department of Biotechnology*/}
                    {/*            <ul className="researchUL">*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Technical Expertise of the Faculty*/}
                    {/*                        Members</Link>*/}
                    {/*                </li>*/}
                    {/*                <li>*/}
                    {/*                    <Link to="" className="researchLinks">Facilities available for Research and*/}
                    {/*                        Consultancy</Link>*/}
                    {/*                </li>*/}
                    {/*            </ul>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className="mb-35">
                        <hr/>
                        <div className="row">
                            <div className="col-lg-6">
                                <a target='_blank' href="assets/pdf/research/Supervisor-Consent-Form.pdf"
                                   className='btn theme-btn btn-block myButton_A'>
                                    Supervisor/Co-Supervisor Consent Forms
                                </a>
                            </div>
                            <div className="col-lg-6">
                                <a target='_blank' href="assets/pdf/research/PhD-Proposal-Format.pdf"
                                   className='btn theme-btn btn-block myButton_A'>
                                    PhD Proposal Format
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Research;