import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const MscMath = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="M.Sc(Mathematics)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Master of Science in Mathematics</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    M.Sc. Mathematics is a two-year post-graduation course. The main objective of this
                                    course is to is to inculcate a mathematical aptitude among students and nurture the
                                    interests of the students towards problem solving. The curriculum of the course is
                                    helpful for students to prepare for national level competitive exams to pursue their
                                    future research work.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/msc-math.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                            <div className="text-justify">
                                <ul className="m-7-v padd-10-v">
                                    <li>On successful completion of M.Sc. Mathematics (two years), the students can
                                        apply their knowledge of mathematics to interdisciplinary fields and can pursue
                                        research in Pure/Applied Mathematics and Statistics. They can also pursue career
                                        in academic/research institutions, software companies etc.
                                    </li>
                                    <li>After acquiring postgraduate degree in mathematics, they can go for scientific
                                        positions in research organizations and can pursue their career in teaching as
                                        well.
                                    </li>
                                    <li>Research facilities exist in pure mathematics, applied mathematics and
                                        statistics. Therefore, after completion of M.Sc. Mathematics, students can
                                        pursue their career in various research field like Control and Dynamical Systems
                                        (including differential equations), Fluid Mechanics, Mathematical Medicine and
                                        Biology, Mathematical Physics, Mathematics of Data Science and Machine Learning,
                                        Scientific Computing and many more.
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed B.A./ B.Sc. or equivalent degree from a recognized
                                University with Mathematics as one of the subject with at least 50% marks in aggregate
                                for General category and 45% for SC/ST are eligible for admission in M.Sc. -Mathematics
                                program.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 2 years long, with Four Semesters.
                            </p>
                        </div>
                    </div>

                    {/*  Lab Facility  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Numerical Analysis & Computational Lab</h2>
                            <p className="font-p-v m-7-v">
                                To cater the need of research and computation requirement of the Graduate, Post-
                                Graduate and Research students, a Numerical Analysis & Computational Laboratory is set
                                up in the Department of Mathematics. It is well equipped with C++, MATLAB and
                                MATHEMATICA software.
                            </p>
                            <div className="col-md-5 col-md-offset-1 text-center">
                                <div className="imageEffect-v-lab">
                                    <img src="./assets/images/lab/math-lab-2.jpg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                            <div className="col-md-5 text-center">
                                <div className="imageEffect-v-lab">
                                    <img src="./assets/images/lab/math-lab-1.jpg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/PG/MSC/Mathematics/Scheme_2023_Final.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default MscMath;