import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const MtechME = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="M.Tech(Mechanical Engg.)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Masters of Technology: Mechanical
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    The department of mechanical engineering also offers two years course at post
                                    graduate level leading to the award of M. Tech. degree in the discipline of
                                    Mechanical Engineering. In this two-year degree programme, students will learn more
                                    theoretical and practical aspects of hard core Mechanical Engineering courses with
                                    sufficient flexibility of elective subjects as per the interest of the students.
                                    Students are exposed to the research areas of the mechanical Engineering streams as
                                    per their choice. Students have to do dissertation work for the entire one semester
                                    duration and students are supposed to write some research publications also from
                                    this dissertation work as the requirement for the award of the M. Tech. degree
                                    courses.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/21.png" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed B.E. / B. Tech. degree in (Mechanical/Production
                                /Industrial/ Automobile or any other relevant field) with at least 50% (45% in case of
                                candidate belonging to reserved category) marks in aggregate shall be eligible to apply
                                for Postgraduate Level M. Tech. Course.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 2 years long, with Four Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/academic/MechEng/MTech/MTech-Mech-Syllabus-2021-Batch-Onwards.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default MtechME;