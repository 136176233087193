import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import FacultyCard from "../components/FacultyCard";
import axios from "axios";
import {URL} from "../components/MyModules";

const DeptMechanicalEng = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(3);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' ******')
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Mechanical Engineering "
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Mechanical Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/*from file*/}
                                    Department of Mechanical Engineering has emerged as one of the major engineering
                                    department of the university. The major strength of the department is highly
                                    qualified/experienced faculty and rich laboratory infrastructure. The faculty is
                                    involved in the research activities and more than 200 research papers have been
                                    published in the reputed international/national journals and conferences. <br/>
                                    {/*from word file*/} <br/>
                                    Department of Mechanical Engineering has been emerged as one of the major
                                    engineering department of the university. The department offers four years course at
                                    undergraduate level leading to the award of B.Tech. degree in the disciplines of
                                    Mechanical Engineering. <br/>
                                    The curriculum for all courses has been designed keeping in view the demand of the
                                    industries. In addition to the classroom instructions, emphasis is also laid on the
                                    professional practice by making practical job training and major project as an
                                    integral part of the curriculum.
                                </p>
                                {/*<p className="font-p-v m-7-v">*/}
                                {/*    Department of Applied Chemistry, Humanities & Management has emerged as one of the*/}
                                {/*    Science department of the university. The major strength of the department is highly*/}
                                {/*    qualified/experienced faculty and rich laboratory infrastructure. The faculty is*/}
                                {/*    involved in the research activities.</p>*/}

                            </div>

                            <div className="col-sm-5 padd-10-v text-center">
                                <img src="/assets/images/faculty/HOD_Mechanical_Dilbag.jpg"
                                     className="HOD-image-v" alt="HOD Mechanical Dept."/>
                                <p className="text-left-10 text-theme-colored2">
                                    <strong> HOD, Department : Dilbag Singh</strong>
                                </p>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechMechEng">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/2.png" className="Image_A"
                                             alt="B. Tech. Mechanical Engineering"/>
                                    </div>
                                    <p className="myColor_A">B. Tech. Mechanical Engineering</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/Bvoc">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/26.png" className="Image_A"
                                             alt="B. Voc. (Refrigeration and Air Conditioning)"/>
                                    </div>
                                    <p className="myColor_A">B. Voc. (Refrigeration and Air Conditioning) </p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MtechME">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/15.png" className="Image_A"
                                             alt="M.Tech"/>
                                    </div>
                                    <p className="myColor_A">M. Tech. Mechanical Engineering </p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/PhdME">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/21.png" className="Image_A"
                                             alt="Ph.D. Mechanical Engineering"/>
                                    </div>
                                    <p className="myColor_A">Ph.D. Mechanical Engineering </p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptMechanicalEng;