import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

// import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";


const SportsClub = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Sports Club" subTitle="Student's Corner"
                               bdLink="Campus Life"/>
            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">Sports Activities</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35 text-justify">
                            <p>Beant College of Engineering and Technology, Gurdaspur established by the Govt. of Punjab
                                in the year 1995 is one of the premier institutes in the area of Technical Education.
                                Sports play an important part in one’s life and in shaping one’s personality. </p>


                            <p>Along with
                                academics, our students have an ample inclination towards sports. Our College has an
                                excellent infrastructure in sports. This includes gym station and playgrounds for all
                                the games. This college is also having flood light Tennis Court.</p>

                            <p>Our students have brought laurels in inter college Sports tournaments held under the
                                umbrella of I. K. Gujral Punjab Technical University, Jalandhar.

                                The flight does not land yet. Sports wind cross another milestone by organizing various
                                tournaments that were unique in itself.</p>

                            <p> Getting advanced by brain is not enough, one need to keep the brain sharp and the body
                                energetic too. This thing is possible only if we generate the true sporting spirit
                                within ourselves.

                                It is very well said that a sound mind always resides in a sound body. So these types of
                                events provide students a relief from the busy day routines as well as help them to
                                remain fit “both physically and mentally.” Sports people are viewed as role models and
                                then they have a correspondingly positive impact upon individuals and the broader
                                community in general.

                            </p>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Director Sports</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <strong>Sh. Pritpal Singh</strong> <br/>

                            <strong>Contact No: 99158-70672</strong> <br/>
                            <strong>Fax: 01874-221463, 221464</strong> <br/>

                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Games Available for students</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <strong className="my-sub-titles certificate ">INDOOR GAMES:
                            </strong>
                            <ul>
                                <li>Table Tennis</li>
                                <li>Carrom</li>
                                <li>Chess</li>
                                <li>Luddo</li>
                            </ul>
                        </div>

                        <div className="mb-35 text-justify">
                            <strong className="my-sub-titles certificate ">OUTDOOR GAMES:
                            </strong>
                            <ul>
                                <li>Volley Ball</li>
                                <li>Basket Ball</li>
                                <li>Hockey</li>
                                <li>Cricket</li>
                                <li>Football</li>
                                <li>Hand Ball</li>
                                <li>Lawn Tennis</li>
                                <li>Kabaddi</li>
                                <li>Kho-Kho</li>
                                <li>Athletics</li>
                                <li>Badminton</li>
                            </ul>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Sports Equipment</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <p>
                                Instead of above mentioned facilities our college also provides every type of sports
                                equipment according to the needs of students.
                            </p>
                        </div>


                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Annual Athletic Meet and other Competitions</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <ul>
                                <li> The College organizes Annual Athletic Meet and other Inter Year/Inter Branch Sports Competitions every year.</li>
                                <li>The College teams participate in various inter colleges tournaments which is organized by I. K. Gujral Punjab Technical University, Jalandhar at various colleges every year.
                                </li>
                                <li>The College also has gym station which has different type of machines so that students can do exercises and keep their body fit.</li>
                            </ul>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">College Participation in PTU Inter College Tournaments</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <p>Beant College of Engineering and Technology, Gurdaspur teams have participated in the various Inter College Tournaments organized by the Punjab Technical University, Jalandhar at various colleges and Beant College of Engineering and Technology, Gurdaspur had also organized the various PTU tournament in the college campus . The detail of participation is given below</p>
                        </div>

                         <div className="mb-35">
                        <h2 className="text-theme-colored2">Activities and Achievements By Sports Club</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className={"table-responsive"}>
                            <table className={"table margin-25-V table-hover"}>
                                <thead>
                                <tr className={"table-head-red-V"}>
                                    <th className={"text-center"}>Sr. No.</th>
                                    <th className={"text-center"}>Activity/Event</th>
                                    <th className={"text-center"}>Details</th>

                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Sports Events/ Competitions College Level (Institution Level) Per Year</td>
                                    <td className={"text-center"}>
                                        <a href="../assets/pdf/sports2.pdf" target="_blank"><span
                                            className="fa fa-download"></span> Download </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Sports Achievements of SBSSU Students</td>
                                    <td className={"text-center"}>
                                        <a href="../assets/pdf/sports.pdf" target="_blank"><span
                                            className="fa fa-download"></span> Download </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default SportsClub;

