import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const Mca = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="MCA" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Master of Computer Applications</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The department of Computer Science and Engineering offers two years degree course at
                                    graduate level leading to the award of Master degree in the discipline of Computer
                                    Applications. In this two year graduate degree programme, the students learn theory
                                    and practice in the core areas of Computer Applications, C++ Programming, Office
                                    automation to name a few.The students also complete a major Computer Applications
                                    Project, working closely with faculty members and industry partners of the
                                    university.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/14.png" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed BCA / Bachelor Degree in Computer Science
                                Engineering or Equivalent Degree.
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                Passed B.Sc./B.Com./B.A. with Mathematics at 10+2 or at Graduation Level (with
                                additional bridge Courses as per the norms of the concerned University). The candidates
                                should have obtained at least 50% marks (45% in case of candidate belonging to reserved
                                category) in the qualifying Examination.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 2 years long, with Four Semesters.
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v">
                                            <a className="btn margin-x-25-v theme_btn_V theme-btn"
                                               href="/assets/pdf/admissions/PG/MCA/Syllabus-2023-batch.pdf"
                                               target="_blank">
                                                <i className="fa fa-file-pdf-o padd-10-v"></i>
                                                <span className="hide-v">
                                                    DownloadSyllabus
                                                </span>
                                            </a>
                                        </td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    {/* 2022 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2022</td>
                                        <td className="table-body-padd-V syllabus-table-v">
                                            <a className="btn margin-x-25-v theme_btn_V theme-btn"
                                               href="/assets/pdf/admissions/PG/MCA/Syllabus-2022-batch.pdf"
                                               target="_blank">
                                                <i className="fa fa-file-pdf-o padd-10-v"></i>
                                                <span className="hide-v">
                                                    DownloadSyllabus
                                                </span>
                                            </a>
                                        </td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2022 Onwards</td>
                                    </tr>

                                    {/* 2021 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/PG/MCA/Syllabus-2021-batch.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>


                </div>
            </div>
            <Footer/>
        </>
    );
}

export default Mca;