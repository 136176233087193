import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const MtechCSE = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="M.Tech(CSE)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Masters of Technology: Computer Science and
                            Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The department of Computer Science and Engineering offers two years degree course at
                                    graduate level leading to the award of M. Tech. degree in the discipline of Computer
                                    Science and Engineering. In this two-year graduate degree programme, the students
                                    learn theory and practice in the core areas of Computer Engineering such as Object
                                    Oriented Programming, Computer Networking, Network Security, Digital Image
                                    Processing, Data Analytics, Machine Learning to name a few.The students also
                                    complete a major Computer Science and Engineering Project and Research thesis
                                    working closely with faculty members and industry partners of the university.
                                </p>
                            </div>
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    The job opportunities for ME/MTech graduates are huge in technical fields. Any
                                    graduate who has successfully pursued the ME/MTech degree can get a job in almost
                                    all the sectors in India. Besides being hired as engineers, ME/MTech graduates are
                                    also hired as consultants, subject matter experts, researchers, managers, etc.
                                    Herein some of the popular job profiles for ME/MTech graduates.
                                    As an ME/MTech graduates, it’s important to be aware of the choices that you are or
                                    you will be making for your future. To make the right decision, you must be aware of
                                    all the choices available to you to lead a better career. Some ME/MTech graduates
                                    also launch their own start-ups.
                                </p>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/MtechCSE.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed B.E. / B. Tech. degree in (B.E. / B. Tech. in
                                Computer Science
                                Engineering / IT or any other relevant field), M.Sc. (IT), M.Sc. (Computer Science), MCA
                                with at least 50% (45% in case of candidate belonging to reserved category) marks in
                                aggregate shall be eligible to apply for Postgraduate Level M. Tech. Course.

                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 2 years long, with Four Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <th className="table-title-V padd-l-10">Scheme</th>
                                    <th className="table-title-V padd-l-36">Syllabus</th>
                                    <th className="table-title-V">Remarks</th>
                                    </thead>
                                    <tbody>
                                    {/*<tr>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">2015</td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v"><a*/}
                                    {/*        className="btn margin-x-25-v theme_btn_V theme-btn"*/}
                                    {/*        href="/assets/pdf/mtech-cse-2015.pdf"*/}
                                    {/*        target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span*/}
                                    {/*        className="hide-v"> Download*/}
                                    {/*        Syllabus</span></a></td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">Batch 2015 Onwards</td>*/}
                                    {/*</tr>*/}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/MTechCSE.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default MtechCSE;