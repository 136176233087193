import NavBar2 from "./components/NavBar2";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Link} from "react-router-dom";
import Footer from "./components/Footer";
import React from "react";


const DownloadsPage = () => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Home" subTitle="Downloads"
                               bdLink="Downloads"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font text-theme-colored2"}>
                        Downloads
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="row" id="myButtons">
                        <div className="col-sm-6 col-xs-12 text-center">
                            <Link to="/downloadsforstudent"
                               className="btn theme-btn btn-block myButton_A">Student Performa</Link>
                        </div>
                        <div className="col-sm-6 col-xs-12 text-center">
                            <Link to="/downloadsforstaff"
                               className="btn theme-btn btn-block myButton_A">Staff Performa</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DownloadsPage;