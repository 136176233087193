import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const MbaDigitalMarketing = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="MBA(Digital Marketing)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Master of Business Administration: Digital Marketing </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The department of Applied Chemistry, Humanities & Management offers two year
                                    postgraduate course of MBA degree in the discipline of Digital Marketing. In this
                                    two year master degree programme, students will learn a wide range of media
                                    strategies, new concepts, creative learning, and also covers the management aspect
                                    of marketing. The program has been designed by industry experts to provide students
                                    with the relevant knowledge and training to efficiently deal with the fast-growing
                                    sectors of digital marketing. MBA in Digital Marketing focuses on creating a path
                                    for students to tackle new technologies, indulge with the working methods of the
                                    industry, create new marketing campaigns, evaluate different marketing trends, and
                                    analyse marketing data.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/11.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have any recognized Bachelor’s Degree of minimum 3 years
                                duration in any discipline from a University recognized by UGC.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 2 years long, with Four Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <th className="table-title-V padd-l-10">Year</th>
                                    <th className="table-title-V padd-l-36">Syllabus</th>
                                    <th className="table-title-V">Remarks</th>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V"
                                            href="/assets/pdf/admissions/PG/MBA/Digital_Marketing-Syllabus-2023-merged.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2022</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V"
                                            href="/assets/pdf/admissions/PG/MBA/Digital_Marketing-Syllabus-2022-batch.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2022 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default MbaDigitalMarketing;