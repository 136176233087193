import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";

const DownloadsForStudents = (props) => {
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Downloads For Students" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Downloads"/>

            <div className="section3">
                <div className={"container pt-90"}>
                    <h1 className="text-theme-colored2">Downloads (Students)</h1>
                    <div className="double-line-bottom-theme-colored-2"></div>
                    <div className="row" id="myButtons">
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/NSSEnrollmentForm.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">New NSS Enrollment Performa</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/InternalReappearPerforma.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Internal Reappear Performa</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/MtechThesisGuidelines.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">M. Tech. Thesis Guidelines</a>
                        </div>
                    </div>
                    <h4 className="text-theme-colored2">Library Membership Performa</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>
                    <div className={"row"} id={"myButtons"}>
                        <div className={"col-sm-4"}></div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/LibraryMembershipForm.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Library Membership Performa</a>
                        </div>
                    </div>

                    {/*<h4 className="text-theme-colored2">Admission to B.Tech. Courses under Tution Fee Waiver Scheme</h4>*/}
                    {/*<div className="double-line-bottom-theme-colored-2"></div>*/}
                    {/*<div className={"row"} id={"myButtons"}>*/}
                    {/*    <div className={"col-sm-2"}></div>*/}
                    {/*    <div className="col-sm-4 col-xs-6 text-center">*/}
                    {/*        <a href="assets/Downloads/DirectAdmissionNew2009.doc" target="_blank" className="btn theme-btn btn-block myButton_A">View Advertisement</a>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-sm-4 col-xs-6 text-center">*/}
                    {/*        <a href="assets/Downloads/WomenPerforma.doc" target="_blank" className="btn theme-btn btn-block myButton_A">M. Tech. Thesis Guidelines</a>*/}
                    {/*    </div>*/}
                    {/*    <div className={"col-sm-2"}></div>*/}
                    {/*</div>*/}

                    <h4 className="text-theme-colored2">Alumni Message & Feedback</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>
                    <p>A Tree is Known by Fruits it Bears, So an Institution is Known by its Students.</p>
                    <div className={"row"} id={"myButtons"}>
                        <div className={"col-sm-2"}></div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/AlumniMessagefromTPO.pdf" target="_blank" className="btn theme-btn btn-block myButton_A">Message from TPO</a>
                        </div>
                        <div className="col-sm-4 col-xs-6 text-center">
                            <a href="assets/Downloads/StudentsFeedback.doc" target="_blank" className="btn theme-btn btn-block myButton_A">Alumni Feedback Form</a>
                        </div>

                    </div>

                    <h4 className="text-theme-colored2">Download guidelines for Major Project & 6-Months Industrial Training Report</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>
                    <div className={"row"} id={"myButtons"}>
                        <div className={"col-sm-4 col-xs-6 text-center"}>
                            <a href={"assets/Downloads/ProjectGuidelineFormatBTech.pdf"} className="btn theme-btn btn-block myButton_A" target={"_blank"}> Major Project Report Guidelines</a>
                        </div>
                        <div className={"col-sm-4 col-xs-6 text-center"}>
                            <a href={"assets/Downloads/SixMonthTrainingGuidelines.pdf"} className="btn theme-btn btn-block myButton_A" target={"_blank"}>6-month Industrial Training Report <br/> Guidelines</a>
                        </div>
                        <div className={"col-sm-4 col-xs-6 text-center"}>
                            <a href={"assets/Downloads/TrainingLetter.docx"} className="btn theme-btn btn-block myButton_A" target={"_blank"}>6 Months / Weeks Training Letter</a>
                        </div>

                    </div>

                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DownloadsForStudents;