import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import FacultyCard from "../components/FacultyCard";
import axios from "axios";
import {URL} from "../components/MyModules";

const DeptChemicalEngineering = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(5);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' ******');
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }


    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Chemical Engineering" bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Chemical Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/*from file*/}
                                    The Department offers versatile and emerging U.G. programme in Chemical Engineering.
                                    The Department of Chemical Engineering has the core competency mainly in three areas
                                    representing Energy, Environment Engineering and Petroleum Engineering. The
                                    department aims to create and ambiance for smooth pursuit of activities in education
                                    and research towards creating national impact in the chemical related areas and
                                    endeavors to produce leaders of tomorrow in this field. The department now boasts of
                                    over 10 faculty members consisting of 06 core faculty members and 04 associated
                                    faculty members drawn from diverse departments of the university. <br/><br/>
                                    {/*from oldweb*/}
                                    We all faculty, staff and students of Chemical Engineering Department of SBSSU
                                    believe in a bright tomorrow, filled with a zest for innovation and celebrating life
                                    in its full glory, empowered by technical expertise, knowhow, skill and humaneness.
                                </p>
                                {/*<p className="font-p-v m-7-v">*/}
                                {/*    Department of Applied Chemistry, Humanities & Management has emerged as one of the*/}
                                {/*    Science department of the university. The major strength of the department is highly*/}
                                {/*    qualified/experienced faculty and rich laboratory infrastructure. The faculty is*/}
                                {/*    involved in the research activities.</p>*/}

                            </div>

                            <div className="col-sm-5 padd-10-v text-center">
                                <img src="/assets/images/faculty/HOD-Chemical-Engg.jpg"
                                     className="HOD-image-v" alt="HOD"/>
                                <p className="text-left-10 text-theme-colored2">
                                    <strong>HOD, Department: Sh. Baljeev Kumar</strong>
                                </p>
                            </div>
                        </div>
                        <div>
                            {/*<p className="font-p-v m-7-v">*/}
                            {/*    /!*from oldweb*!/*/}
                            {/*    The department comprises multi-disciplinary faculty members, at present, mainly related*/}
                            {/*    to Physics, Chemistry, Mathematics, Humanities (Communication English) and Management*/}
                            {/*    background to cater the needs of multi-disciplinary students at B.C.E.T. Campus,*/}
                            {/*    Gurdaspur. Our faculty members and scholars provide a home to the most promising*/}
                            {/*    doctoral and post-doctoral research with an access to educational/Research Laboratory*/}
                            {/*    resources and unparalleled laboratory and library facilities for both Basic/Applied and*/}
                            {/*    Engineering research. Growing steadily, today the department not only teaches various*/}
                            {/*    topics in B.Tech. and M.Tech. courses, but also runs its own M.Sc. Courses in Applied*/}
                            {/*    Mathematics and Applied Physics. Besides the Central Computing facilities of the*/}
                            {/*    Institute, the department has its own Numerical Analysis Lab. The Department is engaged*/}
                            {/*    in providing highest level and quality of academic education. The main focus of the*/}
                            {/*    teaching and research in the department is centered on interdisciplinary themes. The*/}
                            {/*    students are provided a comprehensive practical exposure to the various instrumental*/}
                            {/*    techniques required for their further education.</p>*/}

                        </div>
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechChemicalEng">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/7.png" className="Image_A"
                                             alt="B.Tech in Chemical Engineering"/>
                                    </div>
                                    <p className="myColor_A">B.Tech Chemical Engineering</p>
                                </div>
                            </Link>
                        </div>
                        {/*<div className="col-xs-12">*/}
                        {/*    <a*/}
                        {/*        className="btn theme_btn_V theme-btn"*/}
                        {/*        href="/assets/pdf/btech-chemeng-2021.pdf"*/}
                        {/*        target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span*/}
                        {/*        className="hide-v"> Download First Year*/}
                        {/*                    Syllabus</span></a>*/}
                        {/*</div>*/}
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptChemicalEngineering;