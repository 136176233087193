import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from 'react-router-dom';
import axios from "axios";
import {URL} from "../components/MyModules";
import FacultyCard from "../components/FacultyCard";

const DeptBioTechnology = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(10);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' ******')
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Bio-Technology "
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Bio-Technology</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/*from file*/}
                                    The Department offers versatile and emerging U.G. programme in Biotechnology. The
                                    department of Biotechnology was started in 2006 with B.Tech Programme in Bio
                                    Technology. Annually, 30 students are admitted in B.Tech. Bio-Tech programme. The
                                    department has highly qualified, experienced and dedicated faculty members with
                                    specialization in various fields of Bio-Technology. The laboratories are well
                                    equipped with all the modern instruments and facilities to provide high end research
                                    in different specializations of Bio-Technology. The Department aims at providing the
                                    students not only theoretical but also the practical knowledge to give them best
                                    education and research facility in the upcoming multidisciplinary Bio-Technology
                                    fields.<br/>
                                    {/*from word file*/}
                                    The major strength of the department is qualified and young faculty in different
                                    domains of Biotechnology. The department also enriched with laboratory
                                    infrastructure for UG programme and research. Faculty members of the department have
                                    excellent academic and research credentials with a good expertise in the diverse
                                    field of Biotechnology. The faculty is involved in the research activities and
                                    number of research papers enlisted in National and International Journal/Conferences
                                    enlisted in PubMed (NCBI) and BIOSIS Citation Index.
                                </p>
                                {/*<p className="font-p-v m-7-v">*/}
                                {/*    Department of Applied Chemistry, Humanities & Management has emerged as one of the*/}
                                {/*    Science department of the university. The major strength of the department is highly*/}
                                {/*    qualified/experienced faculty and rich laboratory infrastructure. The faculty is*/}
                                {/*    involved in the research activities.</p>*/}

                            </div>

                            <div className="col-sm-5 padd-10-v text-center">
                                <img src="/assets/images/faculty/HOD-Chemical-Engg.jpg"
                                     className="HOD-image-v" alt="HOD"/>
                                <p className="text-left-10 text-theme-colored2">
                                    <strong>HOD, Department: Sh. Baljeev Kumar</strong>
                                </p>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className="row">
                    <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechBioTech">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/5.png" className="Image_A"
                                             alt="B.Tech Biotechnology"/>
                                    </div>
                                    <p className="myColor_A">B.Tech Biotechnology</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }

                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptBioTechnology;