import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Hostels = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Hostels" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Hostels"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Hostel Facilities</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className="row mb-25">
                        <p>The College has Three boys Hostels and one Girls Hostel.
                            The various facilities available in the hostels are as under</p>
                        <ul className={"fa-1x"}>
                            <li>Adequate furniture and fixtures in every room.</li>
                            <li>Common Room with T.V. Set.</li>
                            <li>Guest Room</li>
                            <li>Facilities for Indoor Games</li>
                            <li>Hostel mess works on Cooperative Mess System.</li>
                            {/*<li>Telephone</li>*/}
                        </ul>
                        {/*<p>Internet facility is provided in the hostels through 6 Mbps leased line.</p>*/}
                        <div className="text-right">
                            <a href={"assets/pdf/hostelrulesandregulations.pdf"} target={"_blank"}>Click here to view
                                Hostel rules and regulations</a>
                        </div>
                    </div>

                    <div className="row mb-25">
                        <div className="col-md-4 col-sm-4 table-head-red-V text-center">
                            <h5 className="text-white"><span className={"fa fa-arrow-circle-right"}></span> Chief Warden</h5>
                        </div>
                        <div className="col-md-4 col-sm-4 table-head-red-V text-center">
                            <h5 className="text-white">Mr. Parveen Kumar, Associate Professor</h5>
                        </div>
                        <div className="col-md-4 col-sm-4 table-head-red-V text-center">
                            <h5 className="text-white">+91-98555-02871</h5>
                        </div>
                    </div>
                    <div className="row mb-25">

                        <div className="col-md-6 col-sm-12">
                            <img src={"../assets/images/host2.jpg"} className={"img-responsive img-thumbnail"}
                                 alt="hostel2"/>
                            <div className="text-center">
                                <p className="hostelTH"><span>Hostel 2 :</span> For First Year Boys</p>
                            </div>
                            <table>
                                <tr>
                                    <td width="35%">
                                        <h6>Hostel Warden</h6>
                                    </td>
                                    <td>
                                        <h6>Mr. Sanjeev Mahajan, Associate Professor</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Contact No.</h6>
                                    </td>
                                    <td>
                                        <h6> 98780-53388</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Total Capacity</h6>
                                    </td>
                                    <td>
                                        <h6>255</h6>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img src={"../assets/images/host3.jpg"} className={"img-responsive img-thumbnail"}
                                 alt="hostel3"/>
                            <div className="text-center">
                                <p className="hostelTH"><span>Hostel 3 : </span> For Second, Third & Fourth Year Boys</p>
                            </div>
                            <table>
                                <tr>
                                    <td width="35%">
                                        <h6>Hostel Warden</h6>
                                    </td>
                                    <td>
                                        <h6>Mr. Pramod Kumar Yadav, Associate Professor</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Contact No.</h6>
                                    </td>
                                    <td>
                                        <h6>88376-63521</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Total Capacity</h6>
                                    </td>
                                    <td>
                                        <h6>200</h6>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <img src={"../assets/images/host4.jpg"} className={"img-responsive img-thumbnail"}
                                 alt="hostel4"/>
                            <div className="text-center">
                                <p className="hostelTH"><span>Hostel 4 : </span> Reserved For Future Use</p>
                            </div>
                            <table>
                                <tr>
                                    <td width="35%">
                                        <h6>Hostel Warden</h6>
                                    </td>
                                    <td>
                                        <h6>Mr. Pramod Kumar Yadav, Associate Professor</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Contact No.</h6>
                                    </td>
                                    <td>
                                        <h6> 88376-63521</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Total Capacity</h6>
                                    </td>
                                    <td>
                                        <h6>255</h6>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <img src={"../assets/images/gh.jpg"} className={"img-responsive img-thumbnail"}
                                 alt="hostel5"/>
                            <div className="text-center">
                                <p className="hostelTH"><span>Hostel 5 : </span> For Girls</p>
                            </div>
                            <table>
                                <tr>
                                    <td width="35%">
                                        <h6>Hostel Warden</h6>
                                    </td>
                                    <td>
                                        <h6>Mrs Mandeep Kaur Sekhon, Associate Professor</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Contact No.</h6>
                                    </td>
                                    <td>
                                        <h6>98779-39413</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h6>Total Capacity</h6>
                                    </td>
                                    <td>
                                        <h6>130</h6>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
        ;
}

export default Hostels;