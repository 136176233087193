import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const BtechChemicalEng = (props) => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Undergraduate Courses" subTitle="B.Tech(Chemical Engg.)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Bachelor of Technology: Chemical Engineering
                        </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">

                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v">
                                    The department of Chemical Engineering offers four years course at undergraduate
                                    level leading to the award of B. Tech. degree in the discipline of Chemical
                                    Engineering. In this four-year degree programme, students will learn theory and
                                    practice in the core areas of Chemical Engineering such as Chemical Reaction
                                    Engineering , Heat Transfer, Mass Transfer, Fluid Mechanics, Process Technology and
                                    Control . Student will also learn the principles of energy , environment and non
                                    conventional fuels for the betterment of the society. Students will develop the
                                    computational skills through Computer aided design and simulation using CHEMCAD,
                                    Comsol software for equipment design. Students will undergo Industrial training for
                                    six months to use knowledge gain in the university campus.
                                </p>


                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/Btech-ce.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                Passed the 10+2 examination from a board recognized or established by central/state
                                government through a legislation and a member of Boards of School Education (COBSE), New
                                Delhi with Physics/ Mathematics/ Chemistry / Biotechnology / Computer Science / Biology
                                / Electronics/ Information Technology/ Informatics Practices / Technical Vocational
                                Subject / Agriculture / Engineering Graphics / Entrepreneurship (Any of three). Obtained
                                at least 45% marks (40% in case of Candidates belonging to reserved category) in the
                                above subject taken together.
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                Passed Diploma in any Engineering Trade from Punjab State Board of Technical Education &
                                Industrial Training, Chandigarh or Sant Longowal Institute of Engineering and
                                Technology, Longowal (SLIET), or any such examination from any other recognized State
                                Board of Technical Education with atleat 45% marks (40% in case of candidates belonging
                                to reserved category).
                            </p>
                            <div className="text-center  m-7-v">
                                <h5 className="text-theme-colored2">--- OR ---</h5>
                            </div>
                            <p className="font-p-v m-7-v">
                                The candidates who have passed two years certificate course from Sant Longowal Institute
                                of Engineering and Technology, Longowal (SLIET) shall be eligible.
                            </p>

                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 4 years long, with Eight Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 */}
                                    {/*<tr>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">2023</td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">*/}
                                    {/*        <a className="btn margin-x-25-v theme_btn_V theme-btn"*/}
                                    {/*           href="/assets/pdf/admissions/UG/BTech/revised-syllabus-Scheme-2023-merged.pdf"*/}
                                    {/*           target="_blank">*/}
                                    {/*            <i className="fa fa-file-pdf-o padd-10-v"></i>*/}
                                    {/*            <span className="hide-v">Download Syllabus</span>*/}
                                    {/*        </a>*/}
                                    {/*    </td>*/}
                                    {/*    <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>*/}
                                    {/*</tr>*/}

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">First Year</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BTech/revised-syllabus-scheme-2023-merged-first-year.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">First Year</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/FY_BTECH2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-chemeng-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2018 Onwards</td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2020</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-chemeng-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2020 Onwards</td>
                                    </tr>

                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/btech-chemeng-2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                    {/*  Lab Facility  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Heat Transfer & Software Lab</h2>
                            <p className="font-p-v m-7-v">
                                To cater the need of research and computation requirement of the Graduate, Post-
                                Graduate and Research students, a Numerical Analysis & Computational Laboratory is set
                                up in the Department of Mathematics. It is well equipped with C++, MATLAB and
                                MATHEMATICA software.
                            </p>
                            <div className="col-md-5 col-md-offset-1 text-center">
                                <div className="imageEffect-v-lab">
                                    <img src="./assets/images/lab/btech-chem-lab-1.png" className="Image_A"
                                         alt="Btech Chemistry Lab"/>
                                </div>
                            </div>
                            <div className="col-md-5 text-center">
                                <div className="imageEffect-v-lab">
                                    <img src="./assets/images/lab/btech-chem-lab-2.png" className="Image_A"
                                         alt="Btech Chemistry Lab"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ALUMNI LIST */}


                    {/*  Campus Placements  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Campus Placements</h2>
                            <p className="font-p-v m-7-v">
                                <div className="table-responsive">
                                    <table className="table-hover table margin-25-V">
                                        <thead className="table-head-red-V">
                                        <th className="table-title-V padd-l-10">Name</th>
                                        <th className="table-title-V ">Roll No.</th>
                                        <th className="table-title-V">Pass out Batch</th>
                                        <th className="table-title-V">Name of Company</th>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Bhavika</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237686</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Integrated Resources Inc.
                                                Gurgaon
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Gursharanjit Kaur</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237693</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Integrated Resources Inc.
                                                Gurgaon
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Divya Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237691</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Integrated Resources Inc.
                                                Gurgaon
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Kanika Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237698</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Integrated Resources Inc.
                                                Gurgaon
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Kaushal</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237696</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Alpha Chemicals,
                                                Chandigarh
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Ruhi Thakur</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237715</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Integrated Resources Inc.
                                                Gurgaon
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Nikhil Bhatti</td>
                                            <td className="table-body-padd-V syllabus-table-v">1298187</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Emcure Pharmaceutical
                                                Jammu
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Udit Mehra</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237718</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Uday Industries
                                                Ludhiana
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Pavitpal Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237708</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">eCLERX services LTD,
                                                chandigarh
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Mrityunjay Kumar</td>
                                            <td className="table-body-padd-V syllabus-table-v">1237703</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">eCLERX services LTD,
                                                chandigarh
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Jatinder Khuswaha</td>
                                            <td className="table-body-padd-V syllabus-table-v">1303508</td>
                                            <td className="table-body-padd-V syllabus-table-v">2016</td>
                                            <td className="table-body-padd-V syllabus-table-v">Saurav Chemicals Ltd.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Jagjit Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1303099</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v">JIL Hamira</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Rahul Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1303106</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v">Lupin Pharmaceuticals
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Suryakant</td>
                                            <td className="table-body-padd-V syllabus-table-v">1303110</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v">K.P. Phyto Extraction Pvt
                                                Ltd.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Manpreet Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1303104</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v"> HMEL Bhathinda</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Ajay Kumar</td>
                                            <td className="table-body-padd-V syllabus-table-v">L-1403085</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v"> Nector Pharmaceuticals
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Kuljit Kaur</td>
                                            <td className="table-body-padd-V syllabus-table-v">L-1403089</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v"> Milkfed</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Rajdeep Kaur</td>
                                            <td className="table-body-padd-V syllabus-table-v">L-1403090</td>
                                            <td className="table-body-padd-V syllabus-table-v">2017</td>
                                            <td className="table-body-padd-V syllabus-table-v"> Ajay Gelatin Mohali</td>
                                        </tr>

                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Nitish Kumar</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403069</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v"> JIL Hamira</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Sahil Khajuria</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403078</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v"> Evian Engg. Pvt. Ltd.
                                                New
                                                Delhi
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Pankaj</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403070</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v"> Century Paper & Plywood
                                                Ltd
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Nitish Kumar</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403069</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v"> JIL Hamira</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Rahul Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403072</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v">Jai Beverages(Pepsi)
                                                Jammu
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Narinder Kumar</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403068</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v">Bharat Insecticides Ltd.
                                                Kathua
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v">Vinod Kumar</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403083</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v">Hero Cycles</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Rajeshwar Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1403074</td>
                                            <td className="table-body-padd-V syllabus-table-v">2018</td>
                                            <td className="table-body-padd-V syllabus-table-v">JIL Hamira</td>
                                        </tr>

                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Manikjit</td>
                                            <td className="table-body-padd-V syllabus-table-v">1501269</td>
                                            <td className="table-body-padd-V syllabus-table-v">2019</td>
                                            <td className="table-body-padd-V syllabus-table-v">Crop Chemicals Pvt ltd
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Ishani Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1501267</td>
                                            <td className="table-body-padd-V syllabus-table-v">2019</td>
                                            <td className="table-body-padd-V syllabus-table-v">DP Choclates Baddi</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Suraj</td>
                                            <td className="table-body-padd-V syllabus-table-v">1501277</td>
                                            <td className="table-body-padd-V syllabus-table-v">2019</td>
                                            <td className="table-body-padd-V syllabus-table-v">Atotech Gurgaon</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Kewal Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1501268</td>
                                            <td className="table-body-padd-V syllabus-table-v">2019</td>
                                            <td className="table-body-padd-V syllabus-table-v">Hero Cycles Ltd</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Vivekanand</td>
                                            <td className="table-body-padd-V syllabus-table-v">1501280</td>
                                            <td className="table-body-padd-V syllabus-table-v">2019</td>
                                            <td className="table-body-padd-V syllabus-table-v">BASF India</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Deepak</td>
                                            <td className="table-body-padd-V syllabus-table-v">1501264</td>
                                            <td className="table-body-padd-V syllabus-table-v">2019</td>
                                            <td className="table-body-padd-V syllabus-table-v">PCCPL Mohali</td>
                                        </tr>

                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Sameer Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1601510</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">S S Polymers,
                                                Hoshiarpur
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Kunal Rana</td>
                                            <td className="table-body-padd-V syllabus-table-v">1702227</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Jagatjit Industries
                                                Limited
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Manpreet Kaur</td>
                                            <td className="table-body-padd-V syllabus-table-v">1702229</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">NFL Nangal</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Simranjit Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1601512</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Hero Ecotech, Ludhiana
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Deepak Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1601497</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">JCT Hoshiarpur</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Arwinder Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1702226</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Hero Ecotech, Ludhiana
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Anmol Bawa</td>
                                            <td className="table-body-padd-V syllabus-table-v">1702225</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Triune Energy Services,
                                                New
                                                Delhi
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Ketan Duggal</td>
                                            <td className="table-body-padd-V syllabus-table-v">1601502</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Ralson Tyres, Ludhiana
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Sonia Thapa</td>
                                            <td className="table-body-padd-V syllabus-table-v">1601515</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Coca Cola Jammu</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Vaneet Sharma</td>
                                            <td className="table-body-padd-V syllabus-table-v">1601518</td>
                                            <td className="table-body-padd-V syllabus-table-v">2020</td>
                                            <td className="table-body-padd-V syllabus-table-v">Nector Pharmaceutical
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Sanpreet Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1701951</td>
                                            <td className="table-body-padd-V syllabus-table-v">2021</td>
                                            <td className="table-body-padd-V syllabus-table-v">Orelia, Bolvia</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Sachin</td>
                                            <td className="table-body-padd-V syllabus-table-v">1701950</td>
                                            <td className="table-body-padd-V syllabus-table-v">2021</td>
                                            <td className="table-body-padd-V syllabus-table-v">Hero Group,Ludhiana</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Shubham Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">1702047</td>
                                            <td className="table-body-padd-V syllabus-table-v">2021</td>
                                            <td className="table-body-padd-V syllabus-table-v">Hero Group,Ludhiana</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Savi Mahajan</td>
                                            <td className="table-body-padd-V syllabus-table-v">1701952</td>
                                            <td className="table-body-padd-V syllabus-table-v">2021</td>
                                            <td className="table-body-padd-V syllabus-table-v">Taskus India Pvt Ltd</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Mehakdeep Singh</td>
                                            <td className="table-body-padd-V syllabus-table-v">L-1801403</td>
                                            <td className="table-body-padd-V syllabus-table-v">2021</td>
                                            <td className="table-body-padd-V syllabus-table-v">DRDO Pune</td>
                                        </tr>
                                        <tr>
                                            <td className="table-body-padd-V syllabus-table-v"> Taneksha</td>
                                            <td className="table-body-padd-V syllabus-table-v">1701954</td>
                                            <td className="table-body-padd-V syllabus-table-v">2021</td>
                                            <td className="table-body-padd-V syllabus-table-v">Sigma Pharmaceutical</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default BtechChemicalEng;