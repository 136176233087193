import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import axios from "axios";
import {URL} from "../components/MyModules";
import FacultyCard from "../components/FacultyCard";

const DeptComputerScienceEng = (props) => {


    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(2);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' ******');
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data);
                // console.log(res.data.data2);

                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments"
                               subTitle="Computer Science & Engineering"
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Computer Science & Engineering</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/*from word file*/}
                                    The department of Computer Science and Engineering is renowned for imparting
                                    professional education endowed with human values. The CSE department came into
                                    existence in the year 1995. The department laboratories are fully equipped with
                                    state-of-the-art equipment to provide exposure to students about the latest
                                    technologies and provide hands-on working experience to meet the ever increasing
                                    demands for software and hardware profession. The department is having highly
                                    qualified faculty and staff members. The department has adequate facilities to
                                    support modern teaching activities. Proper up gradation of teaching and lab
                                    facilities is taken care of. <br/><br/>
                                    The students of department actively participate in various regional and national
                                    competitions and have won many prizes. <br/>
                                    We all faculty, staff and students of Computer Science and Engineering Department of
                                    SBSSU believe in a bright tomorrow, filled with a zest for innovation and
                                    celebrating
                                    life in its full glory, empowered by technical expertise, knowhow, skill and
                                    humaneness.
                                </p>
                                {/*<p className="font-p-v m-7-v">*/}
                                {/*    Department of Applied Chemistry, Humanities & Management has emerged as one of the*/}
                                {/*    Science department of the university. The major strength of the department is highly*/}
                                {/*    qualified/experienced faculty and rich laboratory infrastructure. The faculty is*/}
                                {/*    involved in the research activities.</p>*/}

                            </div>

                            {/* Image Class - img-responsive HOD-image-v */}
                            <div className="col-sm-5 padd-10-v text-center">
                                <img src="/assets/images/faculty/head-cse.jpg"
                                     className="HOD-image-v" alt="HOD, Department"/>
                                <p className="text-left-10 text-theme-colored2">
                                    <strong>HOD, Department: Dr. Sunil Kumar Gupta</strong>
                                </p>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechCSE">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="././assets/images/admission_icons/1.png" className="Image_A"
                                             alt="B. Tech. Computer Science and Engineering"/>
                                    </div>
                                    <p className="myColor_A">B. Tech. Computer Science and Engineering</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BtechIT">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/8.png" className="Image_A"
                                             alt="B.Tech. Information Technology"/>
                                    </div>
                                    <p className="myColor_A">B.Tech. Information Technology</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MtechCSE">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/14.png" className="Image_A"
                                             alt="M. Tech. Computer Science and Engineering."/>
                                    </div>
                                    <p className="myColor_A">M. Tech. Computer Science and Engineering.</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/Mca">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/24.png" className="Image_A"
                                             alt="MCA"/>
                                    </div>
                                    <p className="myColor_A">Master in Computer Applications (MCA)</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/Bca">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/18.png" className="Image_A"
                                             alt="BCA"/>
                                    </div>
                                    <p className="myColor_A">Bachelor in Computer Applications (BCA)</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/PhdCSE">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/19.png" className="Image_A"
                                             alt="Ph.D. (Computer Science & Engineering)"/>
                                    </div>
                                    <p className="myColor_A">Ph.D. (Computer Science & Engineering)</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ? <>
                                    <h3 className="">Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            facultyData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>01
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }


                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}

export default DeptComputerScienceEng;