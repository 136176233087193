import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Link} from "react-router-dom";
import {faBackward} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AcademicPerforma = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Academic Performa" subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Academic Performa"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Academic Performa for Students</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className={"row"}>
                        <div className="col-lg-12 col-md-12">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <tr>
                                    <th className="table-title-V text-center">Sr. No.</th>
                                    <th className="table-title-V text-center">Performa Name</th>
                                    <th className="table-title-V text-center">Date of Uploading</th>
                                    <th className="table-title-V text-center">Download</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1.</td>
                                    <td className={"text-center"}>Provisional Registration Performa</td>
                                    <td className={"text-center"}>20-01-2022</td>
                                    <td className={"text-center"}>
                                        {/* Old Performa */}
                                        {/*<a href={"assets/pdf/prp.pdf"} target={"_blank"}>*/}

                                        {/* Updated Performa */}
                                        <a href={"assets/pdf/academic/Registration_revised_Form.pdf"} target={"_blank"}>
                                            <span className={"fa fa-download"}></span>
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2.</td>
                                    <td className={"text-center"}>Registration Fee Slip</td>
                                    <td className={"text-center"}> 11-12-2018</td>
                                    <td className={"text-center"}><a href={"assets/pdf/FeeDepositSlip.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3.</td>
                                    <td className={"text-center"}>Fee Deposit Form M.Tech (P/T)</td>
                                    <td className={"text-center"}>05-06-2018</td>
                                    <td className={"text-center"}><a href={"assets/pdf/FeeDepositFormM.TechPT.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4.</td>
                                    <td className={"text-center"}>Backlog Performa</td>
                                    <td className={"text-center"}> 01-06-2018</td>
                                    <td className={"text-center"}><a href={"assets/pdf/BacklogPerforma.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5.</td>
                                    <td className={"text-center"}>Application (NOC) For Refund Of Caution Money</td>
                                    <td className={"text-center"}> 16-05-2017</td>
                                    <td className={"text-center"}><a href={"assets/pdf/CautionMoneyPerforma2017.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6.</td>
                                    <td className={"text-center"}>Issuance of PDC</td>
                                    <td className={"text-center"}> 14-05-2014</td>
                                    <td className={"text-center"}><a href={"assets/pdf/PDCPerforma.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7.</td>
                                    <td className={"text-center"}>Examination Fee Deposit Form for RP/RT & Re-Eval</td>
                                    <td className={"text-center"}>06-11-2018</td>
                                    <td className={"text-center"}><a href={"assets/pdf/FeeDepositForm.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8.</td>
                                    <td className={"text-center"}>Internal Re-appear Form</td>
                                    <td className={"text-center"}> 02-04-2013</td>
                                    <td className={"text-center"}><a href={"assets/pdf/InternalReapperForm.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9.</td>
                                    <td className={"text-center"}>Degree-PDC-Migration from University</td>
                                    <td className={"text-center"}> 31-01-2013</td>
                                    <td className={"text-center"}><a
                                        href={"assets/pdf/DegreePDC-MigrationfromUniversity.pdf"}
                                        target={"_blank"}><span className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10.</td>
                                    <td className={"text-center"}>Issuing of DMC</td>
                                    <td className={"text-center"}> 31-01-2013</td>
                                    <td className={"text-center"}><a href={"assets/pdf/IssuingOfDMC.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11.</td>
                                    <td className={"text-center"}>Issuing of PDC</td>
                                    <td className={"text-center"}> 31-01-2013</td>
                                    <td className={"text-center"}><a href={"assets/pdf/IssuingOfPDC.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11.</td>
                                    <td className={"text-center"}>Issuing of Transcripts</td>
                                    <td className={"text-center"}> 31-01-2013</td>
                                    <td className={"text-center"}><a href={"assets/pdf/IssuingOfTranscripts.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>12.</td>
                                    <td className={"text-center"}>Name Correction</td>
                                    <td className={"text-center"}> 31-01-2013</td>
                                    <td className={"text-center"}><a href={"assets/pdf/NameCorrection.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>13.</td>
                                    <td className={"text-center"}>Non Declaration of Results</td>
                                    <td className={"text-center"}> 31-01-2013</td>
                                    <td className={"text-center"}><a href={"assets/pdf/NonDeclarationOfResults.pdf"}
                                                                     target={"_blank"}><span
                                        className={"fa fa-download"}></span></a></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 text-center">
                            <Link to="/DeanAcademicAffairs" className="btn btn-sm btn-theme-colored2"><FontAwesomeIcon
                                icon={faBackward}/> Go Back</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default AcademicPerforma;