import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import axios from "axios";
import {URL} from "../components/MyModules";
import FacultyCard from "../components/FacultyCard";

const DeptPhysics = (props) => {

    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(1);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + 'KEY');
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data.data2);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Applied Physics " bdLink="Departments"/>
            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Applied Physics</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    The Department of Applied Physics was established in the year 2022 after the
                                    upgradation
                                    of Beant College of Engineering and Technology as Sardar Beant Singh State
                                    University. This
                                    department was created with a vision to strengthen the field of basic fundamental
                                    physics
                                    in the university. The strong base of the department has been built and nurtured by
                                    its well
                                    qualified current faculty members. The department has a good number of publications
                                    to its
                                    credit which include research papers at international level, review articles, books
                                    etc.
                                </p>
                                <p className="font-p-v m-7-v">
                                    Initially, this department was integral part of Department of Applied Sciences,
                                    Humanities &amp;
                                    Management which used to impart academic instructions to mainly undergraduate
                                    students
                                    of engineering programs. Now, the department has stimulating undergraduate and
                                    postgraduate following teaching programmes:</p>
                                <ul>
                                    <li>B.Sc. (Non-medical)</li>
                                    <li>M.Sc. (Physics)</li>
                                    <li>M.Sc. Physics (Hons) Integrated</li>
                                </ul>

                            </div>
                            <div className="col-sm-5 padd-10-v text-center">
                                <img src="assets/images/faculty/HOD_Physics_rakesh.jpeg"
                                     className="HOD-image-v" alt=""/>
                                <p className="text-left-10 text-theme-colored2"><strong> HOD, Department of Physics :
                                    Dr.
                                    Rakesh Dogra </strong></p>
                            </div>
                        </div>
                        <div>
                            <p className="font-p-v m-7-v">
                                The department believes in providing quality education to the students. The students are
                                provided a comprehensive practical exposure to the various instrumental techniques
                                required for their further education. The department has good instrumental research
                                facilities such as FT-IR, UV-Visible Spectrophotometer, digital LCR meter,
                                Thermoluminescence reader, Muffle furnace, hydraulic press for powder compression and
                                good computer facility for carrying out advanced research. The department is actively
                                involved in organizing activities like Conferences/ Workshops/ Symposium from time to
                                time. Besides, faculty is actively involved in guiding Ph.D. students and development
                                activities in the form of executing projects funded by external agencies.</p>

                            <p className="font-p-v m-7-v">
                                The department has its own well equipped laboratories for students of undergraduate
                                (B.Tech., B.Sc.) and post-graduate (M.Sc.-Physics) programs. To list a few, we have
                                equipment like He-Ne laser kit, Ultrasonic Interferometers, Spectrometers, Modulation
                                and

                                demodulation of Optical signals, Set-up for intensity response of solar cells and
                                Photodiode,
                                Opto-electronic kits consisting of LDR, VDR, photodiode, LED, Zener diode, Set-up for
                                V-I
                                characteristics of PN diodes, Melde&#39;s electric vibrator, Four-probe method for band
                                gap
                                engineering, Composite Piezo-electric oscillator set-up, Hall Effect Set-up, Logic
                                gates, J K
                                Flip flop, MUX kit, DEMUX kit, Michelson&#39;s Interferometer, Hysteresis-loop tracer
                                for
                                magnetic materials, Impedance analyzer etc. Hands-on practice in physics laboratories
                                gives
                                students an opportunity to experience real world phenomenon and to understand the basic
                                fundamentals of physics.</p>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>

                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MscPhysics">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/10.jpeg" className="Image_A"
                                             alt="M.Sc. Physics (Regular)"/>
                                    </div>
                                    <p className="myColor_A">M.Sc. Physics (Regular)</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/BscNonMed">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/17.png" className="Image_A"
                                             alt="M.Sc. Physics (Regular)"/>
                                    </div>
                                    <p className="myColor_A">BSc.(Non-Medical)</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MscPhysicsHons">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/22.jpg" className="Image_A"
                                             alt="M.Sc. Physics (Regular)"/>
                                    </div>
                                    <p className="myColor_A">M.Sc. Physics(Hons.)</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="">
                            {
                                facultyData.length > 0 ?
                                    <>
                                        <h3 className="">Professor</h3>
                                        {

                                            fetchingData ?
                                                <i className={"fa fa-spinner fa-2x"}></i>
                                                :
                                                facultyData.map((row, index) => {
                                                    return (
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    );
                                                })
                                        }
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptPhysics;