import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const BiogenSociety = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Students" subTitle="Biogen Society" bdLink="Biogen Society"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">
                            Biogen Society</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <p>Biogen society came into existence in year 2008, Ms. Harsharan Kaur (Assistant Professor,BioTech)
                        as teacher incharge with two hundred fifty students membership.

                        BioGen Society of BCET provides a platform for the students to demonstrate and enhance their
                        skills in various fields of:</p>
                    <div className="row">
                        <div className="col-sm-4">
                            <h3 className='text-theme-colored2'>Bio Science Community</h3>
                            <p>
                                <ul>
                                    <li>Environment</li>
                                    <li>Pollution</li>
                                    <li>Waste Management</li>
                                    <li>Group Discussions etc,</li>
                                </ul>
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h3 className='text-theme-colored2'>External Events</h3>
                            <p>
                                <ul>
                                    <li>Modelling(Tehzeeb)</li>
                                    <li>Scrabio</li>
                                    <li>Recycl-o-Mania</li>
                                    <li>Funk from Junk</li>
                                    <li>Perception</li>
                                </ul>
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <h3 className='text-theme-colored2'>Internal Events</h3>
                            <p>
                                <ul>
                                    <li>Awareness Campaign</li>
                                    <li>Gropup Discussion(Cashless India)</li>
                                    <li>Arm Fighting</li>
                                    <li>Debate</li>
                                    <li>Throw it in the bucket(Fun Event)</li>
                                    <li>Cleaner Greener Future</li>
                                    <li>The Eco City</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-4">
                            <h4 className='text-theme-colored2'>Incharge BioGen Club</h4>
                            <strong>Mr. Indesh Attri</strong>
                            <p>Assistant Professor , Biotechnology<br/>
                                E-mail—indeshbiotech21@gmail.com</p>
                        </div>
                        <div className="col-sm-4">
                            <h4 className='text-theme-colored2'>President</h4>
                            <strong>Ms. Eiva Sharma</strong>
                            <p>Branch:BT (4th Year)<br/>
                                E-mail—eviasharma91@gmail.com</p>
                        </div>
                        <div className="col-sm-4">
                            <h4 className='text-theme-colored2'>Vice-President</h4>
                            <strong>Mr. Gurwinder Singh</strong>
                            <p>Branch:BT (4th Year)<br/>
                                E-mail—gurwinder1801193@gmail.com</p>
                        </div>
                    </div>
                    <div className="row m-7-v">
                        <div className="col-sm-4">
                            <h4 className='text-theme-colored2'>General Secretary</h4>
                            <strong>Mr. Tathagat</strong>
                            <p>Branch:BT (3rd Year)<br/>
                                E-mail—tathagat1901181@gmail.com</p>
                        </div>
                        <div className="col-sm-4">
                            <h4 className='text-theme-colored2'>Record Keeper</h4>
                            <strong>Ms. Bhanushere</strong>
                            <p>Branch:BT (3rd Year)<br/>
                                E-mail—bhanushee1901170@gmail.com</p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <h2 className='text-theme-colored2'>Head Executives</h2>
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V">Branch</th>
                            <th className="table-title-V">Year</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Amandep Kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Archana kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Aswin Sharma</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">Germanjit Singh</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">Navjot kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">6.</td>
                                <td className="table-body-padd-V">Nivedita</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">7.</td>
                                <td className="table-body-padd-V">Simranpal kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">8.</td>
                                <td className="table-body-padd-V">Simranjeet kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">9.</td>
                                <td className="table-body-padd-V">Sukhmanjot</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">3rd Year</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <h2 className='text-theme-colored2'>Senior Executives</h2>
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V">Branch</th>
                            <th className="table-title-V">Year</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Abhineet Singh</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Abhishek</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Arshdeep Kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">Ishmeet kaur</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">Kashish</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">6.</td>
                                <td className="table-body-padd-V">Mukund Kumar</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">7.</td>
                                <td className="table-body-padd-V">Navya Mahajan</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">8.</td>
                                <td className="table-body-padd-V">Bavdeep Singh</td>
                                <td className="table-body-padd-V">BT</td>
                                <td className="table-body-padd-V">4th Year</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <h2 className='text-theme-colored2'>Gallery</h2>
                        <div className="col-sm-3 text-center">
                            <img src="assets/images/biogen/mc1.jpg" height='500' className='img-responsive' alt=""/>
                        </div>
                        <div className="col-sm-3 text-center">
                            <img src="assets/images/biogen/mc2.jpg" height='500' className='img-responsive' alt=""/>
                        </div>
                        <div className="col-sm-3 text-center">
                            <img src="assets/images/biogen/mc3.jpg" height='500' className='img-responsive' alt=""/>
                        </div>
                        <div className="col-sm-3 text-center">
                            <img src="assets/images/biogen/mc4.jpg" height='500' className='img-responsive' alt=""/>
                        </div>
                    </div>
                    <div className="row m-7-v">
                        <div className="col-sm-3 text-center">
                            <img src="assets/images/biogen/mc5.jpg" height='500' className='img-responsive' alt=""/>
                        </div>
                        <div className="col-sm-3 text-center">
                            <img src="assets/images/biogen/mc6.jpg" height='500' className='img-responsive' alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default BiogenSociety;

