import Footer from "../pages/components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../pages/components/NavBar2";
// import TopHeader from "./components/TopHeader";
// import "./AboutPage.css";
import BreadCrumbSection from "../pages/components/BreadCrumbSection";

const RefundPolicy = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Refund Policy" subTitle="" bdLink="Refund Policy"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">REFUND POLICY</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <br/>

                        <p className="m-7-v text-justify">We do not issue Any refunds, Refund requests are accepted or rejected at the sole discretion of Management of Beant College of Engineering & Technology now known as Sardar Beant Singh State Technical University</p>

                        <p className="m-7-v text-justify">No Request of Refund is accepted online please visit Accounts Branch on College Campus for any Enquires </p>


                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default RefundPolicy;