import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faCalendarAlt,
    faCreditCardAlt,
    faDownload,
    faEnvelope,
    faUser,
    faUserGraduate
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

import React, {useState, useEffect} from "react";
import {URL} from "./MyModules";
import axios from "axios";
import {CounsellingContext} from "../../App";

const TopHeader = () => {
    // const {counselling, title} = React.useContext(CounsellingContext);
    // console.log(counselling, title);
    return (
        <>
            <div className="header-top navbar-fixed-top">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 col-sm-5  col-xs-6">
                            <ul className="header-top-leftLinks">
                                <li>
                                    {/* Downloads */}
                                    <Link to="/downloadspage" className="top-header-links"
                                          style={{marginRight: "15px"}}>
                                        <FontAwesomeIcon icon={faDownload}/> Downloads
                                    </Link>

                                    {/* Old Website */}
                                    <a href="http://www.bcetgsp.ac.in/" rel="noopener"
                                       className="top-header-links" target="_blank">
                                        <FontAwesomeIcon icon={faArrowLeft}/> Old Website
                                    </a>
                                </li>

                                <li>
                                    {/*<Link to="/downloadsforstaff"><FontAwesomeIcon icon={faUserGraduate}/> Staff Perfoma*/}
                                    {/*</Link>*/}
                                    {/*<a id="acad_calend" href="/assets/pdf/acad_calend.pdf" target="_blank">*/}
                                    {/*    <FontAwesomeIcon icon={faCalendarAlt}/> Academic Calendar*/}
                                    {/*</a>*/}
                                </li>
                                <li>
                                    {/*<a href="/underconstructionpage">*/}
                                    {/*    <FontAwesomeIcon icon={faCreditCardAlt}/> Payment Gateway*/}
                                    {/*</a>*/}
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/alumniaffairs">*/}
                                {/*        <FontAwesomeIcon icon={faUserGraduate}/> Alumni Affairs*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>

                        <div className="col-md-4 col-sm-4 col-xs-6 social-links">
                            <ul className="styled-icons sm-text-center">
                                {/* --- Using Context API (do not delete this code) --- */}
                                {/* --------------------------------------------------- */}

                                {/*<li>*/}
                                {/*{counselling ?*/}
                                {/*        <Link to="/Online-Counselling">*/}
                                {/*            <i className="text-white top-header-links">*/}
                                {/*                {title}*/}
                                {/*            </i>*/}
                                {/*        </Link>*/}
                                {/*        :*/}
                                {/*        <Link to="/admissionpage">*/}
                                {/*            <i className="text-white top-header-links">Admissions 2023-24</i>*/}
                                {/*        </Link>*/}
                                {/*}*/}
                                {/*</li>*/}

                                <li>
                                    <Link to="/admissionpage">
                                        <i className="text-white top-header-links">Admissions</i>
                                    </Link>
                                </li>

                                {/* -- Pre-Registration -- */}
                                {/*<li>*/}
                                {/*    <Link to="/Registration-Details">*/}
                                {/*        <i className="text-white top-header-links">Registration</i>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}

                                {/* -- Online Counselling -- */}
                                {/*<li>*/}
                                {/*    <Link to="/Online-Counselling-Instructions">*/}
                                {/*        <i className="text-white top-header-links">Counselling</i>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </div>

                        {/*<div className="col-md-2 col-sm-2 col-xs-6 text-right">*/}
                        {/*<ul className="header-top-rightLinks">*/}
                        {/*    <li>*/}
                        {/*        <a href="/underconstructionpage">*/}
                        {/*            <FontAwesomeIcon icon={faEnvelope}/> SBSSU Email*/}
                        {/*        </a>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopHeader;