import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const EquipmentCommittee = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Administration" subTitle="
Equipment Store and Purchase Committee" bdLink="
Equipment Store and Purchase Committee"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">
                            Equipment Store and Purchase Committee</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className="">
                        <table className="table-hover table margin-25-V">
                            <thead className="table-head-red-V">
                            <th className="table-title-V">Sr. No</th>
                            <th className="table-title-V">Name</th>
                            <th className="table-title-V">Designation</th>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="table-body-padd-V">1.</td>
                                <td className="table-body-padd-V">Director, Technical Education & Industrial Training, Punjab.</td>
                                <td className="table-body-padd-V">Chairman
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">2.</td>
                                <td className="table-body-padd-V">Representative of the Finance Deptt (Not below the rank of Deputy Secretary)</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">3.</td>
                                <td className="table-body-padd-V">Director, Beant College of Engineering & Technology , Gurdaspur.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">4.</td>
                                <td className="table-body-padd-V">HOD/Professor/Assistant Professor of the concerned Department.</td>
                                <td className="table-body-padd-V">Member
                                </td>
                            </tr>
                            <tr>
                                <td className="table-body-padd-V">5.</td>
                                <td className="table-body-padd-V">One expert in the relevant field in which purchases are being made from any of the following Institutes:-
                                    <ul className="table-body-padd-V">
                                        <li>NIT Jalandhar</li>
                                        <li>GZSCET Bathinda</li>
                                        <li>TIET, Patiala</li>
                                        <li>GNEC Ludhaina</li>
                                        <li>SLIET Longowal</li>
                                    </ul>
                                </td>
                                <td className="table-body-padd-V">Ex-officio member
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default EquipmentCommittee;

