import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const BscNonMed = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Undergraduate Courses" subTitle="B.Sc(Non-Medical)" bdLink="Courses"/>
            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2"> Bachelor of Science: Non Medical </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    In this program, the aspirants will study the subjects such as physics, chemistry,
                                    mathematics, punjabi, english, computer science, healthcare, computer application,
                                    etc. Pursuing a B.Sc. in Non medical opens up multiple avenues for a student. The
                                    job scope for B.Sc. Non-Medical is vast and one can find job as chemist, cytologist,
                                    laboratory, software developer, consultants, bankers and many more. One can pursue
                                    their career as school teacher if he/she completes B.Ed. after B.Sc. program.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/bsc-nm.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed 10+2 or equivalent examination in Science Stream
                                with Physics, Chemistry & Mathematics with at least 50% Marks (45% marks in case of
                                candidate belonging to Reserved Category) in aggregate from any Board recognized or
                                established by Central/State Government through a legislation. The final admission will
                                be based on inter-se-merit in qualifying examination.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 3 years long, with Six Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                   <tr>
                                       <th className="table-title-V padd-l-10">Scheme</th>
                                       <th className="table-title-V padd-l-36">Syllabus</th>
                                       <th className="table-title-V">Remarks</th>
                                   </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BSc-Non-Med/2023_Syllabus.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    {/* 2022 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2022</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BSc-Non-Med/2022_Syllabus.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2022 Onwards</td>
                                    </tr>

                                    {/* 2021 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/UG/BSc-Non-Med/2021_Syllabus.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    {/* 2018 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/bsc-physics-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2018 Onwards</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default BscNonMed;