import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";
import {Link} from "react-router-dom";
import axios from "axios";
import {URL} from "../components/MyModules";
import FacultyCard from "../components/FacultyCard";

const DeptAppliedMathematics = (props) => {
    let [facultyData, setFacultyData] = useState([]);
    let [fetchingData, setFetchingData] = useState(true);
    let [profData, setProfData] = useState([]);
    let abc = [];
    let xyz = [];
    let [assoprofData, setAssoProfData] = useState([]);
    let [assiprofData, setAssiProfData] = useState([]);
    let [otherprofData, setOtherProfData] = useState([]);

    useEffect(() => {
        FetchFaculty(9);
    }, [])

    function FetchFaculty(key) {
        // console.log(key + ' Key');
        axios.get(`${URL}getFacultyList/${key}`)
            .then(async res => {
                // console.log(res.data);
                setFacultyData([]);
                await setFacultyData(res.data.data1);
                await setAssoProfData(res.data.data2);
                await setAssiProfData(res.data.data3);
                await setOtherProfData(res.data.data4);
                // facultyData.map((row, index) => {
                //     if (row.designation === 'Professor') {
                //         abc.push(row)
                //         console.log(row + " -----")
                //     } else if (row.designation === 'Associate Professor') {
                //         xyz.push(row)
                //     }else{
                //
                //     }
                // })
                // console.log(abc)
                // setProfData(abc)
                // console.log(xyz)
                // setAssoProfData(xyz)
                await setFetchingData(false);
            }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Departments" subTitle="Applied Mathematics "
                               bdLink="Departments"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Department of Applied Mathematics</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  CONTENT  */}
                        <div className="row">
                            <div className="col-sm-7 padd-10-v">
                                <p className="font-p-v m-7-v">
                                    {/* NEW CHANGES*/}
                                    The Department of Applied Mathematics of Sardar Beant Singh State University,
                                    Gurdaspur is committed for the development, teaching and learning of Mathematics in
                                    Engineering as well as Basic Science courses. The department has qualified and
                                    experienced faculty who are involved in the research activities and more than 120
                                    research papers have been published in the reputed international/national journals
                                    and conferences. The faculty of the department is committed towards the holistic
                                    development of the students, and provide in-depth knowledge to the students in
                                    advanced Applied Mathematics and prepare them for various research activities.

                                    {/*from oldweb*/}
                                    {/*The Department of Applied Sciences, Humanities and Management from its modest*/}
                                    {/*beginning in 1995 has now grown into an active center of quality teaching and*/}
                                    {/*research. This department was created with the mission of generating human resource,*/}
                                    {/*expanding human knowledge and benefit society through education and research on*/}
                                    {/*fundamental and Applied aspects of Science, Engineering and Technology in an*/}
                                    {/*interdisciplinary atmosphere. <br/>*/}

                                    {/*from word file*/}
                                    {/*The Department of Applied Mathematics of Sardar Beant Singh State University,*/}
                                    {/*Gurdaspur is committed for the development, teaching and learning of Mathematics in*/}
                                    {/*Engineering as well as Basic Science courses. The department has qualified and*/}
                                    {/*experienced faculty who are involved in the research activities and more than 120*/}
                                    {/*research papers have been published in the reputed international/national journals*/}
                                    {/*and conferences. The faculty of the department is committed towards the holistic*/}
                                    {/*development of the students, and provide in-depth knowledge to the students in*/}
                                    {/*advanced Applied Mathematics and prepare them for various research activities.*/}
                                </p>
                                {/*<p className="font-p-v m-7-v">*/}
                                {/*    Department of Applied Chemistry, Humanities & Management has emerged as one of the*/}
                                {/*    Science department of the university. The major strength of the department is highly*/}
                                {/*    qualified/experienced faculty and rich laboratory infrastructure. The faculty is*/}
                                {/*    involved in the research activities.</p>*/}

                            </div>
                            <div className="col-sm-5 padd-10-v">
                                <img src="assets/images/faculty/HOD_Maths_SKSrivastva.jpg"
                                     className="img-responsive HOD-image-v" alt=""/>
                                <p className="text-left-10 text-theme-colored2"><strong> HOD, Department : Dr Sanjay K.
                                    Srivastava</strong></p></div>
                        </div>

                        {/*<p className="font-p-v m-7-v">*/}
                        {/*    /!*from oldweb*!/*/}
                        {/*    The department comprises multi-disciplinary faculty members, at present, mainly related*/}
                        {/*    to Physics, Chemistry, Mathematics, Humanities (Communication English) and Management*/}
                        {/*    background to cater the needs of multi-disciplinary students at B.C.E.T. Campus,*/}
                        {/*    Gurdaspur. Our faculty members and scholars provide a home to the most promising*/}
                        {/*    doctoral and post-doctoral research with an access to educational/Research Laboratory*/}
                        {/*    resources and unparalleled laboratory and library facilities for both Basic/Applied and*/}
                        {/*    Engineering research. Growing steadily, today the department not only teaches various*/}
                        {/*    topics in B.Tech. and M.Tech. courses, but also runs its own M.Sc. Courses in Applied*/}
                        {/*    Mathematics and Applied Physics. Besides the Central Computing facilities of the*/}
                        {/*    Institute, the department has its own Numerical Analysis Lab. The Department is engaged*/}
                        {/*    in providing highest level and quality of academic education. The main focus of the*/}
                        {/*    teaching and research in the department is centered on interdisciplinary themes. The*/}
                        {/*    students are provided a comprehensive practical exposure to the various instrumental*/}
                        {/*    techniques required for their further education.</p>*/}
                    </div>

                    <div>
                        <h2 className="text-theme-colored2">Numerical Analysis & Computational Lab</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <p className="font-p-v m-7-v">To cater the need of research and computation requirement of the
                            Graduate, Post- Graduate and
                            Research students, a Numerical Analysis & Computational Laboratory is set up in the
                            Department of Mathematics. It is well equipped with C++, MATLAB and MATHEMATICA
                            software.</p>

                        <div className="row">
                            <div className="col-sm-6 col-xs-12 text-center myImage_A m-7-v">
                                <div className="text-center">
                                    {/*<div className="">*/}
                                    <img src="./assets/images/appMathsImage-1.jpg" className="Image_A"
                                         alt="MBA in Digital Marketing" style={{width: "100%"}}/>
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div className="col-sm-6 col-xs-12 text-center myImage_A m-7-v">
                                <div className="text-center">
                                    {/*<div className="">*/}
                                    <img src="./assets/images/appMathsImage-2.jpg" className="Image_A"
                                         alt="M.Sc. Chemistry (Regular)" style={{width: "100%"}}/>
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Courses Offered By The Department</h2>

                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/MscMath">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/13.png" className="Image_A"
                                             alt="MBA in Digital Marketing"/>
                                    </div>
                                    <p className="myColor_A">M.Sc. Mathematics</p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-3 col-xs-12 text-center myImage_A m-7-v">
                            <Link to="/">
                                <div className="text-center">
                                    <div className="imageEffect-v">
                                        <img src="./assets/images/admission_icons/22.png" className="Image_A"
                                             alt="M.Sc. Chemistry (Regular)"/>
                                    </div>
                                    <p className="myColor_A">The Doctor of Philosophy</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="row">
                        <h2 className="text-theme-colored2 m-7-v ">Faculty</h2>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="">
                            {
                                facultyData.length > 0 ?
                                    <>
                                        <h3 className="">Professor</h3>
                                        {

                                            fetchingData ?
                                                <i className={"fa fa-spinner fa-2x"}></i>
                                                :
                                                facultyData.map((row, index) => {
                                                    return (
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    );
                                                })
                                        }
                                        <div className="clearfix"></div>
                                        <hr/>
                                    </> : ""
                            }

                            {
                                assoprofData.length > 0 ? <>
                                    <h3 className="">Associate Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assoprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                assiprofData.length > 0 ? <>
                                    <h3 className="">Assistant Professor</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            assiprofData.map((row, index) => {
                                                return (
                                                    <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                 photo={row.photo}
                                                                 facultyname={row.facultyname}
                                                                 designation={row.designation}
                                                                 qualification={row.qualification} email={row.email}/>
                                                );
                                            })
                                    }
                                    <div className="clearfix"></div>
                                    <hr/>
                                </> : ""
                            }

                            {
                                otherprofData.length > 0 ? <>
                                    <h3 className="">Lecturer</h3>
                                    {

                                        fetchingData ?
                                            <i className={"fa fa-spinner fa-2x"}></i>
                                            :
                                            otherprofData.map((row, index) => {
                                                return (
                                                    <>
                                                        <FacultyCard key={index} obj={row} id={row.facultyid}
                                                                     photo={row.photo}
                                                                     facultyname={row.facultyname}
                                                                     designation={row.designation}
                                                                     qualification={row.qualification}
                                                                     email={row.email}/>
                                                    </>
                                                );
                                            })
                                    }
                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DeptAppliedMathematics;