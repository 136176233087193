import NavBar2 from "./components/NavBar2";
import BreadCrumbSection from "./components/BreadCrumbSection";
import Footer from "./components/Footer";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const FacilitiesforResearchConsultancy = () => {
    useEffect(() => {
        document.title = "Placements";
    })
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Research &amp; Development" subTitle="Facilities available for Research &amp; Consultancy"
                               bdLink="Research &amp; Consultancy"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font text-theme-colored2"}>
                        Facilities available for Research and Consultancy
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="mb-35">
                        <p>The Research Scholars/ Industry personnel/Institutes may contact HOD for Research/
                            consultancy etc. in various laboratories of the department.</p>

                        <ul className="researchUL">
                            <li>
                                <strong>Mechanical Measurement and Metrology Lab</strong>
                                <ol type="a" className="researchUL">
                                    <li>
                                        Profile Projector
                                    </li>
                                    <li>
                                        Stroboscope
                                    </li>
                                    <li>
                                        Tool Makers Microscope
                                    </li>
                                    <li>
                                        Electronic Weighing Machine
                                    </li>
                                    <li>
                                        Micro-hardness Tester
                                    </li>
                                    <li>
                                        Dead Weight Gauge Tester
                                    </li>
                                    <li>
                                        Ball Mill
                                    </li>
                                    <li>
                                        Surface Roughness Tester Model SJ 400 (Mitutoyo)
                                    </li>
                                    <li>
                                        Digital Micrometer
                                    </li>
                                    <li>
                                        Slip Gauge Set
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Machining Science Lab</strong>
                                <ol type="a" className="researchUL">
                                    <li>
                                        EDM Machine
                                    </li>
                                    <li>
                                        Smart ZNC EDM
                                    </li>
                                    <li>
                                        Programmable Tubular Furnace
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Solar Energy Lab</strong>
                                <ol type="a" className="researchUL">
                                    <li>
                                        Test rig for performance analysis of duct of single pass solar air heater
                                    </li>
                                    <li>
                                        Test rig for performance analysis of duct of double pass solar air heater
                                    </li>
                                    <li>
                                        Test rig for performance analysis of packed bed energy storage system
                                    </li>
                                    <li>
                                        Test rig for performance analysis of solar water heating system
                                    </li>
                                    <li>
                                        Test rig for performance analysis solar of Photovoltaic (SPV) system
                                    </li>
                                    <li>
                                        Solid Works software for simulation study of solar thermal systems
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Engineering Materials and Metallurgy Lab</strong>
                                <ol type="a" className="researchUL">
                                    <li>
                                        Optical Metallurgical Microscope for Material Analysis
                                    </li>
                                    <li>
                                        Desktop Spectroscopy for Chemical Analysis(Al Alloys)
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Strength of Materials Lab</strong>
                                <ol type="a" className="researchUL">
                                    <li>
                                        Computerized Journal Bearing testing
                                    </li>
                                    <li>
                                        Universal testing machine (100 Tons)
                                    </li>
                                    <li>
                                        Torsion Testing Machine
                                    </li>
                                    <li>
                                        Hardness Testing Machine
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>CAD/CAM lab (Software)</strong>
                                <ol type="a" className="researchUL">
                                    <li>
                                        Autodesk inventor
                                    </li>
                                    <li>
                                        Solid Works/Pro-E
                                    </li>
                                    <li>
                                        CATIA
                                    </li>
                                    <li>
                                        ANSYS
                                    </li>
                                    <li>
                                        CNC Part Programming
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <strong>Rig for Vapor Compression Analysis of Different Refrigerants</strong>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default FacilitiesforResearchConsultancy;