import NavBar2 from "./components/NavBar2";
import BreadCrumbSection from "./components/BreadCrumbSection";
import Footer from "./components/Footer";
import {useEffect} from "react";
import {Link} from "react-router-dom";

const TechnicalExpertiseFacultyMember = () => {
    useEffect(() => {
        document.title = "Placements";
    })
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Research &amp; Development" subTitle="Technical Expertise"
                               bdLink="Technical Expertise"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font text-theme-colored2"}>
                        Technical Expertise of the Faculty Members
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="mb-35">
                        <p>The Research Scholars/ Industry personnel/Institutes may contact the faculty members for the
                            expert advice/ consultancy etc. in the respective fields.</p>

                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <th className="table-title-V">
                                    <b>Sr No.</b>
                                </th>
                                <th className="table-title-V">
                                    <b>Name</b>
                                </th>
                                <th className="table-title-V">
                                    <b>Field Expertise</b>
                                </th>
                                <th className="table-title-V">
                                    <b>Email ID</b>
                                </th>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol>
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <p>Dr. Om Pal Singh</p>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Supply Chain Management, Industrial Engineering</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:ompal65@yahoo.co.in">
                                            ompal65@yahoo.co.in
                                        </a></td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="2">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. R. K. Awasthi
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Mechanical Design</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:awasthi.rka@gmail.com">awasthi.rka@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="3">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Dilbag Singh
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Manufacturing</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:singh_dilbag@yahoo.com">singh_dilbag@yahoo.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="4">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Ranjit Singh
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Renewable Energy Resources</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:rsolar70@gmail.com">rsolar70@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="5">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Harish Pungotra
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>CAD/CAM Software </strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:pungotra11@yahoo.co.in">pungotra11@yahoo.co.in</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="6">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Anil Mahajan
                                    </td>
                                    <td className="table-body-padd-V">
                                        <b>Non-Conventional Machining processes </b>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:ak_101968@yahoo.com">ak_101968@yahoo.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="7">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Jagdev Singh
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Refrigeration and Air Conditioning &amp; Fuzzy Modeling of Engg.
                                            Systems</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:jagdevssaini@yahoo.com">jagdevssaini@yahoo.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="8">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Naveen Beri
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Non-Conventional, Machining processes, TQM &amp; Quality
                                            Systems</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:nav_beri74@yahoo.co.in">
                                            nav_beri74@yahoo.co.in
                                        </a></td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="9">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Nirmal Singh Kalsi
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Manufacturing</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:ns_kalsi@yahoo.com">ns_kalsi@yahoo.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="10">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Brij Bhushan
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Solar Thermal</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:brijbsaini@gmail.com">brijbsaini@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="11">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Nripjit
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Composite Materials</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:nripanu@gmail.com">nripanu@gmail.com</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="12">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Sarabjeet Singh:
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Composite Materials and Manufacturing Techniques</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:sarabjeetsidhu@yahoo.com">
                                            sarabjeetsidhu@yahoo.com
                                        </a></td>
                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">
                                        <ol start="13">
                                            <li></li>
                                        </ol>
                                    </td>
                                    <td className="table-body-padd-V">
                                        Dr. Simranpreet Singh Gill
                                    </td>
                                    <td className="table-body-padd-V">
                                        <strong>Metallurgy of Ferrous Metal and Cryogenic Treatments</strong>
                                    </td>
                                    <td className="table-body-padd-V">
                                        <a href="mailto:ritchie_223@yahoo.com">ritchie_223@yahoo.com</a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default TechnicalExpertiseFacultyMember;