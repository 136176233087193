import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Transportation = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Transportation" subTitle="Sardar Beant State University ,Gurdaspur" bdLink="Transportation" />

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Transportation</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className={"col-lg-12"}>
                        <img src={"assets/images/transport.jpg"} className={"img-responsive img-circle center-block"}/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Transportation;