import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import {Link} from "react-router-dom";

const AlumniAffairs = (props) => {

    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Alumni Affairs"
                               subTitle="Sardar Beant State University ,Gurdaspur"
                               bdLink="Alumni Affairs"/>

            <div className="section3">
                <div className="container pt-90">
                    <h4 className="text-theme-colored2">Vice Chancellor's Message</h4>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className={"row"}>
                        <div className={"col-lg-3 col-md-3 text-center"}>
                            <img id={"picAlumni"} src={"assets/images/faculty/Susheel.png"} className={"img"}/>
                            <br/>
                            <b> Dr. Susheel Mittal <br/>
                                Vice Chancellor <br/>
                                Sardar Beant Singh State University, Gurdaspur. </b>

                        </div>

                        <div className={"col-lg-9 col-md-9"}>
                            <p className={"text-justify"}>
                                My Dear Alumni,<br/>
                                I welcome you all to the Alumni page of Sardar Beant Singh State University Gurdaspur.
                                Beant College of Engineering and Technology, Gurdaspur which was established in 1995,
                                has been upgraded to Sardar Beant Singh State University Gurdaspur from April 1, 2021 by
                                the Punjab Government. The University is imparting quality education in the various
                                courses in Engineering, Sciences and Management. Apart from the education, emphasis is
                                laid to develop the overall personality of students. Our students are not only
                                performing well in academics, but are also excelling in sports and extra-curricular
                                activities at the university and inter-university levels.
                                <br/>
                                Alumni of this University are well placed in the reputed organizations to serve the
                                people all over the Globe. Alumni are the greatest asset of any University. The purpose
                                of this page is to establish a platform for interaction among alumni, students, staff
                                and faculty for mutual benefits and growth of the State and Society. The alumni can
                                interact with the faculty and the students, share their experiences and provide
                                assistance in academics, placements etc.<br/>
                                Being located at the International Border, students come from the marginal families with
                                limited resources and they are in need of financial help and support from all the
                                stakeholders including Alumni. Therefore, the alumni can help the University by
                                providing financial assistance in terms of scholarship to needy students, infrastructure
                                developments like hostels, Alumni guest house, sports fund etc. <br/>
                                Once again, I welcome you all on the Alumni page and provide us your valuable and
                                constructive feedback for the overall development of the University. <br/>
                                I assure my full support to Alumni Association in its every future endeavours.
                            </p>
                        </div>
                    </div>

                    <div>
                        <h4 className={"text-theme-colored2"}>Dean Alumni Affairs’s Message:</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className={"row"}>
                            <div className={"col-lg-3 col-md-3 text-center"}>
                                <img id={"picAlumni"} src={"assets/images/faculty/Dilbag.jpg"} className={"img"}/>
                                <br/>
                                <b> Dr. Dilbag Singh <br/>
                                    Email: singh_dilbag@yahoo.com <br/>
                                    Contact No. : +91-8872458883, +91-1874- 221464
                                </b>
                            </div>

                            <div className={"col-lg-9 col-md-9"}>
                                <p className={"text-justify"}><br/> <br/>
                                    Welcome to the Alumni page of Sardar Beant Singh State University, Gurdaspur,
                                    Punjab,
                                    India. First of all, you will be pleased to know that your parent institute i.e
                                    Beant
                                    College of Engineering and Technology has been upgraded to Sardar Beant Singh State
                                    University, Gurdaspur from April 1, 2021. The Institute is imparting quality
                                    education
                                    in various disciplines. The Alumni of the University are serving in the reputed
                                    organizations globally and are excelling in their respective fields. The purpose of
                                    this
                                    page is to connect among alumni, students, staff and faculty for mutual benefits and
                                    growth. <br/>
                                    I welcome you all to the Alumni page and provide us your valuable suggestions for
                                    the
                                    growth of the University.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h4 className={"text-theme-colored2"}>Associate Dean Alumni Affairs’s Message:</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className={"row"}>
                            <div className={"col-lg-3 col-md-3 text-center"}>
                                <img id={"picAlumni"} src={"assets/images/faculty/Sunil.jpg"} className={"img "}/>
                                <br/>
                                <b> Dr. Sunil Kumar <br/>
                                    Email: skumarluna73@gmail.com <br/>
                                    Contact No. : +91-9463251477, +91-1874- 221464
                                </b>
                            </div>

                            <div className={"col-lg-9 col-md-9"}>
                                <p className={"text-justify"}><br/> <br/>
                                    welcome you all on the Alumni page of Sardar Beant Singh State University,
                                    Gurdaspur,
                                    Punjab. The Alumni of this University are serving at the different organizations all
                                    over the world and contributing a lot. This platform will be helpful for mutual
                                    benefits
                                    among all stakeholders.
                                </p>
                            </div>
                        </div>
                    </div>

                    <h5 className={"text-theme-colored2"}>Objectives of SBSSU Alumni Portal:</h5>
                    <div className="double-line-bottom-theme-colored-2"></div>
                    <ol>
                        <li>To establish a strong link with the Alumni all over the globe</li>
                        <li> To suggest ways and support the development activities in the University</li>
                        <li>To arrange get together of the Alumni from time to time</li>
                        <li>To institute and disburse scholarships, medals, awards, etc. to the meritorious students
                        </li>
                        <li>To promote research culture in the University by providing seed grants</li>
                        <li>To honor distinguished Alumni</li>
                    </ol>

                    <div>
                        <h4 className={"text-theme-colored2"}>Alumni Team:</h4>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <td className={"text-center"}>Sr. No.</td>
                                    <td className={"text-center"}>Name</td>
                                    <td className={"text-center"}>Contact No.</td>
                                    <td className={"text-center"}>Department / Designation</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Dr. Dilbag Singh</td>
                                    <td className={"text-center"}>8872458883</td>
                                    <td className={"text-center"}>Dean Alumni Affairs</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Dr. Sunil Kumar</td>
                                    <td className={"text-center"}>9463251477</td>
                                    <td className={"text-center"}>Associate Dean Alumni Affairs</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Er. Geetanjali Sharma</td>
                                    <td className={"text-center"}>9501708209</td>
                                    <td className={"text-center"}>CSE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}> Er. Lalit</td>
                                    <td className={"text-center"}>9501708209</td>
                                    <td className={"text-center"}>CSE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}> Er.Tarun Mahajan</td>
                                    <td className={"text-center"}>9888376755</td>
                                    <td className={"text-center"}>NE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>Er. Arun Nanda</td>
                                    <td className={"text-center"}>9888490015</td>
                                    <td className={"text-center"}>NE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>Er. Amandeep</td>
                                    <td className={"text-center"}>8360219683</td>
                                    <td className={"text-center"}>ECE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>Er. Akshay Mahajan</td>
                                    <td className={"text-center"}>9417328493</td>
                                    <td className={"text-center"}>BT</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9</td>
                                    <td className={"text-center"}>Er. Kanchan Kumar</td>
                                    <td className={"text-center"}>8437377977</td>
                                    <td className={"text-center"}>CHE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10</td>
                                    <td className={"text-center"}>Er. Ritika</td>
                                    <td className={"text-center"}>9463514164</td>
                                    <td className={"text-center"}>IT</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11</td>
                                    <td className={"text-center"}>Er. Lovepreet Singh</td>
                                    <td className={"text-center"}>9646882285</td>
                                    <td className={"text-center"}>Agri.</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h4 className={"text-theme-colored2"}>PROMINENT ALUMNI:</h4>

                        <h5 className={"text-theme-colored2"}>Mechanical Engineering:</h5>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className={"row"}>
                            <div className={"col-lg-8 col-md-8"}></div>
                            <div className={"col-lg-4 col-md-4 text-right"}>
                                <a href="https://forms.gle/W6SkSoyaZht9Kpsb6" target={"_blank"}>
                                    <button className={"btn btn-lg btn-theme-colored2 bottom-right"}><span
                                        className={"fa fa-sign-in"}></span> Click here to Register
                                    </button>
                                </a>
                            </div>
                        </div>
                        <br/>

                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <th className={"text-center"}>#</th>
                                    <th className={"text-center"}>Name</th>
                                    <th className={"text-center"}>Branch</th>
                                    <th className={"text-center"}>Batch</th>
                                    <th className={"text-center"}>Designation</th>
                                    <th className={"text-center"}>Organisation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Er. Vikram Jeet Singh</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Chief Engineer</td>
                                    <td className={"text-center"}>Synergy Maritime Private Limited</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Er. Ashwani Kumar Dogra</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>General Manager</td>
                                    <td className={"text-center"}>PROMOTECH ME FZE , Sharjah , UAE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Er. Gurpreet Kaur</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Sr. Director</td>
                                    <td className={"text-center"}>Twitter</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}>Er. Rajesh Sharma</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>TRENT XWB-84 Combustor Aero Lead</td>
                                    <td className={"text-center"}> Bangalore Urban, Karnataka, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}>Er. Karun Raj Singh Sareen</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Project Management Contracts</td>
                                    <td className={"text-center"}>Business Development Infrastructure Projects- Metro
                                        Rail,
                                        Railways, Roads, Transmission, Andhra Pradesh, India
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>Er. Karun Raj Singh Sareen</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Project Management Contracts</td>
                                    <td className={"text-center"}>Business Development Infrastructure Projects- Metro
                                        Rail,
                                        Railways, Roads, Transmission, Andhra Pradesh, India
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>Dr. Rupinder Singh</td>
                                    <td className={"text-center"}>PE</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Professor</td>
                                    <td className={"text-center"}>NITTTR Chandigarh, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>Dr. Harpuneet Singh</td>
                                    <td className={"text-center"}>PE</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Professor, Department of Mechanical Engineering</td>
                                    <td className={"text-center"}>Guru Nanak Dev Engineering College, Ludhiana-141006,
                                        Punjab.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9</td>
                                    <td className={"text-center"}>Er. Akhil Mahajan</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1996-2000</td>
                                    <td className={"text-center"}>Sr. IT Manager</td>
                                    <td className={"text-center"}> Procter & Gamble, Ohio, United States</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10</td>
                                    <td className={"text-center"}>Er. Vikramjeet Singh</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1996-2000</td>
                                    <td className={"text-center"}>President & CHRO</td>
                                    <td className={"text-center"}>Bajaj Allianz General Insurance</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11</td>
                                    <td className={"text-center"}>Er. Aman Prashar</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1996-2000</td>
                                    <td className={"text-center"}>Sr. Overhead Line /OHW/OLE</td>
                                    <td className={"text-center"}>Railway Engineer, Australia</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>12</td>
                                    <td className={"text-center"}>Er. Amandeep Singh</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>1996-2000</td>
                                    <td className={"text-center"}>Head (Purchase)</td>
                                    <td className={"text-center"}>Samsung India Electronics Ltd. Delhi, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>13</td>
                                    <td className={"text-center"}>Er. Vimal Gandotra</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2001-2005</td>
                                    <td className={"text-center"}>Chief Manager (Purchase)</td>
                                    <td className={"text-center"}>International Tractors Limited, Hoshiarpur, Punjab,
                                        India
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>14</td>
                                    <td className={"text-center"}>Er. Saurabh Bhanot</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2003-2007</td>
                                    <td className={"text-center"}>Senior Manager (Engineering Cost Control Department)
                                    </td>
                                    <td className={"text-center"}>Maruti Suzuki, Gurgaon</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>15</td>
                                    <td className={"text-center"}>Er. Sanjit Mahajan</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2003-2007</td>
                                    <td className={"text-center"}>Senior Manager</td>
                                    <td className={"text-center"}>Mahindra Defense Systems Limited</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>16</td>
                                    <td className={"text-center"}>Er. Japinder Singh</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2003-2007</td>
                                    <td className={"text-center"}>HVAC Technical Support Engineer</td>
                                    <td className={"text-center"}>FCA Fiat Chrysler Automobiles</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>17</td>
                                    <td className={"text-center"}>Er. Akshay Thusoo</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2004-2008</td>
                                    <td className={"text-center"}>Vice President</td>
                                    <td className={"text-center"}>Sales Sarovar Hotels & Resorts Corporate office,
                                        Gurgaon
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>18</td>
                                    <td className={"text-center"}>Er. Naman Sharma</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Assistant Consultant</td>
                                    <td className={"text-center"}> Tata Consultancy Services</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>19</td>
                                    <td className={"text-center"}>Er. Ankur Kakroo</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Development Engineer</td>
                                    <td className={"text-center"}>Scania Sweden Rönninge, Stockholm County, Sweden</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>20</td>
                                    <td className={"text-center"}>Er. Sanchayan Roy</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Senior Module Leader</td>
                                    <td className={"text-center"}>Tata motors, Pune</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>21</td>
                                    <td className={"text-center"}>Er. Vikramjeet Singh</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2008-2012</td>
                                    <td className={"text-center"}>Area Manager</td>
                                    <td className={"text-center"}>JK Tyre & Ind. Ltd</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>22</td>
                                    <td className={"text-center"}>Er. Prabhpreet Singh</td>
                                    <td className={"text-center"}>ME</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Senior Manager</td>
                                    <td className={"text-center"}>Tata Motors Limited</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h5 className={"text-theme-colored2"}>Computer Science and Engineering:</h5>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <th className={"text-center"}>#</th>
                                    <th className={"text-center"}>Name</th>
                                    <th className={"text-center"}>Branch</th>
                                    <th className={"text-center"}>Batch</th>
                                    <th className={"text-center"}>Designation</th>
                                    <th className={"text-center"}>Organisation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Er. Amandeep Bachho</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Director</td>
                                    <td className={"text-center"}>Amdocs, Pune</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Er. Vishal Devogon</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>DGM (HR, DTG, SM & Civil)</td>
                                    <td className={"text-center"}>BHEL, Industrial Valves Plant, Goindwal Sahib</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Dr. Parminder Singh</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Professor and Head, Department of Computer Science and
                                        Engineering
                                    </td>
                                    <td className={"text-center"}>Guru Nanak Dev Engineering College, Ludhiana-141006,
                                        Punjab.
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}>Dr. Sumit Goswami</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>1995-1999</td>
                                    <td className={"text-center"}>Additional Director</td>
                                    <td className={"text-center"}>DRDO Delhi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}>Dr Jagdeep Kaur</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>1999-2003</td>
                                    <td className={"text-center"}>Assistant Professor,<br/> Department of Computer
                                        Science
                                        and
                                        Engineering
                                    </td>
                                    <td className={"text-center"}>Dr BR Ambedkar National Institute of Technology,
                                        Jalandhar
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>Dr. Kusum Lata</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>1999-2003</td>
                                    <td className={"text-center"}>Assistant Professor</td>
                                    <td className={"text-center"}>Delhi Technological University, Delhi.</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>Er. Atul Kapoor</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>2002-2006</td>
                                    <td className={"text-center"}>Engineer</td>
                                    <td className={"text-center"}>Apple Inc. USA</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>Er. Ayusha Sehgal</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}> 2014-2018</td>
                                    <td className={"text-center"}>Engineer</td>
                                    <td className={"text-center"}>Tata Consultancy Services Limited, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9</td>
                                    <td className={"text-center"}>Er. Sumiran</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}> 2014-2018</td>
                                    <td className={"text-center"}>Engineer</td>
                                    <td className={"text-center"}> Infosys, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10</td>
                                    <td className={"text-center"}>Er. Mayank Gupta</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>2016-2020</td>
                                    <td className={"text-center"}>Software Engineer</td>
                                    <td className={"text-center"}> Coforge Ltd (Erswhile NIIT Technologies)</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11</td>
                                    <td className={"text-center"}>Er. Raghav</td>
                                    <td className={"text-center"}>CSE</td>
                                    <td className={"text-center"}>2016-2020</td>
                                    <td className={"text-center"}>Full Stack Developer</td>
                                    <td className={"text-center"}> Intelizon systems</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h5 className={"text-theme-colored2"}>Electronics and Communication Engineering:</h5>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <th className={"text-center"}>#</th>
                                    <th className={"text-center"}>Name</th>
                                    <th className={"text-center"}>Branch</th>
                                    <th className={"text-center"}>Batch</th>
                                    <th className={"text-center"}>Designation</th>
                                    <th className={"text-center"}>Organisation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Er. Jaspal Singh</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>1997-2001</td>
                                    <td className={"text-center"}>Information Security Manager</td>
                                    <td className={"text-center"}> FIS India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Er. Shamsher Gill</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>1997-2001</td>
                                    <td className={"text-center"}>Director</td>
                                    <td className={"text-center"}>ATREND, USA</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Er. Amandeep Chawla</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>1998-2002</td>
                                    <td className={"text-center"}>Senior Computer Scientist</td>
                                    <td className={"text-center"}>ADOBE, California, USA</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}>Er. Divjot Singh</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>1999-2003</td>
                                    <td className={"text-center"}>Regional Digital Leader</td>
                                    <td className={"text-center"}>General Electric, Toronto, Canada</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}>Er.Parminder Singh</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>1999-2003</td>
                                    <td className={"text-center"}>Lead Engineer</td>
                                    <td className={"text-center"}>Sasken Network</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>Er. Love Kumar</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}> 2002-2006</td>
                                    <td className={"text-center"}>Senior Infrastructure Engineer</td>
                                    <td className={"text-center"}>APPLE California USA</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>Er. Abhinav Gupta</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}> 2002-2006</td>
                                    <td className={"text-center"}>Senior Management Consultant</td>
                                    <td className={"text-center"}>Ernst & Young Atlanta Georgia, USA</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>Er.Nisha Gandotra</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2004-2008</td>
                                    <td className={"text-center"}>Global Patent Operations</td>
                                    <td className={"text-center"}>Google</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9</td>
                                    <td className={"text-center"}>Er. Rajinder Bhagat</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2005-2009</td>
                                    <td className={"text-center"}>Senior Engineer</td>
                                    <td className={"text-center"}>Qualcomm Inc., Bangalore</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10</td>
                                    <td className={"text-center"}>Er. Rajinder Bhagat</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2005-2009</td>
                                    <td className={"text-center"}>Senior Engineer</td>
                                    <td className={"text-center"}>Qualcomm Inc., Bangalore</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11</td>
                                    <td className={"text-center"}>Er. Harmanjeet Pal Singh</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2005-2009</td>
                                    <td className={"text-center"}>Assistant Manager</td>
                                    <td className={"text-center"}>Schneider Electric, Gurgaon</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>12</td>
                                    <td className={"text-center"}>Er. Neha Arora</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2005-2009</td>
                                    <td className={"text-center"}>Portfolio Manager</td>
                                    <td className={"text-center"}>Clairvolex, Gurgaon</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>13</td>
                                    <td className={"text-center"}>Er. Priyanka Grover</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Senior Software Engineer</td>
                                    <td className={"text-center"}>Siemens Technology, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>14</td>
                                    <td className={"text-center"}>Er. Sanjay Mehta</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Technical Lead</td>
                                    <td className={"text-center"}>Cognizant, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>15</td>
                                    <td className={"text-center"}>Er. Megha Mahajan</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2008-2012</td>
                                    <td className={"text-center"}>Technology Analyst</td>
                                    <td className={"text-center"}>Infosys, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>16</td>
                                    <td className={"text-center"}>Er. Shefali Kalia</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2008-2012</td>
                                    <td className={"text-center"}>SharePoint Solution Architect</td>
                                    <td className={"text-center"}>Whitecap Canada Inc.</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>17</td>
                                    <td className={"text-center"}>Er. Gulmeen Kaur Purewal</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>System Reliability Engineer</td>
                                    <td className={"text-center"}>Nutanix San Francisco Bay Area, USA</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>18</td>
                                    <td className={"text-center"}>Er. Iqbal Singh</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Senior Operations Professional</td>
                                    <td className={"text-center"}>IBM India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>19</td>
                                    <td className={"text-center"}>Er. Rubiana Purewal</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2010-2014</td>
                                    <td className={"text-center"}>GTS DEPARTMENT</td>
                                    <td className={"text-center"}>IBM Gurgaon</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>20</td>
                                    <td className={"text-center"}>Er. Sudhanshu Chaudhary</td>
                                    <td className={"text-center"}>ECE</td>
                                    <td className={"text-center"}>2011-2015</td>
                                    <td className={"text-center"}>SE Officer</td>
                                    <td className={"text-center"}>Indian Railway (IES)</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h5 className={"text-theme-colored2"}>Biotechnology:</h5>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <th className={"text-center"}>#</th>
                                    <th className={"text-center"}>Name</th>
                                    <th className={"text-center"}>Branch</th>
                                    <th className={"text-center"}>Batch</th>
                                    <th className={"text-center"}>Designation</th>
                                    <th className={"text-center"}>Organisation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Er. Bikramjeet Singh Ghuman</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>EHS officer</td>
                                    <td className={"text-center"}>McGill University, Canada</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Er. Sachin Manhas</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Quality Officer</td>
                                    <td className={"text-center"}>Rich Graviss Products Pvt. Ltd. HP</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Er. Vivek Thakur</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2006-2010</td>
                                    <td className={"text-center"}>Field officer</td>
                                    <td className={"text-center"}>Department of Forests & Wildlife Preservation,
                                        Punjab
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}>Er. Abhay Mahajan</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2007-2011</td>
                                    <td className={"text-center"}>Regional Manager</td>
                                    <td className={"text-center"}>Modern Diagnostics and Research Centre Pvt Ltd.</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}>Er. Ramnik Pal Singh SherGill</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2007-2011</td>
                                    <td className={"text-center"}>Technical officer</td>
                                    <td className={"text-center"}>Parks and Open Space, City of Salisbury, Australia
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>Er. Meena Jaiswal</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2007-2011</td>
                                    <td className={"text-center"}>Quality Officer</td>
                                    <td className={"text-center"}> Cargill India Private Limited</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>Er. Ravi Choudhary</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2007-2011</td>
                                    <td className={"text-center"}>Quality Officer</td>
                                    <td className={"text-center"}>Pepsi, Patiala</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>Er. Sachin Sharma</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Senior Business Specialist</td>
                                    <td className={"text-center"}>Thermo Fischer Scientific, Delhi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9</td>
                                    <td className={"text-center"}>Er. Amit Lamba</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Engineer</td>
                                    <td className={"text-center"}>Veolia</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10</td>
                                    <td className={"text-center"}>Er. Smita Modi</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Associate Research Scientist</td>
                                    <td className={"text-center"}>Invictus Oncology, New Delhi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11</td>
                                    <td className={"text-center"}>Er. Ishan</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2012-2016</td>
                                    <td className={"text-center"}>Engineer</td>
                                    <td className={"text-center"}>Intas Biopharmaceuticals Limited, Gujarat</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>12</td>
                                    <td className={"text-center"}>Er. Manik Sharma</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2013-2017</td>
                                    <td className={"text-center"}>Quality Engineer</td>
                                    <td className={"text-center"}>Agilent Technologies Pvt. Ltd. Jammu</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>13</td>
                                    <td className={"text-center"}>Er. Sabhi Mahajan</td>
                                    <td className={"text-center"}>Biotechnology</td>
                                    <td className={"text-center"}>2014-2018</td>
                                    <td className={"text-center"}>System Engineer</td>
                                    <td className={"text-center"}>Omics International Pvt Ltd. Noida</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h5 className={"text-theme-colored2"}>Civil Engineering:</h5>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <th className={"text-center"}>#</th>
                                    <th className={"text-center"}>Name</th>
                                    <th className={"text-center"}>Branch</th>
                                    <th className={"text-center"}>Batch</th>
                                    <th className={"text-center"}>Designation</th>
                                    <th className={"text-center"}>Organisation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Mohit Singh</td>
                                    <td className={"text-center"}>CE</td>
                                    <td className={"text-center"}>2016-2020</td>
                                    <td className={"text-center"}>SDO</td>
                                    <td className={"text-center"}>Irrigation Department Punjab</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h5 className={"text-theme-colored2"}>Chemical Engineering:</h5>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="table-responsive">
                            <table className={"table"}>
                                <thead className={"table-head-red-V"}>
                                <tr>
                                    <th className={"text-center"}>#</th>
                                    <th className={"text-center"}>Name</th>
                                    <th className={"text-center"}>Branch</th>
                                    <th className={"text-center"}>Batch</th>
                                    <th className={"text-center"}>Designation</th>
                                    <th className={"text-center"}>Organisation</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={"text-center"}>1</td>
                                    <td className={"text-center"}>Dr. Surinder Singh</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>1996-2000</td>
                                    <td className={"text-center"}>Assistant Professor</td>
                                    <td className={"text-center"}> Panjab University, Chandigarh</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>2</td>
                                    <td className={"text-center"}>Er. Ashish Monga</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2001-2005</td>
                                    <td className={"text-center"}>Manager</td>
                                    <td className={"text-center"}> Johnson & Johnson Ltd., Baddi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>3</td>
                                    <td className={"text-center"}>Er. Satish Singh</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2001-2005</td>
                                    <td className={"text-center"}>Senior Manager</td>
                                    <td className={"text-center"}> Lo’real, Pune</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>4</td>
                                    <td className={"text-center"}>Er. Jatinder Jhatta</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2003-2007</td>
                                    <td className={"text-center"}>Manager</td>
                                    <td className={"text-center"}> ADNOC Group, UAE</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>5</td>
                                    <td className={"text-center"}>Er. Nikhil Pandoh</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2004-2008</td>
                                    <td className={"text-center"}>Manager</td>
                                    <td className={"text-center"}> Kokuyo, Jammu</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>6</td>
                                    <td className={"text-center"}>Er. Sohit Angrish</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Manager</td>
                                    <td className={"text-center"}>Reliance India Ltd., Jamnagar, Gujarat</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>7</td>
                                    <td className={"text-center"}>Er.Arshdeep Sadiora</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2009-2013</td>
                                    <td className={"text-center"}>Manageri</td>
                                    <td className={"text-center"}>Castrol India Ltd., Mumbai</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>8</td>
                                    <td className={"text-center"}>Er. Harsh Walia</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2010-2014</td>
                                    <td className={"text-center"}>Production Executive Engineer</td>
                                    <td className={"text-center"}> HIKAL Ltd. Mumbai</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>9</td>
                                    <td className={"text-center"}>Er. Abhinav Thakur</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2011-2015</td>
                                    <td className={"text-center"}>Manager</td>
                                    <td className={"text-center"}> Reinsurance AON, Mumbai</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>10</td>
                                    <td className={"text-center"}>Er. Arjun Kumar Talgotra</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2011-2015</td>
                                    <td className={"text-center"}>Process Engineer</td>
                                    <td className={"text-center"}> FLISON, Hungary</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>11</td>
                                    <td className={"text-center"}>Er. Mohit Chopra</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2011-2015</td>
                                    <td className={"text-center"}>Sr. Engineer</td>
                                    <td className={"text-center"}>Nuvoco Vistas Group, Chattisgarh</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>12</td>
                                    <td className={"text-center"}>Er. Rahul Vohra</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2011-2015</td>
                                    <td className={"text-center"}>Sr. Technology Transfer Engineer</td>
                                    <td className={"text-center"}>SRF Ltd. Bhiwadi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>13</td>
                                    <td className={"text-center"}>Er. Manshu Kapoor</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2011-2015</td>
                                    <td className={"text-center"}>GET</td>
                                    <td className={"text-center"}>HENKEL Gurgaon, India</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>14</td>
                                    <td className={"text-center"}>Er. Nikhil Bhatti</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2012-2016</td>
                                    <td className={"text-center"}>Assistant Manager</td>
                                    <td className={"text-center"}> Grasim Industries Ltd.</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>15</td>
                                    <td className={"text-center"}>Er. Karan Sharma</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2014-2018</td>
                                    <td className={"text-center"}>Production Engineer</td>
                                    <td className={"text-center"}> PIDILITE INDUSTRIES Ltd., Baddi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>16</td>
                                    <td className={"text-center"}>Er. Kewal Sharma</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2015-2019</td>
                                    <td className={"text-center"}>Process Engineer</td>
                                    <td className={"text-center"}> Honda Motorcycle & Scooter India Pvt Ltd, Gurgaon
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>17</td>
                                    <td className={"text-center"}>Er. Suraj</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2015-2019</td>
                                    <td className={"text-center"}>Engineer</td>
                                    <td className={"text-center"}> ATOTECH Gurgaon</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>18</td>
                                    <td className={"text-center"}>Er. Ashutosh Yadav</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2015-2019</td>
                                    <td className={"text-center"}>Research Analyst</td>
                                    <td className={"text-center"}> FREELANCER, Gurgaon</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>19</td>
                                    <td className={"text-center"}>Er. Puru Jasrotia</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2016-2020</td>
                                    <td className={"text-center"}>GET</td>
                                    <td className={"text-center"}>Kansai Nerolac Paints Ltd., Amritsar</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>20</td>
                                    <td className={"text-center"}>Er. Anmol Bawa</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2016-2020</td>
                                    <td className={"text-center"}>Process Design Engineer</td>
                                    <td className={"text-center"}>Triune Energy Services Pvt. Ltd. New Delhi</td>
                                </tr>
                                <tr>
                                    <td className={"text-center"}>21</td>
                                    <td className={"text-center"}>Er. Sanpreet Singh</td>
                                    <td className={"text-center"}>CHE</td>
                                    <td className={"text-center"}>2017-2021</td>
                                    <td className={"text-center"}>Assistant Manager</td>
                                    <td className={"text-center"}>Orelia Group, Bolivia</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <Footer/>
        </>
    );
}

export default AlumniAffairs;