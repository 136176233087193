import NavBar2 from "../components/NavBar2";
import BreadCrumbSection from "../components/BreadCrumbSection";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

const PunjabiClub = () => {
    document.title = "Punjabi Sabhyacharak Club";
    return (
        <>
            <NavBar2/>

            <BreadCrumbSection mainTitle="Punjabi Sabhyacharak Club"
                               subTitle="Student's Corner" bdLink="PSC"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2 my-font-style">Punjabi Sabhyacharak Club</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>

                        <div className="mb-35 text-justify">
                            <strong>Incharge PSC Club</strong> <br/>
                            <strong>Sh. Surinder Singh</strong> <br/>
                            <strong>Lecturer Workshop Practice</strong> <br/>
                            <strong>Department of Central Workshop</strong> <br/>
                            <strong>PH.-9914039328</strong> <br/>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-capitalize text-theme-colored2">Student Are selected as an
                                Executive Bearer of Punjabi Sabhyacharak Club</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V  ">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                        <th className="table-title-V text-center">Designation</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td align="left"><strong>JAVTESHWAR SINGH BAINS</strong></td>
                                        <td>PROD</td>
                                        <td align="center" valign="top">FINAL</td>
                                        <td align="center" valign="top">PRESIDENT</td>
                                    </tr>

                                    <tr>
                                        <td align="left" valign="top"><strong>RANBIR SINGH</strong></td>
                                        <td valign="top">CSE</td>
                                        <td align="center" valign="top">THIRD</td>
                                        <td align="center" valign="top">VICE-PRESIDENT</td>
                                    </tr>

                                    <tr>
                                        <td align="left" valign="top"><strong>DAVINDER SINGH</strong></td>
                                        <td valign="top">ME</td>
                                        <td align="center" valign="top">THIRD
                                        </td>
                                        <td align="center" valign="top">SECRETARY</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Chief Executve Committee</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V text-center">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>AJAYPAL SINGH SANDHU</td>
                                        <td align="center" valign="top">PROD</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td>RUPINDER SINGH</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td>GAGANDEEP SINGH</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td> DINESH SHARMA</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>INDERPREET SINGH CHAHAL</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>GURWINDER SINGH</td>
                                        <td align="center" valign="top">PROD</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td>GURPREET SINGH</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td>DIXIT MANAN</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td>SANDEEP ATTRI</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">SENIOR EXECUTIVE COMMITTEE: (Third Year
                                )</h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V text-center">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>SIMRANDEEP SINGH</td>
                                        <td align="center" valign="top">BIOTECH</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td>AMRITPAL SINGH SANDHU</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td> NAVNEET KAUR</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td> SIMRANJEET SINGH</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>MANINDER SINGH</td>
                                        <td align="center" valign="top"></td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>GAGADEEP SINGH</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td>LAKHWINDER SINGH</td>
                                        <td align="center" valign="top"></td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td>NAVSIMRAN KAUR</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td>BIKRAMJIT SINGH</td>
                                        <td align="center" valign="top">BIOTECH</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>10.</strong></td>
                                        <td>NIMAR SINGH</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>11.</strong></td>
                                        <td>AFTAB SINGH SANDHU</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>12.</strong></td>
                                        <td> NAVDEEP SINGH</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>13.</strong></td>
                                        <td>NIHARIKA SODHI</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>14.</strong></td>
                                        <td>KARAN WALIA</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">EXECUTIVE
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V text-center">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>VARUNPAL SINGH</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td>MANPREET KAUR</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td> SIMRANJIT KAUR</td>
                                        <td align="center" valign="top">BIOTECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td>HARMANPREET SINGH</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>RAMNIKPAL SINGH</td>
                                        <td align="center" valign="top">CHEM</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>TARUNVEER SINGH</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td>MOHIT ANGURALA</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td> BHARPOOR SINGH SANDHU</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td>JAGBIR SINGH</td>
                                        <td align="center" valign="top">BIOTECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>10.</strong></td>
                                        <td>DILSHER SINGH</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>11.</strong></td>
                                        <td>SHRUTI</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>12.</strong></td>
                                        <td>JASPREET SINGH</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">JUNIOR EXECUTIVE
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V text-center">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>KAPIL</td>
                                        <td align="center" valign="top">CHEMICAL</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td>JASKARAN SINGH VIRDI</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td>NAVDEEP KAUR</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td>ROOHI BEDI</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>JASPINDER KAUR</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>EKTA MAHAJAN</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td>JASVEEN KAUR</td>
                                        <td align="center" valign="top">BIOTECH</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td>HARMAN SAINI</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td> RUPALI</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>10.</strong></td>
                                        <td>MANSI GERA</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>11.</strong></td>
                                        <td>NAVEEN</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>12.</strong></td>
                                        <td>VIKRAMJIT SINGH</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2">Details of Activities Performed in Current
                                Academic Year:
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <div className="mb-35 text-justify">
                                <strong className="my-font-style">Religious Smagam</strong> <br/>
                                <p>Organized Path of Sri Sukhmani Sahib at Gurudwara Sahib of village Briar on dated
                                    14.11.2007</p>
                                <br/>
                                <strong className="my-font-style">College Level</strong> <br/>
                                <p>Following cultural events were performed during inauguration and closing of TechFest
                                    Juventus 2007 (4th & 5th October 2007)</p>
                                <ul>
                                    <li>Girls’ group dance</li>
                                    <li>Boys’ group dance</li>
                                    <li>Malwai Gidha</li>
                                    <li>Punjabi dance (mix)</li>
                                    <li>Bhangra Boys</li>
                                </ul>
                                <br/>
                                <strong className="my-font-style">Inter College level</strong> <br/>
                                <p>Participated with Malwai Gidha team at PTU Zonal Youth Festival,
                                    2008-2009(Zone-II).</p> <br/>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2"> BHANGRA MIX (JUVENTUS Tech. Fest 2K8)
                                16-17th October, 2008
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V text-center">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>Rahul Attri</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td>Nitin Kumar</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td>Navdeep Bedi</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td>Parminder Kaur</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>Neharika</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>Parveen</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td>Amritpal Singh</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td>Pawan</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td>Navneet Kaur</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>10.</strong></td>
                                        <td>Navjot Kuar</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2"> MALWAI GIDDHA PTU ZONAL YOUTH FESTIVAL,
                                2008-09 (ZONE II)
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="table-responsive">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V">Sr.&nbsp;No.</th>
                                        <th className="table-title-V">Name</th>
                                        <th className="table-title-V text-center">Branch</th>
                                        <th className="table-title-V text-center">Year</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td><strong>1.</strong></td>
                                        <td>Harjit Singh</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">FINAL</td>
                                    </tr>
                                    <tr>
                                        <td><strong>2.</strong></td>
                                        <td> Ranbir Singh</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>3.</strong></td>
                                        <td>Amritpal Singh</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>4.</strong></td>
                                        <td>Rajwinder Singh</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>5.</strong></td>
                                        <td>Ravinder Singh</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>6.</strong></td>
                                        <td>Harmanpreet Singh</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>7.</strong></td>
                                        <td>Varunpal Singh</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>8.</strong></td>
                                        <td>Jagbir Singh</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>9.</strong></td>
                                        <td> Dilsher Singh</td>
                                        <td align="center" valign="top">CSE</td>
                                        <td align="center" valign="top">FIRST</td>
                                    </tr>
                                    <tr>
                                        <td><strong>10.</strong></td>
                                        <td> Amrik Singh</td>
                                        <td align="center" valign="top">ECE</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>11.</strong></td>
                                        <td> Tarunbir Singh</td>
                                        <td align="center" valign="top">CHEM</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>12.</strong></td>
                                        <td> Iqbal Singh</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    <tr>
                                        <td><strong>13.</strong></td>
                                        <td> Deep singh</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>14.</strong></td>
                                        <td> Rohit Kumar</td>
                                        <td align="center" valign="top">IT</td>
                                        <td align="center" valign="top">THIRD</td>
                                    </tr>
                                    <tr>
                                        <td><strong>15.</strong></td>
                                        <td>Ramneekpal Singh</td>
                                        <td align="center" valign="top">MECH</td>
                                        <td align="center" valign="top">SECOND</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mb-35 text-justify">
                            <h4 className="my-font-style text-theme-colored2"> Photographs - Punjabi Sabhyacharak Club
                            </h4>
                            <div className="double-line-bottom-theme-colored-2"></div>

                            <div className="col-lg-6  text-center">
                                <div className="imageEffect-v-lab">
                                    <img src="./assets/images/lab/pc1.jpg" className="Image_A"
                                         alt="Bhangra Group"/>
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <div className="imageEffect-v-lab">
                                    <img src="./assets/images/lab/pc2.jpg" className="Image_A"
                                         alt="Bhangran group"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default PunjabiClub;