import {Link, useNavigate} from "react-router-dom";
import {FaHome} from "react-icons/fa";
import "../AboutPage.css";
import RightMenuLinks from "./RightMenuLinks";


const NavLinks = () => {

    const navigate = useNavigate();

    const openURL = () => {
        navigate("/admissions");
    }

    return (
        <>
            <nav className="navbar navbar-default yamm" id="NV1">
                <div className="container-full">
                    <div className="navbar-header">
                        {/* Toggle Icon */}
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>

                        {/*<Link className="navbar-brand with-text" title="PSD to HTML"*/}
                        {/*   to="/">EduPress</Link>*/}

                        {/*<Link className="navbar-brand with-text" title="Logo"*/}
                        {/*   to="/">*/}
                        {/*    <img src="assets/images/beantuni.jpeg" alt="Logo" width="60" />*/}
                        {/*</Link>*/}
                    </div>

                    <Link className="first-child" title="Home Page" to="/">
                        <img className="logo-scrolled-to-fixed" id="logo-scrolled-to-fixed"
                             src="assets/images/logo/beantunilogo.png" alt="Logo"/>
                    </Link>

                    {/*Right Menu Links*/}
                    <RightMenuLinks cname="" />
                    {/*Right Menu Links*/}

                </div>
                {/*container-fluid*/}
            </nav>
        </>
    );
}

export default NavLinks;