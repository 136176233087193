import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const AboutPage = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="About Us" subTitle="About Us" bdLink="About Us"/>

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1>About Us</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        <p className="text-justify p-lh">
                            Sardar Beant Singh State University, Gurdaspur was established by the Govt. of Punjab
                            as an autonomous Institution through registered society. The foundation stone was laid on
                            February 28,1994 and the college was inaugurated on August 20,1995 by the Chief Minister
                            Late Sardar Beant Singh Ji. The college campus including the instructional buildings,
                            amenties, residential and other facilities is spread over 70 acres of Land. The college is
                            imparting instructions in the Engineering disciplines of Bio Technology, Chemical
                            Engineering, Computer Science & Engineering, Civil Engineering, Electronics & Communication
                            Engineering, Information Technology & Mechanical Engineering at under graduate level and
                            Thermal Engineering at Post graduate level. The college has made tremendous progress as
                            under:-
                        </p>
                        <p className="text-justify p-lh">
                            In the year 1995 only 3 B.Tech. disciplines i.e. Computer Science & Engineering, Mechanical
                            Engineering & Production Engineering were started with a total intake of 120 students. Then
                            in the year 1996, seats were increased and one more discipline of Chemical Engineering were
                            added. Then in the year 1997, one more discipline of Electronics & Communications
                            Engineering was added. Then in the year 2001, one more discipline of Information Technology
                            was added and the total intake became 300. then in the year 2003, Two M.Tech course namely
                            Production Engineering and Electronics & Communication Engineering under Regional centre of
                            P.T.U. Jalandhar were started. Then in the year 2006, seats were increased and new course of
                            Bio Technology was added and total intake was increased to 370. Then in the year 2007, one
                            M.Tech in Thermal Engineering was added sanctioned by AICTE directly to the college. In
                            2008, seats of Computer Science and Engg. were increased to 90 and seats of Chemical Engg.
                            were decreased to 30. Therefore at present the seats/discipline position is as under:-
                        </p>

                        <div className="table-responsive">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">
                                <th className="table-title-V">
                                    <b>B.Tech</b>
                                </th>
                                <th className="table-title-V">
                                    <b>&nbsp;</b>
                                </th>
                                <th className="table-title-V">
                                    <b>M.Tech, M.Sc(Regular)</b>
                                </th>
                                <th className="table-title-V">
                                    <b>&nbsp;</b>
                                </th>
                                <th className="table-title-V">
                                    <b>&nbsp;</b>
                                </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>Bio-Technology</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>60</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>M.Tech. in Thermal Engg.</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>18</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>Approved by AICTE, New Delhi</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>Chemical Engineering</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>30</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>M.Sc.(Physics)</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>30</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>Under P.T.U., Kapurthalla</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>Civil Engineering</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>60</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>M.Sc.(Chemistry)</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>30</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>Under P.T.U., Kapurthalla</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>Computer Science &amp; Engineering</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>120</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>B.Sc.Agriculture (Hons)</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>60</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>Under P.T.U., Kapurthalla</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>Electronics &amp; Communication Engineering</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>60</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>BCA</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>60</strong>
                                        </td>
                                        <td className="table-body-padd-V">
                                            <strong>Under P.T.U., Kapurthalla</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-body-padd-V">
                                            <strong>Mechanical Engineering</strong>
                                        </td>
                                        <td className="table-body-padd-V" colSpan="4">
                                            <strong>120</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p className="text-justify p-lh">
                            The college has progressed tremendously infrastructure wise as well. At present College is
                            having 4 Academic Blocks, Hostels(4 Boys & 1 Girls) 157 Houses and Play Grounds(Lawn Tennis,
                            Basketball, Volley Ball, Badminton, Hockey, Cricket, Hand Ball, Athletic Meet) besides
                            indoor games such as Table Tennis, Gym, etc.
                        </p>
                        <p className="text-justify p-lh">
                            The college is fully equipped with state of art Labs in all the disciplines and having
                            independent Computer Labs in each department beside the Central Computer Centre. The college
                            is having total of 412 PCs The College is having independent Internet Connectivity of 12
                            Mbps pure lease line. The whole campus is networked and Internet facility is available in
                            Offices/Labs/Deptts and Hostels as well. The college is having Central Library with over
                            25,000 Books, E-Journals and A.C. Reading Hall. The college is having Central Workshop which
                            caters to the needs of all the Departments.
                        </p>
                        <p className="text-justify p-lh">
                            The college is having Bank with ATM facility, shopping complex, Nescafe, Cafeteria and
                            Dispensary fully equipped.
                        </p>
                        <p className="text-justify p-lh">
                            The students are imparted instructions in various disciplines, Apart from this, they are
                            associated with various activities like Sports/NSS/NCC/Technical Activities/ Cultural
                            Activities/ Tours etc.
                        </p>
                        <p className="text-justify p-lh">
                            The college is also having Independent Training & Placement Cell which is looking after the
                            Training and Placement of students in various Organizations.
                        </p>
                        <p className="text-justify p-lh">
                            The College is also engaged in Research and Consultancy services to the Industry in various
                            areas.
                        </p>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default AboutPage;