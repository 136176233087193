import NavBar2 from "../../pages/components/NavBar2";
import BreadCrumbSection from "../../pages/components/BreadCrumbSection";
import React from "react";
import {Link} from "react-router-dom"

const RegDetails = () => {
    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Registration 2024 | SBSSU" bdLink="Registration"/>

            <div className="section3">
                <div className="container pt-90">
                    <h3 className={"my-font-style my-bold-font"}>
                        Registration
                    </h3>
                    <div className="double-line-bottom-theme-colored-2"></div>

                    <div className="container">
                        <div className="alert alert-danger">
                            <h4 style={{color: '#000'}}>You can register for admission at Sardar Beant Singh State
                                University, Gurdaspur in your
                                preferred course.
                                Please pay pre-registration fee of Rs. 10000/- (Rs. 2000/- for Punjab resident SC Low
                                Income
                                students whose parents are having less than 2,50,000/- annual income).
                            </h4>

                            <h5 style={{margin: '35px 0'}}><b>Note</b>: The Pre-registration fee will be adjusted in the
                                admission fee once you get
                                admitted
                                in the university. In case, you change your mind and do not apply for admission or do
                                not
                                qualify to get admission in this university, this fee will be fully refunded to you.
                            </h5>

                            <h5>
                                <i> Once you <a style={{textDecoration: 'underline', color: '#C21717'}}
                                                href="http://sbssugsp.ac.in:8000/student" target="_blank">Login</a> to
                                    the admission
                                    portal by clicking the link we request you
                                    to:-</i>
                            </h5>

                            <ol>
                                <li>Pay Your Counseling Fees</li>
                                <li>Upload Your Preferences</li>
                                <li>Upload your Marks/Grades (As soon as your result gets declared)</li>
                            </ol>

                            <h5 style={{marginTop: '35px'}}>
                                Use the following link to register for admission. Once you submit the registration form by clicking the link below, you must login to the
                                admission portal using the id and password sent to your e-mail.
                            </h5>

                            <Link style={{textDecoration: 'underline', color: '#C21717'}} to="/Pre-Registration">
                                Online Registration
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default RegDetails