import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import axios from "axios";
import {URL} from "../../pages/components/MyModules";
import Swal from "sweetalert2";

const PreRegForm = ({degreeType}) => {
    let navigate = useNavigate();

    let {
        register,
        handleSubmit,
        formState: {errors},
        reset,
        trigger,
        setFocus,
        clearErrors,
        setError
    } = useForm(); // React Hook Form
    // console.log(errors)

    let [gender, setGender] = useState("Male");
    const HandleGender = (e) => {
        setGender(e.target.value);
    }

    /* To Show Same Correspondence Address in Permanent Address */
    let [cAddress, setCAddress] = useState("");
    let [showSameAddress, setShowSameAddress] = useState(true);

    const isCheckboxClicked = (e) => {
        // console.log(e.target.checked)
        if (e.target.checked) {
            // setFocus("address_permanent");
            // clearErrors("address_permanent")
            setShowSameAddress(e.target.checked);
        } else {
            setShowSameAddress(e.target.checked);
        }
    }

    /* Toggle Aadhaar Number TextBox */
    let [showAadhaar, setShowAadhaar] = useState(false);
    const handleToggleAadhaar = (e) => {
        if (e.target.value === "SC (Low Income)") {
            if (e.target.checked) {
                setShowAadhaar(true);
            } else {
                setShowAadhaar(false);
            }
        }
    }

    const [quotaPunjab, setQuotaPunjab] = useState(false);
    const [quotaOutside, setQuotaOutside] = useState(false);

    /* Check Residence of Student  */
    const CheckResidence = (e) => {
        if (e.target.value === "") {
            setFocus("punjab_outside");
            setQuotaOutside(false);
            setQuotaPunjab(false);
        }

        if (e.target.value === "Punjab Residence") {
            setQuotaOutside(false);
            setQuotaPunjab(true);
        }

        if (e.target.value === "Outside Punjab") {
            setQuotaPunjab(false);
            setQuotaOutside(true);
        }
    }

    /* Disable Button When Form is Submitted Once  */
    let [disableButton, setDisableButton] = useState(false);

    // -----------------------------------------------------------------------

    let [courses, setCourse] = useState([]);
    const GetCoursesList = (e) => {
        let degreeID = e.target.value;

        if (degreeID !== "") {
            clearErrors("degName")
            // console.log(e.target.value);
            axios.get(`${URL}getCourseList?id=${degreeID}`)
                .then(res => {
                    // console.log(res.data.data);
                    setCourse([]);
                    setTimeout(async () => {
                        await setCourse(res.data.data);
                    }, 10);
                }).catch(err => {
                console.log(err);
            });
        } else {
            setError("degName", {
                type: "required",
                message: "*This field is Required.",
            })
            setCourse([]);
        }
    }

    // -----------------------------------------------------------------------

    const checkStudentQuotaIs_SCLowIncome = (data) => {
        // console.log(data);
        let isSCLowIncome = data.checkbox?.includes("SC (Low Income)")
        if (isSCLowIncome && !data.aadhaar) {
            setFocus("aadhaar")
            setError("aadhaar", {
                type: "required",
                message: "Aadhaar Number is required",
            })
            return true
        }
        return false
    }

    const checkPhoto = (data) => {
        if (!data.photo[0]) {
            setFocus("photo")
            setError("photo", {
                type: "required",
                message: "*This field is Required.",
            })
            return true
        }
        return false
    }

    const checkSignature = (data) => {
        if (!data.signature[0]) {
            setFocus("signature")
            setError("signature", {
                type: "required",
                message: "*This field is Required.",
            })
            return true
        }
        return false
    }

    // const checkSignatureDimensions = (data) => {
    //     if (data.signature[0]) {
    //         const reader = new FileReader();
    //         reader.onload = function (e) {
    //             const img = new Image();
    //
    //             img.onload = function () {
    //                 const width = img.width;
    //                 const height = img.height;
    //                 // console.log(width, height)
    //
    //                 // Check the width and height as per your requirements
    //                 if (width > 400 || height > 200) {
    //                     // alert("Image dimensions must not exceed 400x200 pixels.");
    //                     setFocus("signature")
    //                     setError("signature", {
    //                         type: "pattern",
    //                         message: "*Image dimensions must not exceed 400x200 pixels.",
    //                     })
    //                     // input.value = "";  // Reset the input to clear the selected file
    //                 } else {
    //                     // Image is within acceptable dimensions, you can proceed with the upload
    //                     // For example, you can send the file to the server here
    //                     // console.log("Image dimensions are within the acceptable range.");
    //                 }
    //             };
    //             img.src = e.target.result;
    //         };
    //         reader.readAsDataURL(data.signature[0]);
    //     }
    // }

    const checkSignatureDimensions = (data) => {
        if (data.signature[0]) {
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.onload = function (e) {
                    const img = new Image();

                    img.onload = function () {
                        const width = img.width;
                        const height = img.height;

                        // Check the width and height as per your requirements
                        if (width > 400 || height > 200) {
                            // console.log("****")
                            reject(false);
                        } else {
                            resolve(true);
                        }
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(data.signature[0]);
            });
        } else {
            // console.log("****")
            return Promise.resolve(false); // Handle the case when no signature is provided
        }
    };

    // -----------------------------------------------------------------------

    /* Submit This Form  */
    const onSubmit = async (data) => {
        try {
            await checkSignatureDimensions(data)
            // const signatureValidation = await checkSignatureDimensions(data)
            // console.log(signatureValidation)
        } catch (e) {
            // console.log(e)
            setFocus("signature");
            setError("signature", {
                type: "pattern",
                message: "*Image dimensions must not exceed 400x200 pixels.",
            });
            return false
        }
        // console.log("******")

        try {
            data['gender'] = gender
            data['isSameAddress'] = showSameAddress

            if (checkStudentQuotaIs_SCLowIncome(data)) { // check SC (Low Income) or other quota
                return false
            }

            if (checkPhoto(data)) { // check photo / signature uploaded or not
                return false
            }

            if (checkSignature(data)) {  // check photo / signature uploaded or not
                return false
            }

            let formData = new FormData()
            formData.append("photo", data.photo[0])
            formData.append("signature", data.signature[0])
            formData.append("otherDetails", JSON.stringify(data))
            setDisableButton(true); // Set Button Disable

            const response = await axios.post(`${URL}pre-registration`, formData)
            const {resCode, message} = response.data
            if (resCode === 1) {
                Swal.fire({icon: "error", title: message});
            } else if (resCode === 2) {
                reset();
                Swal.fire({icon: "success", title: message});
                setTimeout(() => navigate("/Thank-You"), 100);
                setTimeout(() => window.location.reload(), 2000);
            } else {
            }

            setDisableButton(false); // Set Button Enable Again
        } catch (e) {
            Swal.fire({icon: "error", title: e.message});
        }
    }

    // -----------------------------------------------------------------------

    const isCorrespondenceAddressIsProvided = (address) => {
        if (address === "") {
            setError("address_correspondence", {type: "required", message: "*This field is Required.",})
        } else {
            if (address.includes('<') || address.includes('>')) {
                setError("address_correspondence", {
                    type: "required",
                    message: "Address cannot contain the '<' or '>' character.",
                })
            } else {
                clearErrors("address_correspondence")
            }
        }
        // console.log(address)
        setCAddress(address)
    }

    // -----------------------------------------------------------------------

    return (
        <>
            <div className="mb-35">
                <h5>
                    <span className="text-danger"><b>*</b></span> Indicates a required field.
                </h5>
            </div>

            <div id="message"></div>

            {/* -- FORM -- */}
            <form onSubmit={handleSubmit(onSubmit)} id="studentAdmission">
                {/* ROW 1 -- [Degree Type / Course] */}
                <div className="row">
                    <div className="col-md-6 mb-12">
                        <label htmlFor="degName">
                            Degree Type <span className="text-danger"><b>*</b></span>
                        </label>
                        <select {...register('degName', {required: true})}
                                name="degName" id="degName" onChange={GetCoursesList}
                                className={`form-control ${errors.degName && "invalid-field"}`}>
                            <option value="">Please Select Degree Type</option>
                            {degreeType.map((value, index) => {
                                return <option key={index}
                                               value={value.degree_id}>{value.degree_name}</option>
                            })}
                        </select>

                        {errors.degName?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                    </div>

                    <div className="col-md-6 mb-12">
                        <label htmlFor="course">
                            Course <span className="text-danger"><b>*</b></span>
                        </label>
                        <select {...register('course', {required: true})}
                                name="course" id="course"
                                className={`form-control ${errors.course && "invalid-field"}`}>
                            <option value="">Please Select Course</option>
                            {courses.map((value, index) => {
                                return <option key={index}
                                               value={value.course_category}>
                                    {value.course_category}
                                </option>
                            })}
                        </select>

                        {errors.course?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}
                    </div>
                </div>

                {/* ROW 2 -- [Full Name  / Father's Name / Mother's Name] */}
                <div className="row">
                    {/* Full Name */}
                    <div className="col-md-4 mb-12">
                        <label htmlFor="name">
                            Full Name <span className="text-danger"><b>*</b></span>
                        </label>
                        <input {...register('name', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Special characters and numbers are not allowed in this field."
                            }
                        })}
                               autoComplete="off" type="text" name="name"
                               id="name"
                               className={`form-control ${errors.name && "invalid-field"}`}
                        />

                        {errors.name?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.name?.type === 'pattern' &&
                            <span className="error-msg">{errors.name?.message}</span>}
                    </div>

                    {/* Father's Name */}
                    <div className="col-md-4 mb-12">
                        <label htmlFor="fathername">
                            Father's Name <span className="text-danger"><b>*</b></span>
                        </label>
                        <input {...register('fathername', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Special characters and numbers are not allowed in this field."
                            }
                        })}

                            // data-rule-required="true" onChange={HandleInputs}
                               autoComplete="off" type="text" name="fathername" id="fathername"
                               className={`form-control ${errors.fathername && "invalid-field"}`}
                               placeholder=""/>

                        {errors.fathername?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.fathername?.type === 'pattern' &&
                            <span className="error-msg">{errors.fathername?.message}</span>}
                    </div>

                    {/* Mother's Name */}
                    <div className="col-md-4 mb-12">
                        <label htmlFor="mothername">
                            Mother's Name <span className="text-danger"><b>*</b></span>
                        </label>
                        <input {...register('mothername', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Special characters and numbers are not allowed in this field."
                            }
                        })}

                            // onChange={HandleInputs} autoComplete="off" type="text"
                               name="mothername" id="mothername"
                               className={`form-control ${errors.mothername && "invalid-field"}`}
                               placeholder=""/>

                        {errors.mothername?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.mothername?.type === 'pattern' &&
                            <span className="error-msg">{errors.mothername?.message}</span>}
                    </div>
                </div>

                {/* ROW 3 -- [Photo / Signature] */}
                <div className="row">
                    {/* Photo */}
                    <div className="col-md-6 mb-12">
                        <label htmlFor="photo">
                            Photo <span className="text-danger"><b>*</b></span>
                        </label>

                        <input {...register('photo', {
                            required: true
                        })}
                               type="file" name="photo" id="photo" accept="image/jpeg, image/png"
                               className={`form-control ${errors.photo && "invalid-field"}`}
                        />

                        {errors.photo?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}
                    </div>

                    {/* Signature */}
                    <div className="col-md-6 mb-12">
                        <label htmlFor="signature">
                            Signature <span className="text-danger"><b>*</b></span>
                        </label>

                        <input {...register('signature', {required: true})}
                               type="file" name="signature" id="signature" accept="image/jpeg, image/png"
                               className={`form-control ${errors.signature && "invalid-field"}`}
                        />

                        {errors.signature?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.signature?.type === 'pattern' &&
                            <span className="error-msg">{errors.signature?.message}</span>}
                    </div>
                </div>

                {/* [Punjab Residence / Outside Punjab] */}
                <div className="row">
                    <div className="col-md-12 mb-12">
                        <label htmlFor="punjab_outside">
                            Please Select Your Residential Status&nbsp;
                            <span className="text-danger"><b>*</b></span>
                        </label>
                        <select {...register('punjab_outside',
                            {
                                required: true,
                                onChange: (e) => CheckResidence(e)
                            }
                        )} name="punjab_outside" id="punjab_outside"
                                className={`form-control ${errors.punjab_outside && "invalid-field"}`}>
                            <option value="">Please Select</option>
                            <option value="Punjab Residence">Punjab Residence</option>
                            <option value="Outside Punjab">Outside Punjab</option>
                        </select>

                        {errors.punjab_outside?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}
                    </div>
                </div>

                {quotaPunjab &&
                    <>
                        <div className="container-fluid">
                            <div className="row"
                                 style={{
                                     padding: "15px 0 6px 0",
                                     marginBottom: "30px",
                                     borderRadius: "4px",
                                     backgroundColor: "#ecf0f1"
                                 }}>

                                <div className="col-md-12" style={{marginBottom: "10px"}}>
                                    <label>
                                        Please Select <span
                                        className="text-danger"><b>*</b></span>
                                    </label>
                                </div>

                                <div className="col-md-12 mb-md-3 mb-sm-0">
                                    <div className="row">
                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {
                                                    required: "Please any select Language",
                                                    onChange: (e) => handleToggleAadhaar(e)
                                                }
                                            )} id="SC(Low Income)" type="checkbox"
                                                   value="SC (Low Income)"
                                                   className="mr-3"/>
                                            <label htmlFor="SC(Low Income)">SC (Low
                                                Income)</label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {
                                                    required: "Please any select Language"
                                                }
                                            )} id="BC" type="checkbox" value="BC"
                                                   className="mr-3"/>
                                            <label htmlFor="BC">BC</label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="BorderArea" type="checkbox"
                                                   value="Border Area"
                                                   className="mr-3"/>
                                            <label htmlFor="BorderArea">Border Area</label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="BackArea" type="checkbox"
                                                   value="Back Area"
                                                   className="mr-3"/>
                                            <label htmlFor="BackArea">Back Area</label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="SportsPerson" type="checkbox"
                                                   value="Sports Person"
                                                   className="mr-3"/>
                                            <label htmlFor="SportsPerson">Sports Person</label>
                                        </div>
                                    </div>
                                </div>

                                {/*<div className="col-md-2">*/}
                                {/*    <input {...register("checkbox", {*/}
                                {/*        required: "Please any select Language",*/}
                                {/*        onChange: (e) => handleToggleAadhaar(e)*/}
                                {/*    })}*/}
                                {/*           id="SCLowIncome" type="checkbox"*/}
                                {/*           value="SC (Low Income)"*/}
                                {/*           className="mr-3"/>*/}
                                {/*    <label htmlFor="SCLowIncome">SC (Low Income)</label>*/}
                                {/*</div>*/}


                                <div className="col-md-12 mb-md-3 mb-sm-0">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="FreedomFighter" type="checkbox"
                                                   value="Child/Grand Children of Freedom Fighter"
                                                   className="mr-3"/>
                                            <label htmlFor="FreedomFighter">
                                                Child/Grand Children of Freedom Fighter
                                            </label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="Defence" type="checkbox"
                                                   value="Defence / Ex-Serv." className="mr-3"/>
                                            <label htmlFor="Defence">
                                                Defence / Ex-Serv.
                                            </label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="ParamilitaryPersons" type="checkbox"
                                                   value="Paramilitary Persons"
                                                   className="mr-3"/>
                                            <label htmlFor="ParamilitaryPersons">
                                                Paramilitary Persons
                                            </label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="GirlChild" type="checkbox"
                                                   value="Single Girl Child" className="mr-3"/>
                                            <label htmlFor="GirlChild">
                                                Single Girl Child
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-md-3 mb-sm-0">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="Blind" type="checkbox"
                                                   value="Blind (Disabled Person)"
                                                   className="mr-3"/>
                                            <label htmlFor="Blind">
                                                Blind (Disabled Person)
                                            </label>
                                        </div>

                                        <div className="col-lg-3">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="Deaf" type="checkbox"
                                                   value="Deaf & Dumb (Disabled Person)"
                                                   className="mr-3"/>
                                            <label htmlFor="Deaf">
                                                Deaf & Dumb (Disabled Person)
                                            </label>
                                        </div>

                                        <div className="col-lg-3">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="OtherDisability" type="checkbox"
                                                   value="Other Disability (Disabled Person)"
                                                   className="mr-3"/>
                                            <label htmlFor="OtherDisability">
                                                Other Disability (Disabled Person)
                                            </label>
                                        </div>

                                        <div className="col-lg-2">
                                            <input {...register("checkbox", {required: "Please any select Language"})}
                                                   id="TsunamiVictims" type="checkbox"
                                                   value="Tsunami Victims" className="mr-3"/>
                                            <label htmlFor="TsunamiVictims">
                                                Tsunami Victims
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mb-md-3 mb-sm-0">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <input {...register("checkbox", {required: "Please select any value."})}
                                                   id="Terr_Victims" type="checkbox"
                                                   value="Victims of Terr. Viol. (1984 Riots)"
                                                   className="mr-3"/>
                                            <label htmlFor="Terr_Victims">
                                                Victims of Terr. Viol. (1984 Riots)
                                            </label>
                                        </div>

                                        <div className="col-lg-3">
                                            <input {...register("checkbox", {required: "Please select any value."})}
                                                   id="SC/ST" type="checkbox"
                                                   value="SC/ST"
                                                   className="mr-3"/>
                                            <label htmlFor="SC/ST">
                                                SC/ST
                                            </label>
                                        </div>

                                        <div className="col-lg-3">
                                            <input {...register("checkbox", {required: "Please select any value."})}
                                                   id="General" type="checkbox"
                                                   value="General"
                                                   className="mr-3"/>
                                            <label htmlFor="General">
                                                General
                                            </label>
                                        </div>

                                        <div className="col-md-12">
                                            {errors.checkbox?.type === 'required' &&
                                                <span
                                                    className="error-msg mb-12">*Please select any value.</span>}
                                        </div>
                                    </div>
                                </div>

                                {/*</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </>
                }

                {quotaOutside &&
                    <>
                        <div className="container-fluid">
                            <div className="row" style={{
                                padding: "15px 0 6px 0",
                                marginBottom: "30px",
                                borderRadius: "4px",
                                backgroundColor: "#ecf0f1"
                            }}>
                                <div className="col-md-12" style={{marginBottom: "10px"}}>
                                    <label>
                                        Please Select <span
                                        className="text-danger"><b>*</b></span>
                                    </label>
                                </div>

                                <div className="col-md-2">
                                    <input {...register("checkbox", {required: "Please any select Language"})}
                                           id="SC_Outside" type="checkbox"
                                           value="SC"
                                           className="mr-3"/>
                                    <label htmlFor="SC_Outside">SC</label>
                                </div>

                                <div className="col-md-2">
                                    <input {...register("checkbox", {required: "Please any select Language"})}
                                           id="BC_Outside" type="checkbox" value="BC"
                                           className="mr-3"/>
                                    <label htmlFor="BC_Outside">BC</label>
                                </div>

                                <div className="col-md-2">
                                    <input {...register("checkbox", {required: "Please any select Language"})}
                                           id="General_Outside" type="checkbox"
                                           value="General"
                                           className="mr-3"/>
                                    <label htmlFor="General_Outside">General</label>
                                </div>

                                <div className="col-md-12">
                                    {errors.checkbox?.type === 'required' &&
                                        <span
                                            className="error-msg mb-12">*Please select any value.</span>}
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* Hidden Field [Aadhaar Number] */}
                {/*{showAadhaar &&*/}
                <div className="row">
                    <div className="col-md-12 mb-12">
                        <label htmlFor="aadhaar">Aadhaar Number (Compulsory for SC (Low
                            Income)
                            category, optional for others)</label>
                        <input {...register('aadhaar', {
                            // required: true,
                            // required: {
                            //     value: true,
                            //     message: "Aadhaar Number is required"
                            // },
                            minLength: {
                                value: 12,
                                message: "Aadhaar Number may not be less than 12 characters"
                            },
                            maxLength: {
                                value: 12,
                                message: "Aadhaar Number may not exceed 12 characters"
                            },
                            pattern: {
                                value: /^[0-9]*$/,
                                message: "Only numbers are allowed."
                            }
                        })}
                               onKeyUp={() => {
                                   trigger("aadhaar");
                               }}
                            // onChange={HandleInputs}
                               autoComplete="off"
                               type="text"
                            // type="number"
                               name="aadhaar" id="aadhaar"
                               className={`form-control ${errors.aadhaar && "invalid-field"}`}
                               placeholder=""/>

                        {errors.aadhaar?.type === 'required' &&
                            <span className="error-msg">{errors.aadhaar?.message}</span>
                        }

                        {errors.aadhaar?.type === "maxLength" &&
                            <span className="error-msg">{errors.aadhaar?.message}</span>
                        }

                        {errors.aadhaar?.type === "minLength" &&
                            <span className="error-msg">{errors.aadhaar?.message}</span>
                        }

                        {errors.aadhaar?.type === "pattern" &&
                            <span className="error-msg">{errors.aadhaar?.message}</span>
                        }
                    </div>
                </div>
                {/*}*/}

                {/* ROW 4 [Date of Birth / Gender] */}
                <div className="row">
                    {/* DOB */}
                    <div className="col-md-6 mb-12">
                        <label htmlFor="dob">
                            Date of Birth (All as per Matric
                            Certificate) <span
                            className="text-danger"><b>*</b></span></label>
                        <input {...register('dob', {
                                required: true,
                                pattern: {
                                    value: /^\d{4}-\d{2}-\d{2}$/,
                                    message: "*Please enter a date in the format MM-DD-YYYY"
                                }
                            }
                        )}
                               type="date" name="dob" id="dob"
                               className={`form-control ${errors.dob && "invalid-field"}`}
                        />

                        {errors.dob?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.dob?.type === 'pattern' &&
                            <span className="error-msg">{errors.dob?.message}</span>}
                    </div>

                    {/* Gender */}
                    <div className="col-md-6 mb-12">
                        <label>
                            Gender <span className="text-danger"><b>*</b></span>
                        </label> <br/>

                        <input onChange={(e) => HandleGender(e)}
                               defaultChecked={true}
                               type="radio" name="gender" id="male"
                               value="Male" className="mr-3"/>
                        <label htmlFor="male" className="mr-8">Male</label>

                        <input onChange={(e) => HandleGender(e)}
                               type="radio" name="gender" id="female" value="Female"
                               className="mr-3"/>
                        <label htmlFor="female" className="mr-8">Female</label>

                        <input onChange={(e) => HandleGender(e)}
                               type="radio" name="gender" id="others" value="Transgender/Others"
                               className="mr-3"/>
                        <label htmlFor="others">Transgender/Others</label>
                    </div>
                </div>

                {/* ROW 5 [Mobile Number / Email] */}
                <div className="row">
                    <div className="col-md-6 mb-12">
                        <label htmlFor="mobile">
                            Mobile Number <span className="text-danger"><b>*</b></span>
                        </label>
                        <input {...register('mobile', {
                            required: true,
                            minLength: {
                                value: 10,
                                message: "Mobile Number may not be less than 10 characters"
                            },
                            maxLength: {
                                value: 10,
                                message: "Mobile Number may not exceed 10 characters"
                            },
                            pattern: {
                                value: /^[0-9]*$/,
                                message: "Only numbers are allowed."
                            }
                        })}

                               onKeyUp={() => trigger("mobile")}

                            // onChange={HandleInputs}
                               autoComplete="off" type="tel"
                               name="mobile"
                               id="mobile"
                               className={`form-control ${errors.mobile && "invalid-field"}`}
                               placeholder=""/>

                        {errors.mobile?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.mobile?.type === "maxLength" &&
                            <span className="error-msg">{errors.mobile?.message}</span>
                        }

                        {errors.mobile?.type === "minLength" &&
                            <span className="error-msg">{errors.mobile?.message}</span>
                        }

                        {errors.mobile?.type === "pattern" &&
                            <span className="error-msg">{errors.mobile?.message}</span>}
                    </div>

                    <div className="col-md-6 mb-12">
                        <label htmlFor="email">
                            Email <span className="text-danger"><b>*</b></span>
                        </label>
                        <input {...register('email', {
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid email address."
                            }
                        })}
                               onKeyUp={() => {
                                   trigger("email");
                               }}
                            // onChange={HandleInputs}
                               autoComplete="off" type="email" name="email" id="email"
                               className={`form-control ${errors.email && "invalid-field"}`}
                               placeholder=""/>

                        {errors.email?.type === 'required' &&
                            <span className="error-msg">*This field is Required.</span>}

                        {errors.email?.type === "pattern" &&
                            <span className="error-msg">{errors.email?.message}</span>}
                    </div>
                </div>

                {/* ROW 6 [Correspondence Address / Permanent Address]*/}
                <div className="row">
                    {/* Correspondence Address */}
                    <div className="col-md-12 mb-12">
                        <label htmlFor="address_correspondence">
                            Correspondence Address <span
                            className="text-danger"><b>*</b></span>
                        </label>
                        <textarea {...register('address_correspondence', {
                            required: {
                                value: true,
                                message: "*This field is Required."
                            },
                        })}

                                  onChange={(e) => isCorrespondenceAddressIsProvided(e.target.value)}
                            // onChange={(e) => setCAddress(e.target.value)}
                                  autoComplete="off"
                                  className={`form-control textarea-resize ${errors.address_correspondence && "invalid-field"}`}
                                  name="address_correspondence" id="address_correspondence"
                                  rows="6" placeholder=""/>

                        {errors.address_correspondence?.type === 'required' &&
                            <span className="error-msg">
                                {errors.address_correspondence?.message}
                            </span>
                        }

                        {errors.address_correspondence?.type === 'validate' &&
                            <span className="error-msg">
                                {errors.address_correspondence?.message}
                            </span>
                        }
                    </div>

                    {/* Permanent Address */}
                    <div className="col-md-12 mb-12">
                        <label htmlFor="address_permanent">
                            Permanent Address <span className="text-danger"><b>*</b></span>
                        </label>
                        <p>
                            <input onChange={(e) => isCheckboxClicked(e)}
                                // onChange={() => setShowSameAddress(!showSameAddress)}
                                   type="checkbox" name="sameAddress" id="sameAddress" defaultChecked
                            />&nbsp;
                            <i style={{fontSize: "16px"}}>Same as Correspondence Address</i>
                        </p>

                        {
                            showSameAddress ?

                                <textarea {...register('address_permanent')}
                                          autoComplete="off"
                                          className="form-control textarea-resize"
                                          name="address_permanent"
                                          id="address_permanent" rows="6"
                                          readOnly disabled
                                          value={cAddress}
                                />

                                :
                                <>
                                                             <textarea {...register('address_permanent',
                                                                 {required: true})
                                                                       }
                                                                       autoComplete="off"
                                                                       className={`form-control textarea-resize ${errors.address_permanent && "invalid-field"}`}
                                                                       name="address_permanent"
                                                                       id="address_permanent" rows="6" defaultValue={""}
                                                             />

                                    {errors.address_permanent?.type === 'required' &&
                                        <span
                                            className="error-msg">*This field is Required.</span>}
                                </>
                        }
                    </div>
                </div>

                {/* ROW 7 [District / State] */}
                <div className="row">
                    <div className="col-md-6 mb-12">
                        <label htmlFor="district">
                            District <span className="text-danger"><b>*</b></span>
                        </label>
                        <input type="text" autoComplete="off"
                               name="district" id="district"
                               className={`form-control ${errors.district && "invalid-field"}`}
                               {...register('district', {
                                   required: {
                                       value: true,
                                       message: "*This field is Required."
                                   },
                                   pattern: {
                                       value: /^[a-zA-Z\s]+$/,
                                       message: "Special characters and numbers are not allowed in this field."
                                   }
                               })}
                        />

                        {errors.district?.type === 'required' &&
                            <span className="error-msg">{errors.district?.message}</span>}

                        {errors.district?.type === 'pattern' &&
                            <span className="error-msg">{errors.district?.message}</span>}
                    </div>

                    <div className="col-md-6 mb-12">
                        <label htmlFor="state">
                            State <span className="text-danger"><b>*</b></span>
                        </label>
                        <input type="text" autoComplete="off"
                               name="state" id="state"
                               className={`form-control ${errors.state && "invalid-field"}`}
                               {...register('state', {
                                   required: {
                                       value: true,
                                       message: "*This field is Required."
                                   },
                                   pattern: {
                                       value: /^[a-zA-Z\s]+$/,
                                       message: "Special characters and numbers are not allowed in this field."
                                   }
                               })}
                        />

                        {errors.state?.type === 'required' &&
                            <span className="error-msg">{errors.state?.message}</span>}

                        {errors.state?.type === 'pattern' &&
                            <span className="error-msg">{errors.state?.message}</span>}
                    </div>
                </div>

                {/* ROW 8 [Guardian's Mobile Number / Email ID]*/}
                <div className="row">
                    <div className="col-md-6 mb-12">
                        <label htmlFor="guardian_mobile">Father's/Guardian's Mobile Number
                            (Optional)</label>
                        <input {...register('guardian_mobile')}
                               autoComplete="off" type="tel"
                               name="guardian_mobile"
                               id="guardian_mobile"
                               className="form-control" placeholder=""/>
                    </div>

                    <div className="col-md-6 mb-12">
                        <label htmlFor="guardian_email">
                            Father's/Guardian's Email ID (Optional)
                        </label>
                        <input {...register('guardian_email')}
                            // onChange={HandleInputs}
                               autoComplete="off" type="email"
                               name="guardian_email"
                               id="guardian_email"
                               className="form-control" placeholder=""/>
                    </div>
                </div>

                {/* ROW 9 - Minority */}
                <div className="row">
                    <div className="col-md-12 mb-12">
                        <label style={{marginRight: '10px'}}>Do you belong to minority</label>

                        <input type="radio" {...register('minority')} id="minorityYes"
                               value="Yes" className="me-1"/>
                        <label htmlFor="minorityYes" style={{marginLeft: '2px', marginRight: '6px'}}>Yes</label>

                        <input type="radio" {...register('minority')} id="minorityNo"
                               defaultChecked value="No" className="me-1"/>
                        <label htmlFor="minorityNo" style={{marginLeft: '2px'}}>No</label>
                    </div>
                </div>

                {/* ROW 10 - Hosteller */}
                <div className="row">
                    <div className="col-md-12 mb-12">
                        <label style={{marginRight: '10px'}}>Do you wish to avail hostel</label>

                        <input type="radio" {...register('hosteller')} id="hostellerYes"
                               value="hosteller" className="me-1"/>
                        <label htmlFor="hostellerYes" style={{marginLeft: '2px', marginRight: '6px'}}>Yes</label>

                        <input type="radio" {...register('hosteller')} id="hostellerNo"
                               defaultChecked value="day scholar" className="me-1"/>
                        <label htmlFor="hostellerNo" style={{marginLeft: '2px'}}>No</label>
                    </div>
                </div>

                {/* ROW 11 - Participate in counselling */}
                <div className="row">
                    <div className="col-md-12">
                        <label style={{marginRight: '10px'}}>
                            Do you want to be automatically participate in counseling
                        </label>

                        <input type="radio" {...register('participate_in_counselling')} id="counsellingYes"
                               defaultChecked value="Yes" className="me-1"/>
                        <label htmlFor="counsellingYes" style={{marginLeft: '2px', marginRight: '6px'}}>Yes</label>

                        <input type="radio" {...register('participate_in_counselling')} id="counsellingNo"
                               value="No" className="me-1"/>
                        <label htmlFor="counsellingNo" style={{marginLeft: '2px'}}>No</label>
                    </div>
                </div>

                {/* ROW 12 - Button */}
                <div className="row">
                    <div className="col-md-6 mt-20">
                        <button disabled={disableButton} type="submit" value="SEND"
                                id="submit" className="btn btn-primary" style={{width: '100%'}}>
                            {disableButton ?
                                <span className='spinner-border'></span> : "Submit"
                            }
                        </button>
                    </div>

                    <div className="col-md-6 mt-20">
                        <button disabled={disableButton} onClick={() => reset()} style={{width: '100%'}}
                                type="button" value="SEND" id="submit" className="btn btn-primary"> Reset
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}
export default PreRegForm