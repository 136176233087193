import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const DistributionSeats = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Distribution of Seats Branch and Category Wise" subTitle="Sardar Beant State University ,Gurdaspur" bdLink="Distribution of Seats" />

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Distribution of Seats Branch and Category Wise</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className="table-responsive">
                        <table className={"table table-bordered"}>
                            <thead className="table-head-red-V">
                            <tr className="table-title-V text-center">
                                <th className={"text-center"} colSpan={"20"}>Seat Matrix First Year for session 2022-23</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th colSpan={"15"} className={"text-center"}>85% Category Seats</th>
                                <th colSpan={"3"} className={"text-center"}>15% Category Seats</th>
                            </tr>
                            <tr>
                                <th rowSpan={"3"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Branch</th>
                                <th rowSpan={"3"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Total Seats</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>SC/ST</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>BC</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Border Area</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Back Area</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Sports Person</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Children/Grand <br/>
                                    Children of Freedom<br/> Fighters </th>
                                <th colSpan={"3"} className={"text-center"}>Disabled <br/> Persons</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Defense /
                                   <br/> Ex- Serv. </th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Paramilitary
                                    <br/>Persons</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Victims of  Terr. <br/> Viol. (1984 Riots)</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Tsunami  Victims</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Single Girl Child</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Gen</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>SC/ST</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>BC</th>
                                <th rowSpan={"2"} className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Gen.</th>
                            </tr>
                            <tr>
                                <th className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Blind</th>
                                <th className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Deaf & Dumb</th>
                                <th className={"text-center"} style={{writingMode:"vertical-lr",transform:"rotate(180deg)"}}>Disability<br/> Other</th>
                            </tr>
                            <tr>
                                <th className={"text-center"} className={"text-center"}>25%</th>
                                <th className={"text-center"}>5%</th>
                                <th className={"text-center"}>2%</th>
                                <th className={"text-center"}>2%</th>
                                <th className={"text-center"}>2%</th>
                                <th className={"text-center"}>1%</th>
                                <th className={"text-center"}>1%</th>
                                <th className={"text-center"}>1%</th>
                                <th className={"text-center"}>3%</th>
                                <th className={"text-center"}>2%</th>
                                <th className={"text-center"}>2%</th>
                                <th className={"text-center"}>2%</th>
                                <th className={"text-center"}>1%</th>
                                <th className={"text-center"}>1%</th>
                                <th className={"text-center"}>50%</th>
                                <th className={"text-center"}>25%</th>
                                <th className={"text-center"}>5%</th>
                                <th className={"text-center"}>70%</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={"text-center"}>B. Tech. Bio Tech.</td>
                                <td className={"text-center"}>30</td>
                                <td className={"text-center"}>6</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>13</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Chemical Engg.</td>
                                <td className={"text-center"}>30</td>
                                <td className={"text-center"}>7</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>12</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Civil Engg.</td>
                                <td className={"text-center"}>60</td>
                                <td className={"text-center"}>13</td>
                                <td className={"text-center"}>3</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>25</td>
                                <td className={"text-center"}>2</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>7</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Computer Sc. and Engg.</td>
                                <td className={"text-center"}>210</td>
                                <td className={"text-center"}>44</td>
                                <td className={"text-center"}>9</td>
                                <td className={"text-center"}>4</td>
                                <td className={"text-center"}>4</td>
                                <td className={"text-center"}>4</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>2</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>5</td>
                                <td className={"text-center"}>4</td>
                                <td className={"text-center"}>3</td>
                                <td className={"text-center"}>4</td>
                                <td className={"text-center"}>2</td>
                                <td className={"text-center"}>2</td>
                                <td className={"text-center"}>90</td>
                                <td className={"text-center"}>8</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>22</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Elect and Comm. Engg.</td>
                                <td className={"text-center"}>60</td>
                                <td className={"text-center"}>	12</td>
                                <td className={"text-center"}>	3</td>
                                <td className={"text-center"}>		1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>		1</td>
                                <td className={"text-center"}>			0</td>
                                <td className={"text-center"}>		1	</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>		1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>26</td>
                                <td className={"text-center"}>	2	</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>		6</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Electrical Engg.	</td>
                                <td className={"text-center"}>60	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>		0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>		0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>25</td>
                                <td className={"text-center"}>	3	</td>
                                <td className={"text-center"}>0</td>
                                <td className={"text-center"}>	6</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Information Technology	</td>
                                <td className={"text-center"}>60</td>
                                <td className={"text-center"}>	13</td>
                                <td className={"text-center"}>	3</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>	2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>25</td>
                                <td className={"text-center"}>	3</td>
                                <td className={"text-center"}>	0</td>
                                <td className={"text-center"}>		6</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Tech. Mech. Engg.	</td>
                                <td className={"text-center"}>120	</td>
                                <td className={"text-center"}>26	</td>
                                <td className={"text-center"}>5	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>	1	</td>
                                <td className={"text-center"}>3</td>
                                <td className={"text-center"}>	2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>51	</td>
                                <td className={"text-center"}>4	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>13</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>M. Tech. Mech Engg	</td>
                                <td className={"text-center"}>25</td>
                                <td className={"text-center"}>	6	</td>
                                <td className={"text-center"}>	1</td>
                                <td className={"text-center"}>		1	</td>
                                <td className={"text-center"}>	0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>10	</td>
                                <td className={"text-center"}>1</td>
                                <td className={"text-center"}>	0	</td>
                                <td className={"text-center"}>	3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>M. Tech. Computer Sc. and Engg	</td>
                                <td className={"text-center"}>25</td>
                                <td className={"text-center"}>	5	</td>
                                <td className={"text-center"}>	1	</td>
                                <td className={"text-center"}>	0	</td>
                                <td className={"text-center"}>	1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>11	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Voc. RAC	</td>
                                <td className={"text-center"}>60	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>3	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>26	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>6</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>M.Sc. (Phy)	</td>
                                <td className={"text-center"}>30	</td>
                                <td className={"text-center"}>7	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>M.Sc. (Maths)	</td>
                                <td className={"text-center"}>30	</td>
                                <td className={"text-center"}>6	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>12	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>M.Sc. (Chem)	</td>
                                <td className={"text-center"}>30	</td>
                                <td className={"text-center"}>7	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>M.Sc. Phy (Integr.)	</td>
                                <td className={"text-center"}>30	</td>
                                <td className={"text-center"}>6	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>12	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>2</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>B. Sc. Agri.	</td>
                                <td className={"text-center"}>120	</td>
                                <td className={"text-center"}>26	</td>
                                <td className={"text-center"}>5	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>3	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>51	</td>
                                <td className={"text-center"}>4	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>13</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>Non-Medical	</td>
                                <td className={"text-center"}>60	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>3	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>26	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>7</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>MBA	</td>
                                <td className={"text-center"}>30	</td>
                                <td className={"text-center"}>6	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>3</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>BBA 	</td>
                                <td className={"text-center"}>60	</td>
                                <td className={"text-center"}>12	</td>
                                <td className={"text-center"}>3	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>25	</td>
                                <td className={"text-center"}>3	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>6</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>MCA	</td>
                                <td className={"text-center"}>60	</td>
                                <td className={"text-center"}>13	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>26	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>6</td>
                            </tr>
                            <tr>
                                <td className={"text-center"}>BCA	</td>
                                <td className={"text-center"}>60	</td>
                                <td className={"text-center"}>12	</td>
                                <td className={"text-center"}>3	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>1	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>26	</td>
                                <td className={"text-center"}>2	</td>
                                <td className={"text-center"}>0	</td>
                                <td className={"text-center"}>7</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default DistributionSeats;