import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";

const Dispensary = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Dispensary" subTitle="Sardar Beant State University ,Gurdaspur" bdLink="Contact Us" />

            <div className="section3">
                <div className="container pt-90">
                    <div className="row">
                        <h1 className="text-theme-colored2">Medical Facilities</h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                    </div>
                    <div className={"row"}>
                        <div className="col-lg-7 col-md-7">
                            <table className="table-hover table margin-25-V">
                                <thead className="table-head-red-V">

                                <th className="table-title-V">Sr. No.</th>
                                <th className="table-title-V">Name</th>
                                <th className="table-title-V">Designation</th>

                                </thead>
                                <tbody>
                                <tr>
                                    <td className="table-body-padd-V">1</td>
                                    <td className="table-body-padd-V">Dr. Navneet Singh Lalli (M.B.B.S.)</td>
                                    <td className="table-body-padd-V">Medical Officer</td>

                                </tr>

                                <tr>
                                    <td className="table-body-padd-V">2</td>
                                    <td className="table-body-padd-V">Sh. Gurnam Singh</td>
                                    <td className="table-body-padd-V">Pharmacy Officer</td>

                                </tr>
                                <tr>
                                    <td className="table-body-padd-V">3</td>
                                    <td className="table-body-padd-V">Karan Kumar</td>
                                    <td className="table-body-padd-V">Medical Attendant</td>

                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={"col-lg-5 col-md-5"}>
                        <span className={"mt-4"}></span>
                        <img src={"../assets/images/dispens.jpg"} className={"img img img-responsive img-rounded"}/>

                    </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-lg-12 col-md-12 col-sm-12"}>
                            <p className={"text-justify"}>
                                The college has a dispensary to provide medical facilities under the guidance and observance of a qualified doctor, Staff Nurse, pharmacist & Medical Attendant.
                                The dispensary provides medical aid to both students and staff. The dispensary of the college is well equipped, the various equipments in the health centre include Oxygen cylinder, Digital thermometer, Sterilizer, Auto Clave, Dressing drum, Weight measuring machine, Glucometer, B.P. Apparatus, patient bed, stretcher, screen etc.
                                Tata Sumo jeep is available to carry serious patients for consulting doctors in various hospital.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Dispensary;