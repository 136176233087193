import {Link} from "react-router-dom";
// import {GoLocation} from "react-icons/go";
import {FaHome} from "react-icons/fa";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationDot} from '@fortawesome/free-solid-svg-icons'
import NavLinks from "./NavLinks";
import {useEffect} from "react";



function Navbar(props) {

    useEffect(()=>{
        ToTop();
    },[])

    function ToTop(){
        window.scrollTo(0,0);
    }

    return (
        <>

            {/*<header className={props.headerClass == "" ?"header normal-header":"header normal-header default-header"} data-spy="affix" data-offset-top="197">*/}

            <header className={props.headerClass === "" ? "header normal-header" : "header normal-header default-header affix"}
                    data-spy="affix" data-offset-top="197">
                <div className="container">
                    {/* Section - 1 [LOGO] */}
                    <div className="row toprow" id="toprow">
                        <div className="col-md-8 col-sm-8 col-xs-12">
                            <Link to="/" className="toprowlogo">
                                <img src="assets/images/logo/beantunilogo.png" alt="Logo"/>
                            </Link>
                            <h4 className="toprowheading">Sardar Beant Singh State University</h4>
                        </div>

                        <div className="col-md-4 col-sm-4 col-xs-12 text-right divAddressBar">
                            <div className="row" title="" data-original-title="Click to view Location"
                                 data-toggle="tooltip" data-placement="bottom">
                                <div className="col-md-10 col-xs-10 iconAddress">
                                    <a href="https://goo.gl/maps/PcD5A4q66nmeUzsQ9" className="topMapLink"
                                       target="_blank">Sardar Beant Singh State
                                        University</a>
                                    <h5 className="topAddressLink">Gurdaspur, Punjab - 143530, India</h5>
                                </div>
                                <div className="col-md-2 col-xs-2 text-left iconLocation">
                                    {/*<GoLocation/>*/}
                                    <FontAwesomeIcon icon={faLocationDot}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Section - 2 [LINKS] */}
                    <NavLinks/>
                    {/*end nav*/}
                </div>
                {/*end container*/}
            </header>

        </>
    );
}

export default Navbar;