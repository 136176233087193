import {Link} from "react-router-dom";
import {FaHome} from "react-icons/fa";

const RightMenuLinks = (props) => {
    return (
        <>
            <div id="navbar" className="navbar-collapse collapse">
                <ul className={`nav navbar-nav ${props.cname}`}>
                    <li>
                        <Link title="Home Page" to="/"><FaHome/> Home</Link>
                    </li>

                    {/* Administration */}
                    <li className="dropdown yamm-fw">
                        <Link to="#" data-toggle="dropdown" className="dropdown-toggle">
                            Administration <b className="fa fa-angle-down"></b>
                        </Link>

                        <ul className="dropdown-menu">
                            <li>
                                <div className="yamm-content clearfix">
                                    <div className="row-fluid">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Leadership</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li>
                                                        <Link to="/chancellor">Chancellor</Link>
                                                    </li>
                                                    <li><Link to="/chairman">Chairman Board
                                                        of
                                                        Governor</Link>
                                                    </li>
                                                    <li><Link to="/vicechancellor">Vice
                                                        Chancellor</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/osd-to-vice-chancellor">OSD To Vice Chancellor</Link>
                                                    </li>
                                                    <li><Link
                                                        to="/registrar">Registrar</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Committees</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li>
                                                        <Link to="/boardofgovernors">Board of Governors</Link>
                                                    </li>
                                                    <li><
                                                        Link to="/financecomittee">Finance Committees</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/academic-council">Academic Council</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/grievanceredressalcell">Grievance Redressal
                                                            Cell</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/grievance">Student Grievance Redressal</Link>
                                                    </li>

                                                    {/*<li><Link to="/buildingworkcomittee">Building &*/}
                                                    {/*    Works*/}
                                                    {/*    Committees</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/equipmentcomittee">Equipment*/}
                                                    {/*    Store &amp; Purchase Committees</Link>*/}
                                                    {/*</li>*/}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Transparency</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li><Link to="/rti">Right to
                                                        Information</Link>
                                                    </li>
                                                    <li><a href="/assets/pdf/MandatoryDisclosure.pdf"
                                                           target="_blank">Mandatory
                                                        Disclosure</a>
                                                    </li>
                                                    <li><Link to="/underconstructionpage">University Bye
                                                        Law</Link>
                                                    </li>

                                                    <li><Link to="/university-establishment">
                                                        University Establishment
                                                    </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    {/* Academics */}
                    <li className="dropdown yamm-fw">
                        <Link to="#" data-toggle="dropdown" className="dropdown-toggle active">
                            Academics <b className="fa fa-angle-down"></b>
                        </Link>

                        <ul className="dropdown-menu">
                            <li>
                                <div className="yamm-content clearfix">
                                    <div className="row-fluid">
                                        {/* Departments */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Departments</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li><Link to="/DeptPhysics">Applied
                                                        Physics</Link>
                                                    </li>
                                                    <li><Link to="/DeptChemistryHumanitiesManagement">Applied
                                                        Chemistry,
                                                        Humanities &amp; Management</Link>
                                                    </li>
                                                    <li><Link to="/DeptAppliedMathematics">Applied
                                                        Mathematics</Link>
                                                    </li>
                                                    <li><Link to="/DeptChemicalEngineering">Chemical
                                                        Engineering</Link>
                                                    </li>
                                                    <li><Link to="/DeptComputerScienceEng">Computer
                                                        Science &
                                                        Engineering</Link>
                                                    </li>
                                                    <li><Link to="/DeptElectronicsCommEng">Electronics &
                                                        Communication Engineering</Link>
                                                    </li>
                                                    <li><Link
                                                        to="/DeptBioTechnology">Bio-Technology</Link>
                                                    </li>
                                                    {/*<li><Link to="/underconstructionpage">Information*/}
                                                    {/*    Technology</Link>*/}
                                                    {/*</li>*/}
                                                    <li><Link to="/DeptMechanicalEng">Mechanical
                                                        Engineering</Link>
                                                    </li>
                                                    <li><Link to="/DeptCivilEng">Civil
                                                        Engineering</Link>
                                                    </li>
                                                    <li><Link to="/DeptAgriculture">Agriculture
                                                        Science</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* General */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>General</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li>
                                                        <Link to="/DeanAcademicAffairs">Academic Section</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/ExamSection">Examination</Link>
                                                    </li>
                                                    <li>
                                                        <a href="/assets/pdf/Academic-Calander-2023-24.pdf"
                                                           target="_blank">
                                                            Academic Calendar
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/assets/pdf/academic/general/NIRF.pdf"
                                                           target="_blank">
                                                            NIRF
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="/assets/pdf/academic/general/AISHE.pdf"
                                                           target="_blank">
                                                            AISHE
                                                        </a>
                                                    </li>
                                                    {/*<li><Link to="/DeanAcademicAffairs">Dean of Academic Affairs*/}
                                                    {/*   </Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/distributionofseats">Distribution*/}
                                                    {/*    of*/}
                                                    {/*    Seats</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/underconstructionpage">Academic*/}
                                                    {/*    Section*/}
                                                    {/*    Staff</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/academicperforma">Academic*/}
                                                    {/*    Performa</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li>*/}
                                                    {/*    /!*<button onClick={window.open("./assets/pdf/UGManual.pdf")}>UG Manual</button>*!/*/}
                                                    {/*    <Link to="../assets/pdf/UGManual.pdf" target="_blank">UG*/}
                                                    {/*    Manual</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="../assets/pdf/PGManual.pdf" target="_blank">PG*/}
                                                    {/*    Manual</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="../assets/pdf/AcademicAutomonyLetter.pdf" target="_blank">Academic Autonomy Letter</Link>*/}
                                                    {/*</li>*/}
                                                </ul>
                                            </div>
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Results</p>
                                                <ul className="menu-item">
                                                    <li>
                                                        <Link to="/search-result-notification">
                                                            Search Result Notification
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        {/* Faculty */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Faculty</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li><Link to="/facultychm">Faculty
                                                    </Link>
                                                    </li>
                                                    {/*<li><Link to="/DeanAcademicAffairs">Dean of Academic Affairs*/}
                                                    {/*   </Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/distributionofseats">Distribution*/}
                                                    {/*    of*/}
                                                    {/*    Seats</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/underconstructionpage">Academic*/}
                                                    {/*    Section*/}
                                                    {/*    Staff</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="/academicperforma">Academic*/}
                                                    {/*    Performa</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li>*/}
                                                    {/*    /!*<button onClick={window.open("./assets/pdf/UGManual.pdf")}>UG Manual</button>*!/*/}
                                                    {/*    <Link to="../assets/pdf/UGManual.pdf" target="_blank">UG*/}
                                                    {/*    Manual</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="../assets/pdf/PGManual.pdf" target="_blank">PG*/}
                                                    {/*    Manual</Link>*/}
                                                    {/*</li>*/}
                                                    {/*<li><Link to="../assets/pdf/AcademicAutomonyLetter.pdf" target="_blank">Academic Autonomy Letter</Link>*/}
                                                    {/*</li>*/}
                                                </ul>
                                            </div>

                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Student Portal</p>
                                                <ul className="menu-item">
                                                    <li>
                                                        <a href="https://extranet.sbssugsp.ac.in/#/student-sign-in" target="_blank">
                                                            Sign in
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    {/* Campus Life */}
                    <li className="dropdown yamm-fw">
                        <Link to="#" data-toggle="dropdown" className="dropdown-toggle active">
                            Campus Life <b className="fa fa-angle-down"></b>
                        </Link>

                        <ul className="dropdown-menu">
                            <li>
                                <div className="yamm-content clearfix">
                                    <div className="row-fluid">
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                <p>Student's Corner</p>
                                                {/*<hr/>*/}
                                                <ul className="menu-item">
                                                    <li>
                                                        <a href="http://www.bcetgsp.ac.in/phoenix%20site/home.htm"
                                                           target="_blank">Pheonix Club</a>
                                                    </li>

                                                    <li><a href="http://www.bcetgsp.ac.in/apex%20site/aboutapex.html"
                                                           target="_blank">Apex Managemental Society</a>
                                                    </li>

                                                    <li>
                                                        <Link to="/punjabiculture">
                                                            Punjab Sabhyacharak Club
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <a href="http://www.bcetgsp.ac.in/FAC/index.php"
                                                           target="_blank">Fine Art Club</a>
                                                    </li>

                                                    <li><Link to="/sportsclub">Sports</Link></li>
                                                    <li><Link to="/NSS">NSS</Link></li>
                                                    <li><Link to="/NCC">NCC</Link></li>
                                                    <li><Link to="/iste">ISTE Student Chapter</Link></li>
                                                    <li><Link to="/MusicDramaticsClub">Music &
                                                        Dramatics</Link></li>
                                                    <li><Link to="/BioGenSociety">BioGen
                                                        Society</Link></li>
                                                    <li><Link to="/EDC">EDC
                                                        Club</Link>
                                                    </li>
                                                    <li><Link to="/SAE">S4E
                                                        Club</Link>
                                                    </li>
                                                    <li><
                                                        Link to="/EsterSociety">
                                                        Ester Society
                                                    </Link>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>

                                        {/* Central Facilities */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="row two-columns">
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>Central Facilities</p>
                                                    <ul className="menu-item">
                                                        <li>
                                                            <Link to="/computercenter">Computer Centre</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/library">Library</Link>
                                                        </li>
                                                        <li>
                                                            <a href="http://10.10.20.121:8081" target="_blank">Library OPAC</a>
                                                        </li>
                                                        <li>
                                                            <Link to="/workshop">Workshop</Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>Campus Amenities</p>
                                                    {/*<hr/>*/}
                                                    <ul className="menu-item">
                                                        <li><Link
                                                            to="/hostels">Hostels</Link>
                                                        </li>
                                                        <li><Link
                                                            to="/dispensary">Dispensary</Link>
                                                        </li>
                                                        <li><Link
                                                            to="/staffresidence">Staff
                                                            Residence</Link>
                                                        </li>
                                                        <li><Link
                                                            to="/shoppingcomplex">Shopping
                                                            Complex</Link>
                                                        </li>
                                                        <li><Link
                                                            to="/transportation">Transportation
                                                            Services</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="row two-columns">
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>Discipline</p>
                                                    {/*<hr/>*/}
                                                    <ul className="menu-item">
                                                        <li>
                                                            <Link to="/Anti-Ragging">Anti Ragging</Link>
                                                        </li>
                                                        {/*<li>*/}
                                                        {/*    <Link to="/under-construction">Anti Ragging Committee</Link>*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <Link to="/under-construction">Anti Ragging Squad</Link>*/}
                                                        {/*</li>*/}
                                                        {/*<li>*/}
                                                        {/*    <Link to="/under-construction">Ragging Monitoring*/}
                                                        {/*        Cell</Link>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>

                                                {/*<div className="col-md-12 col-sm-12 col-xs-12 ">*/}
                                                {/*<div className="col-sm-12 col-xs-12 text-center">*/}
                                                {/*<Link to="/admissionpage">*/}
                                                {/*    <img src="../assets/images/adm_pic.jpg" alt=""*/}
                                                {/*         className="admPic" style={{width: "100%"}}/>*/}
                                                {/*</Link>*/}
                                                {/*<button onClick={openURL}*/}
                                                {/*        className="btn theme-btn btn-block pd-6_12">Admissions*/}
                                                {/*    2022-23*/}
                                                {/*</button>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    {/* Admissions */}
                    <li className="dropdown yamm-fw">
                        <Link to="#" data-toggle="dropdown" className="dropdown-toggle">
                            Admissions <b className="fa fa-angle-down"></b>
                        </Link>
                        <ul className="dropdown-menu">
                            <li>
                                <div className="yamm-content clearfix">
                                    <div className="row-fluid">
                                        {/* Under Graduate */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="row two-columns">
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>Under Graduate</p>
                                                    {/*<hr/>*/}
                                                    <ul className="menu-item">
                                                        <li>
                                                            <Link to="/BtechBioTech">
                                                                B.Tech Biotechnology
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BtechChemicalEng">
                                                                B.Tech Chemical Engineering
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BtechCivilEng">
                                                                B. Tech. Civil Engineering
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BtechCSE">
                                                                B. Tech. Computer Science and
                                                                Engineering(CSE)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BtechEE">
                                                                B. Tech. Electrical Engineering(EE)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BtechECE">
                                                                B. Tech. Electronics & Communication
                                                                Engineering(ECE)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BtechIT">
                                                                B. Tech. Information Technology
                                                            </Link>
                                                        </li>
                                                        <li><Link to="/BtechMechEng">B. Tech. Mechanical
                                                            Engineering</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BbaMarketing">
                                                                BBA in Marketing
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Bca">
                                                                BCA (Bachelor Degree in Computer
                                                                Applications)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BscAgriculture">
                                                                B.Sc. Agriculture
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/BscNonMed">
                                                                B.Sc. (NON-MEDICAL)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Bvoc">
                                                                B. Voc. (Refrigeration and Air
                                                                Conditioning)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/MscPhysicsHons">
                                                                5year integrated MSc-Physics
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Post Graduate + PolyTechnical Diploma */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="row two-columns">
                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>Post Graduate</p>
                                                    {/*<hr/>*/}
                                                    <ul className="menu-item">

                                                        <li>
                                                            <Link to="/MbaDigitalMarketing">
                                                                MBA in Digital Marketing
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Mca">
                                                                MCA (Master of Computer Applications)
                                                            </Link>
                                                        </li>
                                                        {/*<li><Link*/}
                                                        {/*    to="/MtechCSE">Mtech(CSE)</Link>*/}
                                                        {/*</li>*/}
                                                        <li>
                                                            <Link to="/MscChemistry">
                                                                M.Sc. Chemistry
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/MscMath">
                                                                M.Sc. Mathematics
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/MscPhysics">
                                                                M.Sc. Physics
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/MtechCSE">
                                                                M.Tech. Computer Science and
                                                                Engineering
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/MtechME">
                                                                M.Tech. Mechanical Engineering
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div
                                                    className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>PolyTechnical Diploma</p>
                                                    {/*<hr/>*/}
                                                    <ul className="menu-item">
                                                        <li><Link to="/underconstructionpage">Diploma in
                                                            Computer Science and Engineering(CSE)</Link>
                                                        </li>
                                                        <li><Link to="/underconstructionpage">Diploma in
                                                            Electronics & Communication Engineering(ECE)</Link>
                                                        </li>
                                                        <li><Link to="/underconstructionpage">Diploma in
                                                            Mechanical Engineering</Link>
                                                        </li>
                                                        {/*<li><Link to="/underconstructionpage">Diploma in*/}
                                                        {/*    Production</Link>*/}
                                                        {/*</li>*/}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Ph.D Programs */}
                                        <div className="col-md-4 col-sm-4 col-xs-12">
                                            <div className="row two-columns">
                                                <div className="col-md-12 col-sm-12 col-xs-12 innermenu">
                                                    <p>PHD Programs</p>
                                                    {/*<hr/>*/}
                                                    <ul className="menu-item">
                                                        <li><Link
                                                            to="/PhdCSE">Ph.D (Computer
                                                            Science &amp; Engineering)</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/PhdECE">
                                                                Ph.D (Electronics &amp; Communication
                                                                Engineering)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/PhdME">
                                                                Ph.D. (Mechanical Engineering)
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/Phd-Chemical-Engineering-Bio-Technology">
                                                                Ph.D. (Chemical Engineering)
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>

                                                {/*<div className="col-md-12 col-sm-12 col-xs-12">*/}
                                                {/*    <Link className="text-uppercase admLinks"*/}
                                                {/*          to='/admissionpage'>*/}
                                                {/*        Admissions 2023-24*/}
                                                {/*    </Link>*/}
                                                {/*</div>*/}

                                                <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <a className="admLinks"
                                                       href='./assets/pdf/admissioins/Shortlisted-PhD-Aug-2022.pdf'
                                                       target="_blank">
                                                        <span style={{color: '#fff'}}>SHORTLISTED CANDIDATES FOR Ph.D.</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>

                    {/* Training & placements */}
                    <li className="dropdown normal-menu has-submenu">
                        <Link to="#" className="dropdown-toggle" data-toggle="dropdown" role="button"
                              aria-haspopup="true" aria-expanded="false">Training & placements <span
                            className="fa fa-angle-down"></span></Link>
                        <ul className="dropdown-menu">
                            <li><Link to="/industrial-training">Industrial Training</Link></li>
                            <li><Link to="/placements">Placements</Link></li>
                            {/*<li><Link to="/Student-Admission">Student-Admission</Link></li>*/}
                        </ul>
                    </li>

                    <li>
                        <Link className="normal-menu" title="Alumni Affairs" to="/alumniaffairs">
                            Alumni Affairs
                        </Link>
                    </li>

                    <li>
                        <Link className="normal-menu" title="Incubation Centre" to="/incubation-centre">
                            Incubation Centre
                        </Link>
                    </li>

                    <li>
                        <a href="/#/polytechnicalwing" target="_blank" className="normal-menu" title="Polytechnical Wing">
                            Polytechnical Wing
                        </a>
                    </li>

                    {/*<li className="dropdown normal-menu has-submenu">*/}
                    {/*    <Link to="#" className="dropdown-toggle" data-toggle="dropdown" role="button"*/}
                    {/*          aria-haspopup="true" aria-expanded="false">TEQIP-II <span*/}
                    {/*        className="fa fa-angle-down"></span></Link>*/}
                    {/*    <ul className="dropdown-menu">*/}
                    {/*        <li><Link to="/underconstructionpage">About TEQIP-II</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpage">Mentoring on TEQIP</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpage">TEQIP-II Cell</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpage">Tenders and Procurement</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpage">Academic Activities</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpagel">Financial Management</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpage">Important Letters</Link></li>*/}
                    {/*        <li><Link to="/underconstructionpage">TEQIP Forms</Link></li>*/}
                    {/*    </ul>*/}
                    {/*</li>*/}

                    {/*<li><Link title="" to="forum.html">Admissions</Link></li>*/}
                </ul>

                {/* Search Icon [Hide] */}
                <ul className="nav navbar-nav navbar-right">
                    <li className="dropdown cartmenu searchmenu hasmenu">
                        {/*<Link to="#" className="dropdown-toggle" data-toggle="dropdown" role="button"*/}
                        {/*      aria-haspopup="true" aria-expanded="false">*/}
                        {/*<i className="fa fa-search"></i>*/}
                        {/*</Link>*/}

                        {/*<ul className="dropdown-menu start-right">*/}
                        {/*    <li>*/}
                        {/*        <div id="custom-search-input">*/}
                        {/*            <div className="input-group">*/}
                        {/*                <input type="text" className="form-control input-lg"*/}
                        {/*                       placeholder="Search here..."/>*/}
                        {/*                <span className="input-group-btn">*/}
                        {/*                                <button className="btn btn-primary btn-lg" type="button">*/}
                        {/*                                    <i className="fa fa-search"></i>*/}
                        {/*                                </button>*/}
                        {/*                            </span>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                    </li>
                </ul>
                {/*end dropdown*/}
            </div>
        </>
    );
}

export default RightMenuLinks;